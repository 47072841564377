import React, { memo, useEffect, useState, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { selectMyGroups } from '../selectors';
import { Feather } from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import GroupPicture from './GroupPicture';
import Avatar from './Avatar';
import MenuUser from './MenuUser';
import Button from './Button';
import ItemMultiColumn from './ItemMultiColumn';
import { GRAY_LINE_COLOR, MAIN_COLOR, SECONDARY_COLOR } from '../constants';
import { cancelGroupRequestApi } from '../actions/cancelGroupRequest';
import { acceptGroupRequestApi } from '../actions/acceptGroupRequest';
import { refuseGroupRequestApi } from '../actions/refuseGroupRequest';
import { confirmAcceptGroupRequest, confirmRefuseGroupRequest } from '../utils/alerts';
import { navigateToUser, navigateToGroup } from '../utils';
import { useGroup, useUser, useUnreadNotifs } from '../hooks';

const tBase = 'group.request';

export default function GroupRequestItem(props) {
    const {t, l} = useContext(AppSettingsContext);
    const {
        groupRequest, 
        navigation
    } = props;
    const [isUnread, setIsUnread] = useState(false);
    const { groupId, id:groupRequestId, created, fromId } = groupRequest;
    const dispatch = useDispatch();
    const myGroups = useSelector(selectMyGroups);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const {unreadGroupRequests} = useUnreadNotifs(); 
    const group = useGroup(groupId);
    const user = useUser(groupRequest.fromId);
    const handleAcceptRequest = () => { 
        confirmAcceptGroupRequest(
            () => { dispatch(acceptGroupRequestApi({ groupRequestId, groupId })) }, dispatch,
            { nickname: user.nickname, name: group.name }
        )
    }
    const handleRefuseGroupRequest = () => {
        confirmRefuseGroupRequest(
            () => dispatch(refuseGroupRequestApi({ groupRequestId, groupId })), dispatch,
            { nickname: user.nickname, name: group.name }
        )
    }
    const handleCancelRequest = () => { dispatch(cancelGroupRequestApi({ groupRequestId, groupId })) };
    const requestByMe = fromId == loggedUserId;
    const myAdminGroupIds = myGroups.filter(group => group.adminIds.includes(loggedUserId)).map(({id}) => id);
    const requestToMe = myAdminGroupIds.includes(groupId);
    const showCancelRequest = requestByMe;
    const showAcceptRefuse = requestToMe && !requestByMe;

    useEffect(() => {
        if(unreadGroupRequests.includes(groupRequest.id)) {
            setIsUnread(true);
        }
    }, [unreadGroupRequests]);

    return (
        <ItemMultiColumn {...props} itemIsUnread={isUnread}>
            { requestToMe ?
                <UserInfo navigation={navigation} user={user} group={group} /> :
                <GroupInfo navigation={navigation} group={group} />
            }
            <View style={styles.actionContainer}>
                { showCancelRequest &&
                    <Text style={styles.actionInfo}>
                        <InterReg text={ t(`${tBase}.youRequestedToJoin`)} />
                        <InterReg style={{ color: '#333' }} text={t(`${tBase}.onThe`)} />
                        <MontSBold style={{ color: MAIN_COLOR }} text={ l(`date.formats.short`, created) } />
                    </Text>
                }
                { showAcceptRefuse &&
                    <Text style={styles.actionInfo}>
                        <InterReg text={ t(`${tBase}.userRequestedToJoin`)} />
                        <MontSBold style={{ color: SECONDARY_COLOR }} text={ group.name + ' ' } />
                        <InterReg style={{ color: '#333' }} text={t(`${tBase}.onThe`)} />
                        <MontSBold style={{ color: MAIN_COLOR }} text={ l(`date.formats.short`, created) } />
                    </Text>
                }
                { showCancelRequest &&
                    <View style={{ flexDirection: 'row' }}>
                        <Button 
                            size='xSmall'
                            theme='grayColorBorder'
                            onPress={handleCancelRequest}
                            buttonStyle={styles.buttons} 
                            text={ t(`${tBase}.cancel`)} />
                    </View>
                }
                { showAcceptRefuse &&
                    <View style={{ flexDirection: 'row' }}>
                        <Button 
                            size='xSmall'
                            onPress={handleAcceptRequest}
                            buttonStyle={[styles.buttons, { marginRight: 8 }]} 
                            text={ t(`${tBase}.accept`)} />
                        <Button 
                            size='xSmall'
                            theme='grayColorBorder'
                            onPress={handleRefuseGroupRequest}
                            buttonStyle={styles.buttons} 
                            text={ t(`${tBase}.refuse`)} />
                    </View>
                }
            </View>
        </ItemMultiColumn>
    )
}

const GroupInfo = memo(({ navigation, group }) => {
    const handleGroupPress = () => { navigateToGroup(navigation, group) };
    return (
        <TouchableOpacity onPress={handleGroupPress} style={{ flexDirection: 'row'}}>
            <GroupPicture picture={group.picture} blurHash={ group.blurHash } isPremium={group?.echelon?.includes('premium')} size={58} />
            <View style={{ flex: 1, marginLeft: 10 }}>
                <MontSBold style={styles.name} numberOfLines={1} text={ group.name } />
                <InterReg style={styles.description} numberOfLines={2} text={ group.description } />
            </View>
        </TouchableOpacity>
    )
})

const UserInfo = memo(({ navigation, user, group }) => {
    const handleUserPress = () => { navigateToUser(navigation, { userId: user.id }) };
    const renderMenuTrigger = () => (
        <View style={styles.menu} pointerEvents='none'>
            <Feather name="more-horizontal" size={20} color="#7f7f7f" />
        </View>
    )
    return (
        <TouchableOpacity onPress={handleUserPress} style={{ flexDirection: 'row'}}>
            <Avatar avatar={user.avatar} blurHash={user.blurHash} avatarSize={55} />
            <View style={{ flex: 1, marginLeft: 10, paddingRight: 34 }}>
                <MontSBold style={styles.name} numberOfLines={1} text={ user.nickname } />
                <InterReg style={styles.description} numberOfLines={2} text={ user.description } />
            </View>
            <MenuUser
                user={user}
                group={group}
                navigation={navigation}
                style={{ position: 'absolute', right: 0 }}
                TriggerButton={ renderMenuTrigger } />
        </TouchableOpacity>
    )
})

const styles = StyleSheet.create({
    name: {
        color: SECONDARY_COLOR, 
        marginTop: -2, 
        lineHeight: 16, 
        marginBottom: 3
    },
    description: {
        color: '#666'
    },
    actionContainer: {
        flexDirection: 'row', 
        marginTop: 10, 
        paddingTop: 10, 
        borderTopWidth: StyleSheet.hairlineWidth, 
        borderTopColor: GRAY_LINE_COLOR
    },
    actionInfo: {
        flex: 1, 
        paddingRight: 15, 
        marginTop: -2
    },
    buttons: {
        paddingHorizontal: 10, 
        marginBottom: 0
    },
    menu: { 
        width: 24, 
        height: 24, 
        borderRadius: 12, 
        backgroundColor: '#e2e2e2', 
        alignItems: 'center', 
        justifyContent: 'center' 
    }
})