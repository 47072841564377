import { GET_LENDS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getLendsInProgress = createAction('GET_LENDS_IN_PROGRESS');
export const getLendsSuccess = createAction('GET_LENDS_SUCCESS');
export const getLendsFailure = createAction('GET_LENDS_FAILURE');

export function getLendsApi({ showLoading=true, checkCaching=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getLendsInProgress, getLendsSuccess, getLendsFailure],
            name: 'getLends',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}