import { REQUEST_FOLLOW_USER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const requestFollowUserInProgress = createAction('REQUEST_FOLLOW_USER_IN_PROGRESS');
export const requestFollowUserSuccess = createAction('REQUEST_FOLLOW_USER_SUCCESS');
export const requestFollowUserFailure = createAction('REQUEST_FOLLOW_USER_FAILURE');

export function requestFollowUserApi({ userId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId },
            actions: [requestFollowUserInProgress, requestFollowUserSuccess, requestFollowUserFailure],
            name: 'requestFollowUser',
            dispatch,
            getState
        })
    }
}