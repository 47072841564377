import React, { useMemo, useCallback, useEffect, useContext, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { selectBorrowing, selectBorrowed } from '../../selectors';
import { getLendsApi, getLendsInProgress } from '../../actions/getLends';
import { updateUnreadNotifsApi } from '../../actions/updateUnreadNotifs';
import { useIsLoading, useUnreadNotifs } from '../../hooks';
import LendsList from '../../components/LendsList';
import Empty from '../../components/Empty';
import HorizontalFilters from '../../components/HorizontalFilters';
import { sortByDate, handleUnreadNotifsDone } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.borrowing';

const filters = [
    { key: 'current', label: 'filter.current' },
    { key: 'finished', label: 'filter.finished' }
]

export default function BorrowingScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('current');
    const [refreshing, setRefreshing] = useState(false);
    const borrowing = useSelector(selectBorrowing);
    const borrowed = useSelector(selectBorrowed);
    const isGettingLends = useIsLoading(getLendsInProgress);
    const { unreadLendReturnRequests, unreadLends } = useUnreadNotifs();
    const filteredBorrows = useMemo(() => {
        if(filter == 'finished') return borrowed;
        return borrowing;
    }, [borrowing, borrowed, filter]);
    const data = useMemo(() => sortByDate(filteredBorrows, 'created'), [filteredBorrows])
    const renderEmpty = useMemo(() => (
        <Empty 
            title={t(`${tBase}.empty.${filter}.title`)} 
            body={t(`${tBase}.empty.${filter}.body`)}
            img={ require('../../assets/noBorrowing.png') }
            primaryActionLabel={ filter === 'current' ? t(`${tBase}.empty.${filter}.searchBook`) : ''}
            primaryActionOnPress={ filter === 'current' ? () => navigation.navigate('Modal', { screen: 'Search' }) : null } />      
    ), [])

    const getLends = (checkCaching=true, showRefreshing=false, callback=() => {}) => {
        if(!isGettingLends) {
            showRefreshing && setRefreshing(true);
            dispatch(getLendsApi({ showLoading: false, checkCaching })).then(() => {
                setRefreshing(false);
                callback();
            });
        }
    }

    const renderHeader = useMemo(() => (
        <View style={{ paddingVertical: 15, paddingRight: 15, justifyContent: 'center', backgroundColor: '#fff' }}>
            <HorizontalFilters 
                tBase={tBase}
                filters={filters} 
                selected={filter} 
                onSelect={setFilter} />
        </View> 
        
    ), [filter]);

    const onRefresh = useCallback(() => getLends(false, true), []);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', handleOnFocus);
        return unsubscribe;
    }, [unreadLendReturnRequests, unreadLends]);

    const handleOnFocus = () => {
        const shouldCheckCache = (() => {
            const borrowingIds = borrowing.map(({ id }) => id);
            if(unreadLends.length > borrowingIds.length) {
                return false;
            } 
            if(unreadLends.length) {
                for(let unreadLendId of unreadLends) {
                    if(!borrowingIds.includes(unreadLendId)) return false;
                }
            }
            return true;
        })()
        const updateUnreadNotifs = () => {
            if(unreadLends.length || unreadLendReturnRequests.length) {
                const notifsRead = {};
                if(unreadLends.length) notifsRead.lends = unreadLends;
                if(unreadLendReturnRequests.length) notifsRead.lendReturnRequests = unreadLendReturnRequests;
                dispatch(updateUnreadNotifsApi({ notifsRead })).then(handleUnreadNotifsDone);
            }
        }
        getLends(shouldCheckCache, false, updateUnreadNotifs);
    }
    const showHeader = borrowing.length || borrowed.length;
    return (
        <LendsList 
            data={data}
            navigation={navigation}
            useStickyHeader={true}
            contentContainerStyle={showHeader ? { paddingTop: 0 } : {}}
            listHeaderStyle={showHeader ? { paddingBottom: 10 } : {} }
            onRefresh={onRefresh}
            refreshing={refreshing}
            renderHeader={showHeader ? renderHeader : undefined}
            renderEmpty={renderEmpty}
        />
    )
}