import React, { memo, useState, useCallback, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {  goBackFromUser, getInitialScreenWidth, isWeb, getShortUrl } from '../utils';
import { followUserApi } from '../actions/followUser';
import { MAIN_COLOR, USER_HEADER_HEIGHT_MIN, HEADER_ACTIONS_HEIGHT, HEADER_ACTIONS_PADDING_HOR, HEADER_ACTIONS_PADDING_HOR_COMPACT } from '../constants';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import Avatar from './Avatar';
import Button from './Button';
import Location from './Location';
import MenuUser from './MenuUser';
import RoundButton from './RoundButton';
import IsStatus from './IsStatus';
import UserHeaderBg from './UserHeaderBg';
import ShareButton from './ShareButton';
import HeaderActionsBar from './HeaderActionsBar';
import { promptLoginOrSignup } from '../utils/alerts';
import Animated, { useAnimatedStyle, interpolate, Extrapolate } from 'react-native-reanimated';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.user';
const HEADER_BTN_GAP = 6;

function UserHeader({ scrollY, user, navigation, onHeaderHeightChange, headerHeight, bookCount, insetTop, headerInputMaxRange, canViewProfile, isYou, isFollowing }) {
    const {t} = useContext(AppSettingsContext);
    const loggedUserId = useSelector(state => state.loggedUserId);
    const mainViewWidth = getInitialScreenWidth(navigation);
    const isSmallScreen = mainViewWidth < 500;
    const dispatch = useDispatch();
    const [followButtonWidth, setFollowButtonWidth] = useState(0);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const [nameLineNumber, setNameLineNumber] = useState(2);
    const followingCount = user.followingIds.length;
    const followersCount = user.followerIds.length;
    const showStatus = isFollowing || isYou;
    const showFollowBtn = !showStatus && canViewProfile;
    const showOptionsMenu = isFollowing || isYou;
    const handleFollowUser = () => { dispatch(followUserApi({ userId: user.id })) };
    const handleFollowersPress = () => handleFollowingFollowersPress('Followers');
    const handleFollowingPress = () => handleFollowingFollowersPress('Following');
    const handleFollowingFollowersPress = (route) => { 
        if(loggedUserId === null) {
            promptLoginOrSignup(dispatch);
        } else {
            navigation.push(route, { userId: user.id }); 
        }
    }
    const renderMenuTrigger = () => <RoundButton type='options' size={ HEADER_ACTIONS_HEIGHT } />;
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const handleBackNavigation = useCallback(() => goBackFromUser(navigation, activeBottomTab), [activeBottomTab]);
    const onLayoutContainer = ({ nativeEvent: { layout: { height }}}) => (!headerHeight.value || !descriptionHeight) && onHeaderHeightChange(height)
    const onLayoutStatus = ({width}) => setFollowButtonWidth(width);
    const onLayoutDescription = ({nativeEvent: { layout: {height}}}) => !descriptionHeight && setDescriptionHeight(height);
    const actionHeaderHorPadding = mainViewWidth > 450 ? HEADER_ACTIONS_PADDING_HOR : HEADER_ACTIONS_PADDING_HOR_COMPACT;
    const url = getShortUrl('user', user.shortId);
    const fullStyles = {
        container: {
            paddingBottom: isSmallScreen ? 10 : 0
        },
        userInfo: {
            paddingTop: HEADER_ACTIONS_HEIGHT + 15,
            paddingBottom: isSmallScreen ? 10 : 15,
            paddingLeft: 20,
            paddingRight: 0,
        },
        stats: {
            height: isSmallScreen ? 35 : 25
        }
    }
    const compactStyles = {
        container: {
            paddingBottom: 0
        },
        userInfo: {
            paddingTop: 0,
            paddingLeft: HEADER_ACTIONS_HEIGHT + actionHeaderHorPadding + HEADER_BTN_GAP,
            paddingRight: followButtonWidth + HEADER_BTN_GAP + HEADER_ACTIONS_HEIGHT + (showOptionsMenu ? HEADER_BTN_GAP + HEADER_ACTIONS_HEIGHT : 0) + actionHeaderHorPadding,
            paddingBottom: 0
        }
    }
    const renderStats = () => (
        <Animated.View style={[
            isSmallScreen && { alignSelf: 'center', width: '100%' },
            styles.stats, 
            animStyles.stats]}>
            <View style={[ styles.stat, isSmallScreen && styles.statSmallScreen ]}>
                <MontSBold style={styles.countNbr} text={ bookCount } />
                <InterReg style={styles.countLabel} text={ t('general.books', { count: bookCount}) } />
            </View>
            <TouchableOpacity onPress={handleFollowersPress} style={[ styles.stat, isSmallScreen && styles.statSmallScreen ]}>
                <MontSBold style={styles.countNbr} text={ followersCount } />
                <InterReg style={styles.countLabel} text={ t('general.followers') } />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleFollowingPress}  style={[ styles.stat, { borderRightWidth: 0 }, isSmallScreen && styles.statSmallScreen ]}>
                <MontSBold style={styles.countNbr} text={ followingCount } />
                <InterReg style={styles.countLabel} text={ t('general.following') } />
            </TouchableOpacity>
        </Animated.View>
    )
    const animStyles = {
        container: useAnimatedStyle(() => ({
            height: !headerHeight.value ? 'auto' : interpolate(scrollY.value, [0, headerInputMaxRange], [headerHeight.value, USER_HEADER_HEIGHT_MIN+insetTop], Extrapolate.CLAMP),
            paddingBottom: interpolate(scrollY.value, [0, headerInputMaxRange], [fullStyles.container.paddingBottom, compactStyles.container.paddingBottom], Extrapolate.CLAMP)
        })),
        avatarWrapper: useAnimatedStyle(() => {
            const size = interpolate(scrollY.value, [0, headerInputMaxRange], [95, 34], Extrapolate.CLAMP);
            return {
                height: size,
                width: size,
                borderWidth: interpolate(scrollY.value, [0, headerInputMaxRange], [5, 0], Extrapolate.CLAMP)
            }
        }),  
        avatar: useAnimatedStyle(() => ({
            transform: [
                { translateY: interpolate(scrollY.value, [0, headerInputMaxRange], [0, -25], Extrapolate.CLAMP) },
                { scale: interpolate(scrollY.value, [0, headerInputMaxRange], [1, 0.4], Extrapolate.CLAMP) },
            ],
        })),
        stats: useAnimatedStyle(() => ({
            opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0], Extrapolate.CLAMP),
            height: interpolate(scrollY.value, [0, headerInputMaxRange], [fullStyles.stats.height, 0], Extrapolate.CLAMP)
        })),
        location: useAnimatedStyle(() => ({
            opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0], Extrapolate.CLAMP),
            height: interpolate(scrollY.value, [0, headerInputMaxRange], [17, 0], Extrapolate.CLAMP),
        })),
        description: useAnimatedStyle(() => ({
            opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0], Extrapolate.CLAMP),
            height: !descriptionHeight ? 'auto' : interpolate(scrollY.value, [0, headerInputMaxRange], [descriptionHeight, 0], Extrapolate.CLAMP),
            paddingVertical: interpolate(scrollY.value, [0, headerInputMaxRange], [12, 0], Extrapolate.CLAMP)
        })),
        nickname: useAnimatedStyle(() => ({
            marginLeft: interpolate(scrollY.value, [0, headerInputMaxRange], [0, -5], Extrapolate.CLAMP),
            marginTop: interpolate(scrollY.value, [0, headerInputMaxRange], [0, 5], Extrapolate.CLAMP)
        })),
        userInfo: useAnimatedStyle(() => ({
            paddingTop: interpolate(scrollY.value, [0, headerInputMaxRange], [fullStyles.userInfo.paddingTop, compactStyles.userInfo.paddingTop], Extrapolate.CLAMP),
            paddingLeft: interpolate(scrollY.value, [0, headerInputMaxRange], [fullStyles.userInfo.paddingLeft, compactStyles.userInfo.paddingLeft], Extrapolate.CLAMP),
            paddingRight: interpolate(scrollY.value, [0, headerInputMaxRange/6, headerInputMaxRange], [fullStyles.userInfo.paddingRight, compactStyles.userInfo.paddingRight/2, compactStyles.userInfo.paddingRight], Extrapolate.CLAMP),
            paddingBottom: interpolate(scrollY.value, [0, headerInputMaxRange/6, headerInputMaxRange], [fullStyles.userInfo.paddingBottom, fullStyles.userInfo.paddingBottom/2, compactStyles.userInfo.paddingLeft], Extrapolate.CLAMP)
        }))
    }
    const onTextLayout = () => {
        if(scrollY.value >= headerInputMaxRange && nameLineNumber == 2) {
            setNameLineNumber(1) 
        } else if(scrollY.value < headerInputMaxRange && nameLineNumber == 1) {
            setNameLineNumber(2) 
        }
    }
    return (
        <Animated.View 
            onLayout={onLayoutContainer}
            style={[ styles.container, { paddingTop: insetTop, backgroundColor: user.avatar ? '#fff' : MAIN_COLOR }, animStyles.container]}>
            <UserHeaderBg
                blurhash={user.blurHash}
                height={headerHeight.value}
                width={getInitialScreenWidth(navigation)} />
            <HeaderActionsBar top={ insetTop } mainViewWidth={ mainViewWidth } onPressBack={ handleBackNavigation }>
                { showStatus &&
                <IsStatus 
                    theme='mainColor'
                    height={ HEADER_ACTIONS_HEIGHT }
                    onLayout={ onLayoutStatus }
                    style={[styles.following, { marginRight: showOptionsMenu ? HEADER_BTN_GAP : 0 }]} 
                    text={ t(isYou ? 'general.yourself' : 'general.areFollowing') } />
                }
                { showFollowBtn &&
                <Button 
                    buttonStyle={[styles.follow, { marginRight: showOptionsMenu ? 0 : HEADER_BTN_GAP }]} 
                    size='xSmall'
                    requiresAccount={ true }
                    onLayout={ onLayoutStatus }
                    onPress={ handleFollowUser }
                    text={ t('general.follow') } /> 
                }
                <ShareButton 
                    type='user'
                    url={ url }
                    name={ user.nickname }
                    isOwner={ isYou }
                    buttonType='round'
                 />
                { showOptionsMenu &&
                <MenuUser
                    user={user}
                    placement='bottom'
                    navigation={navigation}
                    style={{ marginLeft: HEADER_BTN_GAP, width: HEADER_ACTIONS_HEIGHT, height: HEADER_ACTIONS_HEIGHT }}
                    TriggerButton={ renderMenuTrigger } />
                }
            </HeaderActionsBar>
            <Animated.View pointerEvents='box-none' style={[styles.userInfo, animStyles.userInfo]}>
                <Animated.View style={[ { borderRadius: 99, borderWidth: 5, borderColor: 'rgba(255,255,255, 0.3)' }, animStyles.avatarWrapper]}>
                    <Animated.View style={animStyles.avatar}>
                        <Avatar avatarStyle={{ alignSelf: 'center' }} avatarSize={85} blurHash={user.blurHash} avatar={ user.avatar } />
                    </Animated.View>
                </Animated.View>
                <View style={{ flex: 1 }}>
                    <View style={{ paddingLeft: 15 }}>
                        <Animated.View style={animStyles.nickname}>
                            <MontSBold 
                                onTextLayout={onTextLayout} 
                                style={styles.nickname} 
                                numberOfLines={nameLineNumber} 
                                text={ user.nickname } />
                        </Animated.View>
                        <Animated.View style={animStyles.location}>
                            <Location 
                                iconColor='rgba(0,0,0,0.45)' 
                                location={user.location} />
                        </Animated.View>
                        <Animated.View style={[styles.description, animStyles.description]} onLayout={onLayoutDescription}>
                            <InterReg 
                                style={!user.description && { color: 'rgba(0,0,0,0.4)', fontStyle: 'italic' }} 
                                text={ user.description || t('user.description.none') } />
                        </Animated.View>
                    </View>
                    { !isSmallScreen && renderStats() }
                </View>
            </Animated.View>
            { isSmallScreen && renderStats() }
        </Animated.View>
    )
}

export default memo(UserHeader);

const styles = StyleSheet.create({
    container: {
        width: '100%', 
        overflow: 'hidden',
        zIndex: 1,
        position: isWeb() ? 'relative' : 'absolute',
        top: 0,
        left: 0
    },
    userInfo: {
        minHeight: HEADER_ACTIONS_HEIGHT,
        flexDirection: 'row'
    },
    nickname: {
        fontSize: 16,
        marginTop: 3,
        marginBottom: 3,
        borderLeftWidth: 0,
        color: 'rgba(0,0,0,0.7)'
    },
    description: {
        color: 'rgba(0,0,0,0.7)',
        paddingRight: 15
    },
    stats: {
        flexDirection: 'row', 
        marginBottom: 5,
        maxWidth: 400,
        paddingLeft: 15
    },
    stat: {
        borderRightWidth: StyleSheet.hairlineWidth, 
        borderRightColor: 'rgba(0,0,0,0.3)', 
        alignItems: 'center', 
        flexDirection: 'row',
        marginRight: 20,
        paddingRight: 20
    },
    statSmallScreen: {
        flexDirection: 'column',
        flex: 1,
        marginRight: 0,
        paddingRight: 0
    },
    countNbr: {
        color: 'rgba(0,0,0,0.7)',
        fontSize: 14,
        marginRight: 5
    },
    countLabel: {
        fontSize: 12,
        textTransform: 'uppercase',
        color: 'rgba(0,0,0,0.5)'
    }, 
    following: {
        paddingLeft: 8,
        paddingRight: 12
    },
    follow: {
        height: HEADER_ACTIONS_HEIGHT,
        borderRadius: 17,
        alignItems: 'center', 
        justifyContent: 'center',
        paddingHorizontal: 15,
        marginRight: HEADER_BTN_GAP

    }
    
})