import React, { memo } from 'react'
import MontSBold  from './MontserratBold';
import { MAIN_COLOR  } from '../constants';
import Animated, { BounceIn, BounceOut } from 'react-native-reanimated';

const Badge = ({count, style, color='#fff', size=18, top=0, left=0 }) => (
    <Animated.View 
        entering={BounceIn} 
        exiting={BounceOut}
        style={[{
            position: 'absolute',
            top,
            left,
            borderRadius: 10,
            minWidth: size,
            paddingHorizontal: 3,
            height: size,
            backgroundColor: MAIN_COLOR,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2}, style]}
        pointerEvents='box-none'>
        <MontSBold
            text={count > 9 ? '9+' : count} 
            style={{ color, fontSize: 12}}  />
    </Animated.View>
)

export default memo(Badge)