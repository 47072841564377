import React, { memo, useEffect, useContext } from 'react';
import { Pressable } from 'react-native';
import InterReg from './InterReg';
import Avatar from './Avatar';
import { useSelector, shallowEqual } from 'react-redux';
import { navigateToUser } from '../utils';
import { useDiscussion, useUser } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.discussion';

function DiscussionHeader(props) {
    const {t} = useContext(AppSettingsContext);
    const { navigation, tintColor, route, style: { fontSize } } = props;
    const discussionId = route.params.discussionId;
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const discussion = useDiscussion(discussionId);
    const userId = discussion?.userIds?.filter(userId => userId != loggedUserId)[0];
    const correspondent = useUser(userId);
    const handlePress = () => userId && navigateToUser(navigation, { userId });
    useEffect(() => {
        navigation.setOptions({ 
            title: t(`${tBase}.title`, { nickname: correspondent?.nickname ?? t(`${tBase}.unknown`) }) 
        })
    }, []);
    return (
        <Pressable onPress={handlePress} style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Avatar avatar={ correspondent ? correspondent.avatar : '' } blurHash={correspondent?.blurHash}  avatarStyle={{ marginRight: 8 }} avatarSize={22} /> 
            <InterReg style={{ color: tintColor, fontSize }} text={correspondent ? correspondent.nickname : 'User deleted'} />
        </Pressable>
    )
}

export default memo(DiscussionHeader)