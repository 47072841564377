import React, { useEffect, useContext } from 'react';
import { View } from 'react-native';
import AppHeader from '../navigation/AppHeader';
import UsersList from '../components/UsersList';
import { useGroup } from '../hooks';
import { isInModal } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';

export default function GroupMembersScreen({ navigation, route }) {
    const {t} = useContext(AppSettingsContext);
    const { groupId } = route.params;
    const inModal = isInModal(navigation);
    const group = useGroup(groupId);
    const title = t(`screens.groupMembers.title`);
    useEffect(() => {
        if(inModal) navigation.setOptions({ title })
    })
    if(!group) return <View />
    return (
        <>
            { !inModal &&
            <AppHeader 
                title={title} 
                navigation={ navigation } />
            }
            <UsersList 
                navigation={navigation}
                context='groupMembers'
                route={route}
                userIds={group?.memberIds} />
        </>
    )
}