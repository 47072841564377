import { createReducer } from '@reduxjs/toolkit'
import { matchResetStore, hasUnreadNotifs } from './matchers';

const initialState = {};

export const unreadNotifs = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasUnreadNotifs, (state, action) => {
        return action.payload.unreadNotifs;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})