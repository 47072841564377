import React, { useContext } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AppSettingsContext from '../context/AppSettingsContext';
import { MAIN_COLOR } from '../constants';
import Button from '../components/Button';
import * as SplashScreen from 'expo-splash-screen';


export default function WelcomeScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const insets = useSafeAreaInsets();
    return (
        <View 
            style={[ styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom } ]} 
            onLayout={ async() => await SplashScreen.hideAsync() }>
            <View style={{ maxWidth: 400, minWidth: 300, width: '100%', marginBottom: 50 }}>
                    <Image 
                        source={ require('../assets/myblio-vert-logo-white.png') } 
                        resizeMode='contain' 
                        style={{ alignSelf: 'center', height: 200, maxWidth: 230, width: '80%', marginBottom: 50 }} 
                    />
                <Button 
                    text={ t('screens.welcome.gotoLoginScreen') } 
                    theme='secondaryColor'
                    buttonStyle={{ alignSelf: 'stretch' }} 
                    onPress={ () => navigation.navigate('SignIn')} />
                <Button 
                    text={ t('screens.welcome.gotoSignupScreen') } 
                    theme='whiteColorBorder'
                    onPress={ () => navigation.navigate('SignUp')} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        paddingHorizontal: 20,
        backgroundColor: MAIN_COLOR, 
        alignItems: 'center', 
        justifyContent: 'center'
    }
})
