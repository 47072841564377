import { createReducer } from '@reduxjs/toolkit'
import { loginOrSignup, matchResetStore } from './matchers';

const initialState = [];

export const tags = createReducer(initialState, (builder) => {
    builder
    .addMatcher(loginOrSignup, (_, action) => {
        return action.payload.tags;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})