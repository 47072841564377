import React, { memo, useContext } from 'react';
import { StyleSheet } from 'react-native';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import ActivityDetail from './ActivityDetail';
import AppSettingsContext from '../context/AppSettingsContext';
import { SECONDARY_COLOR, GRAY_TEXT_PALE } from '../constants';
import { getTimeSinceNow, navigateToUser } from '../utils';
import { useUser } from '../hooks';

const tBase = 'screens.activity';

function ActivityReadingStatusSetToReading({navigation, activityItem, isLast }) {
    const {t, locale, timeAgoInWords} = useContext(AppSettingsContext);
    const user = useUser(activityItem.userId);
    const handleUserPress = () => { navigateToUser(navigation, { userId: activityItem.userId }) };
    return (
        <ActivityDetail activityItem={activityItem} navigation={navigation} isLast={isLast}>
            <MontSBold onPress={handleUserPress} style={styles.nickname} text={user.nickname} />{' '} 
            <InterReg text={ t(`${tBase}.startedReading`) } />{'  '}
            <InterReg style={styles.date} text={getTimeSinceNow(activityItem.latest, locale, timeAgoInWords)} />
        </ActivityDetail>
    )
}

const styles = StyleSheet.create({
    nickname: {
        color: SECONDARY_COLOR
    },
    date: {
        color: GRAY_TEXT_PALE, 
        fontSize: 13
    }
})

export default memo(ActivityReadingStatusSetToReading);