import { REMOVE_BOOK_FROM_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const removeBookFromGroupInProgress = createAction('REMOVE_BOOK_FROM_GROUP_IN_PROGRESS');
export const removeBookFromGroupSuccess = createAction('REMOVE_BOOK_FROM_GROUP_SUCCESS');
export const removeBookFromGroupFailure = createAction('REMOVE_BOOK_FROM_GROUP_FAILURE');

export function removeBookFromGroupApi({ bookId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, groupId },
            actions: [removeBookFromGroupInProgress, removeBookFromGroupSuccess, removeBookFromGroupFailure],
            name: 'removeBookFromGroup',
            dispatch,
            getState
        })
    }
}