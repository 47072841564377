import { REMOVE_BOOK_FROM_LIST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const removeBookFromListInProgress = createAction('REMOVE_BOOK_FROM_LIST_IN_PROGRESS');
export const removeBookFromListSuccess = createAction('REMOVE_BOOK_FROM_LIST_SUCCESS');
export const removeBookFromListFailure = createAction('REMOVE_BOOK_FROM_LIST_FAILURE');

export function removeBookFromListApi({ book, listId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { book, listId },
            actions: [removeBookFromListInProgress, removeBookFromListSuccess, removeBookFromListFailure],
            name: 'removeBookFromList',
            dispatch,
            getState
        })
    }
}