import { UPDATE_ACCOUNT_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateAccountInProgress = createAction('UPDATE_ACCOUNT_IN_PROGRESS');
export const updateAccountSuccess = createAction('UPDATE_ACCOUNT_SUCCESS');
export const updateAccountFailure = createAction('UPDATE_ACCOUNT_FAILURE');

export function updateAccountApi({ account, avatar }) {
    return (dispatch, getState) => {
        const { loggedUserId } = getState();
        return api({
            url,
            data: { account: { ...account, id: loggedUserId }, avatar },
            actions: [updateAccountInProgress, updateAccountSuccess, updateAccountFailure],
            name: 'updateAccount',
            dispatch,
            getState
        })
    }
}