import { UPDATE_SHELF_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateShelfInProgress = createAction('UPDATE_SHELF_IN_PROGRESS');
export const updateShelfSuccess = createAction('UPDATE_SHELF_SUCCESS');
export const updateShelfFailure = createAction('UPDATE_SHELF_FAILURE');

export function updateShelfApi({ shelf, groupId, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { shelf, groupId },
            actions: [updateShelfInProgress, updateShelfSuccess, updateShelfFailure],
            name: 'updateShelf',
            showLoading,
            dispatch,
            getState
        })
    }
}