import { UPDATE_BOOKS_IN_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateBooksInGroupInProgress = createAction('UPDATE_BOOKS_IN_GROUP_IN_PROGRESS');
export const updateBooksInGroupSuccess = createAction('UPDATE_BOOKS_IN_GROUP_SUCCESS');
export const updateBooksInGroupFailure = createAction('UPDATE_BOOKS_IN_GROUP_FAILURE');

export function updateBooksInGroupApi({ bookIds, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookIds, groupId },
            actions: [updateBooksInGroupInProgress, updateBooksInGroupSuccess, updateBooksInGroupFailure],
            name: 'updateBooksInGroup',
            dispatch,
            getState
        })
    }
}