import React, { memo, useContext } from 'react';
import Empty from './Empty';
import { selectAccount } from '../selectors';
import { useSelector, shallowEqual } from 'react-redux';
import { isWeb } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';

const ADD_BOOK_SCREEN = isWeb() ? 'AddBookSearch' : 'Scan';

function ActivityEmpty({ tBase, navigation }) {
    const {t} = useContext(AppSettingsContext);
    const account = useSelector(selectAccount);
    const shelves = useSelector(state => state.shelves, shallowEqual);
    if(!account) return null;
    const hasBooks = (() => {
        let hasBooks = false;
        for(const shelfId of account.shelfIds) {
            const shelf = shelves[shelfId]
            if(shelf?.bookIds.length) {
                hasBooks = true;
                break;
            }
        }
        return hasBooks;
    })()
    const isFollowing = account.followingIds.length;
    const bodyKey = (() => {
        if(hasBooks && isFollowing) return 'bodyHasBooksAndIsFollowing';
        if(hasBooks && !isFollowing) return 'bodyHasBooksAndIsNotFollowing';
        return 'bodyHasNoBooks';
    })()
    const actionKey = (() => {
        if(hasBooks && !isFollowing) return 'searchUsers';
        return 'addBooks';
    })()
    const actionOnPress = (() => {
        if(hasBooks && isFollowing) return;
        if(hasBooks && !isFollowing) return () => navigation.navigate('Modal', { screen: 'Search' });;
        return () => navigation.navigate('Modal', { screen: ADD_BOOK_SCREEN });
    })()
    return (
        <Empty 
            title={ t(`${tBase}.empty.title`) }
            body={ t(`${tBase}.empty.${bodyKey}`) }
            img={ require('../assets/empty.png') }
            primaryActionLabel={ t(`${tBase}.empty.${actionKey}`) }
            primaryActionOnPress={ actionOnPress } />
    )
}

export default memo(ActivityEmpty);