import React, { useEffect, useContext } from 'react';
import AppHeader from '../navigation/AppHeader';
import UsersList from '../components/UsersList';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { followUserApi } from '../actions/followUser';
import { useUser } from '../hooks';
import { isInModal } from '../utils';
import Empty from '../components/Empty';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.followers';

export default function FollowersScreen({ navigation, route }) {
    const {t} = useContext(AppSettingsContext);
    const { userId } = route.params;
    const user = useUser(userId);
    const {followerIds} = user;
    const inModal = isInModal(navigation);
    const dispatch = useDispatch();
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const followUser = () => { dispatch(followUserApi({ userId })) }
    const isYourself = loggedUserId && loggedUserId == user.id;
    const emptyBody = (() => {
        let tKey = `${tBase}.empty.`;
        tKey += user.isPrivate && !isYourself ? 'bodyPrivate' : '';
        tKey += isYourself ? 'bodyYourself' : ''; 
        tKey += !isYourself && !user.isPrivate ? 'body' : '';
        return t(tKey, { nickname: user.nickname });
    })()
    const renderEmpty = () => (
        <Empty
            title={ t(`${tBase}.empty.title`)}
            body={ emptyBody } 
            img={ require('../assets/noUsersSearch.png') }
            primaryActionLabel={ t(`${tBase}.empty.follow`)}
            primaryActionOnPress={ user.isPrivate || isYourself ? null : followUser } />
    )
    useEffect(() => {
        if(inModal) navigation.setOptions({ title: t(`${tBase}.title`) })
    })
    return (
        <>
            { !inModal &&
            <AppHeader 
                title={t('screens.followers.title')} 
                navigation={ navigation } />
            }
            <UsersList 
                navigation={navigation}
                context='list'
                renderEmpty={renderEmpty}
                route={route}
                userIds={followerIds} />
            </>
    )
}