import React, { memo, useContext } from 'react';
import { ScrollView, TouchableOpacity, StyleSheet, View, Platform } from 'react-native';
import InterReg from './InterReg';
import { SECONDARY_COLOR, GRAY_LIGHT, GRAY_LINE_COLOR, PILL_TAB_HEIGHT, PILL_TAB_R_MARGIN, PILL_TAB_HOR_PADDING } from '../constants';
import { LinearGradient } from 'expo-linear-gradient';
import AppSettingsContext from '../context/AppSettingsContext';

const HorizontalFilters = memo(({ onSelect, filters, selected, style={}, tBase }) => {
    const {t} = useContext(AppSettingsContext);
    return (
        <View style={style}>
            <ScrollView 
                horizontal={true}
                showsHorizontalScrollIndicator={false} 
                contentContainerStyle={{ paddingLeft: 15, paddingRight: 15 }} 
                style={styles.container}>
                    { filters.map((filter) => (
                        <TouchableOpacity
                        key={filter.key} 
                        style={[
                            styles.filter, 
                            selected == filter.key && styles.filterActive
                        ]} 
                        onPress={ () => onSelect(filter.key)}>
                            <InterReg 
                                style={[styles.filterText, selected == filter.key && { color: '#fff' }]} 
                                text={ tBase ? t(`${tBase}.${filter.label}`) : filter.label } />
                        </TouchableOpacity>
                    ))}
            </ScrollView>
            <LinearGradient pointerEvents='none' style={styles.gradient} start={[1,1]} locations={[0.3,0.8]} colors={['#fff', 'rgba(255,255,255, 0)']} />
        </View>
    )
})

const styles = StyleSheet.create({
    container: {
        maxHeight: PILL_TAB_HEIGHT,
        minHeight: PILL_TAB_HEIGHT
    },
    filter: {
        borderRadius: PILL_TAB_HEIGHT/2,
        backgroundColor: GRAY_LIGHT,
        borderWidth: 1,
        borderColor: GRAY_LINE_COLOR,
        paddingHorizontal: PILL_TAB_HOR_PADDING,
        marginRight: PILL_TAB_R_MARGIN,
        justifyContent: 'center'
    },
    filterActive: { 
        backgroundColor: SECONDARY_COLOR, 
        borderColor: SECONDARY_COLOR
    },
    filterText: {
        color: SECONDARY_COLOR,
        fontSize: 14,
        ...Platform.select({
            web: {
                lineHeight: 14
            },
            default: {}
        })
    },
    gradient: {
        position: 'absolute', 
        right: 0, 
        width: 50, 
        height: PILL_TAB_HEIGHT
    }
})

export default HorizontalFilters;