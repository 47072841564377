import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { searchBookProvidersSuccess, clearBookProviderResultsAction, updateBookProvidersResult } from '../actions/searchBookProviders';
import { matchResetStore } from './matchers';
import Dayjs from 'dayjs';

const initialState = {};

export const bookProviderResults = createReducer(initialState, (builder) => {
    builder
    .addCase(searchBookProvidersSuccess, (state, action) => {
        const { books, query, method } = action.payload;
        if(method == 'search') {
            return { books, query, datetime: Dayjs().format() }
        }
    })
    .addCase(updateBookProvidersResult, (state, action) => {
        const { index, data } = action.payload;
        if(state?.books[index]) {
            for(const [key, value] of Object.entries(data)) {
                state.books[index][key] = value;
            }
        }  
    })
    .addMatcher(isAnyOf(matchResetStore, clearBookProviderResultsAction), () => {
        return initialState;
    })
})