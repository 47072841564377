import React, { useEffect, useMemo, useState, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Keyboard } from 'react-native';
import HorizontalFilters from './HorizontalFilters';
import { BOOK_ORDER_OPTIONS } from '../constants';
import { useKeywordsFromBooks } from '../hooks';
import { getKeywordLabel, getResponsiveValue, isNative } from '../utils';
import MenuSort from './MenuSort';
import Input from './Input';
import {Ionicons} from '@expo/vector-icons';
import Animated, { useSharedValue, useAnimatedStyle, interpolate, Extrapolate, withTiming, runOnJS } from 'react-native-reanimated';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'forms.filterGroup';

const CONTAINER_HEIGHT = 60;
const CONTAINER_PADDING_RIGHT = 10;
const FILTERS_MARGIN_RIGHT = 8;
const INPUT_HEIGHT = 40;
const SEARCH_ICON_WIDTH = 34;

function GroupOptionsBar({ bookIds=[], filter, sort, pattern, updateFilter, updatePattern, updateSort }) {
    const {locale} = useContext(AppSettingsContext);
    const shouldShowSearchByDefault = getResponsiveValue([false, false, false, false, true], 'x');
    const [containerWidth, setContainerWidth] = useState(null);
    const [sortWidth, setSortWidth] = useState(null);
    const [searchInputWidth, setSearchInputWidth] = useState(getResponsiveValue([200, 220, 240, 260, 280], 'x'));
    const showInput = useSharedValue(shouldShowSearchByDefault ? 1 : 0);
    const keywords = useKeywordsFromBooks(bookIds);
    const filters = useMemo(() => {
        const filters = keywords.map(keyword => ({
            key: keyword.id,
            label: getKeywordLabel(keyword, locale)
        }))
        filters.unshift({ key: 'all', label: 'All'})
        return filters;
    }, [keywords, locale])
    const inputAnimStyles = useAnimatedStyle(() => ({
        width: interpolate(showInput.value, [0,1], [0, searchInputWidth], Extrapolate.CLAMP),
        opacity: showInput.value
    }));
    const searchIconAnimStyles  = useAnimatedStyle(() => ({ opacity: interpolate(showInput.value, [0, 1], [1, 0]) }))
    const closeIconAnimStyles   = useAnimatedStyle(() => ({ opacity: showInput.value }))
    const filtersIconAnimStyles = useAnimatedStyle(() => ({ width: showInput.value ? 0 : 'auto' } ))
    function onLayoutContainer({ nativeEvent: {layout: {width}}}) { !containerWidth && setContainerWidth(width) }
    function onLayoutSort({ nativeEvent: {layout: {width}}}) { !sortWidth && setSortWidth(width) }
    function resetPattern() { 
        updatePattern('');
        isNative() && Keyboard.dismiss(); 
    }
    function handleSearchIconPress() {
        showInput.value = withTiming(showInput.value ? 0 : 1, { duration: 250 }, () => runOnJS(resetPattern)())
    }
    useEffect(() => {
        if(containerWidth && sortWidth && containerWidth < 500) {
            setSearchInputWidth(containerWidth-sortWidth-SEARCH_ICON_WIDTH-FILTERS_MARGIN_RIGHT-CONTAINER_PADDING_RIGHT);
        }
    }, [containerWidth, sortWidth])
    return (
        <View style={styles.container} onLayout={onLayoutContainer}>
            <Animated.View style={[{ flex: 1, marginRight: 8, height: 34 }, filtersIconAnimStyles]} >
                <HorizontalFilters selected={filter} filters={filters} onSelect={updateFilter}/>
            </Animated.View>
            <View style={{flexDirection: 'row'}}>
                { !shouldShowSearchByDefault &&
                    <TouchableOpacity onPress={handleSearchIconPress}>
                        <View style={{ width: SEARCH_ICON_WIDTH, height: INPUT_HEIGHT }}>
                            <Animated.View style={closeIconAnimStyles}>
                                <Ionicons name='close' color='#666' size={ 25 } style={{ position: 'absolute', left: 5, top: 5 }} />
                            </Animated.View>
                            <Animated.View style={searchIconAnimStyles}>
                                <Ionicons name='search' color='#666' size={ 23 } style={{ position: 'absolute', left: 5, top: 5 }} />
                            </Animated.View>
                        </View>
                    </TouchableOpacity>
                }
                <Animated.View style={[inputAnimStyles, { overflow: 'hidden', height: INPUT_HEIGHT }]}>
                    <Input 
                        inputIcon={{ Icon: Ionicons, size: 17, name: 'search' }}
                        containerStyle={{ flex: 1, marginRight: FILTERS_MARGIN_RIGHT }}
                        inputHeight={INPUT_HEIGHT}
                        showFormErrors={ false }
                        shouldFocus={!!showInput.value}
                        inputProps={{ value: pattern, onChange: updatePattern, name: 'search' }}
                        tBase={ tBase } />
                </Animated.View>
            </View>
            <View onLayout={onLayoutSort}>
                <MenuSort options={BOOK_ORDER_OPTIONS} selected={sort} onSelect={updateSort} />
            </View>
        </View>
    )
}

export default GroupOptionsBar;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#fff',
        height: CONTAINER_HEIGHT,
        alignItems: 'center',
        paddingRight: CONTAINER_PADDING_RIGHT, 
        flexDirection: 'row'
    }
})