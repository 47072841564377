import React, { memo, useContext } from 'react';
import { sendGroupRequestApi } from '../actions/sendGroupRequest';
import { cancelGroupRequestApi } from '../actions/cancelGroupRequest';
import { confirmJoinGroup } from '../utils/alerts';
import { useDispatch } from 'react-redux';
import { openModalOrPushScreen } from '../utils';
import { useGroupRequest } from '../hooks';
import Empty from './Empty';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.group.empty';

function GroupEmpty({isAdmin, isMember, group, navigation}) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const groupRequestByMe = useGroupRequest(group.id);
    const sendJoinGroupRequest = () => { dispatch(sendGroupRequestApi({ groupId: group.id }))}
    const cancelJoinGroupRequest   = () => { dispatch(cancelGroupRequestApi({ groupId: group.id, groupRequestId: groupRequestByMe.id })) }
    const title = (() => {
        if(isAdmin || isMember) return t(`${tBase}.memberOrAdmin.title`);
        return t(`${tBase}.user.title`)
    })()
    const body = (() => {
        if(isAdmin || isMember) return t(`${tBase}.memberOrAdmin.body`);
        if(groupRequestByMe) return t(`${tBase}.user.bodyRequestWaiting`);
        return t(`${tBase}.user.body`)
    })()
    const primaryActionLabel = (() => {
        if(isAdmin || isMember) return t(`${tBase}.memberOrAdmin.addYourBooks`);
        if(groupRequestByMe) return t(`${tBase}.user.cancelJoinRequest`);
        return t(`${tBase}.user.joinGroup`)
    })()
    const primaryActionOnPress = (() => {
        if(isAdmin || isMember) return () => openModalOrPushScreen(navigation, 'BooksInGroup', { groupId: group.id })
        if(groupRequestByMe) return () => cancelJoinGroupRequest();
        return () => confirmJoinGroup(sendJoinGroupRequest, dispatch, { name: group.name })
    })()
    const primaryActionTheme = (() => {
        if(groupRequestByMe) return 'grayColor';
        return;
    })()
    return (
        <Empty 
            title={title} 
            body={body}
            img={ require('../assets/empty.png') }
            primaryActionTheme={primaryActionTheme}
            primaryActionLabel={primaryActionLabel}
            primaryActionOnPress={primaryActionOnPress} />
    )
}

export default memo(GroupEmpty);