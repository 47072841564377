import { createReducer } from '@reduxjs/toolkit'
import { addGroupCollectionSuccess } from '../actions/addGroupCollection';
import { hasGroupCollections, hasGroupCollection, matchResetStore, hasGroupCollectionToRemove } from './matchers';

const initialState = {};

export const groupCollections = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasGroupCollectionToRemove, (state, action) => {
        const { groupId, customOrder } = state[action.payload.groupCollectionId];
        delete state[action.payload.groupCollectionId];
        for(let [id, values] of Object.entries(state)) {
            if(groupId === values.groupId && values.customOrder > customOrder) {
                state[id].customOrder -= 1;
            }
        }
    })
    .addMatcher(hasGroupCollection, (state, action) => {
        const { groupCollection } = action.payload;
        if(action.type == addGroupCollectionSuccess) {
            for(let [id, values] of Object.entries(state)) {
                if(groupCollection.groupId === values.groupId) {
                    state[id].customOrder += 1;
                }
            }
        }
        state[groupCollection.id] = groupCollection;
    })
    .addMatcher(hasGroupCollections, (state, action) => {
        const { groupCollections } = action.payload;
        if(groupCollections) {
            groupCollections.forEach(groupCollection => state[groupCollection.id] = groupCollection)
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})