import { REQUEST_RETURN_BOOK_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const requestReturnBookInProgress = createAction('REQUEST_RETURN_BOOK_IN_PROGRESS');
export const requestReturnBookSuccess = createAction('REQUEST_RETURN_BOOK_SUCCESS');
export const requestReturnBookFailure = createAction('REQUEST_RETURN_BOOK_FAILURE');

export function requestReturnBookApi({ bookId, lendId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, lendId },
            actions: [requestReturnBookInProgress, requestReturnBookSuccess, requestReturnBookFailure],
            name: 'requestReturnBook',
            dispatch,
            getState
        })
    }
}