import { LOG_EVENT_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const logEventInProgress = createAction('LOG_EVENT_IN_PROGRESS');
export const logEventSuccess = createAction('LOG_EVENT_SUCCESS');
export const logEventFailure = createAction('LOG_EVENT_FAILURE');

export function logEventApi(event) {
    return (dispatch, getState) => {
        return api({
            url,
            data: event,
            actions: [logEventInProgress, logEventSuccess, logEventFailure],
            name: 'logEvent',
            showLoading: false, 
            checkCaching: false,
            addToPayload: { event },
            dispatch,
            getState
        })
    }
}