import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import BlurHash from './BlurHash';
import Img from './Img';
import { BlurView } from 'expo-blur';

function BookHeaderBg({ width, height, blurhash, uri }) {
    if(uri) {
        return (
            <View style={StyleSheet.absoluteFill}>
                <Img resizeMode='cover' style={{ height: '100%', width: '100%' }} uri={uri} />
                <BlurView intensity={100} style={StyleSheet.absoluteFill} />
            </View>
        )
    }
    return (
        <BlurHash
            blurhash={blurhash}
            height={height}
            width={width}
            punch={1}
            resizeMode='cover'
            style={StyleSheet.absoluteFillObject} />
    )
}

export default memo(BookHeaderBg);