import { GET_LIST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getListInProgress = createAction('GET_LIST_IN_PROGRESS');
export const getListSuccess = createAction('GET_LIST_SUCCESS');
export const getListFailure = createAction('GET_LIST_FAILURE');

export function getListApi({ listId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { listId },
            actions: [getListInProgress, getListSuccess, getListFailure],
            name: 'getList',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}