import { createReducer } from '@reduxjs/toolkit'
import { hasGroupBookAttributes, matchResetStore } from './matchers';
import { updateShelfSuccess } from '../actions/updateShelf';

const initialState = {};

export const groupBookAttributes = createReducer(initialState, (builder) => {
    builder
    .addCase(updateShelfSuccess, (state, action) => {
        const { id:shelfId, availableOnSiteForGroupIds, onSiteLocationForGroups } = action.payload.shelf;
        for(let groupId of availableOnSiteForGroupIds) {
            if(state[groupId]) {
                const onSiteShelfInfoIndex = state[groupId]?.onSiteLocationInfo.findIndex(locationInfo => locationInfo[shelfId]);
                if(onSiteShelfInfoIndex > -1 && onSiteLocationForGroups[groupId] !== undefined) {
                    state[groupId].onSiteLocationInfo[onSiteShelfInfoIndex][shelfId] = onSiteLocationForGroups[groupId];
                } else {
                    state[groupId].onSiteLocationInfo.push({[shelfId]: onSiteLocationForGroups[groupId] })
                }
            } else {
                console.log(`No group id in state for this group ${groupId}`)
            }
        }
    })
    .addMatcher(hasGroupBookAttributes, (state, action) => {
        const { group, groupBookAttributes } = action.payload;
        if(group?.id && groupBookAttributes) {
            state[group.id] = groupBookAttributes;
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})