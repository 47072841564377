import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import { HEADER_ACTIONS_HEIGHT, HEADER_ACTIONS_PADDING_HOR, HEADER_ACTIONS_PADDING_HOR_COMPACT } from '../constants';
import RoundButton from './RoundButton';

export default memo(function HeaderActionBar({ top, children, mainViewWidth, onPressBack }) {
    const paddingHorizontal = mainViewWidth > 450 ? HEADER_ACTIONS_PADDING_HOR : HEADER_ACTIONS_PADDING_HOR_COMPACT;
    return (
        <View style={[styles.container, { top, paddingHorizontal }]}>
            <RoundButton
                size={ HEADER_ACTIONS_HEIGHT }
                style={{ marginRight: 'auto' }}
                onPress={ onPressBack } />
            { children }
        </View>
    )
})
const styles = StyleSheet.create({
    container: {
        width: '100%', 
        flexDirection: 'row', 
        position: 'absolute', 
        height: HEADER_ACTIONS_HEIGHT
    }
})