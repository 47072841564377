import React, { memo, useContext } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import { OFF_BLACK } from '../constants'
import { shortenText } from '../utils' 
import { MaterialCommunityIcons as MCIcons } from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';

const Location = memo(({ 
        iconSize=17, 
        iconColor='#666', 
        textStyle={}, 
        containerStyle={}, 
        location, 
        charLength 
    }) => {
    const {t} = useContext(AppSettingsContext);
	const renderLocationText = shortenText((location || t('user.location.unknown')), charLength)
	return (
		<View style={[ styles.container, containerStyle ]}>
		    <MCIcons name='map-marker' size={ iconSize } color={ iconColor } />
		    <Text style={ [styles.locationLabel, textStyle] } numberOfLines={ 1 }>{ renderLocationText }</Text>
		</View>
	)
})

export default Location;

const styles = StyleSheet.create({
	container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    locationLabel: {
    	color: OFF_BLACK,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 4,
        fontSize: 14,
        fontFamily: 'Inter_400Regular'
    },
})