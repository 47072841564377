import { createReducer } from '@reduxjs/toolkit'
import { deleteUserKeywordSuccess } from '../actions/deleteUserKeyword';
import { hasUserKeyword, hasUserKeywords, matchResetStore } from './matchers';

const initialState = [];

export const userKeywords = createReducer(initialState, (builder) => {
    builder
    .addCase(deleteUserKeywordSuccess, (state, action) => {
        const { userKeywordId } = action.payload;
        const userKeywordIndex = state.findIndex(({ id }) => id === userKeywordId);
        if(userKeywordIndex > -1) {
            state.splice(userKeywordIndex, 1);
        }
    })
    .addMatcher(hasUserKeywords, (state, action) => {
        const { userKeywords } = action.payload;
        for(let userKeyword of userKeywords) {
            updateUserKeyword(state, userKeyword);
        }
    })
    .addMatcher(hasUserKeyword, (state, action) => {
        const { keyword } = action.payload;
        updateUserKeyword(state, keyword)
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})

function updateUserKeyword(state, userKeyword) {
    const userKeywordIndex = state.findIndex(({ id }) => id === userKeyword.id)
    if(userKeywordIndex === -1) { 
        state.push(userKeyword); 
    } else {
        state[userKeywordIndex] = userKeyword;
    }
}