import { createReducer } from '@reduxjs/toolkit'
import { matchResetStore } from './matchers';
import { getActivitySuccess } from '../actions/getActivity';

const initialState = [];

export const activity = createReducer(initialState, (builder) => {
    builder
    .addCase(getActivitySuccess, (state, action) => {
        return [...state, ...action.payload.activity];
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})