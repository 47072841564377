import { ADD_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addGroupInProgress = createAction('ADD_GROUP_IN_PROGRESS');
export const addGroupSuccess = createAction('ADD_GROUP_SUCCESS');
export const addGroupFailure = createAction('ADD_GROUP_FAILURE');

export function addGroupApi({ group, picture }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { group, picture },
            actions: [addGroupInProgress, addGroupSuccess, addGroupFailure],
            name: 'addGroup',
            dispatch,
            getState
        })
    }
}