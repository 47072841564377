import { createReducer } from '@reduxjs/toolkit'
import { showAlert } from '../actions';
import { hideAlertAction } from '../actions';

const initialState = {};

export const alert = createReducer(initialState, (builder) => {
    builder
    .addCase(showAlert, (state, action) => {
        return action.payload
    })
    .addCase(hideAlertAction, () => {
        return initialState;
    })
})