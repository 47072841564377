import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native';
import Book from './Book';
import { useScrollToTop } from '@react-navigation/native';
import { useGetBookItemLayout, useFlatListPadding } from '../hooks';
import { useIsFocused } from '@react-navigation/native';
import { keyExtractor, getInitialScreenWidth, getResponsiveValue, isNative } from '../utils';
import { BOOK_LIST_ITEM_BOTTOM_SPACING, RATIO_BOOK_LIST_ITEM, MAIN_COLOR } from '../constants';
import Animated from 'react-native-reanimated';

const ITEM_X_SPACING = getResponsiveValue([16, 18, 18, 18, 20], 'x');
const CONTAINER_HOR_SPACING = getResponsiveValue([5, 10, 10, 10, 15], 'x');

const AnimatedFlatlist = Animated.createAnimatedComponent(FlatList);

export default function BooksList(props) {
    const [initialNumToRender, setInitialNumToRender] = useState(null);
    const [layout, setLayout] = useState({ width: getInitialScreenWidth(props.navigation), height: null });
    const {columns, itemWidth} = useGetBookItemLayout(layout.width, CONTAINER_HOR_SPACING, ITEM_X_SPACING);
    const onLayout = ({ nativeEvent: { layout: { width, height} } }) => {
        if((layout.height === null && width && height) ||  layout.width !== width) {
            setLayout({ width, height });
        }
    }
    const { paddingTop, paddingBottom } = useFlatListPadding();
    useEffect(() => {
        if(layout.height) {
            const itemHeight = (itemWidth*RATIO_BOOK_LIST_ITEM)+BOOK_LIST_ITEM_BOTTOM_SPACING;
            const height = props.renderListHeader ? layout.height : layout.height - paddingTop;
            setInitialNumToRender(Math.ceil(height/itemHeight));
        }
    }, [layout.height])
    return (
        <View style={{ flex: 1 }} onLayout={onLayout}>
            { initialNumToRender ?
                <Books 
                    {...props} 
                    columns={columns} 
                    itemWidth={itemWidth} 
                    paddingTop={paddingTop} 
                    paddingBottom={paddingBottom} 
                    initialNumToRender={initialNumToRender} /> :
                <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={ MAIN_COLOR } size="large" />
                </View>
            }
        </View>
    )
}

const Books = ({ books, navigation, refreshing=false, useStickyHeader=false, context, scrollHandler, contextId, onRefresh, renderListHeader, renderEmpty=() => null, listId='', shelfId='', groupId, groupSlug, columns, initialNumToRender, paddingTop, paddingBottom, itemWidth, contentContainerStyle }) => {
    const ref = useRef(null);
    const [pressedBookId, setPressedBookId] = useState(null);
    const isFocused = useIsFocused();
    useEffect(() => {
        if(isFocused && pressedBookId) {
            setPressedBookId(null);
        }
    }, [isFocused])
    const rememberPressedBookId = useCallback((bookId) => setPressedBookId(bookId), []);
    const renderItem = ({ item }) => (
        <Book 
            bookId={item.id}
            title={item.title}
            author={item.author}
            listId={listId}
            shelfId={shelfId}
            groupId={groupId}
            groupSlug={groupSlug}
            width={itemWidth} 
            isFocused={isNative() ? isFocused : false}
            ownerId={item.ownerId}
            context={context}
            contextId={contextId}
            rememberPressedBookId={rememberPressedBookId}
            pressedBookId={pressedBookId}
            marginHorizontal={ITEM_X_SPACING/2} 
            navigation={navigation} />
    )
    const ListHeaderComponent = renderListHeader || (() => <View style={{paddingTop}} />);
    const ItemSeparatorComponent = useCallback(() => <View style={{height: BOOK_LIST_ITEM_BOTTOM_SPACING }} />)
    const windowSize = Math.min(initialNumToRender*5, 15);
    useScrollToTop(ref);
    return (
        <AnimatedFlatlist 
            ref={ref}
            onScroll={scrollHandler}
            contentContainerStyle={[
                { flexGrow: 1, marginHorizontal: CONTAINER_HOR_SPACING, paddingBottom },
                contentContainerStyle
            ]}
            data={books} 
            key={columns}
            numColumns={columns}
            refreshing={refreshing}
            scrollEventThrottle={16}
            onRefresh={onRefresh}
            stickyHeaderIndices={useStickyHeader ? [0] : []}
            ListHeaderComponent={ListHeaderComponent}
            ListEmptyComponent={renderEmpty}
            ItemSeparatorComponent={ItemSeparatorComponent}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            initialNumToRender={initialNumToRender}
            maxToRenderPerBatch={10}
            windowSize={windowSize} />
    )
}