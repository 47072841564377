import { GET_BOOK_OWNERS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getBookOwnersInProgress = createAction('GET_BOOK_OWNERS_IN_PROGRESS');
export const getBookOwnersSuccess = createAction('GET_BOOK_OWNERS_SUCCESS');
export const getBookOwnersFailure = createAction('GET_BOOK_OWNERS_FAILURE');

export function getBookOwnersApi({ bookId, showLoading }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId },
            actions: [getBookOwnersInProgress, getBookOwnersSuccess, getBookOwnersFailure],
            name: 'getBookOwners',
            showLoading,
            dispatch,
            getState
        })
    }
}