import { createReducer } from '@reduxjs/toolkit'
import { getMyGroupsSuccess } from '../actions/getMyGroups';
import { hasGroups, hasGroup, matchResetStore, hasGroupToRemove } from './matchers';
import { deleteBookSuccess } from '../actions/deleteBook';

const initialState = {};

export const groups = createReducer(initialState, (builder) => {
    builder
    .addCase(deleteBookSuccess, (state, action) => {
        const { bookId:deletedBookId } = action.payload;
        for(const [key, group] of Object.entries(state)) {
            if(group.bookIds.includes(deletedBookId)) {
                state[key].bookIds = state[key].bookIds.filter(bookId => bookId != deletedBookId)
            }
        }
    })
    .addMatcher(hasGroupToRemove, (state, action) => {
        delete state[action.payload.groupId];
    })
    .addMatcher(hasGroup, (state, action) => {
        state[action.payload.group.id] = action.payload.group;
    })
    .addMatcher(hasGroups, (state, action) => {
        const { groups, loggedUserId } = action.payload;
        groups.forEach(group => state[group.id] = group)
        if(action.type == getMyGroupsSuccess) {
            for(const [key, group] of Object.entries(state)) {
                const index = groups.findIndex(({id}) => id == group.id);
                if(index == -1 && group.memberIds.includes(loggedUserId)) {
                    state[key].memberIds = state[key].memberIds.filter(memberId => memberId != loggedUserId)
                }
            }
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})