import { GET_BORROW_REQUESTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getBorrowRequestsInProgress = createAction('GET_BORROW_REQUESTS_IN_PROGRESS');
export const getBorrowRequestsSuccess = createAction('GET_BORROW_REQUESTS_SUCCESS');
export const getBorrowRequestsFailure = createAction('GET_BORROW_REQUESTS_FAILURE');

export function getBorrowRequestsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getBorrowRequestsInProgress, getBorrowRequestsSuccess, getBorrowRequestsFailure],
            name: 'getBorrowRequests',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}