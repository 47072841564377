import React, { useEffect, useContext } from 'react';
import { View } from 'react-native';
import { Feather } from '@expo/vector-icons';
import MenuList from '../components/MenuList';
import BooksList from '../components/BooksList';
import FetchError from '../components/FetchError';
import AppHeader from '../navigation/AppHeader';
import Empty from '../components/Empty';
import ShareButon from '../components/ShareButton';
import ListInfo from '../components/ListInfo';
import FilterStateHeader from '../components/FilterStateHeader';
import { getListApi } from '../actions/getList';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getListName, goBackFromList, isWeb, getShortUrl, isInModal, capitalizeWord } from '../utils';
import { useUser, useList, useGetDataOnNavFocus, useListBooks, useGetListDone, useSetInitialPath, useSearchShelfOrListBooks } from '../hooks';
import { SECONDARY_COLOR, DEFAULT_LISTS, BOOK_STATUS_LISTS } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';
import ListsSearchContext from '../context/ListsSearchContext';

const tBase = 'screens.list';
const ADD_BOOK_SCREEN = isWeb() ? 'AddBookSearch' : 'Scan';

export default function ListScreen({ navigation, route }) {
    const listId = route.params.listId;
    const list = useList(listId);
    const dispatch = useDispatch();
    const [listFetchStatus, done] = useGetListDone(listId);
    const getList = () => { dispatch(getListApi({ listId, showLoading: !list })).then(done); }
    useGetDataOnNavFocus(navigation, getList);
    if(!listFetchStatus && !list) return <View />;
    if(['failed', 'notFound', 'notAllowed'].includes(listFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                navigation={navigation} 
                status={ listFetchStatus } />
        )
    }
    return (
        <List 
            list={list} 
            route={route}
            navigation={navigation} />
    )
}

function List({ list, navigation, route }) {
    if(!list) return <View />
    const {t} = useContext(AppSettingsContext);
    useSetInitialPath(route);
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isOwner = list?.ownerId == loggedUserId;
    const inModal = isInModal(navigation);
    const url = getShortUrl('list', list.shortId);
    useEffect(() => {
        const title = getListName(list, t);
        navigation.setOptions({ title })
    })
    function deleteDone(json) {
        if(json?.success) handleBack();
    }
    function handleBack() {
        goBackFromList(navigation, activeBottomTab);
    }
    function headerRight() {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center'}}>
                { Boolean(list.shortId) && 
                    <ShareButon 
                        url={ url } 
                        type='list' 
                        isOwner={ isOwner }
                        name={ getListName(list, t) } />
                }
                { isOwner &&
                    <MenuList
                        list={list}
                        deletable={!DEFAULT_LISTS.includes(list.type)}
                        navigation={navigation}
                        deleteDone={deleteDone}
                        TriggerButton={ () => (
                            <View style={{ height: 25, alignItems: 'center', justifyContent: 'center' }}>
                                <Feather name="more-horizontal" size={30} color={ SECONDARY_COLOR } />
                            </View>
                        )} />
                }
            </View>
        )
    }
    if(!list) return <View />
    return (
        <>
            { !inModal &&
            <AppHeader 
                title={ getListName(list, t) } 
                isPrivate={list.visibility == 'private'}
                headerRight={ headerRight } 
                headerRightStyle={{ paddingRight: 12 }}
                handleBack={ handleBack }
                navigation={ navigation } />
            }
            <ListBooks 
                list={list} 
                isOwner={isOwner}
                navigation={navigation} />
        </>
    )
}

function ListBooks({ list, isOwner, navigation }) {
    const { t } = useContext(AppSettingsContext);
    const primaryActionOnPress = () => navigation.navigate('Modal', { screen: ADD_BOOK_SCREEN, params: { listId: list.id } });
    const user = useUser(list.ownerId);
    const books = useListBooks(list.sort, list.bookIds);
    const { matchedBooks, hasSearch, resetSearch } = useSearchShelfOrListBooks(books, isOwner, ListsSearchContext);
    const emptyBodyKey = (() => {
        if(BOOK_STATUS_LISTS.includes(list.type) && isOwner) {
            return 'body' + capitalizeWord(list.type);
        }
        return isOwner ? 'bodyOwner' : 'bodyUser';
    })()
    const showPrimaryAction = !BOOK_STATUS_LISTS.includes(list.type) && isOwner;
    const renderEmpty = () => (
        <Empty  
            title={ t(`${tBase}.empty.title`) }
            body={ t(`${tBase}.empty.${emptyBodyKey}`, { nickname: user?.nickname || '' }) }
            img={ require('../assets/empty.png') }
            primaryActionLabel={ t(`${tBase}.empty.addBooks`) }
            primaryActionOnPress={ showPrimaryAction ? primaryActionOnPress : null } />
    )
    const renderHeader = () => {
        if(hasSearch && isOwner) {
            return (
                <FilterStateHeader 
                    tBase={tBase} 
                    resetPattern={resetSearch}
                    bookCount={matchedBooks.length} />
            )
        }
        return (
            <ListInfo 
                list={list} 
                user={user} 
                tBase={tBase}
                isOwner={isOwner}
                navigation={navigation} />
        )
    }
    return (
        <BooksList 
            renderEmpty={renderEmpty} 
            listId={list.id} 
            books={matchedBooks} 
            context='list'
            useStickyHeader={ hasSearch && isOwner ? true : false } 
            renderListHeader={ renderHeader }
            contextId={list.id}
            navigation={navigation} />
    )
}