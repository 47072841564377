import { SET_GROUP_COLLECTIONS_ORDER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setGroupCollectionsOrderInProgress = createAction('SET_GROUP_COLLECTIONS_ORDER_IN_PROGRESS');
export const setGroupCollectionsOrderSuccess = createAction('SET_GROUP_COLLECTIONS_ORDER_SUCCESS');
export const setGroupCollectionsOrderFailure = createAction('SET_GROUP_COLLECTIONS_ORDER_FAILURE');

export function setGroupCollectionsOrderApi({ groupCollections, groupId, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupCollections, groupId },
            actions: [setGroupCollectionsOrderInProgress, setGroupCollectionsOrderSuccess, setGroupCollectionsOrderFailure],
            name: 'setGroupCollectionsOrder',
            showLoading,
            dispatch,
            getState
        })
    }
}