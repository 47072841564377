import React, { useEffect } from 'react';
import { Image, Keyboard, StyleSheet } from 'react-native';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming
} from 'react-native-reanimated';

import { isWeb, isNative } from '../utils';

export default function AuthLogo() {
    const marginBottom = useSharedValue(60);
    const style = useAnimatedStyle(() => {
        return {
            marginBottom: withTiming(marginBottom.value, { duration: 200 }),
        }
    })
    useEffect(() => {
        const keywordWillShowListener = Keyboard.addListener("keyboardWillShow", keyboardWillShow);
        const keywordWillHideListener = Keyboard.addListener("keyboardWillHide", keyboardWillHide);
        return () => {
            keywordWillShowListener.remove();
            keywordWillHideListener.remove();
        };
    })
    function keyboardWillShow() {
        marginBottom.value = 20;
    }
    function keyboardWillHide() {
        marginBottom.value = 60; 
    }
    return (
        <Animated.View style={[styles.imageContainer, isNative() && style ]}>
            <Image 
                source={ require('../assets/myblio-hor-logo.png') } 
                resizeMode='contain' 
                style={{ width: '100%', height: '100%' }} 
            />
        </Animated.View>
    )

}

const styles = StyleSheet.create({
    imageContainer: { 
        minHeight: 30, 
        paddingHorizontal: 40,
        marginBottom: isWeb() ? '10vh' : null, 
        marginTop: isWeb() ? '10vh' : null, 
        maxHeight: 65, 
        height: isWeb() ? '12vh' : '6%', 
        width: '100%',
    }
})


