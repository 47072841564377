import React, { memo, useMemo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
import { useSelector, shallowEqual } from 'react-redux';
import Avatar from './Avatar';
import BooksList from './ActivityBookList';
import { GRAY_LINE_COLOR, SECONDARY_COLOR, SECONDARY_COLOR_SUBTLE } from '../constants';
import { navigateToUser } from '../utils';
import { useUser } from '../hooks';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

function ActivityDetail(props) {
    const { navigation, activityItem, isLast, tTitleKey, tTitleParams } = props;
    const windowW = Dimensions.get('window').width;
    const books = useSelector(state => state.books, shallowEqual);
    const user = useUser(activityItem.userId);
    const handleUserPress = () => { navigateToUser(navigation, { userId: activityItem.userId }) };
    const activityBooks = useMemo(() => {
        const found = [];
        if(activityItem.bookIds) {
            activityItem.bookIds.forEach(bookId => books[bookId] && found.push(books[bookId]))
        } else if(activityItem.bookId) {
            books[activityItem.bookId] && found.push(books[activityItem.bookId])
        }
        return found; 
    })
    const paddingHorizontal = (() => {
        if(windowW > 500 && windowW < 700) return 18;
        if(windowW >= 700) return 20;
        return 15;
    })()
    return (
        <View style={{ paddingBottom: 15 }}>
            <View style={{ flexDirection: 'row', paddingHorizontal, alignItems: 'center' }}>
                { user &&
                <TouchableOpacity onPress={handleUserPress}>
                    <Avatar avatar={user.avatar } blurHash={user.blurHash} avatarSize={30} />
                </TouchableOpacity>
                }
                <Text style={{ paddingLeft: 8, paddingVertical: 5, flex: 1 }}>
                    { props.children }
                </Text>
            </View>
            { !!activityBooks.length && 
            <View style={{marginTop: 10}}>
                <BooksList 
                    books={activityBooks} 
                    tTitleKey={tTitleKey}
                    tTitleParams={tTitleParams}
                    user={user} 
                    navigation={navigation} />
            </View> }
            { !isLast && <View style={styles.border} /> }
        </View>
    )
}

const styles = StyleSheet.create({
    border: {
        marginTop: 15,
        marginHorizontal: 15,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: GRAY_LINE_COLOR
    }
})

export default memo(ActivityDetail);