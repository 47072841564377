import { GET_ACTIVITY_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { getNewestActivity } from '../utils';
import { api } from '../utils/api';

export const getActivityInProgress = createAction('GET_ACTIVITY_IN_PROGRESS');
export const getActivitySuccess = createAction('GET_ACTIVITY_SUCCESS');
export const getActivityFailure = createAction('GET_ACTIVITY_FAILURE');

export function getActivityApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        const since = getNewestActivity(getState().activity);
        return api({
            url,
            data: { since },
            actions: [getActivityInProgress, getActivitySuccess, getActivityFailure],
            name: 'getActivity',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}