import { GET_PUBLIC_GROUPS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getPublicGroupsInProgress = createAction('GET_PUBLIC_GROUPS_IN_PROGRESS');
export const getPublicGroupsSuccess = createAction('GET_PUBLIC_GROUPS_SUCCESS');
export const getPublicGroupsFailure = createAction('GET_PUBLIC_GROUPS_FAILURE');

export function getPublicGroupsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getPublicGroupsInProgress, getPublicGroupsSuccess, getPublicGroupsFailure],
            name: 'getPublicGroups',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}