import React, { useContext } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ActivityScreen from '../screens/Home/ActivityScreen';
import CommunityScreen from '../screens/Home/CommunityScreen';
import MainHeader from './MainHeader';
import { getTabBarOptions, isLargeScreen, isIOS } from '../utils';
import { TAB_HEIGHT } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'tabs.home';

const HomeStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();
const Tab = createMaterialTopTabNavigator();

export default function HomeTabsStack() {
    const {t} = useContext(AppSettingsContext);
    const insets = useSafeAreaInsets()
    const paddingBottom = (() => {
        if(isLargeScreen()) return 0;
        return TAB_HEIGHT + insets.bottom;
    })()
    return (
        <HomeStack.Navigator 
            screenOptions={{ 
                header: ({navigation}) => (
                    <MainHeader title={t(`${tBase}.header.title`)} navigation={navigation} />
                ),
                cardStyle: { flex: 1, paddingBottom }
            }}>
            <HomeStack.Screen
                name="HomeTabs"
                component={HomeTabs} />
        </HomeStack.Navigator>
    )
} 

function HomeTabs() {
    const {t} = useContext(AppSettingsContext);
    return (
        <Tab.Navigator 
            screenOptions={{...getTabBarOptions() }}>
            <Tab.Screen 
                name="Activity" 
                component={ActivityScreen}
                options={{
                    title: t(`${tBase}.tabs.activity.title`)
                }} />
            <Tab.Screen 
                name="Community" 
                component={CommunityScreen}
                options={{
                    title: t(`${tBase}.tabs.community.title`)
                }} />
        </Tab.Navigator>
    );
}