import { createReducer } from '@reduxjs/toolkit'
import { getUserBookLikesSuccess } from '../actions/getUserBookLikes';
import { getBookLikesSuccess } from '../actions/getBookLikes';
import { uniq } from '../utils';
import { hasBookLike, hasBookLikes, matchResetStore } from './matchers';

const initialState = {};

export const bookLikes = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasBookLike, (state, action) => {
        const { bookLike, like } = action.payload; 
        if(like) {
            state[bookLike.id] = bookLike;
        } else {
            delete state[bookLike.id];
        }
    })
    .addMatcher(hasBookLikes, (state, action) => {
        const { bookLikes, loggedUserId, bookId } = action.payload;
        bookLikes.forEach(bookLike => state[bookLike.id] = bookLike);
        let keysToRemove = []; 
        if(action.type == getBookLikesSuccess) {
            for(const [_, bookLike] of Object.entries(state)) {
                if(bookLike.bookId == bookId) keysToRemove.push(bookLike.id);    
            }
            if(keysToRemove.length) {
                for(const [_, bookLike] of Object.entries(state)) {
                    if(bookLikes.find(({id}) => id == bookLike.id) || 
                        bookLikes.find(({bookId, providerBookId}) => bookId != bookLike.bookId && providerBookId != bookLike.providerBookId)) {
                        keysToRemove = keysToRemove.filter(key => key != bookLike.id);
                    }
                }
            }
        }
        if(action.type == getUserBookLikesSuccess) {
            for(const [_, bookLike] of Object.entries(state)) {
                if(bookLike.byId == loggedUserId && !bookLikes.find(({id}) => id == bookLike.id)) {
                    keysToRemove.push(bookLike.id);
                }
            }
        }
        uniq(keysToRemove).forEach(key => delete state[key]);
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})