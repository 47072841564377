import React, { memo, useState, useContext } from 'react';
import { View, ScrollView, TouchableOpacity, Dimensions, StyleSheet } from 'react-native';
import InterReg from './InterReg';
import { GRAY_LIGHT } from '../constants';
import { SharedElement } from 'react-navigation-shared-element';
import { LinearGradient } from 'expo-linear-gradient';
import MontSBold from './MontserratBold';
import Cover from './Cover';
import Button from './Button';
import AppSettingsContext from '../context/AppSettingsContext';
import { navigateToBook, darkenToContrast, normalizeToHsp } from '../utils';
import { getAverageColor } from '../utils/blurhash';
import Color from 'color';

const tBase = 'screens.activity';

const MAX_WIDTH = 300;
const MAX_BOOKS_SCROLL = 4;

function ActivityBookList({ books, user, navigation, tTitleKey='booksListTitle', tTitleParams={} }) {
    const {t} = useContext(AppSettingsContext);
    const titleParams = (() => {
        let params = { count: books.length, ...tTitleParams };
        if(user) {
            params.name = user.nickname
        }
        return params;
    })();
    const windowW = Dimensions.get('window').width;
    const [containerWidth, setContainerWidth] = useState(windowW);
    const width = books.length == 1 ? Math.min(containerWidth-30, MAX_WIDTH) : Math.min(containerWidth * 0.6, MAX_WIDTH);
    const booksToRender = books.slice(0, MAX_BOOKS_SCROLL);
    const booksListTitle = t(`${tBase}.${tTitleKey}`, titleParams);
    const handleSeeAllBooks = () => navigation.navigate('BooksList', { bookIds: books.map(({id}) => id), title: booksListTitle })
    const onLayout = ({nativeEvent: {layout: { width }}}) => { setContainerWidth(width) };
    const paddingLeft = (() => {
        if(windowW > 500 && windowW < 700) return 18;
        if(windowW >= 700) return 20;
        return 15;
    })()
    function renderBook({ id, title, author, cover, coverHash, blurHash }) {
        return (
            <Book 
                key={id} 
                id={id} 
                title={title} 
                author={author} 
                cover={cover}
                coverHash={coverHash}
                blurHash={blurHash} 
                width={width}
                navigation={navigation} />
        )
    }
    return (
        <ScrollView 
            contentContainerStyle={{ paddingLeft, alignItems: 'center'}} 
            showsHorizontalScrollIndicator={false}
            onLayout={onLayout}
            horizontal={true}>
                { booksToRender.map(renderBook) }
                { books.length > MAX_BOOKS_SCROLL &&
                    <Button
                        buttonStyle={{ marginTop: 'auto', marginBottom: 'auto', marginRight: 20, marginLeft: 10 }} 
                        onPress={handleSeeAllBooks} 
                        size='xSmall' 
                        text={ t('screens.activity.viewAllBooks') } />
                }
        </ScrollView>
    )
}

export const Book = memo(({ id, title, author, cover, navigation, width, blurHash, coverHash }) => { 
    const handleBookPress = () => { navigateToBook(navigation, { bookId: id }) };
    const arrRgb = blurHash ? getAverageColor(blurHash) : Color(GRAY_LIGHT).rgb().array();
    const rgbAvg = `rgb(${arrRgb.join(',')})`;
    const rgbHspNormalized = normalizeToHsp({ rgb: rgbAvg, hspTreshold: blurHash ? 210 : 245 });
    const backgroundColor = rgbHspNormalized;
    const titleColor = darkenToContrast({ rgb: rgbHspNormalized, targetContrast: 5, desaturateValue: 0.1 });
    const authorColor = darkenToContrast({ rgb: rgbHspNormalized, targetContrast: 4, desaturateValue: 0.1 });
    return (
            <TouchableOpacity 
                onPress={handleBookPress}
                style={[styles.container, { minWidth: width, maxWidth: width, backgroundColor }]}>
                <LinearGradient style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, borderRadius: 10 }} start={{ x: 1, y: 0 }} end={{ x: 0, y: 0 }} locations={[0,1]} colors={[ `rgba(255,255,255, 0.2)`, `rgba(255,255,255, 0)`]} />
                <SharedElement id={`cover.${id}.photo`}>
                    <Cover 
                        width={50}
                        height={75}
                        cover={cover} 
                        blurHash={blurHash} 
                        coverHash={coverHash} 
                        style={{ borderRadius: 5, borderWidth: 0, borderColor: '#fff', marginLeft: 7, marginTop : 7 }} />
                </SharedElement>
                <View style={styles.meta}>
                    <MontSBold numberOfLines={2} style={{ color: titleColor, marginBottom: 3 }} text={title} />
                    <InterReg numberOfLines={2} style={{ color: authorColor, fontSize: 13 }} text={author} />
                </View>
            </TouchableOpacity> 
    )
})

const styles = StyleSheet.create({
    container: { 
        height: 89,
        flexDirection: 'row',
        marginRight: 10, 
        borderRadius: 10, 
    },
    meta: {
        paddingTop: 10, 
        paddingHorizontal: 12,
        paddingLeft: 12, 
        flex: 1
    }
})

export default memo(ActivityBookList);