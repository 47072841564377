import React, { useContext } from 'react';
import { addMenu } from '../utils/menuConfigs';
import Menu from './Menu';
import { isNative } from '../utils/';
import AppSettingsContext from '../context/AppSettingsContext';

export default function MenuAdd({ style, TriggerButton, navigation, placement='auto' }) {
    const {t} = useContext(AppSettingsContext);
    const addBoookScreen = isNative() ? 'Scan' : 'AddBookSearch';
    const onAddBook     = () => navigation.navigate('Modal', { screen: addBoookScreen })
    const onAddShelf    = () => navigation.navigate('Modal', { screen: 'AddShelf' })
    const onAddList     = () => navigation.navigate('Modal', { screen: 'AddList' })
    return (
        <Menu
            placement={placement}
            menuOptions={ 
                addMenu({ 
                    actions: { 
                        onAddBook,
                        onAddShelf,
                        onAddList
                    },
                    t
                }) 
            }
            style={ style }
            TriggerButton={ TriggerButton } />
    )
}