import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deleteListApi } from '../actions/deleteList';
import { listMenu } from '../utils/menuConfigs';
import AppSettingsContext from '../context/AppSettingsContext';

import Menu from './Menu';

export default function MenuList({ style, list, TriggerButton, deletable, deleteDone, navigation, placement='bottom' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const onDelete = () => { dispatch(deleteListApi({ listId: list.id })).then(deleteDone); }
    const onUpdate = () => { navigation.navigate('Modal', { screen: 'UpdateList', params: { listId: list.id }}) }
    return (
        <Menu
            placement='bottom'
            triggerButtonHitSlop={{ top: 8, left: 3, right: 10, bottom: 7 }}
            TriggerButton={ TriggerButton }
            menuOptions={ 
                listMenu({ 
                    dispatch,
                    navigation, 
                    deletable, 
                    actions: { 
                        onDelete, 
                        onUpdate 
                    },
                    t
                })
            }
        />
    )
}