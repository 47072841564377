import React, { useMemo, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortByDate } from '../../utils';
import { useIsLoading, useGetDataOnNavFocus, useBooks } from '../../hooks';
import BooksList from '../../components/BooksList';
import { selectBookLikesByMe } from '../../selectors' ;
import { getUserBookLikesApi, getUserBookLikesInProgress } from '../../actions/getUserBookLikes';
import Empty from '../../components/Empty';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.favourites';

export default function FavouritesScreen({ navigation }) {
    const [refreshing, setRefreshing] = useState(false);
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const isGettingBookLikes = useIsLoading(getUserBookLikesInProgress);
    const bookLikes = useSelector(selectBookLikesByMe);
    const bookLikesSorted = useMemo(() => sortByDate(bookLikes, 'created'), [bookLikes]);
    const books = useBooks(bookLikesSorted.map(({bookId}) => bookId));
    const getUserBookLikes = (checkCaching=true, showRefreshing=false) => { 
        if(!isGettingBookLikes) {
            showRefreshing && setRefreshing(true)
            dispatch(getUserBookLikesApi({ checkCaching, showLoading: false })).then(() => setRefreshing(false));
        }
    };
    useGetDataOnNavFocus(navigation, getUserBookLikes);
    const onRefresh = () => getUserBookLikes(false);
    const renderEmpty = () => (
        <Empty  
            title={ t(`${tBase}.empty.title`) }
            body={ t(`${tBase}.empty.body`) }
            img={ require('../../assets/empty.png') } />
    )
    return (
        <BooksList
            books={books} 
            refreshing={refreshing}
            onRefresh={onRefresh}
            renderEmpty={renderEmpty}
            navigation={navigation} />
    )
}