import { createReducer } from '@reduxjs/toolkit'
import { requestBorrowBookSuccess } from '../actions/requestBorrowBook';
import { acceptBorrowRequestSuccess } from '../actions/acceptBorrowRequest';
import { hasBorrowRequests, removeBorrowRequest, matchResetStore } from './matchers';

const initialState = [];

export const borrowRequests = createReducer(initialState, (builder) => {
    builder
    .addCase(requestBorrowBookSuccess, (state, action) => {
        state.push(action.payload.borrowRequest);
    })
    .addCase(acceptBorrowRequestSuccess, (state, action) => {
        const requestIndex = state.findIndex(request => request.id == action.payload.borrowRequest.id);
        if(requestIndex > -1) state[requestIndex] = action.payload.borrowRequest;
    })
    .addMatcher(removeBorrowRequest, (state, action) => {
        return state.filter(borrowRequest => borrowRequest.id != action.payload.borrowRequestId);
    })
    .addMatcher(hasBorrowRequests, (_, action) => {
        return action.payload.borrowRequests;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})