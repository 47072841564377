import { GET_FOLLOW_USER_REQUESTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getFollowUserRequestsInProgress = createAction('GET_FOLLOW_USER_REQUESTS_IN_PROGRESS');
export const getFollowUserRequestsSuccess = createAction('GET_FOLLOW_USER_REQUESTS_SUCCESS');
export const getFollowUserRequestsFailure = createAction('GET_FOLLOW_USER_REQUESTS_FAILURE');

export function getFollowUserRequestsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getFollowUserRequestsInProgress, getFollowUserRequestsSuccess, getFollowUserRequestsFailure],
            name: 'getFollowUserRequests',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}