import React, { useEffect, useState, useMemo, memo, useContext } from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getBookOwnersApi } from '../actions/getBookOwners';
import { GRAY_LIGHT, MAIN_COLOR } from '../constants';
import { Ionicons } from '@expo/vector-icons';
import MontSBold from './MontserratBold';
import TitleLine from './TitleLine';
import Avatar from './Avatar';
import Button from './Button';
import { sortAlphabetically, navigateToUser } from '../utils';
import {useIsMounted} from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

function AlsoOwnBook({ bookId, bookOwnerId, navigation }) {
    const {t} = useContext(AppSettingsContext);
    const [users, setUsers] = useState([]);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const ownedByLoggedUser = bookOwnerId == loggedUserId;
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    useEffect(() => { 
        setTimeout( () => {
            isMounted() && dispatch(getBookOwnersApi({ bookId, showLoading: false })).then((json) => isMounted() && done(json)) 
        }, 1200);
    }, []);
    function done(json) {
        if(json?.success) {
            setUsers(json.users);
        }
    }
    const sortedUsers = useMemo(() => {
        if(!users.length) {
            return []; 
        } else if(ownedByLoggedUser) {
            return sortAlphabetically(users, 'nickname');
        } else {
            const owner = users.find(user => user.id == bookOwnerId)
            if(owner) return [owner, ...sortAlphabetically(users.filter(({id}) => id != bookOwnerId))];
            return sortAlphabetically(users.filter(({id}) => id != bookOwnerId))
        }
    }, [users])
    const title = bookOwnerId == loggedUserId ? t('screens.book.otherOwners.alsoOwnBook') : t('screens.book.otherOwners.ownThisBook');
    return (
        <View style={styles.container}>
            <TitleLine style={{ marginHorizontal: 20 }} title={title} />
            <ScrollView 
                horizontal={ true } 
                style={{ alignSelf: 'stretch' }}
                contentContainerStyle={{ paddingLeft: 20, paddingBottom: 20, justifyContent: 'flex-start'}}>
                { sortedUsers.map(({id, avatar, nickname, blurHash}) => (
                        <User 
                            key={id}
                            userId={id} 
                            nickname={nickname} 
                            blurHash={blurHash}
                            navigation={navigation}
                            isCurrentBookOwner={!ownedByLoggedUser && bookOwnerId == id}
                            avatar={avatar} />
                    )
                )}
            </ScrollView>
        </View>
    )
}

const User = memo(({ nickname, avatar, isCurrentBookOwner, blurHash, userId, navigation }) => {
    const {t} = useContext(AppSettingsContext);
    const handleUserPress = () => {
        navigateToUser(navigation, { userId });
    }
    return (
        <View style={[ styles.user, { borderColor: isCurrentBookOwner ? '#eaeaea' : GRAY_LIGHT }]}>
            <View style={styles.avatar}>
                <Avatar avatar={avatar} blurHash={blurHash}  avatarSize={60} />
                { isCurrentBookOwner &&
                <View style={styles.tick}>
                    <Ionicons name="checkmark" size={17} color="#fff"  />
                </View>
                }
            </View>
            <MontSBold style={styles.nickname} numberOfLines={1} text={nickname} />
            <Button 
                text={ t('screens.book.otherOwners.viewUser') } 
                size='xSmall'
                onPress={handleUserPress} 
                buttonStyle={{ marginBottom: 0 }} />
        </View>
    )
})

export default memo(AlsoOwnBook, (prevProps, nextProps) => prevProps.bookId == nextProps.bookId )

const styles = StyleSheet.create({
    container: {
        paddingTop: 30, 
        alignItems: 'center', 
        alignSelf: 'stretch'
    },  
    user: {
        padding: 15, 
        minWidth: 114, 
        maxWidth: 120, 
        borderWidth: 3, 
        borderRadius: 10, 
        marginRight: 14, 
        alignItems: 'center', 
        backgroundColor: GRAY_LIGHT 
    },
    avatar: {
        borderRadius: 34, 
        borderWidth: 4, 
        borderColor: '#fff'
    },
    tick: {
        width: 24, 
        height: 24, 
        borderRadius: 12, 
        backgroundColor: MAIN_COLOR,
        alignItems: 'center', 
        justifyContent: 'center', 
        position: 'absolute', 
        top: -7, 
        left: -7 
    },
    nickname: {
        marginTop: 6, 
        marginBottom: 12
    }
})