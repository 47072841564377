import React, { memo, useState, useCallback } from 'react'
import { View, StyleSheet } from 'react-native'
import Img from './Img'
import { Ionicons } from '@expo/vector-icons';
import { DOMAIN } from '../constants';
import { getAverageColor } from '../utils/blurhash';

const Avatar = memo(({ avatarSize, avatar, blurHash, avatarStyle={} }) => {
    const [backgroundColor, setBackgroudColor] = useState(blurHash ? `rgb(${getAverageColor(blurHash).join(',')})` : '#fff');
    const handleImageLoadEnd = useCallback(() => setBackgroudColor('#fff'), []);
    const uri = (() => {
        if(!avatar) {
            return '';
        } else if(avatar.indexOf('http') > -1) {
            return avatar;
        }
        return `${DOMAIN}${avatar}`;
    })()
	return (
		<View style={ [styles.container, avatarStyle, { width: avatarSize, height: avatarSize, borderRadius: avatarSize/2}] }>
            { avatar ?
                <Img style={{ width: avatarSize, height: avatarSize, backgroundColor }} onLoadEnd={handleImageLoadEnd} ImageResizeMode='cover' {...{uri}} /> :
                <Ionicons name='md-person' size={ avatarSize * 0.6 } color='rgba(255,255,255,0.7)' />
            }
        </View>
	)
}, (prevProps, nextProps) => {
    return prevProps.avatar === nextProps.avatar;
})

export default Avatar

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'lightgray', 
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center'
    }
})