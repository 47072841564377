import { GET_MY_GROUPS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getMyGroupsInProgress = createAction('GET_MY_GROUPS_IN_PROGRESS');
export const getMyGroupsSuccess = createAction('GET_MY_GROUPS_SUCCESS');
export const getMyGroupsFailure = createAction('GET_MY_GROUPS_FAILURE');

export function getMyGroupsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getMyGroupsInProgress, getMyGroupsSuccess, getMyGroupsFailure],
            name: 'getMyGroups',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}