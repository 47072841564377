import { 
    createSelector, 
    // createSelectorCreator, 
    // defaultMemoize 
} from 'reselect';

import { 
    sortShelvesBooks, 
    sortGroupCollectionBooks, 
    sortByDate, 
    sortAlphabetically, 
    sortGroupCollections, 
    sortKeywordsAlphabetically,
    matchBooksByPatternAndFilters
} from '../utils';
import Dayjs from 'dayjs';

import { BOOK_ORDER_OPTIONS } from '../constants';

const getLoggedUserId = (state) => state.loggedUserId;
const getShelves = (state) => state.shelves
const getLists = (state) => state.lists
const getUsers = (state) => state.users
const getBooks = (state) => state.books
const getLends = (state) => state.lends
const getBorrowRequests = (state) => state.borrowRequests
const getFollowRequests = (state) => state.followRequests
const getMessages = (state) => state.messages
const getDiscussions = (state) => state.discussions
const getGroups = (state) => state.groups
const getKeywords = (state) => state.keywords
const getUserKeywords = (state) => state.userKeywords
const getGroupInvitations = (state) => state.groupInvitations
const getGroupRequests = (state) => state.groupRequests
const getBookLikes = (state) => state.bookLikes
const getBookReviews = (state) => state.bookReviews
const getSearches = (state) => state.search;
const getAdddedBooksHistory = (state) => state.addedBooksHistory;
const getBookReviewHeights = (state) => state.settings.bookReviewHeights;
const getTags = (state) => state.tags;
const getBookProdiverResults = (state) => state.bookProviderResults;
const getGroupCollections = (state) => state.groupCollections;
const getGroupProducts = (state) => state.groupProducts;
const getUnreadDiscussions = (state) => state.unreadNotifs.discussions;
const getUnreadFollowRequests = (state) => state.unreadNotifs.followRequests;
const getUnreadGroupRequests = (state) => state.unreadNotifs.groupRequests;
const getUnreadGroupInvitations = (state) => state.unreadNotifs.groupInvitations;
const getUnreadBorrowRequests = (state) => state.unreadNotifs.borrowRequestsSent;
const getUnreadLends = (state) => state.unreadNotifs.lends;
const getUnreadBorrowRequestsAccepted = (state) => state.unreadNotifs.borrowRequestsAccepted;
const getUnreadLendReturnRequests = (state) => state.unreadNotifs.lendReturnRequests;
const getUnreadLendReturnsIndicatedByBorrower = (state) => state.unreadNotifs.lendReturnsIndicatedByBorrower;
const getUnreadConsiergeBorrows = (state) => state.unreadNotifs.consiergeBorrows;
const getGroupBookAttributes = (state) => state.groupBookAttributes;

// const createDebugSelector = createSelectorCreator(defaultMemoize, {
//     equalityCheck: (previousVal, currentVal) => {
//         const rv = currentVal === previousVal;
//         if (!rv) console.log('Selector param value changed', currentVal, previousVal);
//         return rv;
//     },
// });

export const selectAccount = createSelector(
    [getLoggedUserId, getUsers],
    (loggedUserId, users) => {
        return users[loggedUserId] || undefined
    }
)  

export const selectAccountShelves = createSelector(
    [getLoggedUserId, getShelves],
    (loggedUserId, shelves) => {
        const accountShelves = [];
        for(let key in shelves) {
            const shelf = shelves[key];
            if(shelf.ownerId == loggedUserId) {
                accountShelves.push(shelf);
            }
        }
        return sortAlphabetically(accountShelves, 'name')
    }
)

export const selectLending = createSelector(
    [getLends, getLoggedUserId],
    (lends, loggedUserId) => {
        const lending = [];
        for(let lend of lends) {
            if(lend.fromId == loggedUserId && lend.returnedDatetime == null) {
                lending.push(lend);
            }
        }
        return lending;
    }
)

export const selectLent = createSelector(
    [getLends, getLoggedUserId],
    (lends, loggedUserId) => {
        const lent = [];
        for(let lend of lends) {
            if(lend.fromId == loggedUserId && lend.returnedDatetime) {
                lent.push(lend);
            }
        }
        return lent;
    }
)

export const selectBooksFromShelves = createSelector(
    [selectAccountShelves, getBooks],
    (shelves, books) => {
        let foundBooks = [];
        for(const shelf of shelves) {
            const shelfBooks = [];
            shelf.bookIds.forEach(id => books[id] && shelfBooks.push(books[id]));
            foundBooks = [...foundBooks, ...shelfBooks];
        }
        return foundBooks
    }
)

export const selectFilteredBooksForShelves = createSelector(
    [selectAccountShelves, getBooks, selectLending, (_, props) => props],
    (shelves, books, lending, { pattern, filters }) => {
        const shelvesWithBooks = {};
        let allBooksMatchedPattern = [];
        let allBooks = [];
        for(const shelf of shelves) {
            const shelfBooks = [];
            shelf.bookIds.forEach(id => books[id] && shelfBooks.push(books[id]));
            allBooks = [...allBooks, ...shelfBooks];
            const sortedBooks = sortShelvesBooks(shelfBooks, shelf.sort);
            const { booksMatchedPattern, filteredBooks } = matchBooksByPatternAndFilters(sortedBooks, pattern, filters, lending);
            allBooksMatchedPattern = [...allBooksMatchedPattern, ...booksMatchedPattern];
            shelvesWithBooks[shelf.id] = filteredBooks;
        }
        return [allBooks, shelvesWithBooks, allBooksMatchedPattern];
    }
)

export const selectAccountLists = createSelector(
    [getLoggedUserId, getLists],
    (loggedUserId, lists) => {
        const accountLists = [];
        for(let key in lists) {
            const list = lists[key];
            if(list.ownerId == loggedUserId) {
                accountLists.push(list);
            }
        }
        return accountLists;
    }
)

export const selectBooksFromLists = createSelector(
    [selectAccountLists, getBooks],
    (lists, books) => {
        let foundBooks = [];
        for(const list of lists) {
            const listBooks = [];
            list.bookIds.forEach(id => books[id] && listBooks.push(books[id]));
            foundBooks = [...foundBooks, ...listBooks];
        }
        return foundBooks
    }
)

export const selectFilteredBooksForLists = createSelector(
    [selectAccountLists, getBooks, selectLending, (_, props) => props],
    (lists, books, lending, { pattern, filters }) => {
        const listsWithBooks = {};
        let allBooksMatchedPattern = [];
        let allBooks = [];
        for(const list of lists) {
            const listBooks = [];
            list.bookIds.forEach(id => books[id] && listBooks.push(books[id]));
            allBooks = [...allBooks, ...listBooks];
            const sortedBooks = sortShelvesBooks(listBooks, list.sort);
            const { booksMatchedPattern, filteredBooks } = matchBooksByPatternAndFilters(sortedBooks, pattern, filters, lending);
            allBooksMatchedPattern = [...allBooksMatchedPattern, ...booksMatchedPattern];
            listsWithBooks[list.id] = filteredBooks;
        }
        return [allBooks, listsWithBooks, allBooksMatchedPattern];
    }
)

export const selectAccountBooks = createSelector(
    [getLoggedUserId, getBooks],
    (loggedUserId, books) => {
        const accountBooks = [];
        for(let key in books) {
            const book = books[key];
            if(book.ownerId == loggedUserId) {
                accountBooks.push(book);
            }
        }
        return accountBooks
    }
)

export const selectFilteredAccountBooks = createSelector(
    [selectAccount, selectAccountBooks, selectLending, (_, props) => props],
    (account, books, lending, { pattern, filters }) => {
        const sort = account?.params?.sortLibraryBooks ?? BOOK_ORDER_OPTIONS[0].value;
        const sortedBooks = sortShelvesBooks(books, sort);
        const { booksMatchedPattern, filteredBooks } = matchBooksByPatternAndFilters(sortedBooks, pattern, filters, lending);
        return [books, filteredBooks, booksMatchedPattern];
    }
)

export const selectRecentlyAddedBooks = createSelector(
    [getAdddedBooksHistory, getBooks],
    (adddedBooksHistory, books) => {
        const twentyFourHoursAgo = Dayjs().subtract(24, 'hour'); 
        const recentlyAddedBooks = [];
        for(const addedBook of adddedBooksHistory) {
            const book = books[addedBook.bookId];
            if(book) {
                const bookCreated = Dayjs(book.created);
                if(book && bookCreated.isAfter(twentyFourHoursAgo)) {
                    recentlyAddedBooks.push(book);
                }
            }
        }
        return recentlyAddedBooks;
    }
)

export const makeSelectSortedShelfBooks = () => makeSelectSortedBooks();

export const makeSelectSortedListBooks = () => makeSelectSortedBooks();

function makeSelectSortedBooks() {
    return createSelector(
        [getBooks, (_, props) => props],
        (books, { sort, bookIds }) => {
            const shelfBooks = [];
            for(let bookId of bookIds) {
                if(books[bookId]) shelfBooks.push(books[bookId]);
            }
            return sortShelvesBooks(shelfBooks, sort);
        }
    )
}

export const makeSelectCurrentBookLend = () => {
    return createSelector(
        [getLends, (_, props) => props],
        (lends, { bookId }) => {
            for(let lend of lends) {
                if(lend.bookId == bookId && lend.returnedDatetime == null) return lend;
            }
            return undefined;
        }
    )
}

export const makeSelectCurrentBookBorrowRequest = () => {
    return createSelector(
        [getBorrowRequests, (_, props) => props],
        (borrowRequests, { bookId }) => {
            for(let borrowRequest of borrowRequests) {
                if(borrowRequest.bookId == bookId) return borrowRequest;
            }
            return undefined;
        }
    )
}

export const makeSelectBookBorrowRequestByMe = () => {
    return createSelector(
        [getBorrowRequests, getLoggedUserId, (_, props) => props],
        (borrowRequests, loggedUserId, { bookId }) => {
            for(let borrowRequest of borrowRequests) {
                if(borrowRequest.bookId == bookId && borrowRequest.fromId == loggedUserId) return borrowRequest;
            }
            return undefined;
        }
    )
}

export const makeSelectBookReviewByMe = () => {
    return createSelector(
        [getBookReviews, getLoggedUserId, (_, props) => props],
        (bookReviews, loggedUserId, { bookId }) => {
            for(let [key, bookReview] of Object.entries(bookReviews)) {
                if(bookReview.bookId == bookId && bookReview.byId == loggedUserId) return bookReview;
            }
            return;
        }
    )
}

export const makeSelectHighestBookReview = () => {
    return createSelector(
        [getBookReviewHeights, (_, props) => props],
        (bookReviewHeights, { bookReviewIds }) => {
            let heights = [];
            for(let bookReviewId of bookReviewIds) {
                const height = bookReviewHeights[bookReviewId];
                if(height) heights.push(height);
            }
            return heights.sort((a,b) => b-a);
        }
    )
}

export const makeSelectTagsForBookReview = () => {
    return createSelector(
        [getTags, getBookReviews, (_, props) => props],
        (tags, bookReviews, { bookReviewId }) => {
            const bookReview = bookReviews[bookReviewId];
            let found = [];
            if(bookReview) {
                for(const tagId of bookReview.tags) {
                    const tag = tags.find(({id}) => id == tagId);
                    if(tag) found.push(tag);
                }
            }
            return found;
        }
    )
}

export const selectBorrowing = createSelector(
    [getLends, getLoggedUserId],
    (lends, loggedUserId) => {
        const borrowing = [];
        for(let lend of lends) {
            if(lend.toId == loggedUserId && lend.returnedDatetime == null) {
                borrowing.push(lend);
            }
        }
        return borrowing;
    }
)

export const selectBorrowed = createSelector(
    [getLends, getLoggedUserId],
    (lends, loggedUserId) => {
        const borrowed = [];
        for(let lend of lends) {
            if(lend.toId == loggedUserId && lend.returnedDatetime) {
                borrowed.push(lend);
            }
        }
        return borrowed;
    }
)

export const selectBorrowRequests = createSelector(
    [getBorrowRequests, getLoggedUserId],
    (borrowRequests, loggedUserId) => {
        const requests = [];
        for(let borrowRequest of borrowRequests) {
            if(borrowRequest.toId == loggedUserId || borrowRequest.fromId == loggedUserId) {
                requests.push(borrowRequest);
            }
        }
        return requests;
    }
)

export const makeSelectBookUsingId = () => {
    return createSelector(
        [getBooks, (_, props) => props],
        (books, { bookId}) => {
            return books[bookId] ?? undefined;
        }
    )
}

export const makeSelectBooksUsingIds = () => {
    return createSelector(
        [getBooks, (_, props) => props],
        (books, { bookIds }) => {
            let found = [];
            bookIds.forEach(bookId => {
                if(books[bookId]) found.push(books[bookId]);
            })
            return found;
        }
    )
}

export const makeSelectUserUsingId = () => {
    return createSelector(
        [getUsers, (_, props) => props],
        (users, { userId }) => {
            return users[userId] ?? undefined;
        }
    )
}

export const makeSelectUsersUsingIds = () => {
    return createSelector(
        [getUsers, (_, props) => props],
        (users, { userIds }) => {
            let found = [];
            userIds.forEach(userId => {
                if(users[userId]) found.push(users[userId]);
            })
            return found;
        }
    )
}

export const makeSelectUserShelves = () => {
    return createSelector(
        [getShelves, (_, props) => props],
        (shelves, { userId }) => {
            const userShelves = [];
            for(let key in shelves) {
                const shelf = shelves[key];
                if(shelf.ownerId == userId) {
                    userShelves.push(shelf);
                }
            }
            return userShelves
        }
    )
}

export const makeSelectUserLists = () => {
    return createSelector(
        [getLists, (_, props) => props],
        (lists, { userId }) => {
            const userLists = [];
            for(let key in lists) {
                const shelf = lists[key];
                if(shelf.ownerId == userId) {
                    userLists.push(shelf);
                }
            }
            return userLists
        }
    )
}

export const makeSelectAccountListIdsForBook = () => {
    return createSelector(
        [selectAccountLists, (_, props) => props],
        (lists, { bookId }) => {
            const listIds = [];
            for(let list of lists) {
                if(list.bookIds.includes(bookId)) {
                    listIds.push(list.id);
                }
            }
            return listIds
        }
    )
}

export const makeSelectAccountBookUsingProviderIds = () => {
    return createSelector(
        [selectAccountBooks, (_, props) => props],
        (books, { providerRessourceIds }) => {
            const book = (() => {
                for(let book of books) {
                    if(book.providerRessourceIds.some(providerRessourceId => providerRessourceIds.includes(providerRessourceId))) {
                        return book;
                    }
                }
                return;
            })();
            return book;
        }
    )
}

export const makeSelectFollowRequestFromUserId = () => {
    return createSelector(
        [getLoggedUserId, getFollowRequests, (_, props) => props],
        (loggedUserId, followRequests, { userId }) => {
            const found = [];
            followRequests.forEach((request) => {
                if([request.toId, request.fromId].includes(userId) && loggedUserId != userId) {
                    found.push(request);
                }
            })
            return found;
        }
    )
}

export const selectRemainingProfileElementsToFill = createSelector(
	[selectAccount],
	(account) => {
		let remainingElements = 0;
		if(!account) return remainingElements
		if(!account.description) remainingElements += 1
		if(!account.location) remainingElements += 1
		if(!account.nickname) remainingElements += 1
		if(!account.avatar) remainingElements += 1
		return remainingElements
	}
)

export const selectFollowRequestsToMe = createSelector(
    [getFollowRequests, getLoggedUserId],
    (followRequests, loggedUserId) => {
        let found = [];
        followRequests.forEach(followRequest => {
            if(followRequest.toId == loggedUserId) {
                found.push(followRequest);
            }
        });
        return found;
    }
)

export const makeSelectMessagesForDiscussion = () => {
    return createSelector(
        [getMessages, (_, props) => props],
        (messages, { discussionId }) => {
            let found = [];
            messages.forEach(message => {
                if(message.discussionId == discussionId) {
                    found.push(message)
                }
            }) 
            return found;
        }
    )
}

export const makeSelectDiscussionUsingId = () => {
    return createSelector(
        [getDiscussions, (_, props) => props],
        (discussions, { discussionId }) => {
            let found;
            discussions.forEach(discussion => {
                if(discussion.id == discussionId) {
                    found = discussion;
                }
            })
            return found;
        }
    )
}

export const makeSelectExistingDiscussion = () => {
    return createSelector(
        [getDiscussions, getLoggedUserId, (_, props) => props],
        (discussions, loggedUserId, { userId }) => {
            let found;
            discussions.forEach(discussion => {
                if(discussion.userIds.includes(loggedUserId) && discussion.userIds.includes(userId)) {
                    found = discussion;
                }
            })
            return found;
        }
    )
}

export const selectDiscussionIdsSortedByMostRecentMessage = createSelector(
    [getMessages, getDiscussions],
    (messages, discussions) => {
        let found = [];
        const sortedMessage = sortByDate([...messages], 'created');
        sortedMessage.forEach(message => {
            if(!found.includes(message.discussionId)) {
                found.push(message.discussionId);
            }
        })
        discussions.forEach(discussion => {
            if(!found.includes(discussion.id)) {
                found.push(discussion.id);
            }
        })
        return found;
    }
)

export const selectUnreadMessageCount = createSelector(
    [getUnreadDiscussions],
    (unreadDiscussions) => {
        let count = 0;
        if(unreadDiscussions) {
            Object.values(unreadDiscussions).forEach(discussion => {
                count += discussion.length;
            })
        }
        return count;
    }
)

export const selectUnreadFollowRequests = createSelector(
    [getUnreadFollowRequests],
    (unreadFollowRequests) => {
        return unreadFollowRequests || [];
    }
)

export const selectUnreadGroupRequests = createSelector(
    [getUnreadGroupRequests],
    (unreadGroupRequests) => {
        return unreadGroupRequests || [];
    }
)

export const selectUnreadGroupInvitations = createSelector(
    [getUnreadGroupInvitations],
    (unreadGroupInvitations) => {
        return unreadGroupInvitations || [];
    }
)

export const selectUnreadBorrowRequests = createSelector(
    [getUnreadBorrowRequests],
    (unreadBorrowRequests) => {
        return unreadBorrowRequests || [];
    }
)

export const selectUnreadBorrowRequestsAccepted = createSelector(
    [getUnreadBorrowRequestsAccepted],
    (unreadBorrowRequestsAccepted) => {
        return unreadBorrowRequestsAccepted || [];
    }
)

export const selectUnreadLends = createSelector(
    [getUnreadLends],
    (unreadLends) => {
        return unreadLends || [];
    }
)

export const selectUnreadLendReturnRequests = createSelector(
    [getUnreadLendReturnRequests],
    (unreadLendReturnRequests) => {
        return unreadLendReturnRequests || [];
    }
)

export const selectUnreadLendReturnsIndicatedByBorrower = createSelector(
    [getUnreadLendReturnsIndicatedByBorrower],
    (unreadLendReturnsIndicatedByBorrower) => {
        return unreadLendReturnsIndicatedByBorrower || [];
    }
)

export const selectUnreadConsiergeBorrows = createSelector(
    [getUnreadConsiergeBorrows],
    (unreadConsiergeBorrows) => {
        return unreadConsiergeBorrows || [];
    }
)

export const selectMyGroups = createSelector(
    [getGroups, getLoggedUserId],
    (groups, loggedUserId) => {
        let found = [];
        for (const [key, group] of Object.entries(groups)) {
            if(group.memberIds.includes(loggedUserId)) found.push(group);
        }
        return found;
    }
)

export const makeSelectGroupUsingId = () => {
    return createSelector(
        [getGroups, (_, props) => props],
        (groups, { groupId }) => {
            return groups[groupId];
        }
    )
}

export const makeSelectGroupsUsingIds = () => {
    return createSelector(
        [getGroups, (_, props) => props],
        (groups, { groupIds }) => {
            let found = [];
            groupIds.forEach(groupId => {
                if(groups[groupId]) found.push(groups[groupId]);
            })
            return found;
        }
    )
}

export const makeSelectBooksSharedInGroup = () => {
    return createSelector(
        [getBooks, getLoggedUserId, (_, props) => props],
        (books, loggedUserId, { bookIds }) => {
            let found = [];
            bookIds.forEach(bookId => {
                const book = books[bookId]
                if(book && book.ownerId == loggedUserId) found.push(book);
            })
            return found;
        }
    )
}

export const makeSelectShelfUsingId = () => {
    return createSelector(
        [getShelves, (_, props) => props],
        (shelves, { shelfId }) => {
            return shelves[shelfId] ?? undefined;
        }
    )
}

export const makeSelectListUsingId = () => {
    return createSelector(
        [getLists, (_, props) => props],
        (lists, { listId }) => {
            return lists[listId] ?? undefined;
        }
    )
}

export const makeSelectKeywordsFromBooks = () => {
    return createSelector(
        [getBooks, getKeywords, getUserKeywords, (_, props) => props],
        (books, keywords, userKeywords, { bookIds, sort, locale }) => {
            let found = [];
            if(!bookIds?.length || !keywords?.length) return found;
            let keywordOccurrences = {};
            for(const bookId of bookIds) {
                if(!books[bookId]) break
                const { keywordIds } = books[bookId];
                keywordIds.forEach(keywordId => {
                    const keyword = keywords.find(({ id }) => id === keywordId);
                    const userKeyword = userKeywords.find(({ id }) => id === keywordId);
                    if(keyword || userKeyword) {
                        keywordOccurrences[keywordId] = keywordOccurrences[keywordId] ? keywordOccurrences[keywordId] + 1 : 1;
                        if(keyword && !found.find(({ id }) => keyword.id === id )) {
                            found.push(keyword);
                        } else if (userKeyword && !found.find(({ id }) => userKeyword.id === id)) {
                            found.push(userKeyword);
                        }
                    }
                })
            }
            if(sort === 'alphabetically') return sortKeywordsAlphabetically(found, locale);
            return found.sort((a,b) => keywordOccurrences[b.id] - keywordOccurrences[a.id]);
             
        }
    )
}

export const selectGroupInvitationsToMe = createSelector(
    [getGroupInvitations, getLoggedUserId],
    (groupInvitations, loggedUserId) => {
        let found = [];
        for(const [key, groupInvitation] of Object.entries(groupInvitations)) {
            if(groupInvitation.toId == loggedUserId) found.push(groupInvitation);
        }
        return found;
    }
)

export const selectGroupRequestsByMe = createSelector(
    [getGroupRequests, getLoggedUserId],
    (groupRequests, loggedUserId) => {
        let found = [];
        for(const [key, groupRequest] of Object.entries(groupRequests)) {
            if(groupRequest.fromId == loggedUserId) found.push(groupRequest);
        }
        return found;
    }
)

export const selectGroupRequestsToMe = createSelector(
    [selectMyGroups, getGroupRequests, getLoggedUserId],
    (myGroups, groupRequests, loggedUserId) => {
        let found = [];
        const myAdminGroupIds = myGroups.filter(({adminIds}) => adminIds.includes(loggedUserId)).map(({id}) => id);
        for(const [key, groupRequest] of Object.entries(groupRequests)) {
            if(myAdminGroupIds.includes(groupRequest.groupId)) found.push(groupRequest);
        }
        return found;
    }
)

export const selectAllGroupRequests = createSelector(
    [selectGroupRequestsByMe, selectGroupRequestsToMe],
    (groupRequestByMe, groupRequestToMe) => {
        return groupRequestByMe.concat(groupRequestToMe);
    }
)

export const selectPublicGroups = createSelector(
    [getGroups],
    (groups) => {
        let found = [];
        for(const [key, group] of Object.entries(groups)) {
            if( group.public && 
                group.description && 
                group.picture && 
                group.bookIds.length &&
                group.memberIds.length > 0 ) {
                    found.push(group);
                }
        }
        return found;
    }
)

export const makeSelectGroupRequestByMe = () => {
    return createSelector(
        [getLoggedUserId, getGroupRequests, (_, props) => props],
        (loggedUserId, groupRequests, { groupId }) => {
            for(const [key, groupRequest] of Object.entries(groupRequests)) {
                if(groupRequest.groupId == groupId && groupRequest.fromId == loggedUserId) return groupRequest;
            }
            return;
        }
    )
}

export const makeSelectInvitationsSentForGroup = () => {
    return createSelector(
        [getGroupInvitations, (_, props) => props],
        (groupInvitations, { groupId }) => {
            const found = [];
            for(const [key, groupInvitation] of Object.entries(groupInvitations)) {
                if(groupInvitation.groupId == groupId) found.push(groupInvitation);
            }
            return sortByDate(found, 'created');
        }
    )
}

export const selectBookLikesByMe = createSelector(
    [getBookLikes, getLoggedUserId],
    (bookLikes, loggedUserId) => {
        let found = [];
        for(const [key, bookLike] of Object.entries(bookLikes)) {
            if(bookLike.byId == loggedUserId) found.push(bookLike);
        }
        return found;
    }
)

export const makeSelectBookLikesOfBook = () => { 
    return createSelector(
        [getBookLikes, (_, props) => props],
        (bookLikes, { bookId, providerBookId }) => {
            let found = [];
            for(const [key, bookLike] of Object.entries(bookLikes)) {
                if(bookLike.bookId == bookId || (providerBookId && bookLike.providerBookId == providerBookId)) {
                    found.push(bookLike);
                }
            }
            return found;
        }
    )
}

export const makeSelectKeywordsForBook = () => {
    return createSelector(
        [getKeywords, getUserKeywords, getBooks, (_, props) => props],
        (keywords, userKeywords, books, { bookId }) => {
            let found = [];
            let keywordIds = books[bookId]?.keywordIds ?? [];
            for(const keywordId of keywordIds) {
                const keyword = keywords.find(({id}) => id == keywordId);
                const userKeyword = userKeywords.find(({id}) => id == keywordId);
                if(keyword) {
                    found.push(keyword);
                } else if(userKeyword) {
                    found.push(userKeyword);
                }
            }
            return found;
        }
    )
}

export const makeSelectReviewsForBook = () => {
    return createSelector(
        [getBookReviews, (_, props) => props],
        (bookReviews, { bookId, providerBookId }) => {
            let found = [];
            for(const [key, bookReview] of Object.entries(bookReviews)) {
                if(bookReview.bookId == bookId || (bookReview.providerBookId != null && bookReview.providerBookId == providerBookId)) found.push(bookReview);
            }
            return sortByDate(found, 'created');
        }
    )
}

export const makeSelectReview = () => {
    return createSelector(
        [getBookReviews, (_, props) => props],
        (bookReviews, { reviewId }) => {
            return bookReviews[reviewId];
        }
    )
}

export const makeSelectSearchResults = () => {
    return createSelector(
        [getSearches, (_, props) => props],
        (searches, { resultKey }) => {
            return searches[resultKey];
        }
    )
}

export const makeSelectBookProviderResultBook = () => {
    return createSelector(
        [getBookProdiverResults, (_, props) => props],
        (bookProderResults, { index }) => {
            if(!bookProderResults || !bookProderResults.books || !bookProderResults.books[index]) return undefined;
            return bookProderResults.books[index];
        }
    )
}

export const makeSelectGroupCollections = () => {
    return createSelector(
        [getGroupCollections, (_, props) => props],
        (groupCollections, { collectionIds, canModify }) => {
            const foundCollections = [];
            for(let collectiondId of collectionIds) {
                const groupCollection = groupCollections[collectiondId];
                if(groupCollection && (groupCollection.bookIds.length || canModify)) foundCollections.push(groupCollection);
            }
            return sortGroupCollections(foundCollections);
        }
    )
}

export const makeSelectGroupCollectionById = () => {
    return createSelector(
        [getGroupCollections, (_, props) => props],
        (groupCollections, { groupCollectionId }) => {
            return groupCollections[groupCollectionId];
        }
    )
}

export const makeSelectGroupCollectionBooks = () => {
    return createSelector(
        [getGroupCollections, getBooks, (_, props) => props],
        (groupCollections, books, { groupCollectionId }) => {
            const groupCollection = groupCollections[groupCollectionId];
            const grouCollectionBooks = [];
            if(groupCollection) {
                for(let bookId of groupCollection.bookIds) {
                    const book = books[bookId];
                    if(book) grouCollectionBooks.push(book);
                }
            } else {
                return [];
            }
            return sortGroupCollectionBooks(grouCollectionBooks, groupCollection.sort, groupCollection.id);
        }
    )
}

export const selectGroupProductFromId = createSelector(
    [getGroupProducts, (_, props) => props],
    (groupProducts, { groupProductId }) => {
        if(groupProductId) {
            return groupProducts.find(groupProduct => groupProduct.id === groupProductId);
        }
        return;
    }
)

export const makeSelectGroupBookAttributes = () => {
    return createSelector(
        [getGroupBookAttributes, getBooks, (_, props) => props],
        (groupBookAttributes, books, { groupId, bookId }) => {
            if(!groupId) return undefined;
            const book = books[bookId];
            const attributes = groupBookAttributes[groupId];
            const location = (() => {
                const found = attributes?.onSiteLocationInfo.find(obj => {
                    return Object.keys(obj)[0] === book.shelfId;
                })
                if(found) return found[book.shelfId];
                return '';
            })();
            return {
                availableOnSite: attributes?.onSiteBookIds?.includes(bookId),
                availableForSelfService: attributes?.selfServiceBookIds?.includes(bookId),
                location
            }
        }
    )
}

export const makeSelectGroupBookAttributesForGroup = () => {
    return createSelector(
        [getGroupBookAttributes, (_, props) => props],
        (groupBookAttributes, { groupId }) => {
            return groupBookAttributes[groupId]
        }
    )
}

export const selectAllKeywordsSorted = createSelector(
    [getKeywords, getUserKeywords, (_, props) => props],
    (keywords, userKeywords, { locale }) => {
        return sortKeywordsAlphabetically(
            [...keywords, ...userKeywords], 
            locale
        );
    }
) 

export const makeSelectUserKeywordUsingId = () => {
    return createSelector(
        [getUserKeywords, (_, props) => props],
        (userKeywords, { userKeywordId }) => {
            return userKeywords.find(({ id }) => id === userKeywordId);
        }
    )
}

export const makeSelectBookCOuntWithUserKeyword = () => {
    return createSelector(
        [selectAccountBooks, (_, props) => props],
        (books, { userKeywordId }) => {
            let count = 0;
            for(let book of books) {
                if(book.keywordIds.includes(userKeywordId)) {
                    count++;
                }
            }
            return count;
        }
    )
}