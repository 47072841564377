import React, { useEffect, useState, memo } from 'react';
import { View, StyleSheet, Pressable, BackHandler } from 'react-native';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { hideAlert } from '../actions';
import InterReg from './InterReg';
import MontserratBold from './MontserratBold';
import Button from './Button';
import { SECONDARY_COLOR } from '../constants';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withTiming,
    Easing,
    runOnJS
} from 'react-native-reanimated';
import { isWeb } from '../utils';

function Alert() {
    let backHandler;
    const [callback, setCallback] = useState(null);
    const opacity = useSharedValue(0);
    const translateY = useSharedValue(1000);
    const alertAminStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(opacity.value, { duration: 300 }),
            transform: [{ 
                translateY: withTiming(
                    translateY.value, 
                    { 
                        duration: 300,
                        easing: Easing.bezier(0.25, 0.1, 0.25, 1) }, 
                    () => opacity.value == 0 && runOnJS(dismissAlert)()
                )
            }]
        }
    })
    const backdropStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(opacity.value, { duration: 250 }),
        }
    })
    const options = useSelector(state => state.alert, shallowEqual);
    let { title='', subTitle='', Content, actions=[], alertStyle, canBeDismissed=true } = options;
    const dispatch = useDispatch();
    useEffect(() => { 
        if((options.title || options.Content) && opacity.value === 0) {
            showAnimation();
        } else if((options?.title && options?.Content) && opacity.value === 1) {
            hideAnimation();
        }
    }, [options])
    useEffect(() => {
        if(isWeb()) {
            window.addEventListener('keydown', closeAlertIfEscapeKeyPressed, { once: true });
            return () => {
                window.removeEventListener('keydown', closeAlertIfEscapeKeyPressed);

            };
        }
    }, [options]);
    useEffect(() => {
        const backAction = () => {
            if(canBeDismissed && (options.title || options.Content)) {
                hideAnimation();
                return true;
            }
            return false;
        }
        if(options.title || options.Content) {
            backHandler = BackHandler.addEventListener(
                'hardwareBackPress',
                backAction,
            );
        }
        return () => backHandler?.remove && backHandler.remove();
    }, [options])
	function dismissAlert() {
        if(options.title || options.Content) {
            dispatch(hideAlert()).then(() => {
                if(callback && typeof callback === 'function') callback();
                backHandler?.remove && backHandler.remove();
            });
        }
	}
    function showAnimation() {
        opacity.value = 1;
        translateY.value = 0;
    }
    function hideAnimation() {
        opacity.value = 0;
        translateY.value = 1000;
    }
    function closeAlertIfEscapeKeyPressed(e) {
        if(e.code == "Escape" && (options.title || options.Content) && canBeDismissed) hideAnimation()
    };
	return (
		<View style={[styles.container, { height: options.title || options.Content ? '100%' : 0 }]}>
            <Pressable style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} onPress={() => canBeDismissed && hideAnimation()}>
                <Animated.View style={[styles.backdrop, backdropStyle]}></Animated.View>
            </Pressable>
            <Animated.View style={[alertAminStyle, styles.alert, alertStyle]}>
                { Content && Content}
                { !!title && <MontserratBold text={ title } style={styles.title} /> }
                { !!subTitle && <InterReg text={ subTitle } style={styles.subtitle} /> }
                { options.actions?.length ?
                    <View style={styles.buttons}>
                        { actions.map((action, index) => {
                            let theme;
                            switch (action.style) {
                                case 'cancel':
                                    theme = 'grayColorBorder';
                                    break;
                                case 'destructive':
                                    theme = 'dangerColor'
                                    break;
                                default:
                                    theme = 'mainColor'
                            }
                            if(action?.theme) theme = action.theme;
                            return (
                                <Button 
                                    text={ action.text }
                                    buttonStyle={{ flex: 1, marginHorizontal: 7, marginBottom: 0 }} 
                                    theme={ theme }
                                    size='medium'
                                    key={ index }
                                    onPress={ () => { 
                                        setCallback(() => action.onPress);
                                        hideAnimation();
                                    }} />
                                )
                            })
                        }
                    </View> : 
                    null 
                }
            </Animated.View>
		</View>
	)
}

export default memo(Alert);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center', 
        justifyContent: 'center', 
        top: 0, 
        left: 0, 
        right: 0, 
        position: 'absolute', 
        overflow: 'hidden',
    },
    backdrop: {
        top: 0, 
        left: 0, 
        right: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.3)'
    },
    alert: {
        backgroundColor: '#fff', 
        borderRadius: 8, 
        width: '80%', 
        maxWidth: 400, 
        paddingVertical: 17, 
        alignSelf: 'center'
    },
    title: { 
        color: SECONDARY_COLOR,
        textAlign: 'center', 
        fontSize: 16, 
        paddingHorizontal: 17 
    },
    subtitle: { 
        color: '#333',
        textAlign: 'center', 
        paddingTop: 7, 
        paddingHorizontal: 17 
    },
    buttons: { 
        flexDirection: 'row', 
        paddingHorizontal: 10, 
        paddingTop: 20
    }
})
