import React, { useEffect, memo, useState, useMemo, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getBookReviewsApi } from '../actions/getBookReviews';
import { MaterialIcons as MIcons, MaterialCommunityIcons as MCIcons } from '@expo/vector-icons';
import { MAIN_COLOR, GRAY_LINE_COLOR, GRAY_TEXT_PALE } from '../constants';
import { confirmDeleteBookReview } from '../utils/alerts';
import { openModalOrPushScreen, getTagLabel } from '../utils';
import { deleteBookReviewApi } from '../actions/deleteBookReview';
import { setBookReviewHeight } from '../actions';
import Swiper from 'react-native-web-swiper';
import Button from './Button';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import { useBookReviewByMe, useBookReviewHeights, useBookReviewTags, useBookReviews, useUser, useIsMounted, useBookReview } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

function BookReviews({navigation, bookId, providerBookId, tBase }) { 
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const reviews = useBookReviews(bookId, providerBookId);
    const myBookReview = useBookReviewByMe(bookId);
    const handleAddBookReview = () => openModalOrPushScreen(navigation, 'AddBookReview', { bookId });
    const isMounted = useIsMounted();
    const bookReviewHeights = useBookReviewHeights(reviews.map(({id}) => id));
    useEffect(() => { 
        setTimeout( () => {
            isMounted() && dispatch(getBookReviewsApi({ bookId, showLoading: false })) 
        }, 800);
    }, [bookId]);
    const renderReviews = useMemo(() => (
        reviews.map(({ id }) => (
            <Review
                key={id} 
                id={id}
                tBase={tBase}
                navigation={navigation} />
            )
        ) 
    ), [reviews])
    const highestSlide = bookReviewHeights.length ? bookReviewHeights[0] : 300;
    const swiperHeight = Math.max(120, highestSlide + 40);
    return (
        !reviews.length ?
            <View style={{ alignItems: 'center', padding: 30 }}>
                <InterReg style={{marginBottom: 15, color: '#666'}} text={ t(`${tBase}.noReviewsYet`) } />
                <Button
                    size='xSmall'
                    text={ t(`${tBase}.writeReviewBtn`) } 
                    onPress={ handleAddBookReview }
                    requiresAccount={ true }
                    buttonStyle={{ alignSelf: 'center', marginBottom: 0, paddingHorizontal: 12 }} />
            </View> :
            <View>
                <Swiper 
                    controlsProps={{
                        dotActiveStyle: { backgroundColor: MAIN_COLOR },
                        PrevComponent: ({onPress}) => <Button size='xSmall' buttonStyle={{ marginBottom: 0 }} theme='mainColorSubtle' onPress={onPress} text={t(`${tBase}.previousReviewBtn`) }/>,
                        NextComponent: ({onPress}) => <Button size='xSmall' buttonStyle={{ marginBottom: 0 }} theme='mainColorSubtle' onPress={onPress} text={t(`${tBase}.nextReviewBtn`) }/>
                    }}
                    containerStyle={{ height: swiperHeight }} 
                    slideWrapperStyle={{ height: highestSlide }} 
                    innerContainerStyle={{ height: swiperHeight }}>
                    { renderReviews }
                </Swiper>
                { !myBookReview &&
                <Button
                    size='xSmall'
                    text={ t(`${tBase}.writeReviewBtn`) } 
                    onPress={ handleAddBookReview }
                    buttonStyle={{ alignSelf: 'center', marginBottom: 0, paddingHorizontal: 12 }} />
                }
            </View>
    )
}

export default memo(BookReviews, (prevProps, nextProps) => {
    return prevProps.bookId === nextProps.bookId && prevProps.providerBookId === nextProps.providerBookId;
});

function Review({id, navigation, tBase}) {
    const {t, l, locale} = useContext(AppSettingsContext);
    const review = useBookReview(id);
    if(!review) return null;
    const { body, created, byId, recommended } = review;
    const [height, setHeight] = useState(null);
    const dispatch = useDispatch();
    const user = useUser(byId);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const loggedUserIsAuthor = byId == loggedUserId;
    const deleteBookReview = () => { dispatch(deleteBookReviewApi({ bookReviewId: id })) }
    const handleUpdateReview = () => openModalOrPushScreen(navigation, 'UpdateBookReview', { bookReviewId: id });
    const handleDeleteReview = () => confirmDeleteBookReview(deleteBookReview, dispatch);
    const renderTags = (tag) => (
        <>
            <InterReg 
                key={tag.id}
                style={{ color: MAIN_COLOR, paddingRight: 5 }} 
                text={`#${getTagLabel(tag, locale)}`} />
            {'  '}
        </>
    );
    const bookReviewTags = useBookReviewTags(id);
    useEffect(() => {
        if(height) dispatch(setBookReviewHeight({ id, height }))
    }, [height]);
    return (
        <View style={{ paddingHorizontal: 20, paddingBottom: 20 }} onLayout={ ({ nativeEvent: { layout: { height }}}) => setHeight(height)}>
            <View style={styles.reviewTop}>
                <MontSBold text={ user.nickname} style={{ marginRight: 10 }} />
                <MIcons size={18} name='thumb-up-alt' style={{ marginRight: 5 }} color={ recommended ? MAIN_COLOR : GRAY_TEXT_PALE } />
                <MIcons size={18} name='thumb-down-alt' color={ !recommended ? MAIN_COLOR : '#ccc' } />
                <InterReg text={ l(`date.formats.long`, created) } style={styles.reviewDate} />
            </View>
            <InterReg text={body} />
            <View style={{ flexDirection: 'row', height: 32, marginTop: 12 }}>
                <Text>
                    { bookReviewTags.map(renderTags) }
                </Text>
                { loggedUserIsAuthor &&
                <View style={{flexDirection: 'row',   marginLeft: 'auto'}}>
                    <Button 
                        text={ t(`${tBase}.modifyReviewBtn`) } 
                        size='xSmall'
                        onPress={handleUpdateReview} 
                        buttonStyle={{ marginBottom: 0}} />
                    <View style={{ marginHorizontal: 12, marginVertical: 3, borderLeftWidth: StyleSheet.hairlineWidth, borderLeftColor: GRAY_LINE_COLOR }} />
                    <TouchableOpacity hitSlop={{ top: 8, right: 6, bottom: 8, left: 6 }} style={{ marginTop: 2}}>
                        <MCIcons onPress={handleDeleteReview} color={GRAY_TEXT_PALE} name='delete' size={28} />
                    </TouchableOpacity>
                </View>
                }
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    reviewTop: {
        flexDirection: 'row', 
        paddingBottom: 8, 
        marginBottom: 8, 
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderBottomColor: GRAY_LINE_COLOR
    },
    reviewDate: {
        marginLeft: 'auto',
        fontSize: 13
    }
})