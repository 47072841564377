import React, { useContext, useState, memo } from 'react';
import { Pressable, View, StyleSheet, TextInput, Platform } from 'react-native';
import {
    useNavigationBuilder,
    createNavigatorFactory,
    TabRouter,
    TabActions,
} from '@react-navigation/native';
import MenuFilterLibrary from '../components/MenuFilterLibrary';
import InterSemiBold from '../components/InterSemiBold';
import InterReg from '../components/InterReg';
import {Ionicons} from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';
import BooksSearchContext from '../context/BooksSearchContext';
import ShelvesSearchContext from '../context/ShelvesSearchContext';
import ListsSearchContext from '../context/ListsSearchContext';
import BooksScrollContext from '../context/BooksScrollContext';
import ShelvesScrollContext from '../context/ShelvesScrollContext';
import ListsScrollContext from '../context/ListsScrollContext';
import { getBookFilterCount, isNative, isWeb } from '../utils';
import { useBooksTabBarAnimStyles } from '../hooks';
import Animated, { useSharedValue, useAnimatedStyle, useAnimatedProps, runOnJS, interpolateColor, interpolate, withTiming } from 'react-native-reanimated';
import { 
    MAIN_COLOR, 
    SECONDARY_COLOR, 
    GRAY_LIGHT, 
    GRAY_LINE_COLOR, 
    PLACEHOLDER_TEXT_COLOR, 
    PILL_TAB_HEIGHT, 
    PILL_TABS_VERT_SPACING, 
    PILL_TAB_HOR_PADDING, 
    PILL_TAB_R_MARGIN,
    GRAY_TEXT_PALE 
} from '../constants';

const SEARCH_COMPACT_HEIGHT = PILL_TAB_HEIGHT;
const SEARCH_OPEN_HEIGHT = 38;
const CANCEL_WIDTH = 60;
const CANCEL_LEFT_MARGIN = 10;
const NAV_BAR_HOR_PADDING = 15;

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
const AnimatedIonicons = Animated.createAnimatedComponent(Ionicons);
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

const routeNameToFilterKey = {
    Books: 'booksFilter',
    Shelves: 'shelvesFilter',   
    Lists: 'listsFilter'
}

const routeNameToTranslationKey = {
    Books: 'books',
    Shelves: 'shelves',
    Lists: 'lists'
}

function BooksTabNavigator({
    initialRouteName,
    children,
    screenOptions,
    tabBarStyle,
    contentStyle,
}) {
    const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder(
        TabRouter, {
        children,
        screenOptions,
        initialRouteName,
    });
    const activeRouteName = state.routeNames[state.index];
    const [width, setWidth] = useState(null);
    function onLayout({ nativeEvent: { layout }}) {
        !width && setWidth(layout.width-(NAV_BAR_HOR_PADDING*2))
    }
    const handleFilterPress = () => navigation.navigate('Modal', { screen: 'FilterBooks', params: { filterKey: routeNameToFilterKey[activeRouteName] } });
    const renderFilterButton = () => {
        const renderInner = () => {
            switch(activeRouteName) {
                case 'Books':
                    return <FilterBooksInnerWithContext />;
                case 'Shelves':
                    return <FilterShelvesInnerWithContext />;
                case 'Lists':
                    return <FilterListsInnerWithContext />;
            }
        }
        if(isNative()) {
            return (
                <Pressable
                    onPress={handleFilterPress} 
                    hitSlop={{ top: 5, right: 5, bottom: 5, left: 5 }}
                    style={[styles.filter, { marginLeft: width > 600 ? PILL_TAB_R_MARGIN  : 'auto' }]}>
                    {renderInner()}
                </Pressable>
            )
        }
        return <View style={styles.filter}>{ renderInner() }</View>
    }
    const renderSearch = () => {
        const props = { width, tKey: routeNameToTranslationKey[activeRouteName] }
        switch(activeRouteName) {
            case 'Books':
                return <SearchBooksWithContext {...props} />;
            case 'Shelves':
                return <SearchShelvesWithContext {...props} />;
            case 'Lists':
                return <SearchListsWithContext {...props} />;
        }
    }
    const getScrollContext = () => {
        switch(activeRouteName) {
            case 'Books':
                return BooksScrollContext;
            case 'Shelves':
                return ShelvesScrollContext;
            case 'Lists':
                return ListsScrollContext;
        }
    }
    const animStyles = useBooksTabBarAnimStyles(getScrollContext());
    return (
        <NavigationContent>
            <Animated.View onLayout={onLayout} style={[styles.container, tabBarStyle, animStyles]}>
                <View style={styles.tabs}>
                    {state.routes.map((route, index) => (
                        <Pressable
                            key={route.key}
                            hitSlop={{ top: 5, right: 5, bottom: 5, left: 5 }}
                            onPress={() => {
                                const isFocused = state.index === index;
                                const event = navigation.emit({
                                    type: 'tabPress',
                                    target: route.key,
                                    canPreventDefault: true,
                                });

                                if (!isFocused && !event.defaultPrevented) {
                                    navigation.dispatch({
                                    ...TabActions.jumpTo(route.name, route.params),
                                    target: state.key,
                                    });
                                }
                            }}
                            style={[
                                styles.tab,
                                state.index === index && styles.tabActive
                            ]}>
                            <InterReg 
                                text={descriptors[route.key].options.title ?? route.name}
                                style={[styles.tabText, state.index === index && styles.tabTextActive]} />
                        </Pressable>
                    ))}
                    { width && renderSearch() }
                    { (width && isWeb()) &&
                        <MenuFilterLibrary 
                            style={{ marginLeft: width > 600 ? PILL_TAB_R_MARGIN  : 'auto' }}
                            filterKey={routeNameToFilterKey[activeRouteName]}
                            TriggerButton={renderFilterButton}
                        />
                    }
                    { (width && isNative()) && renderFilterButton()}
                </View>
            </Animated.View>
            <View style={[{ flex: 1 }, contentStyle]}>
                {state.routes.map((route, i) => {
                    return (
                        <View
                            key={route.key}
                            style={[
                                StyleSheet.absoluteFill,
                                { display: i === state.index ? 'flex' : 'none' },
                            ]}>
                            {descriptors[route.key].render()}
                        </View>
                    );
                })}
            </View>
        </NavigationContent>
    );
}

const SearchBooksWithContext = memo(({ tKey, width }) => {
    const { searchPattern, setSearchPattern } = useContext(BooksSearchContext);
    return (
        <SearchBooks 
            tKey={tKey}
            width={width} 
            pattern={searchPattern}
            setPattern={setSearchPattern} /> 
    )
})

const SearchShelvesWithContext = memo(({ tKey, width }) => {
    const { searchPattern, setSearchPattern } = useContext(ShelvesSearchContext);
    return (
        <SearchBooks 
            tKey={tKey}
            width={width} 
            pattern={searchPattern}
            setPattern={setSearchPattern} /> 
    )
})

const SearchListsWithContext = memo(({ tKey, width }) => {
    const { searchPattern, setSearchPattern } = useContext(ListsSearchContext);
    return (
        <SearchBooks 
            tKey={tKey}
            width={width} 
            pattern={searchPattern}
            setPattern={setSearchPattern} /> 
    )
})

const FilterBooksInnerWithContext = memo(({ }) => {
    const { filters } = useContext(BooksSearchContext);
    const filterCount = getBookFilterCount(filters);
    return <FilterInner filterCount={filterCount} />
})

const FilterShelvesInnerWithContext = memo(({ }) => {
    const { filters } = useContext(ShelvesSearchContext);
    const filterCount = getBookFilterCount(filters);
    return <FilterInner filterCount={filterCount} />
})

const FilterListsInnerWithContext = memo(({ }) => {
    const { filters } = useContext(ListsSearchContext);
    const filterCount = getBookFilterCount(filters);
    return <FilterInner filterCount={filterCount} />
})

const FilterInner = memo(({ filterCount }) => (
    <>
        { filterCount > 0 && 
            <View style={styles.filterCount}>
                <InterSemiBold style={styles.filterCountNumber} text={filterCount} />
            </View>
        }
        <Ionicons name='filter' color='#fff' size={18} style={styles.filterIcon} />
    </>
))

const SearchBooks = memo(({ width, pattern, setPattern, tKey }) => {
    const { t } = React.useContext(AppSettingsContext);
    const availableWidthForSearch = width - PILL_TAB_HEIGHT - PILL_TAB_R_MARGIN  - CANCEL_WIDTH - CANCEL_LEFT_MARGIN;
    const searchBoxOpenWidth = Math.min(availableWidthForSearch, 270);
    const compact = useSharedValue(width > 600 ? 0 : 1);
    const showCancel = useSharedValue(width > 600 ? 0 : 1);
    const inputFocused = useSharedValue(false);
    const inputRef = React.useRef(null);
    const animStyles = {
        search: useAnimatedStyle(() => {
            if(width > 600) return {}
            return {
                left: interpolate(compact.value, [0,1], [0, width - (PILL_TAB_HEIGHT*2) - PILL_TAB_R_MARGIN ]),
                transform: [{ translateY: interpolate(compact.value, [0,1], [-2, 0]), }]
            }
        }),
        searchBox: useAnimatedStyle(() => {
            const styles = {
                width: interpolate(compact.value, [0,1], [searchBoxOpenWidth, PILL_TAB_HEIGHT]),
                height: interpolate(compact.value, [0,1], [SEARCH_OPEN_HEIGHT, SEARCH_COMPACT_HEIGHT]),
                borderWidth: interpolate(compact.value, [0,1], [2, 0]),
                backgroundColor: interpolateColor(compact.value, [0,1], [GRAY_LIGHT, SECONDARY_COLOR]),
                borderRadius: interpolate(compact.value, [0,1], [8, PILL_TAB_HEIGHT/2])
            }
            if(inputFocused.value) {
                styles.borderColor = SECONDARY_COLOR;
            } else {
                styles.borderColor = interpolateColor(compact.value, [0,1], [GRAY_LIGHT, SECONDARY_COLOR]);
            }
            return styles;
        }),
        input: useAnimatedStyle(() => ({
            opacity: interpolate(compact.value, [0, 1],[1,0]),
            width: compact.value < 0.3 ? 'auto' : 0,
            flex: compact.value ? 0 : 1
        })),
        searchIcon: useAnimatedStyle(() => ({
            transform: [ { scale: interpolate(compact.value, [0,1], [0.8, 1]), }]
        })),
        cancel: useAnimatedStyle(() => {
            if(width > 600) return {
                opacity: interpolate(showCancel.value, [0, 1],[0,1]),
                width: interpolate(showCancel.value, [0,1],[0, CANCEL_WIDTH])
            }
            return {
                opacity: interpolate(compact.value, [0, 0.5, 1],[1, 0, 0]),
                width: interpolate(compact.value, [0,1],[CANCEL_WIDTH, 0]),
                marginLeft: interpolate(compact.value, [0,1], [CANCEL_LEFT_MARGIN, 0]),

            }
        }),
    }
    const animatedProps = useAnimatedProps(() => {
        return {
            color: interpolateColor(compact.value, [0,1], [GRAY_TEXT_PALE, '#fff'])
        }
    })
    const handleFocus = () => inputFocused.value = true;
    const handleBlur  = () => inputFocused.value = false;
    const focusInput  = () => inputRef.current.focus();
    const blurInput = () => {
        inputRef.current.blur();
        setPattern('');
    }
    const handleSearchBoxPress = () => {
        if(width < 600) {
            compact.value = withTiming(0, { duration: 300 }, () => runOnJS(focusInput)())
        } else {
            focusInput();
        }
    }
    const handleCancelPress = () => compact.value = withTiming(1, {}, () => runOnJS(blurInput)())
    const handlePatternChange = (value) => setPattern(value) 
    return (
        <Animated.View style={[styles.search, animStyles.search, width > 600 ? { marginLeft: 'auto' } : { position: 'absolute' }]}>
            <AnimatedPressable 
                hitSlop={{ top: 5, right: 5, bottom: 5, left: 5 }}
                onPress={handleSearchBoxPress} 
                style={[styles.searchBox, animStyles.searchBox, inputFocused.value && { borderColor: SECONDARY_COLOR }]}>
                <AnimatedIonicons 
                    size={18}
                    name='search' 
                    animatedProps={animatedProps} 
                    style={[styles.searchIcon, animStyles.searchIcon]} />
                <AnimatedTextInput 
                    ref={inputRef}
                    value={pattern}
                    onChangeText={handlePatternChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={t(`forms.searchBooks.${tKey}.placeholders.pattern`)}
                    placeholderTextColor={ PLACEHOLDER_TEXT_COLOR }
                    style={[animStyles.input, styles.input]} />
            </AnimatedPressable>      
            <AnimatedPressable 
                hitSlop={{ top: 10, right: 5, bottom: 10, left: 5 }}
                style={[animStyles.cancel, styles.cancel]} 
                onPress={handleCancelPress}>
                <InterReg text={ t(`forms.searchBooks.cancel`) } style={{ position: 'absolute', top: 0, left: 0 }} />
            </AnimatedPressable>
        </Animated.View>
    )
})

const styles = StyleSheet.create({
    container: {
        ...Platform.select({
            web: {},
            default: {
                position: 'absolute',
                top: 0,
                left: 0, 
                right: 0,
                zIndex: 1,
                backgroundColor: 'rgba(255,255,255,0.95)'
            }
        }),
        paddingVertical: PILL_TABS_VERT_SPACING,
        paddingHorizontal: NAV_BAR_HOR_PADDING
    },
    tabs: {
        flexDirection: 'row',
        height: PILL_TAB_HEIGHT,
        minHeight: PILL_TAB_HEIGHT,
        maxHeight: PILL_TAB_HEIGHT,
    },
    tab: {
        borderRadius: PILL_TAB_HEIGHT/2,
        backgroundColor: GRAY_LIGHT, 
        borderWidth: 1,
        borderColor: GRAY_LINE_COLOR,
        paddingHorizontal: PILL_TAB_HOR_PADDING,
        marginRight: PILL_TAB_R_MARGIN,
        justifyContent: 'center'
    },
    tabActive: { 
        backgroundColor: SECONDARY_COLOR, 
        borderColor: SECONDARY_COLOR
    },
    tabText: {
        color: SECONDARY_COLOR,
        fontSize: 14,
        ...Platform.select({
            web: {
                lineHeight: 14
            },
            default: {}
        })
    },
    tabTextActive: {
        color: '#fff'
    },
    search: {
        flexDirection: 'row', 
        backgroundColor: '#fff',
        alignItems: 'center'
    },
    searchBox: {
        marginLeft: 'auto',
        minWidth: PILL_TAB_HEIGHT,
        minHeight: PILL_TAB_HEIGHT,
        height: PILL_TAB_HEIGHT,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...Platform.select({
            web: {
                transitionDuration: '200ms',
            },
            default: {},
        }),
    },
    input: {
        paddingHorizontal: 5,
        height: PILL_TAB_HEIGHT,
        backgroundColor: 'transparent',
        ...Platform.select({
            web: {
                outlineStyle: 'none'
            },
            default: {},
        }),
    },
    searchIcon: {
        ...Platform.select({
            ios: {
                marginLeft: 7
            },
            default: {
                marginLeft: 6
            },
        })
    },
    cancel: {
        overflow: 'hidden',
        height: 20
    },
    filter: {
        width: PILL_TAB_HEIGHT, 
        height: PILL_TAB_HEIGHT, 
        borderRadius: PILL_TAB_HEIGHT, 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: SECONDARY_COLOR
    },
    filterIcon: {
        ...Platform.select({
            ios: {
                marginRight: -2,
                marginBottom: -1
            },
            default: {},
        })
    },
    filterCount: {
        position: 'absolute', 
        top: -6, 
        right: -6, 
        width: 18, 
        height: 18, 
        backgroundColor: MAIN_COLOR, 
        borderRadius: 9, 
        alignItems: 'center', 
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: '#fff'
    },
    filterCountNumber: {
        color: '#fff', 
        fontSize: 11
    }
})

export const createBooksTabNavigator = createNavigatorFactory(BooksTabNavigator);