import React, { useState, useMemo, useEffect, useContext } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { selectBorrowRequests } from '../../selectors';
import { getBorrowRequestsApi, getBorrowRequestsInProgress } from '../../actions/getBorrowRequests';
import { updateUnreadNotifsApi } from '../../actions/updateUnreadNotifs';
import { useIsLoading, useUnreadNotifs } from '../../hooks';
import LendsList from '../../components/LendsList';
import HorizontalFilters from '../../components/HorizontalFilters';
import Empty from '../../components/Empty';
import { sortByDate, handleUnreadNotifsDone } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.borrowRequest';

const filters = [
    { key: 'all', label: 'filter.all' },
    { key: 'requestByMe', label: 'filter.requestByMe',  },
    { key: 'requestToMe', label: 'filter.requestToMe' },
    { key: 'accepted', label: 'filter.accepted' },
]

export default function BookRequestScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('all');
    const [refreshing, setRefreshing] = useState(false);
    const borrowRequests = useSelector(selectBorrowRequests);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isGettingBorrowRequest = useIsLoading(getBorrowRequestsInProgress);
    const { unreadBorrowRequests, unreadBorrowRequestsAccepted } = useUnreadNotifs();
    const filteredRequests = useMemo(() => {
        if(filter == 'requestByMe') return borrowRequests.filter(({ fromId }) => fromId == loggedUserId);
        if(filter == 'requestToMe') return borrowRequests.filter(({ toId }) => toId == loggedUserId);
        if(filter == 'accepted')    return borrowRequests.filter(({ accepted }) => accepted);
        return borrowRequests;
    }, [borrowRequests, filter]);
    const renderEmpty = useMemo(() => (
        <Empty 
            title={t(`${tBase}.empty.${filter}.title`)} 
            body={t(`${tBase}.empty.${filter}.body`)}
            img={ require('../../assets/noBookRequests.png') } />      
    ), []);
    const renderHeader = useMemo(() => (
        <View style={{ paddingVertical: 15, paddingRight: 15, justifyContent: 'center', backgroundColor: '#fff' }}>
            <HorizontalFilters 
                tBase={tBase}
                filters={filters} 
                selected={filter} 
                onSelect={setFilter} />
        </View> 
        
    ), [filter]);
    const getBorrowRequests = (checkCaching=true, showRefreshing=false, callback=() => {}) => {
        if(!isGettingBorrowRequest) {
            showRefreshing && setRefreshing(true);
            dispatch(getBorrowRequestsApi({ showLoading: false, checkCaching })).then(() => {
                setRefreshing(false);
                callback();
            });
        }
    }
    const data = useMemo(() => sortByDate(filteredRequests, 'created'), [filteredRequests]);
    const onRefresh = () => getBorrowRequests(false, true);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', handleOnFocus);
        return unsubscribe;
    }, [unreadBorrowRequests, unreadBorrowRequestsAccepted]);

    const handleOnFocus = () => {   
        const shouldCheckCache = (() => {
            const borrowRequestsNotAcceptedIds = borrowRequests.filter(({ accepted }) => !accepted).map(({ id }) => id);
            const borrowRequestsAcceptedIds = borrowRequests.filter(({ accepted }) => accepted).map(({ id }) => id);
            if((unreadBorrowRequests.length > borrowRequestsNotAcceptedIds.length) ||
               (unreadBorrowRequestsAccepted.length > borrowRequestsAcceptedIds.length)) {
                return false;
            } 
            if(unreadBorrowRequests.length) {
                for(let unreadBorrowRequestId of unreadBorrowRequests) {
                    if(!borrowRequestsNotAcceptedIds.includes(unreadBorrowRequestId)) return false;
                }
            }
            if(unreadBorrowRequestsAccepted.length) {
                for(let unreadBorrowResquestAcceptedId of unreadBorrowRequestsAccepted) {
                    if(!borrowRequestsAcceptedIds.includes(unreadBorrowResquestAcceptedId)) return false;
                }
            }
            return true;
        })()
        const updateUnreadNotifs = () => {
            if(unreadBorrowRequests.length || unreadBorrowRequestsAccepted.length) {
                const notifsRead = {};
                if(unreadBorrowRequests.length) notifsRead.borrowRequestsSent = unreadBorrowRequests;
                if(unreadBorrowRequestsAccepted.length) notifsRead.borrowRequestsAccepted = unreadBorrowRequestsAccepted;
                dispatch(updateUnreadNotifsApi({ notifsRead })).then(handleUnreadNotifsDone);
            }
        }
        getBorrowRequests(shouldCheckCache, false, updateUnreadNotifs);
        
    };
    const showHeader = !!borrowRequests.length;
    return (
        <LendsList 
            data={data}
            navigation={navigation}
            useStickyHeader={true}
            contentContainerStyle={showHeader ? { paddingTop: 0 } : {}}
            listHeaderStyle={showHeader ? { paddingBottom: 10 } : {} }
            onRefresh={onRefresh}
            refreshing={refreshing}
            renderEmpty={renderEmpty}
            renderHeader={showHeader ? renderHeader : undefined}
        />
    )
}