import React, { useState, memo, useCallback, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { Feather, MaterialIcons as MIcons} from '@expo/vector-icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { sendGroupRequestApi } from '../actions/sendGroupRequest';
import { MAIN_COLOR, GROUP_HEADER_HEIGHT_MIN, HEADER_ACTIONS_HEIGHT, HEADER_ACTIONS_PADDING_HOR, HEADER_ACTIONS_PADDING_HOR_COMPACT } from '../constants';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import GroupPicture from './GroupPicture';
import Button from './Button';
import Location from './Location';
import MenuGroup from './MenuGroup';
import IsStatus from './IsStatus';
import RoundButton from './RoundButton';
import GroupHeaderBg from './GroupHeaderBg';
import HeaderActionsBar from './HeaderActionsBar';
import ShareButton from './ShareButton';
import Animated, { useSharedValue, useAnimatedStyle, interpolate, Extrapolate } from 'react-native-reanimated';
import { displayGroupWebsiteUrl, getInitialScreenWidth, getShortUrl, isWeb, goBackFromGroup, isNative, isIOS, getResponsiveValue } from '../utils';
import { confirmJoinGroup, promptLoginOrSignup } from '../utils/alerts';
import { useGroupRequest } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.group.header';
const HEADER_BTN_GAP = 6;


function GroupHeader({ scrollY, headerHeight, isAdmin, isMember, group, onHeaderHeightChange, insetTop, navigation, headerInputMaxRange }) {
    const {t} = useContext(AppSettingsContext);
    const mainViewWidth = getInitialScreenWidth(navigation);
    const loggedUserId = useSelector(state => state.loggedUserId);
    const descriptionHeight = useSharedValue(0);
    const [showMenu, setShowMenu] = useState(true);
    const [nameLineNumber, setNameLineNumber] = useState(2);
    const dispatch = useDispatch();
    const [memberGroupStatusWidth, setMemberGroupStatusWidth] = useState(0);
    const url = getShortUrl('group', group.shortId);
    const bookCount = group.bookIds.length;
    const memberCount = group.memberIds.length;
    const groupRequestByMe = useGroupRequest(group.id);
    const showJoinButton = !isMember && !groupRequestByMe;
    const handleJoinGroup = () => { confirmJoinGroup(sendJoinGroupRequest, dispatch, { name: group.name }) }
    const sendJoinGroupRequest = () => { dispatch(sendGroupRequestApi({ groupId: group.id })) }
    const handleMembersPress = () => {
        if(loggedUserId === null) {
            promptLoginOrSignup(dispatch);
        } else {
            navigation.push('GroupMembers', { groupId: group.id })
        }
    }
    const renderMenuTrigger = () => <RoundButton type='options' size={ HEADER_ACTIONS_HEIGHT } />;
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const deleteDone = useCallback((json) => { if(json?.success) goBackFromGroup(navigation, activeBottomTab); }, [activeBottomTab])
    const handleBackNavigation = useCallback(() => goBackFromGroup(navigation, activeBottomTab), [activeBottomTab]);
    const handleLinkPress = () => isNative() && Linking.openURL(group.websiteUrl);
    const onLayoutContainer = ({ nativeEvent: { layout: { height }}}) => {
        !headerHeight.value && onHeaderHeightChange(height)
    }
    const onLayoutGroupStatus = ({width}) => setMemberGroupStatusWidth(width);
    const onLayoutDescription = ({ nativeEvent: { layout: { height }}}) => { if(!descriptionHeight.value) descriptionHeight.value = height }
    const defaultStyles = {
        groupInfo: {
            paddingRight: 15,
            paddingLeft: 20
        }
    }
    const compactStyles = {
        groupInfo: {
            paddingRight: (showMenu ? HEADER_ACTIONS_HEIGHT + HEADER_BTN_GAP : 0) + HEADER_BTN_GAP + HEADER_ACTIONS_HEIGHT + memberGroupStatusWidth + 20,
            paddingLeft: HEADER_ACTIONS_HEIGHT + (mainViewWidth > 450 ? HEADER_ACTIONS_PADDING_HOR : HEADER_ACTIONS_PADDING_HOR_COMPACT) + 6
        }
    }
    const animStyles = {
        container: useAnimatedStyle(() => {
            if(!headerHeight.value) return { height: 'auto', paddingBottom: HEADER_ACTIONS_HEIGHT + 15 }
            return {
                height: interpolate(scrollY.value, [0, headerInputMaxRange], [headerHeight.value, GROUP_HEADER_HEIGHT_MIN+insetTop], Extrapolate.CLAMP)
            }
        }),
        pictureWrapper: useAnimatedStyle(() => {
            const size = interpolate(scrollY.value, [0, headerInputMaxRange], [95, 34], Extrapolate.CLAMP);
            return {
                height: size,
                width: size,
                borderRadius: interpolate(scrollY.value, [0, headerInputMaxRange], [8, 99], Extrapolate.CLAMP),
                borderWidth: interpolate(scrollY.value, [0, headerInputMaxRange], [5, 0], Extrapolate.CLAMP)
            }
        }),  
        picture: useAnimatedStyle(() => {
            return {
                transform: [
                    {translateY: interpolate(scrollY.value, [0, headerInputMaxRange], [0, -26], Extrapolate.CLAMP)},
                    {scale: interpolate(scrollY.value, [0, headerInputMaxRange], [1, 0.4], Extrapolate.CLAMP)},
                ],
            }
        }),
        stats: useAnimatedStyle(() => {
            return {
                opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0]),
                height: interpolate(scrollY.value, [0, headerInputMaxRange], [20, 0], Extrapolate.CLAMP),
            }
        }),
        location: useAnimatedStyle(() => {
            return {
                marginLeft: -2,
                opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0]),
                height: interpolate(scrollY.value, [0, headerInputMaxRange], [17, 0], Extrapolate.CLAMP),
            }
        }),
        name: useAnimatedStyle(() => {
            return {
                transform: [
                    { translateY: interpolate(scrollY.value, [0, headerInputMaxRange], [0, 7], Extrapolate.CLAMP) },
                    { translateX: interpolate(scrollY.value, [0, headerInputMaxRange], [0, -5], Extrapolate.CLAMP) }
                ],
            }
        }),
        description: useAnimatedStyle(() => {
            if(!descriptionHeight.value) return {}
            return {
                opacity: interpolate(scrollY.value, [0, 10, headerInputMaxRange], [1, 0.5, 0]),
                height: interpolate(scrollY.value, [0, headerInputMaxRange], [descriptionHeight.value, 0], Extrapolate.CLAMP),
            }
        }),
        groupInfo: useAnimatedStyle(() => {
            return {
                transform: [
                    { translateY: interpolate(scrollY.value, [0, headerInputMaxRange], [HEADER_ACTIONS_HEIGHT + 15, 0], Extrapolate.CLAMP) },
                ],
                marginBottom: interpolate(scrollY.value, [0, headerInputMaxRange], [19, 1], Extrapolate.CLAMP),
                paddingLeft: interpolate(scrollY.value, [0, headerInputMaxRange], [defaultStyles.groupInfo.paddingLeft, compactStyles.groupInfo.paddingLeft], Extrapolate.CLAMP),
                paddingRight: interpolate(scrollY.value, [0, headerInputMaxRange/6, headerInputMaxRange], [defaultStyles.groupInfo.paddingRight/2, compactStyles.groupInfo.paddingRight/2, compactStyles.groupInfo.paddingRight], Extrapolate.CLAMP),
            }
        })
    }
    const onTextLayout = () => {
        if(scrollY.value >= headerInputMaxRange && nameLineNumber == 2) {
            setNameLineNumber(1) 
        } else if(scrollY.value < headerInputMaxRange && nameLineNumber == 1) {
            setNameLineNumber(2) 
        }
    }
    const statRightPadding = getResponsiveValue([10, 10, 10, 10, 15], 'x');
    const statRightMargin = getResponsiveValue([10, 10, 10, 10, 15], 'x');
    return (
        <Animated.View 
            onLayout={onLayoutContainer}
            style={[ styles.container, { paddingTop: insetTop, overflow: 'hidden', backgroundColor: group.picture ? '#fff' : MAIN_COLOR }, animStyles.container]}>
            <GroupHeaderBg
                blurhash={group.blurHash}
                height={headerHeight.value}
                width={mainViewWidth} />
            <HeaderActionsBar top={ insetTop } mainViewWidth={ mainViewWidth } onPressBack={ handleBackNavigation }>
                { isMember &&
                <IsStatus 
                    theme='mainColor'
                    height={ HEADER_ACTIONS_HEIGHT }
                    onLayout={ onLayoutGroupStatus }
                    iconName={ isAdmin ? 'md-star' : undefined }
                    iconSize={ isAdmin ? 15 : undefined }
                    style={ styles.status } 
                    text={ isAdmin ? t('group.isAdmin') : t('group.isMember') } />
                }
                { showJoinButton &&
                <Button 
                    buttonStyle={ styles.join } 
                    size='xSmall'
                    requiresAccount={ true }
                    onLayout={ onLayoutGroupStatus }
                    onPress={ handleJoinGroup }
                    text={ t('group.join') } />
                }
                <ShareButton 
                    type='group'
                    url={ url }
                    name={ group.name }
                    isOwner={ isAdmin }
                    buttonType='round'
                 />
                { showMenu &&
                <MenuGroup
                    placement='bottom'
                    hasItems={setShowMenu}
                    deleteDone={ deleteDone }
                    group={group}
                    context='groupDetail'
                    navigation={navigation}
                    style={{ marginLeft: HEADER_BTN_GAP, width: HEADER_ACTIONS_HEIGHT, height: HEADER_ACTIONS_HEIGHT }}
                    TriggerButton={ renderMenuTrigger } />
                }
            </HeaderActionsBar>
            <Animated.View pointerEvents='box-none' style={[styles.groupInfo, animStyles.groupInfo]}>
                <View style={{ flexDirection: 'row'}}>
                    <Animated.View style={[ { borderRadius: 8, borderWidth: 5, overflow: 'hidden', borderColor: 'rgba(255,255,255, 0.3)' }, animStyles.pictureWrapper]}>
                        <Animated.View style={animStyles.picture}>
                            <GroupPicture style={{ alignSelf: 'center' }} size={85} blurHash={ group.blurHash } isPremium={ group?.echelon?.includes('premium') } picture={ group.picture } />
                        </Animated.View>
                    </Animated.View>
                    <View style={{ flex: 1 }}>
                        <View style={[{ paddingLeft: 15, marginTop: 4 }]}>
                            <Animated.View style={[animStyles.name, { flexDirection: 'row' }]}>
                                { !group.public && <MIcons name='lock' style={{ marginRight: 6, marginTop: -2 }} size={16} color='rgba(0,0,0,0.7)' /> }
                                <MontSBold 
                                    onTextLayout={onTextLayout}
                                    style={styles.name} 
                                    numberOfLines={nameLineNumber} 
                                    text={ '' + group.name } />
                            </Animated.View>
                            <Animated.View style={animStyles.location}><Location iconColor='rgba(0,0,0,0.45)' location={group.location} /></Animated.View>
                            <Animated.View 
                                onLayout={ onLayoutDescription }
                                style={animStyles.description}>
                                    <InterReg style={styles.description} text={group.description} />
                            </Animated.View>
                        </View>
                    </View>
                </View>
                <Animated.View style={[styles.stats, animStyles.stats]}>
                    <View style={[ styles.stat, { paddingRight: statRightPadding, marginRight: statRightMargin, paddingLeft: 4 } ]}>
                        <MontSBold style={styles.countNbr} text={ bookCount } />
                        <InterReg style={styles.countLabel} text={ t('general.books', { count: bookCount }) } />
                    </View>
                    <TouchableOpacity 
                        onPress={handleMembersPress} 
                        style={[styles.stat, { paddingRight: statRightPadding, marginRight: statRightMargin, borderRightWidth: group.websiteUrl ? 1 : 0 } ]}>
                        <MontSBold style={styles.countNbr} text={ memberCount } />
                        <InterReg style={styles.countLabel} text={ t(`${tBase}.memberCountLabel`, { count: memberCount }) } />
                    </TouchableOpacity>
                    { !!group.websiteUrl &&
                    <TouchableOpacity 
                        accessibilityRole="link"
                        href={ group.websiteUrl }
                        target="_blank"
                        onPress={handleLinkPress} 
                        style={[styles.stat, { borderRightWidth: 0, flex: 1, justifyContent: 'flex-start' }]}>
                        <Feather name='external-link' size={14} color='rgba(0,0,0,0.8)' style={{ marginRight: 4 }} />
                        <InterReg style={styles.countLabel} numberOfLines={1} text={ displayGroupWebsiteUrl(group.websiteUrl) } />
                    </TouchableOpacity>
                    }
                </Animated.View>
            </Animated.View>
        </Animated.View>
    )
}

export default memo(GroupHeader);

const styles = StyleSheet.create({
    container: {
        width: '100%',
        zIndex: 2,
        position: isWeb() ? 'relative' : 'absolute',
        top: 0,
        left: 0
    },
    groupInfo: {
        minHeight: HEADER_ACTIONS_HEIGHT,
    },
    name: {
        fontSize: 16,
        marginTop: -3,
        marginBottom: 3,
        borderLeftWidth: 0,
        flex: 1,
        color: 'rgba(0,0,0,0.7)',
    },
    description: {
        marginTop: 5, 
        paddingRight: 10,
        maxWidth: 700,
        color: 'rgba(0,0,0,0.7)',
    },
    stats: {
        marginTop: 14,
        flexDirection: 'row'
    },
    stat: {
        flexDirection: 'row', 
        borderRightWidth: StyleSheet.hairlineWidth, 
        borderRightColor: 'rgba(0,0,0,0.3)', 
        alignItems: 'center',
        marginRight: 10,
        paddingRight: 10
    },
    countNbr: {
        color: 'rgba(0,0,0,0.7)',
        fontSize: 14,
        marginRight: 5
    },
    countLabel: {
        fontSize: 13,
        marginTop: isIOS() ? 1 : 0,
        textTransform: 'uppercase',
        color: 'rgba(0,0,0,0.5)'
    },
    status: {
        paddingLeft: 9,
        marginRight: HEADER_BTN_GAP
    },
    join: {
        paddingHorizontal: 15,
        height: HEADER_ACTIONS_HEIGHT,
        borderRadius: 17,
        alignItems: 'center', 
        justifyContent: 'center',
        marginRight: HEADER_BTN_GAP
    }
})