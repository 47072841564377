import React, { useState, memo } from 'react';
import { FlatList, View } from 'react-native';
import { useFlatListPadding, useGetLendItemLayout } from '../hooks';
import { useSelector, shallowEqual } from 'react-redux';
import BookLend from '../components/BookLend';
import { keyExtractor, getLendContext, getInitialScreenWidth } from '../utils';

const ITEM_X_SPACING = 20;
const ITEM_Y_SPACING = 15;

function LendsList({ navigation, renderEmpty, renderHeader, useStickyHeader=false, onRefresh, refreshing=false, data, contentContainerStyle={}, listHeaderStyle={} }) {
    const [width, setWidth] = useState(getInitialScreenWidth(navigation));
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const renderItem = ({item, index}) => {
        const context = getLendContext(item, loggedUserId);
        const props = ['lending', 'borrowing', 'lent', 'borrowed'].includes(context) ? { lend: item } : { borrowRequest: item }; 
        return (
            <BookLend 
                {...props}
                index={index}
                itemWidth={itemWidth} 
                numColumns={columns}
                marginHorizontal={ITEM_X_SPACING/2}
                context={context} 
                navigation={navigation} />
        )
    }
    const renderSeparator = () => (
        <View style={{ marginTop: ITEM_Y_SPACING }} />
    )
    const onLayout = ({ nativeEvent: { layout } }) => {
        if(layout.width && layout.width != width) setWidth(layout.width);
    }
    const {paddingBottom, paddingTop, paddingHorizontal} = useFlatListPadding();
    const {columns, itemWidth} = useGetLendItemLayout(width, paddingHorizontal, ITEM_X_SPACING);
    const columnWrapperStyle = columns > 1 ? { paddingHorizontal } : undefined;
    return (
        <FlatList 
            data={data}
            key={columns}
            onLayout={onLayout}
            numColumns={columns}
            onRefresh={onRefresh}
            ListEmptyComponent={renderEmpty}
            ListHeaderComponent={renderHeader}
            ListHeaderComponentStyle={listHeaderStyle}
            ItemSeparatorComponent={renderSeparator}
            refreshing={refreshing}
            columnWrapperStyle={columnWrapperStyle}
            stickyHeaderIndices={useStickyHeader ? [0] : []}
            contentContainerStyle={[
                { flexGrow: 1, paddingBottom, paddingTop },
                contentContainerStyle
            ]}
            keyExtractor={keyExtractor}
            renderItem={renderItem} />
    )
}

export default memo(LendsList);