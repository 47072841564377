import * as SecureStore from 'expo-secure-store';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

export function generateToken() {
    return uuidv4();
}

export async function saveToken(value) {
    return await SecureStore.setItemAsync('token', value);
}

export async function deleteToken() {
    return await SecureStore.deleteItemAsync('token');
}

export async function getToken() {
    try {
        let result = await SecureStore.getItemAsync('token');
        if(result) {
            return result;
        } else {
            return;
        }
    } catch (e) {
        await deleteToken();
        return;
    }
}