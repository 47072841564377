import { LEND_BOOK_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const lendBookInProgress = createAction('LEND_BOOK_IN_PROGRESS');
export const lendBookSuccess = createAction('LEND_BOOK_SUCCESS');
export const lendBookFailure = createAction('LEND_BOOK_FAILURE');

export function lendBookApi({ bookId, email, toId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, toId, email },
            actions: [lendBookInProgress, lendBookSuccess, lendBookFailure],
            name: 'lendBook',
            dispatch,
            getState
        })
    }
}