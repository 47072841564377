import { GET_UNREAD_NOTIFS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getUnreadNotifsInProgress = createAction('GET_UNREAD_NOTIFS_IN_PROGRESS');
export const getUnreadNotifsSuccess = createAction('GET_UNREAD_NOTIFS_SUCCESS');
export const getUnreadNotifsFailure = createAction('GET_UNREAD_NOTIFS_FAILURE');

export function getUnreadNotifsApi({ showLoading=false, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getUnreadNotifsInProgress, getUnreadNotifsSuccess, getUnreadNotifsFailure],
            name: 'getUnreadNotifs',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}