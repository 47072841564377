import React, { useEffect, useState, memo, useMemo, useCallback, useContext } from 'react';
import { View, FlatList, TouchableOpacity, Pressable } from 'react-native';
import { Feather } from '@expo/vector-icons';
import InterReg from './InterReg'
import MontSBold from './MontserratBold';
import BookCoverMenu from './BookCoverMenu';
import { useIsFocused } from '@react-navigation/native';
import { SECONDARY_COLOR } from '../constants';
import Animated, { useSharedValue, useAnimatedStyle, withTiming, withRepeat, interpolate, withDelay, runOnJS } from 'react-native-reanimated';
import AppSettingsContext from '../context/AppSettingsContext';
import { navigateToBook, isNative, keyExtractor, getInitialScreenWidth } from '../utils';

const BOOK_WIDTH = 140;
const BOOK_HEIGHT = 257;
const BOOK_MARGIN_RIGHT = 15;
const FLAT_LIST_PADDING_LEFT = 20;

function BookListHor({ navigation, books, context, contextId, onSeeAllPress, listId='', shelfId='', limit=10 }) {
    const contentWidth = getInitialScreenWidth(navigation);
    const initialNumToRender = Math.min(Math.ceil((contentWidth-FLAT_LIST_PADDING_LEFT) / (BOOK_WIDTH+BOOK_MARGIN_RIGHT)), limit);
    const [endReached, setEndReached] = useState(false);
    const [pressedBookId, setPressedBookId] = useState(null);
    const moreBooksToSee = books.length > limit;
    const booksToShow = useMemo(() => {
        if(moreBooksToSee) {
            const limitedBooks = books.slice(0, limit)
            limitedBooks.push({ id: 'placeholder' });
            return limitedBooks;
        }
        return books; 
    }, [books])
    const isFocused = useIsFocused();
    useEffect(() => {
        if(isFocused && pressedBookId) {
            setPressedBookId(null);
        }
    }, [isFocused]);
    const onEndReached = useCallback(() => moreBooksToSee && setEndReached(true), []);
    const rememberPressedBookId = useCallback((bookId) => setPressedBookId(bookId), []);
    const renderItem = ({ item, index }) => {
        if(item.id == 'placeholder') return (
            <SeeAll 
                endReached={endReached}
                onEndReachedAnimationDone={ () => setEndReached(false) }
                booksLeftToSee={books.length-limit}
                handlePress={onSeeAllPress} />
        )
        return (
            <Book 
                navigation={navigation} 
                listId={listId}
                shelfId={shelfId}
                isFocused={isFocused} 
                context={context}
                contextId={contextId}
                rememberPressedBookId={rememberPressedBookId}
                pressedBookId={pressedBookId}
                title={item.title}
                bookId={item.id}
                index={index}
            />
        )
    }
    return (
        <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingTop: 15, paddingLeft: FLAT_LIST_PADDING_LEFT }}
            data={ booksToShow }
            onEndReachedThreshold={0}
            onEndReached={onEndReached}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            initialNumToRender={initialNumToRender}
            maxToRenderPerBatch={10}
            windowSize={limit}
        />
    )
}

export default memo(BookListHor);

const Book = memo(({ navigation, title, bookId, context, contextId, rememberPressedBookId, isFocused, listId, shelfId }) => {
    const [showMenu, setShowMenu] = useState(true);
    const params = (() => {
        let params = { bookId };
        if(listId) params.listId = listId;
        if(shelfId) params.shelfId = shelfId;
        return params;
    })()
    useEffect(() => {
        if(isFocused && !showMenu) setShowMenu(true);
    }, [isFocused])
    const handleBookPress = () => {
        navigateToBook(navigation, params);
        rememberPressedBookId(bookId);
        isNative() && setShowMenu(false);
    }
    return (
        <Pressable 
            style={{ paddingBottom: 10, marginRight: BOOK_MARGIN_RIGHT, width: BOOK_WIDTH, height: BOOK_HEIGHT }} 
            onPress={handleBookPress}>
            <BookCoverMenu context={context} contextId={contextId} navigation={navigation} bookId={bookId} listId={listId} showMenu={showMenu} />
            <View style={{ flexDirection: 'row' }}>
                <MontSBold numberOfLines={2} style={{ flex: 1, marginTop: 5, color: '#545454', fontSize: 13 }} text={ title } />
            </View>
        </Pressable>
    )
}, (prevProps, nextProps) => {
    return (prevProps.isFocused == nextProps.isFocused) || (prevProps.isFocused != nextProps.isFocused && prevProps.bookId != nextProps.pressedBookId);
});


function SeeAll({handlePress, endReached, onEndReachedAnimationDone}) {
    const {t} = useContext(AppSettingsContext);
    const end = useSharedValue(0);
    const buttonStyle = useAnimatedStyle(() => {
        return {
            transform: [{translateX: interpolate(end.value, [0, 1], [0, 10])}],
        }
    })
    useEffect(() => {
        if(endReached) {
            end.value = withDelay(1000, withRepeat(
                withTiming(1, { duration: 300 }),
                4,
                true,
                () => runOnJS(onEndReachedAnimationDone)()
            ))
        } else {
            end.value = 0;
        }
    }, [endReached])
    return (
        <TouchableOpacity 
            activeOpacity={0.8}
            onPress={handlePress}
            style={{alignItems: 'center', justifyContent: 'center', width: 140, marginRight: 15 }}>
            <Animated.View style={[{marginBottom: 20, marginTop: -40, alignItems: 'center', justifyContent: 'center', width: 46, height: 46, borderRadius: 23, backgroundColor: SECONDARY_COLOR }, buttonStyle]}>
                <Feather style={{right: -2}} color={'#fff'} name='chevron-right' size={40} />
            </Animated.View>
            <InterReg text={ t('book.viewAll') } style={{color: '#666'}} />
        </TouchableOpacity>
    )
}