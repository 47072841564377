import { DELETE_GROUP_COLLECTION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteGroupCollectionInProgress = createAction('DELETE_GROUP_COLLECTION_IN_PROGRESS');
export const deleteGroupCollectionSuccess = createAction('DELETE_GROUP_COLLECTION_SUCCESS');
export const deleteGroupCollectionFailure = createAction('DELETE_GROUP_COLLECTION_FAILURE');

export function deleteGroupCollectionApi({ groupId, groupCollectionId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId, groupCollectionId },
            actions: [deleteGroupCollectionInProgress, deleteGroupCollectionSuccess, deleteGroupCollectionFailure],
            name: 'deleteGroupCollection',
            dispatch,
            getState
        })
    }
}