import { UPDATE_LIST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateListInProgress = createAction('UPDATE_LIST_IN_PROGRESS');
export const updateListSuccess = createAction('UPDATE_LIST_SUCCESS');
export const updateListFailure = createAction('UPDATE_LIST_FAILURE');

export function updateListApi({ list }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { list },
            actions: [updateListInProgress, updateListSuccess, updateListFailure],
            name: 'updateList',
            dispatch,
            getState
        })
    }
}