import { ACCEPT_GROUP_INVITATION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const acceptGroupInvitationInProgress = createAction('ACCEPT_GROUP_INVITATION_IN_PROGRESS');
export const acceptGroupInvitationSuccess = createAction('ACCEPT_GROUP_INVITATION_SUCCESS');
export const acceptGroupInvitationFailure = createAction('ACCEPT_GROUP_INVITATION_FAILURE');

export function acceptGroupInvitationApi({ groupInvitationId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupInvitationId, groupId },
            actions: [acceptGroupInvitationInProgress, acceptGroupInvitationSuccess, acceptGroupInvitationFailure],
            name: 'acceptGroupInvitation',
            dispatch,
            getState
        })
    }
}