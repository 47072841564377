import { GET_BOOK_REVIEWS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getBookReviewsInProgress = createAction('GET_BOOK_REVIEWS_IN_PROGRESS');
export const getBookReviewsSuccess = createAction('GET_BOOK_REVIEWS_SUCCESS');
export const getBookReviewsFailure = createAction('GET_BOOK_REVIEWS_FAILURE');

export function getBookReviewsApi({ bookId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId },
            actions: [getBookReviewsInProgress, getBookReviewsSuccess, getBookReviewsFailure],
            name: 'getBookReviews',
            showLoading,
            checkCaching,
            dispatch,
            getState
        })
    }
}