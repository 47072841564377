import React, { useState, useContext } from 'react';
import { View, StyleSheet, Platform, Pressable } from 'react-native';
import InterReg from './InterReg';
import Animated, { FadeInRight, FadeOutRight } from 'react-native-reanimated';
import AppSettingsContext from '../context/AppSettingsContext';
import { SECONDARY_COLOR } from '../constants';
import { Ionicons } from '@expo/vector-icons';

const BORDER_WIDTH = 8;
const SHOWER_COUNT = 4;
const SHADOWS_STYLES = Array(SHOWER_COUNT).fill({}).map((_, index) => ({ 
        styles: {
            position: 'absolute',
            borderColor: 'transparent',
            borderWidth: BORDER_WIDTH + (index+1),
            borderRightColor: `rgba(0, 0, 0, 0.0${SHOWER_COUNT-index})`
        }
    })
);

export default function CollectionInfo({ circleHeight, handleCloseInfo, tBase }) {
    const {t} = useContext(AppSettingsContext);
    const [containerDims, setContainerDims] = useState({ height: 0, width: 0 });
    const onLayout = ({ nativeEvent: { layout }}) => setContainerDims(layout);
    return (
        <Animated.View 
            entering={FadeInRight.delay(1000)} 
            exiting={FadeOutRight}
            onLayout={onLayout} 
            style={[styles.container, { left: circleHeight + 20, top: ((circleHeight + 10) - containerDims.height) / 2 }]}>
                <InterReg text={ t(`${tBase}.collections.info`)} style={{color: '#fff'}} />
                { SHADOWS_STYLES.map((shadow, index) => (
                    <View key={index} style={[shadow.styles, { right: containerDims.width, top: (containerDims.height / 2) - (BORDER_WIDTH + (index+2)) }]}/>
                ))}
            <View style={[styles.arrow, { right: containerDims.width, top: (containerDims.height / 2) - BORDER_WIDTH }]}/>
            <Pressable 
                onPress={handleCloseInfo}
                hitSlop={{ top: 7, left: 7, bottom: 7, right: 7 }}
                style={styles.close}>
                <Ionicons name='close' color='rgba(255,255,255,0.7)' size={ 20 } />
            </Pressable>
        </Animated.View>
    )
} 

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 1,
        padding: 10,
        width: 220,
        paddingHorizontal: 14,
        paddingRight: 30, 
        backgroundColor: SECONDARY_COLOR,
        borderRadius: 6,
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
        })
    },
    arrow: {
        borderWidth: BORDER_WIDTH,
        borderRightColor: SECONDARY_COLOR,
        borderLeftColor: 'transparent',
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        position: 'absolute',
    },
    close: {
        width: 30, 
        height: 30, 
        alignItems: 'center', 
        justifyContent: 'center', 
        position: 'absolute',
        top: 0,
        right: 0
    }
})