import { createReducer } from '@reduxjs/toolkit'
import { getGroupInvitationsSuccess } from '../actions/getGroupInvitations';
import { hasGroupInvitations, hasGroupInvitation, removeGroupInvitation, matchResetStore } from './matchers';
import { removeOldKeysFromState } from '../utils';

const initialState = {};

export const groupInvitations = createReducer(initialState, (builder) => {
    builder
    .addMatcher(removeGroupInvitation, (state, action) => {
        delete state[action.payload.groupInvitationId];
    })
    .addMatcher(hasGroupInvitation, (state, action) => {
        state[action.payload.groupInvitation.id] = action.payload.groupInvitation;
    })
    .addMatcher(hasGroupInvitations, (state, action) => {
        action.payload.groupInvitations.forEach(groupInvitation => state[groupInvitation.id] = groupInvitation)
        if(action.type == getGroupInvitationsSuccess) {
            removeOldKeysFromState(state, action.payload.groupInvitations);
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})