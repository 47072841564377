import React, { memo, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import ActivityDetail from './ActivityDetail';
import AppSettingsContext from '../context/AppSettingsContext';
import { SECONDARY_COLOR, MAIN_COLOR, GRAY_TEXT_PALE } from '../constants';
import { getTimeSinceNow, navigateToGroup, navigateToGroupCollection } from '../utils';
import { useGroupCollection, useGroup } from '../hooks';

const tBase = 'screens.activity';

function ActivityAddedBooksToGroupCollection({navigation, activityItem, isLast }) {
    const {t, locale, timeAgoInWords} = useContext(AppSettingsContext);
    const group = useGroup(activityItem.groupId);
    const groupCollection = useGroupCollection(activityItem.groupCollectionId);
    if(!groupCollection || !group) return <View />
    const booksListTitle = t(`${tBase}.groupCollectionBooksListTitle`, { name: groupCollection.name, count: activityItem.bookIds.length});
    const handleGroupPress = () => { navigateToGroup(navigation, group) };
    const handleGroupCollectionPress = () => { navigateToGroupCollection(navigation, groupCollection, group) };
    const handleBooksPress = () => { navigation.navigate('BooksList', { bookIds: activityItem.bookIds, title: booksListTitle }) };
    return (
        <ActivityDetail activityItem={activityItem} navigation={navigation} isLast={isLast} tTitleKey='groupCollectionBooksListTitle' tTitleParams={{ name: groupCollection.name}}>
            <MontSBold text={activityItem.bookIds.length} style={styles.count} />{' '}
            <MontSBold onPress={handleBooksPress} style={styles.bookLabel} text={ t('general.books', { count: activityItem.bookIds.length }) } />{' '} 
            <InterReg text={ t(`${tBase}.toTheCollection`) } />{' '} 
            <MontSBold style={styles.groupLabel} onPress={handleGroupCollectionPress} text={groupCollection.name} />{' '}
            <InterReg text={ t(`${tBase}.inGroup`) } />{' '} 
            <MontSBold style={styles.groupLabel} onPress={handleGroupPress} text={group.name} />{'  '}
            <InterReg style={styles.date} text={getTimeSinceNow(activityItem.latest, locale, timeAgoInWords)} />
        </ActivityDetail>
    )
}

const styles = StyleSheet.create({
    nickname: {
        color: SECONDARY_COLOR
    },
    count: {
        color: MAIN_COLOR
    },
    bookLabel: {
        color: MAIN_COLOR
    },
    groupLabel: {
        color: SECONDARY_COLOR
    },
    date: {
        color: GRAY_TEXT_PALE, 
        fontSize: 13
    }
})

export default memo(ActivityAddedBooksToGroupCollection);