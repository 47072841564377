import { SET_BOOK_LIKE_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setBookLikeInProgress = createAction('SET_BOOK_LIKE_IN_PROGRESS');
export const setBookLikeSuccess = createAction('SET_BOOK_LIKE_SUCCESS');
export const setBookLikeFailure = createAction('SET_BOOK_LIKE_FAILURE');

export function setBookLikeApi({ bookId, like }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, like },
            actions: [setBookLikeInProgress, setBookLikeSuccess, setBookLikeFailure],
            name: 'setBookLike',
            dispatch,
            getState
        })
    }
}