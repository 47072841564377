import React, { useRef } from 'react';
import { Text, StyleSheet, Pressable } from 'react-native';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming,
    Easing
} from 'react-native-reanimated';
import { isEmpty, isWeb } from '../utils';
import { promptLoginOrSignup } from '../utils/alerts';
import { getButtonThemes } from '../utils/buttonThemes';
import { Ionicons } from '@expo/vector-icons';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';
import { useDispatch, useSelector } from 'react-redux';

const sizes = {
    big: {
        textStyle: { 
            fontSize: 16 
        },
        buttonStyle: { 
            height: 50, 
            paddingBottom: 0 
        }
    },
    medium: {
        textStyle: { 
            fontSize: 15 
        },
        buttonStyle: { 
            height: 45, 
            paddingBottom: 1 
        }
    },
    small: {
        textStyle: { 
            fontSize: 14 
        },
        buttonStyle: { 
            height: 40, 
            paddingBottom: 1 
        }
    },
    xSmall: {
        textStyle: { 
            fontSize: 13 
        },
        buttonStyle: { 
            height: 32, 
            paddingBottom: 1 
        }
    }
}

const hitSlopDefault = {top: 5, left: 5, bottom: 5, right: 5}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

export default function Button({ 
        text, 
        onPress, 
        buttonStyle, 
        textStyle, 
        iconName, 
        iconSize=15, 
        iconColor, 
        iconStyle={},
        Icon=null, 
        showHover=true, 
        theme='mainColor',
        requiresAccount=false, 
        onLayout, 
        size='big', 
        hitSlop=hitSlopDefault 
    }) {
    const themes = getButtonThemes();
    function getThemeStyle(key) {
        return themes[theme] && themes[theme][key] ? themes[theme][key] : {}
    }
    let scale = useSharedValue(1);
    const scaleStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: withTiming(scale.value, {
                duration: 200,
                easing: Easing.elastic()
            })}],
        }
    })
    let themeButtonStyle    = getThemeStyle('themeButtonStyle')
    let themeTextStyle      = getThemeStyle('themeTextStyle')
    let themeIconColor      = getThemeStyle('themeIconColor')
    let themeButtonHover    = getThemeStyle('themeButtonHover')
    let themeTextHover      = getThemeStyle('themeTextHover')
    let themeIconColorHover = getThemeStyle('themeIconColorHover')
    let textMarginRight     = text && iconName ? 6 : 0
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const isFocused = useFocus(ref);
    const isActive = useActive(ref);
    function showHoverStyle() {
        return isHovered && showHover
    }
    const dispatch = useDispatch();
    const loggedUserId = useSelector(state => state.loggedUserId);
    const makeOnPress = () => {
        if(requiresAccount && loggedUserId === null) return () => promptLoginOrSignup(dispatch);
        return onPress;
    }
	return (
        <AnimatedPressable 
            ref={ref}
            onPress={ makeOnPress() }
            onPressIn={ () => scale.value = 1.015 }
            onPressOut={ () => scale.value = 1 }
            hitSlop={hitSlop}
            pointerEvents={ onPress ? 'auto' : 'none' }
            onLayout={
                ({nativeEvent: {layout: {x, y, width, height}}}) => {
                    if(width > 0 && onLayout) {
                        onLayout({x, y, width, height})
                    }
                }
            }
            style={[
                scaleStyle, 
                styles.button, 
                { paddingHorizontal: theme.includes('Border') ? 10 :  12 },
                isWeb() && { transitionProperty: 'background-color', transitionDuration: '300ms' },
                sizes[size].buttonStyle, 
                themeButtonStyle, 
                buttonStyle,
                showHoverStyle() && themeButtonHover
            ]}>
                { iconName ? 
                    <Ionicons 
                        name ={ iconName } 
                        size ={ iconSize } 
                        style={ iconStyle }
                        color={ showHoverStyle() && !isEmpty(themeIconColorHover) ? themeIconColorHover : iconColor || themeIconColor || '#fff' } />  : null
                }
                { Icon && Icon }
                { text ? 
                    <Text 
                        allowFontScaling={ false }  
                        style={[
                            { marginLeft: textMarginRight },
                            styles.buttonText, 
                            sizes[size].textStyle, 
                            themeTextStyle, 
                            textStyle,
                            showHoverStyle() && themeTextHover,
                        ]}>{ text }
                    </Text>
                : null }
        </AnimatedPressable>	
	)
}

const styles = StyleSheet.create({
	buttonText: {
        fontFamily: 'Montserrat_700Bold',
        textAlign: 'center'
    },
    button: {
        alignSelf: 'stretch',
        marginBottom: 13,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    }
})
