import React, { useState, useCallback, memo, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, interpolate, Extrapolate } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { setBookLikeApi } from '../actions/setBookLike';
import { SharedElement } from 'react-navigation-shared-element';
import { getBookLikesApi } from '../actions/getBookLikes';
import { requestBorrowBookApi } from '../actions/requestBorrowBook';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { selectRemainingProfileElementsToFill } from '../selectors';
import { MAIN_COLOR, BOOK_COVER_RATIO, HEADER_ACTIONS_HEIGHT } from '../constants';
import RoundButton from './RoundButton';
import MenuBook from './MenuBook';
import Button from './Button';
import BookHeaderBg from './BookHeaderBg';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import HeaderActionsBar from './HeaderActionsBar';
import ShareButton from './ShareButton';
import { IcoMoon } from '../utils/customIcons';
import { isNative, coverUri, goBackFromBook, isInModal, isIOS, getInitialScreenWidth, getShortUrl, isLent } from '../utils';
import { useGetDataOnNavFocus, useUser, useCanBorrowBook, useBookLikes, useBookCurrentLend } from '../hooks';
import { profileMustBeFilledToRequestBook, confirmSendBorrowRequest } from '../utils/alerts';
import Cover from './Cover';
import AppSettingsContext from '../context/AppSettingsContext';

export const UPPER_Y_SCROLL = 140;
export const COVER_WIDTH = 175;
export const COVER_HEIGHT = COVER_WIDTH * BOOK_COVER_RATIO;
export const COVER_OVERLAP = 160;
const COVER_SCALING = 0.5;
const COVER_SCALED_WIDTH = COVER_WIDTH * COVER_SCALING;
const COVER_SCALED_PADDING_LEFT = 20;
const BOOK_HEADER_HEIGHT = 215;

function BookHeader({ navigation, book, route, animateHeader, scrollY }) {
    const {t} = useContext(AppSettingsContext);
    const mainViewWidth = getInitialScreenWidth(navigation);
    const inModal = isInModal(navigation);
    const bookId = route.params.bookId;
    const listId = route.params?.listId ?? '';
    const shelfId = route.params?.shelfId ?? '';
    const insets = useSafeAreaInsets();
    const lend = useBookCurrentLend(bookId);
    const bookIsLent = isLent(lend);
    const dispatch = useDispatch();
    const [headerWidth, setHeaderWidth] = useState(mainViewWidth);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const handleLikeBook = () => { dispatch(setBookLikeApi({ bookId, like: !likedByMe })) }
    const handleBackNavigation = useCallback(() => {
        const group = (() => {
            if(route?.params?.groupId && route?.params?.groupSlug) return { id: route.params.groupId, slug: route.params.groupSlug };
            return
        })()
        goBackFromBook(navigation, activeBottomTab, group);
    }, [activeBottomTab]);
    const renderMenuTrigger = () => <RoundButton type='options' size={ HEADER_ACTIONS_HEIGHT } />;
    const deleteDone = useCallback((json) => { if(json?.success) goBackFromBook(navigation, activeBottomTab); }, [])
    const bookLikes = useBookLikes(bookId, book.providerBookId);
    const likedByMe = !!bookLikes.filter(({ byId }) => byId == loggedUserId).length;
    const isOwner = loggedUserId === book.ownerId;
    const url = getShortUrl('book', book.shortId);
    const uri = coverUri(book.cover, book.coverHash);
    const remainingProfileElementsToFill = useSelector(selectRemainingProfileElementsToFill);
    const owner = useUser(book.ownerId);
    const showBorrowButton = useCanBorrowBook(book);
    const showBorrowedStatus = !showBorrowButton && bookIsLent;
    const handleBorrowBookPress = () => {
        if(remainingProfileElementsToFill == 0) {
            confirmSendBorrowRequest(handleSenBorrowBookRequest, dispatch, { user: owner, book })
        } else {
            profileMustBeFilledToRequestBook(() => navigation.navigate('Modal', { screen: 'UpdateProfile' }), dispatch)
        }
    }
    const handleSenBorrowBookRequest = () => { dispatch(requestBorrowBookApi({ bookId, context: 'detail' })) }
    const top = (() => {
        if((isNative() && !inModal) || (inModal && !isNative())) return Math.max(insets.top, 28);
        return 20 
    })()
    const animatedStyles = {
        cover: useAnimatedStyle(() => {
            const translateXEnd = animateHeader.value ? -((headerWidth-COVER_SCALED_WIDTH)/2) + COVER_SCALED_PADDING_LEFT : 0;
            return {
                transform: [
                    { translateY: interpolate(scrollY.value, [0, UPPER_Y_SCROLL], [-COVER_OVERLAP, -BOOK_HEADER_HEIGHT], Extrapolate.CLAMP)},
                    { translateX: interpolate(scrollY.value, [0, UPPER_Y_SCROLL*0.8, UPPER_Y_SCROLL], [0, 0, translateXEnd], Extrapolate.CLAMP)},
                    { scale: interpolate(scrollY.value, [0, UPPER_Y_SCROLL], [1, COVER_SCALING], Extrapolate.CLAMP)},
                ]
            }
        }),
        headerInfo: useAnimatedStyle(() => {
            const opacityEnd = animateHeader.value ? 1 : 0;
            return {
                opacity: interpolate(scrollY.value, [0, UPPER_Y_SCROLL*0.8, UPPER_Y_SCROLL], [0, 0, opacityEnd], Extrapolate.CLAMP),
                transform: [{translateX: interpolate(scrollY.value, [0, UPPER_Y_SCROLL*0.8, UPPER_Y_SCROLL], [-50, -50, 0], Extrapolate.CLAMP)}]
            }
        })
    }
    const getBookLikes = () => { dispatch(getBookLikesApi({ bookId, showLoading: false })) }
    const onLayout = ({nativeEvent: { layout: { width }}}) => headerWidth != width && setHeaderWidth(width)
    useGetDataOnNavFocus(navigation, getBookLikes);
    return (
        <>
            <Animated.View 
                style={[{ height: BOOK_HEADER_HEIGHT + top, width: '100%', backgroundColor: uri ? '#fff' : MAIN_COLOR }, animatedStyles.header]}
                onLayout={onLayout}>
                <BookHeaderBg
                    uri={ isIOS() ? book.cover : undefined }
                    blurhash={ book.blurHash }
                    height={BOOK_HEADER_HEIGHT + top}
                    width={mainViewWidth} />
                <HeaderActionsBar top={ top } mainViewWidth={ mainViewWidth } onPressBack={ handleBackNavigation }>
                    { showBorrowButton &&
                    <Button 
                        buttonStyle={styles.borrowBtn} 
                        size='xSmall'
                        onPress={ handleBorrowBookPress }
                        requiresAccount={ true } 
                        text={ t('book.borrow') } />
                    }
                    { showBorrowedStatus && 
                     <Button 
                        Icon={ <IcoMoon name='upload' size={14} color="#fff" style={{paddingRight: 8}} /> }
                        theme='mainColor'
                        buttonStyle={styles.borrowBtn} 
                        size='xSmall'
                        text={ t('book.lending') } />
                    }
                    <ShareButton
                        type='book'
                        url={ url }
                        name={ book.title }
                        isOwner={ isOwner }
                        buttonType='round' />
                    <RoundButton
                        style={ styles.like }
                        size={ HEADER_ACTIONS_HEIGHT }
                        requiresAccount={ true }
                        onPress={ handleLikeBook } 
                        type='like'
                        iconName={ likedByMe ? 'heart' : 'heart-outline' } />
                    <MenuBook
                        placement='bottom'
                        navigation={navigation}
                        deleteDone={ deleteDone }
                        context='detail'
                        book={ book }
                        style={{ width: 34, height: 34 }}
                        TriggerButton={ renderMenuTrigger } />
                </HeaderActionsBar>
                <Animated.View style={[styles.mainInfo, animatedStyles.headerInfo]}>
                    <View>
                        <MontSBold text={book.title} numberOfLines={2} style={[ styles.bookTitle, { marginBottom: book.subTitle ? 3 : 5 } ]} />
                        { book.subTitle ? <InterReg numberOfLines={1} text={book.subTitle} style={styles.bookSubTitle} /> : null }
                        { book.author ? <InterReg numberOfLines={2} text={book.author} style={styles.bookAuthor} /> : null }
                    </View>
                </Animated.View>
            </Animated.View>
            <Animated.View style={animatedStyles.cover}>
                { isNative() && uri ?
                <SharedElement id={`cover.${book.id}${listId}${shelfId}.photo`} >
                    <Cover 
                        cover={book.cover} 
                        blurHash={book.blurHash} 
                        coverHash={book.coverHash} 
                        style={{alignSelf: 'center', borderRadius: 8}}
                        width={COVER_WIDTH} />
                </SharedElement> :
                <Cover 
                    cover={book.cover} 
                    blurHash={book.blurHash} 
                    coverHash={book.coverHash}
                    style={{alignSelf: 'center', borderRadius: 8}} 
                    width={COVER_WIDTH} />
                }
            </Animated.View>
        </>
    )
}

export default memo(BookHeader);

const styles = StyleSheet.create({
    buttons: {
        width: 34, 
        height: 34,
        borderRadius: 17,
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    back: {
        paddingRight: 2
    },
    like: {
        marginRight: 6,
        marginLeft: 6
    },
    actionsBtn: { 
        width:22, 
        height:22,
        position: 'absolute',
        right: 0,
        top: 6 
    },
    bookTitle: {
        fontSize: 17,
        marginBottom: 5,
        color: 'rgba(0,0,0,0.9)'
    },
    bookSubTitle: {
        fontSize: 15,
        marginBottom: 5,
        color: 'rgba(0,0,0,0.8)'
    },
    bookAuthor: {
        fontSize: 15,
        color: '#666',
        color: 'rgba(0,0,0,0.6)'
    },
    mainInfo: {
        position: 'absolute', 
        height: COVER_HEIGHT/2,
        left: 0, 
        paddingLeft: (COVER_WIDTH/2) + COVER_SCALED_PADDING_LEFT + 15, 
        bottom: 20, 
        paddingRight: 15 
    },
    borrowBtn: {
        paddingHorizontal: 15,
        height: HEADER_ACTIONS_HEIGHT,
        borderRadius: 17,
        marginRight: 6
    },
})