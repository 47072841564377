import React, { useContext, useState, memo, useCallback } from 'react';
import { Text, View, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import AppSettingsContext from '../context/AppSettingsContext';
import { READING_STATUSES, GRAY_LIGHT, MAIN_COLOR, SECONDARY_COLOR, GRAY_TEXT_PALE } from '../constants';
import { MaterialCommunityIcons as MCIcons, MaterialIcons as MIcons, Ionicons } from '@expo/vector-icons';
import InterReg from './InterReg';
import TextLink from './TextLink';
import InterSemiBold from './InterSemiBold';
import { IcoMoon } from '../utils/customIcons';
import { isWeb, getKeywordLabel } from '../utils';

function FilterLibrary({ searchContext, height='auto', paddingHorizontal=20 }) {
    const { t } = useContext(AppSettingsContext);
    const { filters, setFilters, keywords, filterCounts, resetFilters } = searchContext;
    const [containerWidth, setContainerWidth] = useState(null);
    const onLayout = ({ nativeEvent: { layout }}) => !containerWidth && setContainerWidth(layout.width-(paddingHorizontal*2));
    return (
        <View onLayout={onLayout} style={{ width: '100%', height }}>
            <View style={{paddingHorizontal, paddingVertical: 5, paddingTop: isWeb() ? 15 : 0, paddingBottom: isWeb() ? 0 : 15, flexDirection: 'row', justifyContent: 'center' }}>
                { isWeb() ? 
                <View style={{marginBottom: 15}}>
                    <InterSemiBold text={t(`book.filters.title`)} style={styles.title} />
                    <Text style={{ textAlign: 'center', fontSize: 13 }}>
                        <InterReg text={filterCounts.total} style={{color: '#666'}} />
                        { ' ' }
                        <InterReg text={t('general.books', { count: filterCounts.total })} style={{color: '#666'}}  />
                    </Text>
                </View> :
                <Text style={{ fontSize: 13 }}>
                    <InterReg text={filterCounts.total} style={{color: '#666'}} />
                    { ' ' }
                    <InterReg text={t('general.books', { count: filterCounts.total })} style={{color: '#666'}}  />
                </Text>
                }
                <TextLink 
                    text={t(`book.filters.clearAll`)} 
                    style={[styles.clearAll, isWeb() && { position: 'absolute', right: paddingHorizontal }]} 
                    onPress={resetFilters} />
            </View>
            { containerWidth && 
                <ScrollView contentContainerStyle={{ paddingHorizontal, paddingBottom: 20 }}>
                    <FilterReadingStatus
                        counts={filterCounts.readingStatus} 
                        setFilters={setFilters} 
                        selected={filters.readingStatus} 
                        containerWidth={containerWidth} />
                    <FilterPossessed
                        counts={filterCounts.possessed} 
                        setFilters={setFilters} 
                        style={{ marginTop: 10 }}
                        selected={filters.possessed} 
                        containerWidth={containerWidth} />
                    <FilterLending 
                        counts={filterCounts.lending}
                        setFilters={setFilters} 
                        style={{ marginTop: 10 }}
                        selected={filters.lending} 
                        containerWidth={containerWidth} />
                    <FilterKeywords
                        keywords={keywords}
                        counts={filterCounts.keywords}
                        setFilters={setFilters} 
                        style={{ marginTop: 10 }}
                        selected={filters.keywords} 
                        containerWidth={containerWidth} />
                </ScrollView> 
            }
        </View>
    )
}

const renderLendingIcon = (statusKey, selected) => ({
    'lending': <IcoMoon name='upload' size={16} style={{ position: 'relative', right: -2 }} color={selected ? '#fff' : MAIN_COLOR } />,
    'notLending': <Ionicons name='close-circle' size={19} color={ selected === false ? '#fff' : MAIN_COLOR } style={{ position: 'relative', right: -2 }} />
}[statusKey]);

const lending = [
    { key: 'lending', value: true },
    { key: 'notLending', value: false }
]

const FilterLending = memo(({ style={}, selected, setFilters, containerWidth, counts }) => {
    const { t } = useContext(AppSettingsContext);
    const handlePress = useCallback((value) => {
        const lending = value === selected ? null : value;
        setFilters({ lending });
    }, [setFilters]);
    return (
        <Filter style={style} title={t(`book.filters.lending`)}>
            { lending.map(({ key, value }) => (
                <Status
                    key={key}
                    statusWidth={(containerWidth / 2) - 5}
                    isSelected={selected === value}
                    count={counts ? counts[value] : null}
                    label={t(`book.${key}`)}
                    handlePress={() => handlePress(value)}
                    Icon={renderLendingIcon(key, selected)}
                />
            ))}
        </Filter>
    )
})

const renderPossessedIcon = (statusKey, selected) => ({
    'possessed': <Ionicons name='checkmark-circle' size={19} iconSize={11} color={ selected ? '#fff' : MAIN_COLOR } style={{ position: 'relative', right: -2 }} />,
    'notPossessed': <Ionicons name='close-circle' size={19} color={ selected === false ? '#fff' : MAIN_COLOR } style={{ position: 'relative', right: -2 }} />
}[statusKey]);

const possessed = [
    { key: 'possessed', value: true },
    { key: 'notPossessed', value: false }
]

const FilterPossessed = memo(({ style={}, selected, setFilters, containerWidth, counts }) => {
    const { t } = useContext(AppSettingsContext);;
    const handlePress = useCallback((value) => {
        const possessed = value === selected ? null : value;
        setFilters({ possessed });
    }, [setFilters]);
    return (
        <Filter style={style} title={t(`book.filters.ownership`)}>
            { possessed.map(({ key, value }) => (
                <Status
                    key={key}
                    statusWidth={(containerWidth / 2) - 5}
                    isSelected={selected === value}
                    count={counts ? counts[value] : null}
                    label={t(`book.${key}`)}
                    handlePress={ () => handlePress(value)}
                    Icon={renderPossessedIcon(key, selected)}
                />
            ))}
        </Filter>
    )
})

const renderReadingStatusIcon = (statusKey, selected) => ({
    'reading': <MCIcons name='bookmark' color={selected === 'reading' ? '#fff' : MAIN_COLOR} size={21} style={{ position: 'relative', right: -1}} />,
    'read':    <MCIcons name='bookmark-check' color={selected === 'read' ? '#fff' : MAIN_COLOR} size={22} />,
    'toRead':  <MIcons name='watch-later' color={selected === 'toRead' ? '#fff' : MAIN_COLOR} size={19} style={{ position: 'relative', right: -1}} />,
    'unread':  <MCIcons name='bookmark-minus' color={selected === 'unread' ? '#fff' : MAIN_COLOR} size={22} style={{ position: 'relative', right: -1}} />,
}[statusKey]);

const FilterReadingStatus = memo(({ style={}, selected, setFilters, containerWidth, counts }) => {
    const { t } = useContext(AppSettingsContext);
    const handlePress = useCallback((value) => {
        const readingStatus = value === selected ? null : value;
        setFilters({ readingStatus });
    }, [setFilters]);
    return (
        <Filter style={style} title={t(`book.filters.readingStatus`)}>
            { READING_STATUSES.map(statusKey => (
                <Status
                    key={statusKey}
                    statusWidth={(containerWidth / 2) - 5}
                    isSelected={selected === statusKey}
                    count={counts ? counts[statusKey] : null}
                    label={t(`book.status.${statusKey}`)}
                    handlePress={() => handlePress(statusKey)} 
                    Icon={renderReadingStatusIcon(statusKey, selected)}
                /> 
            ))}
        </Filter>
    )
})

const FilterKeywords = memo(({ style={}, selected, setFilters, keywords, counts }) => {
    const { t, locale } = useContext(AppSettingsContext);
    const handlePress = useCallback((value) => {
        setFilters({ keywords: updatedSelected(selected, value) });
    }, [setFilters]);
    return (
        <Filter style={style} statusContainerStyle={{justifyContent: 'flex-start'}} title={t(`book.filters.keywords`)}>
            { keywords.map(keyword => (
                <Status
                    key={keyword.id}
                    style={{ marginRight: 8 }}
                    statusWidth='auto'
                    count={counts ? counts[keyword.id] : null}
                    isSelected={selected.includes(keyword.id)}
                    label={getKeywordLabel(keyword, locale)}
                    handlePress={ () => handlePress(keyword.id)}
                />
            ))}
        </Filter>
    )
})

const Status = memo(({ label, Icon, handlePress, isSelected, statusWidth, style={}, count=null }) => {
    const isDisabled = count !== null && count === 0 && !isSelected;
    const textColor = (() => {
        if(isSelected) return '#fff';
        if(isDisabled) return '#c2c2c2';
        return SECONDARY_COLOR
    })()
    const countTextColor = (() => {
        if(isSelected) return 'rgba(255,255,255,0.8)';
        if(isDisabled) return '#c2c2c2';
        return GRAY_TEXT_PALE
    })();
    return (
        <TouchableOpacity
            activeOpacity={isDisabled ? 1 : 0.6}
            onPress={isDisabled ? () => {} : handlePress}
            hitSlop={{ top: 5, right: 5, bottom: 5, left: 5 }}
            style={[
                styles.status, 
                isSelected && styles.statusSelected,
                isDisabled && { cursor: 'default' }, 
                { width: statusWidth, maxWidth: statusWidth, minWidth: statusWidth }, 
                style
            ]}>
            <>
            { Icon && 
                <View style={[styles.statusIcon, {opacity: isDisabled ? 0.4 : 1 }]}>{Icon}</View> 
            }
            <InterReg 
                text={label} 
                style={[styles.statusText, { color: textColor }]} />
            { count !== null && 
                <InterReg 
                    style={[styles.statusCount, { color: countTextColor }]} 
                    text={count} /> 
            }
            </>
        </TouchableOpacity>
    )
});

const Filter = memo((props) => {
    const { style, title, statusContainerStyle } = props;
    return (
        <View style={style}>
            <InterSemiBold text={title} style={styles.statusTitle} />
            <View style={[styles.statusContainer, statusContainerStyle]}>
                {props.children}
            </View>
        </View>
    )
})

function updatedSelected(selected, key) {
    let newSelected = [...selected];
    if(selected.includes(key)) {
        newSelected = newSelected.filter(s => s !== key) 
    } else {
        newSelected.push(key);
    }
    return newSelected;
}

export default memo(FilterLibrary);

const styles = StyleSheet.create({
    title: {
        textAlign: 'center',
        marginBottom: 5
    },
    clearAll: {
        color: MAIN_COLOR,
        marginLeft: 'auto'
    },
    statusTitle: {
        color: SECONDARY_COLOR,
        marginBottom: 10,
        fontSize: 15
    },
    statusContainer: {
        flex: 1,
        flexWrap: 'wrap', 
        flexDirection: 'row', 
        justifyContent: 'space-between'
    },
    status: {
        flexDirection: 'row',
        height: 36,
        borderRadius: 16,
        backgroundColor: GRAY_LIGHT,
        alignItems: 'center',
        paddingHorizontal: 8,
        marginBottom: 10
    },
    statusIcon: {
        width: 22
    },
    statusText: {
        paddingLeft: 4,
        paddingRight: 4
    },
    statusCount: {
        fontSize: 11,
        marginLeft: 'auto',
        paddingLeft: 6,
        paddingRight: 4
    },
    statusSelected: {
        backgroundColor: MAIN_COLOR
    }
})