import AsyncStorage from '@react-native-async-storage/async-storage';
import createCompressor from 'redux-persist-transform-compress'
import { createStore, applyMiddleware } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import ExpoFileSystemStorage from "redux-persist-expo-filesystem"
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import app from './reducers';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { isAndroid, isWeb } from './utils';


const migrations = {
    0: (state) => {
        if(state.profile && state.profile.id) {
            return {
                ...state,
                books: {},
                groups: {},
                users: { [state.profile.id]: state.profile },
                loggedUserId: state.profile.id,
                updates: {},
                organisation: {},
                modals: [],
                globalSearch: [],
                globalSearchPattern: '',
                discussions: [],
                shelves: []  
            }
        }
        return state
    },
    1: () => {
        return {}
    }
}

const compressor = createCompressor();
const transforms = [];

if(isWeb()) transforms.push(compressor);

const persistConfig = {
    key: 'root',
    keyPrefix: '',
    timeout: 10000,
    version: 1,
    transforms,
    stateReconciler: autoMergeLevel2,
    storage: isAndroid() ? ExpoFileSystemStorage : AsyncStorage, 
    migrate: createMigrate(migrations, { debug: false }),
    blacklist: [
        'loading', 
        'settings',
        'generatedCovers', 
        'alert',
    ]
}

const middlewares = [thunk];

if (process.env.NODE_ENV === `development` && isWeb()) { 
    middlewares.push(logger);
}

const persistedReducer = persistReducer(persistConfig, app);

function configureStore() {
    let store = createStore(
        persistedReducer, 
        applyMiddleware(...middlewares),
    )
    let persistor = persistStore(store)
    return { store, persistor }
}

const { store, persistor } = configureStore();

export { store, persistor };