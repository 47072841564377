import React, { memo } from 'react'
import { Image } from 'react-native'
import CachedImage from '../utils/CachedImage';

import { isWeb } from '../utils'

function Img({ resizeMode='cover', style={}, uri, useCachedImg=true, onLoadEnd }) {
	const props = { resizeMode, style, source: { uri }};
	if(onLoadEnd) props.onLoadEnd = onLoadEnd;
	if(isWeb() || !useCachedImg) {
		return <Image {...props} />
	} else {
		return <CachedImage {...props} cacheKey={`${uri.split('/').pop()}`} />
	}
}

export default memo(Img)