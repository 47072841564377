import { SET_BOOK_HIDDEN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setBookHiddenInProgress = createAction('SET_BOOK_HIDDEN_IN_PROGRESS');
export const setBookHiddenSuccess = createAction('SET_BOOK_HIDDEN_SUCCESS');
export const setBookHiddenFailure = createAction('SET_BOOK_HIDDEN_FAILURE');

export function setBookHiddenApi({ bookId, hidden }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, hidden },
            actions: [setBookHiddenInProgress, setBookHiddenSuccess, setBookHiddenFailure],
            name: 'setBookHidden',
            dispatch,
            getState
        })
    }
}