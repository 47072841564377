import Color from 'color';

import {
	MAIN_COLOR, 
    MAIN_COLOR_DARK, 
    MAIN_COLOR_LIGHT,
    SECONDARY_COLOR,
    SECONDARY_COLOR_DARK,
    SECONDARY_COLOR_LIGHT,
    TERTIARY_COLOR,
    TERTIARY_COLOR_DARK,
    TERTIARY_COLOR_LIGHT,
    DANGER_COLOR,
    TWITTER_COLOR,
    FACEBOOK_COLOR
} from '../constants'

export function getButtonThemes() {
    const buttonStyles = getButtonsStyles()
    return {
        mainColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.mainColor },
            themeTextStyle: { ...buttonStyles.mainColorText },
            themeButtonHover: { ...buttonStyles.mainColorHover }
        },
        mainColorDarker: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.mainColorDarker },
            themeTextStyle: { ...buttonStyles.mainColorDarkerText },
            themeButtonHover: { ...buttonStyles.mainColorDarkerHover }
        },
        secondaryColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.secondaryColor },
            themeTextStyle: { ...buttonStyles.secondaryColorText },
            themeButtonHover: { ...buttonStyles.secondaryColorHover }
        },
        tertiaryColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.tertiaryColor },
            themeTextStyle: { ...buttonStyles.tertiaryColorText },
            themeButtonHover: { ...buttonStyles.tertiaryColorHover }
        },
        tertiaryColorDark: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.tertiaryColorDark },
            themeTextStyle: { ...buttonStyles.tertiaryColorDarkText },
            themeButtonHover: { ...buttonStyles.tertiaryColorDarkHover }
        },
        whiteColor: {
            themeIconColor: '#666',
            themeButtonStyle: { ...buttonStyles.whiteColor },
            themeTextStyle: { ...buttonStyles.whiteColorText }
        },
        grayColor: {
            themeIconColor: '#666',
            themeButtonStyle: { ...buttonStyles.grayColor },
            themeTextStyle: { ...buttonStyles.grayColorText },
            themeButtonHover: { ...buttonStyles.grayColorHover }
        },
        dangerColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.dangerColor },
            themeTextStyle: { ...buttonStyles.dangerColorText },
            themeButtonHover: { ...buttonStyles.dangerColorHover }
        },
        mainColorSubtle: {
            themeIconColor: MAIN_COLOR,
            themeButtonStyle: { ...buttonStyles.mainColorSubtle },
            themeTextStyle: { ...buttonStyles.mainColorSubtleText },
            themeButtonHover: { ...buttonStyles.mainColorSubtleHover }
        },
        secondaryColorSubtle: {
            themeIconColor: SECONDARY_COLOR,
            themeButtonStyle: { ...buttonStyles.secondaryColorSubtle },
            themeTextStyle: { ...buttonStyles.secondaryColorSubtleText },
            themeButtonHover: { ...buttonStyles.secondaryColorSubtleHover }
        },
        twitterColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.twitterColor },
            themeTextStyle: { ...buttonStyles.twitterColorText },
            themeButtonHover: { ...buttonStyles.twitterColorHover },
        },
        facebookColor: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.facebookColor },
            themeTextStyle: { ...buttonStyles.facebookColorText },
            themeButtonHover: { ...buttonStyles.facebookColorHover },
        },
        mainColorBorder: {
            themeIconColor: MAIN_COLOR,
            themeButtonStyle: { ...buttonStyles.mainColorBorder },
            themeTextStyle: { ...buttonStyles.mainColorBorderText },
            themeButtonHover: { ...buttonStyles.mainColorBorderHover },
            themeTextHover: { ...buttonStyles.mainColorBorderTextHover },
            themeIconColorHover: '#fff'
        },
        secondaryColorBorder: {
            themeIconColor: SECONDARY_COLOR,
            themeButtonStyle: { ...buttonStyles.secondaryColorBorder },
            themeTextStyle: { ...buttonStyles.secondaryColorBorderText },
            themeButtonHover: { ...buttonStyles.secondaryColorBorderHover },
            themeTextHover: { ...buttonStyles.secondaryColorBorderTextHover },
            themeIconColorHover: '#fff'
        },
        tertiaryColorBorder: {
            themeIconColor: TERTIARY_COLOR,
            themeButtonStyle: { ...buttonStyles.tertiaryColorBorder },
            themeTextStyle: { ...buttonStyles.tertiaryColorBorderText },
            themeButtonHover: { ...buttonStyles.tertiaryColorBorderHover },
            themeTextHover: { ...buttonStyles.tertiaryColorBorderTextHover },
            themeIconColorHover: '#fff'
        },
        grayColorBorder: {
            themeIconColor: '#666',
            themeButtonStyle: { ...buttonStyles.grayColorBorder },
            themeTextStyle: { ...buttonStyles.grayColorBorderText },
            themeButtonHover: { ...buttonStyles.grayColorBorderHover },
            themeTextHover: { ...buttonStyles.grayColorBorderTextHover },
            themeIconColorHover: '#fff'
        },
        dangerColorBorder: {
            themeIconColor: DANGER_COLOR,
            themeButtonStyle: { ...buttonStyles.dangerColorBorder },
            themeTextStyle: { ...buttonStyles.dangerColorBorderText }
        },
        whiteColorBorder: {
            themeIconColor: '#fff',
            themeButtonStyle: { ...buttonStyles.whiteColorBorder },
            themeTextStyle: { ...buttonStyles.whiteColorBorderText }
        },
        ghost: {
            themeIconColor: '#666',
            themeButtonStyle: { ...buttonStyles.ghostColor },
            themeTextStyle: { ...buttonStyles.ghostColorText }
        }
    }
}

function getButtonsStyles() {
    let mainColor = Color(MAIN_COLOR)
    let mainColorDark = Color(MAIN_COLOR_DARK)
    let secondaryColor = Color(SECONDARY_COLOR)
    let tertiaryColor = Color(TERTIARY_COLOR)
    let dangerColor = Color(DANGER_COLOR)
    let twitterColor = Color(TWITTER_COLOR)
    let facebookColor = Color(FACEBOOK_COLOR)
    return {
        mainColorSubtleText: {
            color: MAIN_COLOR
        },
        mainColorSubtle: {
            backgroundColor: 'rgba(238, 102, 102, 0.07)',
            borderColor: 'rgba(238, 102, 102, 0.3)',
            borderWidth: 2
        },
        mainColorSubtleHover: {
            backgroundColor: '#fff',
        },
        secondaryColorSubtleText: {
            color: SECONDARY_COLOR
        },
        secondaryColorSubtle: {
            backgroundColor: 'rgba(43, 69, 112, 0.09)',
            borderColor: 'rgba(43, 69, 112, 0.3)',
            borderWidth: 2
        },
        secondaryColorSubtleHover: {
            backgroundColor: '#fff',
        },
        mainColorText: {
            color: '#fff'
        },
        mainColor: {
            backgroundColor: MAIN_COLOR,
        },
        mainColorHover: {
            backgroundColor: mainColor.darken(0.08).hex(),
        },
        mainColorDarkerText: {
            color: '#fff'
        },
        mainColorDarker: {
            backgroundColor: mainColorDark.darken(0.2).hex()
        },
        mainColorDarkerHover: {
            backgroundColor: mainColorDark.darken(0.3).hex()
        },
        secondaryColorText: {
            color: '#fff'
        },
        secondaryColor: {
            backgroundColor: SECONDARY_COLOR
        },
        secondaryColorHover: {
            backgroundColor: SECONDARY_COLOR_DARK
        },
        tertiaryColorText: {
            color: '#fff'
        },
        tertiaryColor: {
            backgroundColor: TERTIARY_COLOR
        },
        tertiaryColorHover: {
            backgroundColor: tertiaryColor.darken(0.08).hex()
        },
        tertiaryColorDarkText: {
            color: '#fff'
        },
        tertiaryColorDark: {
            backgroundColor: TERTIARY_COLOR_DARK
        },
        tertiaryColorDarkHover: {
            backgroundColor: TERTIARY_COLOR
        },
        dangerColor: {
            backgroundColor: DANGER_COLOR
        },
        dangerColorText: {
            color: '#fff'
        },
        dangerColorHover: {
            backgroundColor: dangerColor.darken(0.08).hex()
        },
        grayColor: {
            backgroundColor: '#DEE4E9'
        },
        grayColorHover: {
            backgroundColor: '#D1D6DA'
        },
        grayColorText: {
            color: '#7C7F85'
        },
        whiteColor: {
            backgroundColor: '#fff'
        },
        whiteColorText: {
            color: '#666'    
        },
        twitterColor: {
            backgroundColor: twitterColor.hex()
        },
        twitterColorText: {
            color: '#fff'
        },
        twitterColorHover: {
            backgroundColor: twitterColor.darken(0.08).hex()
        },
        facebookColor: {
            backgroundColor: facebookColor.hex()
        },
        facebookColorText: {
            color: '#fff'
        },
        facebookColorHover: {
            backgroundColor: facebookColor.darken(0.08).hex()
        },
        mainColorBorderText: {
            color: MAIN_COLOR
        },
        mainColorBorder: {
            backgroundColor: '#fff',
            borderColor: MAIN_COLOR,
            borderWidth: 2
        },
        mainColorBorderHover: {
            backgroundColor: MAIN_COLOR,
        },
        mainColorBorderTextHover: {
            color: '#fff',
        },
        secondaryColorBorderText: {
            color: SECONDARY_COLOR
        },
        secondaryColorBorder: {
            backgroundColor: '#fff',
            borderColor: SECONDARY_COLOR,
            borderWidth: 2
        },
        secondaryColorBorderHover: {
            backgroundColor: SECONDARY_COLOR,
        },
        secondaryColorBorderTextHover: {
            color: '#fff',
        },
        tertiaryColorBorderText: {
            color: TERTIARY_COLOR
        },
        tertiaryColorBorder: {
            backgroundColor: '#fff',
            borderColor: TERTIARY_COLOR,
            borderWidth: 2
        },
        tertiaryColorBorderHover: {
            backgroundColor: TERTIARY_COLOR,
        },
        tertiaryColorBorderTextHover: {
            color: '#fff',
        },
        dangerColorBorder: {
            backgroundColor: '#fff',
            borderColor: DANGER_COLOR,
            borderWidth: 2
        },
        dangerColorBorderText: {
            color: DANGER_COLOR,
        },
        grayColorBorderText: {
            color: '#888C91'
        },
        grayColorBorder: {
            backgroundColor: 'transparent',
            borderColor: '#B4B7BF',
            borderWidth: 2
        },
        grayColorBorderHover: {
            backgroundColor: '#B4B7BF',
        },
        grayColorBorderTextHover: {
            color: '#fff',
        },
        whiteColorBorderText: {
            color: '#fff'
        },
        whiteColorBorder: {
            backgroundColor: 'transparent',
            borderColor: '#fff',
            borderWidth: 2
        },
        ghostColor: {
            backgroundColor: 'transparent'
        },
        ghostColorText: {
            color: '#666'
        }
    }
}