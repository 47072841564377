import { GET_BOOK_LIKES_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getBookLikesInProgress = createAction('GET_BOOK_LIKES_IN_PROGRESS');
export const getBookLikesSuccess = createAction('GET_BOOK_LIKES_SUCCESS');
export const getBookLikesFailure = createAction('GET_BOOK_LIKES_FAILURE');

export function getBookLikesApi({ bookId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId },
            actions: [getBookLikesInProgress, getBookLikesSuccess, getBookLikesFailure],
            name: 'getBookLikes',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}