import React from 'react';
import AppHeader from '../navigation/AppHeader';
import BooksList from '../components/BooksList';
import { useBooks } from '../hooks';

export default function ActivityBooksScreen({ navigation, route }) {
    const bookIds = route.params?.bookIds;
    const title = route.params?.title ?? '';
    const books = useBooks(bookIds);
    return (
        <>  
            <AppHeader 
                title={ title } 
                navigation={ navigation } />
            <BooksList books={books} navigation={navigation} />
        </>
    )
}