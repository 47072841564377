import { SIGNUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const signupInProgress = createAction('SIGNUP_IN_PROGRESS');
export const signupSuccess = createAction('SIGNUP_SUCCESS');
export const signupFailure = createAction('SIGNUP_FAILURE');

export function signupApi({ email, password, invitedBy, prevSuggestedEmail, prevSuggestionForEmail }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { 
                email, 
                password, 
                invitedBy, 
                prevSuggestedEmail, 
                prevSuggestionForEmail, 
                enableEmailSuggestion: true
            },
            actions: [signupInProgress, signupSuccess, signupFailure],
            name: 'signup',
            dispatch,
            getState
        })
    }
}