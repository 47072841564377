import { createReducer } from '@reduxjs/toolkit'
import { setBookHiddenSuccess } from '../actions/setBookHidden';
import { hasDeletedBookIds, hasBooks, hasBook, matchResetStore, hasBookToRemove } from './matchers';

const initialState = {};

export const books = createReducer(initialState, (builder) => {
    builder
    .addCase(setBookHiddenSuccess, (state, action) => {
        state[action.payload.bookId].hidden = action.payload.hidden;
    })
    .addMatcher(hasBookToRemove, (state, action) => {
        delete state[action.payload.bookId];
    })
    .addMatcher(hasBook, (state, action) => {
        if(action.payload.book.id) {
            state[action.payload.book.id] = action.payload.book;
        }
    })
    .addMatcher(hasBooks, (state, action) => {
        action.payload.books.forEach(book => state[book.id] = book)
    })
    .addMatcher(hasDeletedBookIds, (state, action) => {
        action.payload.deletedBookIds.forEach(bookId => {
            delete state[bookId];
        })
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})