import { createReducer } from '@reduxjs/toolkit';
import { searchSuccess } from '../actions/search';
import { addBookSuccess } from '../actions/addBook';
import { logEventSuccess } from '../actions/logEvent';
import { matchResetStore } from './matchers';
import { 
    modalOpen, 
    modalClose, 
    setModalSize,
    clearUserIdAction,
    resetSearchPattern,
    setWindowDimensions,
    setInitialLoadPath,
    setActiveBottomTab,
    setBookReviewHeight
} from '../actions';

const initialState = {
    initialLoadPath: null,
    navigationEvent: null,
    activeBottomTab: null,
    modal: {
        isOpen: false,
        size: null
    },
    window: {},
    search: {
        pattern: "",
        key: ""
    },
    bookReviewHeights: {},
    lastBookAdded: null
};

export const settings = createReducer(initialState, (builder) => {
    builder
    .addCase(modalOpen, (state) => {
        state.modal.isOpen = true;
    })
    .addCase(modalClose, (state) => {
        state.modal.isOpen = false;
    })
    .addCase(clearUserIdAction, (state) => {
        state.modal.isOpen = false;
    })
    .addCase(resetSearchPattern, (state, action) => {
        state.search = {
            pattern: "",
            key: ""
        }
    })
    .addCase(searchSuccess, (state, action) => {
        state.search = {
            pattern: action.payload.results.pattern,
            key: action.payload.results.key
        }
    })
    .addCase(setModalSize, (state, action) => {
        state.modal.size = action.payload;
    })
    .addCase(setWindowDimensions, (state, action) => {
        state.window = action.payload;
    })
    .addCase(setInitialLoadPath, (state, action) => {
        state.initialLoadPath = action.payload;
    })
    .addCase(setActiveBottomTab, (state, action) => {
        state.activeBottomTab = action.payload;
    })
    .addCase(addBookSuccess, (state, action) => {
        state.lastBookAdded = action.payload.book;
    })
    .addCase(setBookReviewHeight, (state, action) => {
        state.bookReviewHeights[action.payload.id] = action.payload.height;
    })
    .addCase(logEventSuccess, (state, action) => {
        state.navigationEvent = action.payload.event;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})