import React, { useMemo, useCallback, useState } from 'react';
import { getPublicGroupsApi, getPublicGroupsInProgress } from '../../actions/getPublicGroups';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublicGroups } from '../../selectors';
import { useIsLoading, useGetDataOnNavFocus } from '../../hooks';
import GroupsList from '../../components/GroupsList';
import { sortByDate } from '../../utils';

export default function PublicGroupsScreen({ navigation }) {
    const dispatch = useDispatch();
    const publicGroups = useSelector(selectPublicGroups);
    const [refreshing, setRefreshing] = useState(false);
    const isGettingPublicGroups = useIsLoading(getPublicGroupsInProgress);
    const getPublicGroups = (checkCaching=true, showRefreshing=false) => {
        if(!isGettingPublicGroups) {
            showRefreshing && setRefreshing(true);
            dispatch(getPublicGroupsApi({ showLoading: false, checkCaching })).then(() => setRefreshing(false));
        }
    }
    const data = useMemo(() => sortByDate(publicGroups, 'created'), [publicGroups])
    useGetDataOnNavFocus(navigation, getPublicGroups);
    const onRefresh = useCallback(() => getPublicGroups(false, true), []);
    return (
        <GroupsList
            data={data}
            onRefresh={onRefresh}
            refreshing={refreshing}
            navigation={navigation} />
    )
}