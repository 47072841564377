import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { bookLendMenu } from '../utils/menuConfigs';
import { useStartDiscussion } from '../hooks';
import { navigateToBook, navigateToUser } from '../utils';
import { requestReturnBookApi } from '../actions/requestReturnBook';
import AppSettingsContext from '../context/AppSettingsContext';
import Menu from './Menu';

export default function MenuBookLend({ style, TriggerButton, shouldShowContact, navigation, book, lend, user, context, placement='bottom' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const onViewBook = () => { navigateToBook(navigation, { bookId: book.id }); };
    const onViewUser = () => { navigateToUser(navigation, { userId: user.id }) };
    const onContactUser = useStartDiscussion(navigation, user.id);
    const onRequestReturn = () => { dispatch(requestReturnBookApi({ lendId: lend.id, bookId: book.id }))}
    return (
        <Menu
            placement={placement}
            menuOptions={ 
                bookLendMenu({ 
                    dispatch,
                    context,
                    lend,
                    user,
                    book,
                    shouldShowContact,
                    navigation, 
                    actions: {
                        onViewBook,
                        onViewUser,
                        onContactUser,
                        onRequestReturn
                    },
                    t
                }) 
            }
            style={ style }
            TriggerButton={ TriggerButton } />
    )
}