import { REMOVE_ADMIN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const removeAdminInProgress = createAction('REMOVE_ADMIN_IN_PROGRESS');
export const removeAdminSuccess = createAction('REMOVE_ADMIN_SUCCESS');
export const removeAdminFailure = createAction('REMOVE_ADMIN_FAILURE');

export function removeAdminApi({ userId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId, groupId },
            actions: [removeAdminInProgress, removeAdminSuccess, removeAdminFailure],
            name: 'removeAdmin',
            dispatch,
            getState
        })
    }
}