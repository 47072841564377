import Dayjs from 'dayjs';

export const CACHING_WHITE_LIST = [
    'getShelves',
    'getLists',
    'getLends', 
    'getMyGroups',
    'getGroup',
    'getGroupCollection',
    'getBook',
    'getGroupRequests',
    'getGroupInvitations',
    'getBorrowRequests',
    'getFollowUserRequests',
    'getPublicGroups',
    'getUserBookLikes',
    'getBookLikes',
    'getBookReviews',
    'getActivity',
    'getKeywords',
    'getUnreadNotifs'
]

export const CACHING_SUB_KEY_DATA_PATH = {
    getGroup: {
        fetchDataPath: 'groupId',
        payloadPath: 'group.id'
    },
    getGroupCollection: {
        fetchDataPath: 'groupCollectionId',
        payloadPath: 'groupCollection.id'
    },
    getBookLikes: {
        fetchDataPath: 'bookId',
        payloadPath: 'bookId'
    },
    getBookReviews: {
        fetchDataPath: 'bookId',
        payloadPath: 'bookId'
    }
}

const CACHING_DURATION = {
    getUnreadNotifs: 30, // 30 seconds
    getShelves: 60 * 60, // 1 hour
    getLists: 60 * 60, // 1 hour
    getKeywords: 60 * 60 * 24, // 1 day
}

export default function shouldFetch(caching, key, data) {
    const duration = CACHING_DURATION[key] || 180;
    const nowMinusCachingDuration = Dayjs().subtract(duration, 'second');
    if(key == 'getUsers' && caching.users) {
        const userIdsToFetch = [];
        for(const userId of data.userIds) {
            if(!caching.users[userId] || hasCacheDelayExpired(caching.users[userId], nowMinusCachingDuration)) {
                userIdsToFetch.push(userId);
            }
        }
        return [!!userIdsToFetch.length, { userIds: userIdsToFetch }];
    } else if(key == 'getUser' && caching.usersFullProfile) {
        if(!caching.usersFullProfile[data.userId] || hasCacheDelayExpired(caching.usersFullProfile[data.userId], nowMinusCachingDuration)) {
            return [true, data];
        } else {
            return [false, data];
        }
    } else if(key == 'getShelf' && caching.shelves) {
        if(!caching.shelves[data.shelfId] || hasCacheDelayExpired(caching.shelves[data.shelfId], nowMinusCachingDuration)) {
            return [true, data];
        } else {
            return [false, data];
        }
    } else if(key == 'getList' && caching.lists) {
        if(!caching.lists[data.listId] || hasCacheDelayExpired(caching.lists[data.listId], nowMinusCachingDuration)) {
            return [true, data];
        } else {
            return [false, data];
        }
    } else if(key == 'getBook' && caching.books) {
        if(!caching.books[data.bookId] || hasCacheDelayExpired(caching.books[data.bookId], nowMinusCachingDuration)) {
            return [true, data];
        } else {        
            return [false, data];
        }
    // TO DO : do the same check for getBooks as getUsers
    } else if(CACHING_WHITE_LIST.includes(key) && caching && caching[key]) {
        const lastFetchedTimestamp = (() => {
            const subKeyDataPath = CACHING_SUB_KEY_DATA_PATH[key];
            if(subKeyDataPath?.fetchDataPath) {
                const subKeyDataPathParts = subKeyDataPath.fetchDataPath.split('.');
                let subKey = null;
                for(let subKeyDataPathPart of subKeyDataPathParts) {
                    subKey = subKey ? subKey[subKeyDataPathPart] : data[subKeyDataPathPart];
                }
                if(subKey) return caching[key][subKey];
            }
            if(!subKeyDataPath && caching[key]) return caching[key];
            return;
        })()
        if(lastFetchedTimestamp && !hasCacheDelayExpired(lastFetchedTimestamp, nowMinusCachingDuration)) return [false, data];
    }
    return [true, data];
}

function hasCacheDelayExpired(lastFetchedTimestamp, nowMinusCachingDuration) {
    return nowMinusCachingDuration.isAfter(Dayjs(lastFetchedTimestamp))
}