import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { SECONDARY_COLOR } from '../constants';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import Location from './Location';
import Avatar from './Avatar';
import Cover from './Cover';
import TitleLine from './TitleLine';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'alerts.sendBorrowRequest';

export default function CnfirmBorrowBook({ book, user}) {
    const {t} = useContext(AppSettingsContext);
    return (
        <View style={{ flexDirection: 'column', alignItems: 'center'}}>
            <MontSBold text={ t(`${tBase}.title`) } style={styles.title}/>
            <View style={{flexDirection: 'column', alignSelf: 'stretch', paddingHorizontal: 25, alignItems: 'flex-start'}}>
                <View style={{ alignItems: 'center', backgroundColor: 'rgba(238, 102, 102, 0.07)', width: '100%', padding: 10, borderRadius: 8, flexDirection: 'row' }}>
                    <Cover 
                        cover={book.cover} 
                        blurHash={book.blurHash} 
                        coverHash={book.coverHash}
                        width={50} />
                    <View style={{paddingLeft: 15, flex: 1}}>
                        <MontSBold text={book.title} numberOfLines={2} style={styles.bookTitle} />
                        <InterReg text={book.author} numberOfLines={1} style={styles.author}/>
                    </View>
                </View>
                <TitleLine style={{ marginHorizontal: 15, marginTop: 15}} titleStyle={{ color: '#666' }} title={ t(`${tBase}.from`) } />
                <View style={{ alignItems: 'center', backgroundColor: 'rgba(43, 69, 112, 0.09)', width: '100%', padding: 10, paddingVertical: 13, borderRadius: 8, flexDirection: 'row' }}>
                    <Avatar avatar={user.avatar} blurHash={user.blurHash} avatarSize={55} />
                    <View style={{paddingLeft: 15, flex: 1}}>
                        <MontSBold text={user.nickname} numberOfLines={1} style={styles.nickname} />
                        <Location location={user.location} />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center', 
        justifyContent: 'center', 
        top: 0, 
        left: 0, 
        right: 0, 
        position: 'absolute', 
        overflow: 'hidden',
    },
    backdrop: {
        top: 0, 
        left: 0, 
        right: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.3)'
    },
    alert: {
        backgroundColor: '#fff', 
        borderRadius: 8, 
        width: '80%', 
        maxWidth: 400, 
        paddingVertical: 17, 
        alignSelf: 'center'
    },
    title: { 
        color: SECONDARY_COLOR,
        textAlign: 'center', 
        fontSize: 16, 
        paddingHorizontal: 17,
        marginBottom: 18 
    },
    bookTitle: {
        color: 'rgba(0,0,0,0.9)',
        fontSize: 15,
        paddingBottom: 5
    },
    author: {
        fontSize: 14,
        color: 'rgba(0,0,0,0.6)',
    },  
    nickname: { 
        color: 'rgba(0,0,0,0.9)',
        fontSize: 15,
        paddingBottom: 5
    },
    buttons: { 
        flexDirection: 'row', 
        paddingHorizontal: 10, 
        paddingTop: 20
    }
})