import React, { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import { FlatList, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupRequestsApi, getGroupRequestsInProgress } from '../../actions/getGroupRequests';
import { getGroupInvitationsApi, getGroupInvitationsSuccess } from '../../actions/getGroupInvitations';
import { updateUnreadNotifsApi } from '../../actions/updateUnreadNotifs';
import { selectGroupInvitationsToMe, selectAllGroupRequests } from '../../selectors';
import { useIsLoading, useFlatListPadding, useGetLendItemLayout, useUnreadNotifs } from '../../hooks';
import { sortByDate, keyExtractor, getInitialScreenWidth, handleUnreadNotifsDone } from '../../utils';
import GroupInvitationItem from '../../components/GroupInvitationItem';
import GroupRequestItem from '../../components/GroupRequestItem';
import Empty from '../../components/Empty';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.groupRequests';

const ITEM_X_SPACING = 20;
const ITEM_Y_SPACING = 15;

export default function GroupRequestsScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [refreshing, setRefreshing] = useState(false);
    const [width, setWidth] = useState(getInitialScreenWidth(navigation));
    const isGettingGroupRequests = useIsLoading(getGroupRequestsInProgress);
    const isGettingGroupInvitations = useIsLoading(getGroupInvitationsSuccess);
    const groupInvitations = useSelector(selectGroupInvitationsToMe);
    const groupRequests = useSelector(selectAllGroupRequests);
    const { unreadGroupRequests, unreadGroupInvitations } = useUnreadNotifs();
    const renderEmpty = () => (
        <Empty 
            title={t(`${tBase}.empty.title`)} 
            body={t(`${tBase}.empty.body`)} 
            img={ require('../../assets/empty.png') } />      
    )
    const renderSeparator = () => (
        <View style={{ marginTop: ITEM_Y_SPACING }} />
    )
    const renderItem = ({item, index}) => {
        const props = { itemWidth, index, navigation, numColumns: columns, marginHorizontal: ITEM_X_SPACING/2 };
        if(item.toId) return <GroupInvitationItem {...props} groupInvitation={item} />;
        return  <GroupRequestItem {...props} groupRequest={item} />;
    }
    const onLayout = ({ nativeEvent: { layout } }) => {
        if(layout.width && layout.width != width) setWidth(layout.width);
    }
    const getData = async (checkCachingInvitations=true, checkCachingRequests=true, showRefreshing=false, callback=() => {}) => {
        const calls = [];
        showRefreshing && setRefreshing(true);
        if(!isGettingGroupRequests) calls.push(dispatch(getGroupRequestsApi({ showLoading: false, checkCaching: checkCachingRequests })));
        if(!isGettingGroupInvitations) calls.push(dispatch(getGroupInvitationsApi({ showLoading: false, checkCaching: checkCachingInvitations })));
        await Promise.all(calls).then(() => {
            setRefreshing(false);
            callback();
        });
    }
    const data = useMemo(() => sortByDate(groupInvitations.concat(groupRequests), 'created'), [groupInvitations, groupRequests])
    const {paddingBottom, paddingTop, paddingHorizontal} = useFlatListPadding();
    const {columns, itemWidth} = useGetLendItemLayout(width, paddingHorizontal, ITEM_X_SPACING);

    const onRefresh = useCallback(() => getData(false, false, true), []); 

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', handleOnFocus);
        return unsubscribe;
    }, [unreadGroupRequests, unreadGroupInvitations]);

    const handleOnFocus = () => {
        const shouldCheckCacheRequests = (() => {
            const groupRequestIds = groupRequests.map(({ id }) => id);
            if(unreadGroupRequests.length > groupRequestIds.length) {
                return false;
            }
            if(unreadGroupRequests.length) {
                for(let unreadGroupRequestId of unreadGroupRequests) {
                    if(!groupRequestIds.includes(unreadGroupRequestId)) return false;
                }
            }
            return true;
        })()
        const shouldCheckCacheInvitations = (() => {
            const groupInvitationIds = groupInvitations.map(({ id }) => id);
            if(unreadGroupInvitations.length > groupInvitationIds.length) {
                return false;
            } 
            if(unreadGroupInvitations.length) {
                for(let unreadGroupInvitationId of unreadGroupInvitations) {
                    if(!groupInvitationIds.includes(unreadGroupInvitationId)) return false;
                }
            }
            return true;
        })();
        const updateUnreadNotifs = () => {
            if(unreadGroupRequests.length || unreadGroupInvitations.length) {
                const notifsRead = {};
                if(unreadGroupRequests.length) notifsRead.groupRequests = unreadGroupRequests;
                if(unreadGroupInvitations.length) notifsRead.groupInvitations = unreadGroupInvitations;
                dispatch(updateUnreadNotifsApi({ notifsRead })).then(handleUnreadNotifsDone);
            }
        }
        getData(shouldCheckCacheInvitations, shouldCheckCacheRequests, false, updateUnreadNotifs)
    };
    return (
        <FlatList
            contentContainerStyle={{ flexGrow: 1, paddingBottom, paddingTop, paddingHorizontal: columns > 1 ? paddingHorizontal : 0 }}
            data={data}
            key={columns}
            onLayout={onLayout}
            numColumns={columns}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            onRefresh={onRefresh}
            refreshing={refreshing}
            ItemSeparatorComponent={renderSeparator}
            ListEmptyComponent={renderEmpty}
        />
    )
}