import { createReducer } from '@reduxjs/toolkit'
import { matchResetStore } from './matchers';
import { addBookSuccess } from '../actions/addBook';
import Dayjs from 'dayjs';

const initialState = [];

export const addedBooksHistory = createReducer(initialState, (builder) => {
    builder
    .addCase(addBookSuccess, (state, action) => {
        let item = {
            bookId: action.payload.book.id,
            addMethod: action.payload.addMethod,
            addDatetime: Dayjs().format(),
        }
        state.push(item);
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})