import React, { memo, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { returnBookApi } from '../actions/returnBook';
import { cancelBorrowRequestApi } from '../actions/cancelBorrowRequest';
import { refuseBorrowRequestApi } from '../actions/refuseBorrowRequest';
import { acceptBorrowRequestApi } from '../actions/acceptBorrowRequest';
import { finaliseBorrowRequestApi } from '../actions/finaliseBorrowRequest';
import { getOtherIdFromLend, getOtherIdFromBorrowRequest } from '../utils';
import { useStartDiscussion, useUser } from '../hooks';
import Button from './Button';
import MenuBookLend from './MenuBookLend';
import AppSettingsContext from '../context/AppSettingsContext';
import { 
    confirmReturnBook, 
    confirmReturnBookByBorrower,
    confirmLentTo, 
    confirmBorrowedFrom, 
    confirmRefuseBorrowRequest, 
    confirmAcceptBorrowRequest, 
    confirmCancelBorrowRequest 
} from '../utils/alerts';

function BookLendActions({ context, lend, borrowRequest, book, navigation, shouldShowContactButton=true }) {
    const {t} = useContext(AppSettingsContext);
    const screen = (() => {
        if(context.indexOf('borrowRequest') > -1) return 'borrowRequest';
        return context; 
    })()
    const tBase = `screens.${screen}`;
    const otherId = lend ? getOtherIdFromLend(lend, context) : getOtherIdFromBorrowRequest(borrowRequest, context);
    const user = useUser(otherId);
    const dispatch = useDispatch();
    const handleReturnBook          = () => confirmReturnBook(returnBook, dispatch);
    const handleReturnBookBorrower  = () => confirmReturnBookByBorrower(returnBook, dispatch);
    const handleCancelRequest       = () => confirmCancelBorrowRequest(cancelBorrowRequest, dispatch);
    const handleAcceptRequest       = () => confirmAcceptBorrowRequest(acceptBorrowRequest, dispatch);
    const handleRefuseRequest       = () => confirmRefuseBorrowRequest(refuseBorrowRequest, dispatch);
    const handleFinaliseLend        = () => {
        context == 'borrowRequestToMe' ?
        confirmLentTo(finaliseBorrowRequest, dispatch, { username: user.nickname }) :
        confirmBorrowedFrom(finaliseBorrowRequest, dispatch, { username: user.nickname });
    }
    const handleContactUser     = useStartDiscussion(navigation, user?.id);
    const cancelBorrowRequest   = () => { dispatch(cancelBorrowRequestApi({ borrowRequestId: borrowRequest.id })) }
    const acceptBorrowRequest   = () => { dispatch(acceptBorrowRequestApi({ borrowRequestId: borrowRequest.id })) }
    const refuseBorrowRequest   = () => { dispatch(refuseBorrowRequestApi({ borrowRequestId: borrowRequest.id })) }
    const finaliseBorrowRequest = () => { dispatch(finaliseBorrowRequestApi({ borrowRequestId: borrowRequest.id })) }
    const returnBook            = () => { dispatch(returnBookApi({ lendId: lend?.id })) }
    const borrowRequestByMe = context == 'borrowRequestByMe' && !borrowRequest?.accepted;
    const borrowRequestToMe = context == 'borrowRequestToMe' && !borrowRequest?.accepted;
    const showMoreMenu = context != 'lent' && context != 'borrowed';
    const showContactButton = context == 'borrowing' && shouldShowContactButton && lend?.returnIndicatedByBorrowerDatetime != null;
    const showReturnBookByBorrower = context == 'borrowing' && lend?.returnIndicatedByBorrowerDatetime === null;
    const returnIndicatedByBorrower = lend?.returnIndicatedByBorrowerDatetime != null;
    return (
        <View style={styles.container}>
            { context == 'lending' &&
            <Button 
                onPress={handleReturnBook} 
                text={ t(`${tBase}.${returnIndicatedByBorrower ? 'confirmReturn' : 'indicateReturn'}`) } 
                buttonStyle={styles.actionBtn} 
                size='xSmall' />
            }
            { showReturnBookByBorrower &&
            <Button 
                onPress={handleReturnBookBorrower} 
                text={ t(`${tBase}.indicateReturnedBook`) }
                buttonStyle={styles.actionBtn} 
                size='xSmall' />
            }
            { showContactButton && 
            <Button 
                onPress={handleContactUser} 
                text={ t(`${tBase}.contactUser`) }
                buttonStyle={styles.actionBtn} 
                size='xSmall' />
            }
            { borrowRequestByMe &&
            <Button 
                onPress={handleCancelRequest} 
                text={ t(`${tBase}.cancelRequest`) }
                buttonStyle={styles.actionBtn} 
                size='xSmall' />
            }
            { borrowRequestToMe &&
            <>
                <Button 
                    onPress={handleAcceptRequest} 
                    text={ t(`${tBase}.acceptRequest`) }
                    buttonStyle={styles.actionBtn} 
                    size='xSmall' />
                <Button 
                    onPress={handleRefuseRequest} 
                    text={ t(`${tBase}.refuseRequest`) }
                    buttonStyle={styles.actionBtn} 
                    theme='grayColorBorder'
                    size='xSmall' />
            </>
            }
            { borrowRequest?.accepted &&
            <Button 
                onPress={handleFinaliseLend} 
                text={ t(`${tBase}.finaliseLend`) }
                buttonStyle={styles.actionBtn} 
                size='xSmall' />
            }
            { showMoreMenu &&
            <MenuBookLend
                placement='auto'
                navigation={navigation}
                context={context}
                lend={lend}
                book={book}
                user={user}
                shouldShowContact={!showContactButton && shouldShowContactButton}
                style={{ width: 32, height: 32, marginLeft: 'auto', marginTop: 'auto' }}
                TriggerButton={ () => (
                    <Button iconName='ellipsis-horizontal' theme='grayColor' iconSize={ 20 } buttonStyle={{ width: 32, height: 32, marginBottom: 0, paddingHorizontal: 5 }} size='xSmall' />
                )} />
            }
        </View>
    )
}

export default memo(BookLendActions);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', 
        flex: 1, 
        alignItems: 'center', 
        paddingTop: 10
    },
    actionBtn: {
        marginTop: 'auto', 
        marginBottom: 0, 
        paddingHorizontal: 10, 
        marginRight: 10
    }
})