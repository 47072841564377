import { SEND_GROUP_INVITATIONS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const sendGroupInvitationsInProgress = createAction('SEND_GROUP_INVITATIONS_IN_PROGRESS');
export const sendGroupInvitationsSuccess = createAction('SEND_GROUP_INVITATIONS_SUCCESS');
export const sendGroupInvitationsFailure = createAction('SEND_GROUP_INVITATIONS_FAILURE');

export function sendGroupInvitationsApi({ groupId, emails }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId, emails },
            actions: [sendGroupInvitationsInProgress, sendGroupInvitationsSuccess, sendGroupInvitationsFailure],
            name: 'sendGroupInvitations',
            dispatch,
            getState
        })
    }
}