import { GET_SHELF_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getShelfInProgress = createAction('GET_SHELF_IN_PROGRESS');
export const getShelfSuccess = createAction('GET_SHELF_SUCCESS');
export const getShelfFailure = createAction('GET_SHELF_FAILURE');

export function getShelfApi({ shelfId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { shelfId },
            actions: [getShelfInProgress, getShelfSuccess, getShelfFailure],
            name: 'getShelf',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}