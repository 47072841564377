import { createReducer } from '@reduxjs/toolkit'
import { searchSuccess } from '../actions/search';
import { matchResetStore } from './matchers';

const initialState = {};

export const search = createReducer(initialState, (builder) => {
    builder
    .addCase(searchSuccess, (state, action) => {
        const {results} = action.payload;
        state[results.key] = results;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})