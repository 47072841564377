import { GET_GROUP_PRODUCTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getGroupProductsInProgress = createAction('GET_GROUP_PRODUCTS_IN_PROGRESS');
export const getGroupProductsSuccess = createAction('GET_GROUP_PRODUCTS_SUCCESS');
export const getGroupProductsFailure = createAction('GET_GROUP_PRODUCTS_FAILURE');

export function getGroupProductsApi() {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getGroupProductsInProgress, getGroupProductsSuccess, getGroupProductsFailure],
            name: 'getGroupProducts',
            dispatch,
            getState,
            showLoading: false,
            checkCaching: false
        })
    }
}