import { ACCEPT_BORROW_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const acceptBorrowRequestInProgress = createAction('ACCEPT_BORROW_REQUEST_IN_PROGRESS');
export const acceptBorrowRequestSuccess = createAction('ACCEPT_BORROW_REQUEST_SUCCESS');
export const acceptBorrowRequestFailure = createAction('ACCEPT_BORROW_REQUEST_FAILURE');

export function acceptBorrowRequestApi({ borrowRequestId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { borrowRequestId },
            actions: [acceptBorrowRequestInProgress, acceptBorrowRequestSuccess, acceptBorrowRequestFailure],
            name: 'acceptBorrowRequest',
            dispatch,
            getState
        })
    }
}