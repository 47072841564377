import React, { memo, useContext } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Ionicons, MaterialIcons as MIcons } from '@expo/vector-icons';
import { sendGroupRequestApi } from '../actions/sendGroupRequest';
import Location from './Location';
import Button from './Button';
import MenuGroup from './MenuGroup';
import GroupPicture from './GroupPicture';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import { useGroupRequest } from '../hooks';
import { SharedElement } from 'react-navigation-shared-element';
import { MAIN_COLOR, SECONDARY_COLOR, GRAY_LINE_COLOR } from '../constants';
import { confirmJoinGroup } from '../utils/alerts';
import AppSettingsContext from '../context/AppSettingsContext';

function GroupListItem({ group, navigation, width, onPressGroup, marginHorizontal }) {
    const {t} = useContext(AppSettingsContext);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const dispatch = useDispatch();
    const { 
        id: groupId, 
        name, 
        description, 
        location,
        memberIds,
        bookIds,
        adminIds, 
        picture
    } = group;
    const groupRequestByMe = useGroupRequest(group.id);
    const isAdmin = adminIds.includes(loggedUserId);
    const isMember = memberIds.includes(loggedUserId);
    const showRequestJoin = !isMember && !groupRequestByMe;
    const handleJoinGroup = () => { dispatch(sendGroupRequestApi({groupId}))};
    const paddingRight = width > 400 ? 18 : 12;
    return (
        <TouchableOpacity 
            style={[styles.container, { width, minWidth: width, maxWidth: width, marginHorizontal}]}
            onPress={ () => { onPressGroup(group) } }>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ height: 110, width: 110, marginRight: 13 }}>
                    <SharedElement id={ `group.picture.${group.id}` } >
                        <GroupPicture picture={ picture } isAdmin={isAdmin} blurHash={ group.blurHash } isPremium={group?.echelon?.includes('premium')} size={110} style={{marginRight: 13}} />
                    </SharedElement>
                </View>
                <View style={{ flex: 1 }}>
                    <Text numberOfLines={ 2 }>
                        { !group.public && <><MIcons name='lock' size={15} color={SECONDARY_COLOR} />{' '}</> }
                        <MontSBold  style={styles.groupName} text={name} />
                    </Text>
                    <InterReg numberOfLines={ 4 } style={ styles.groupDescription } text={description} />
                </View>
            </View>
            <View style={[ styles.footer, styles.groupStats]}>
                <View style={[styles.groupStatsPart, { paddingRight }]}>
                    <MIcons name='book' size={21} color={ MAIN_COLOR } />
                    <MontSBold style={ styles.statNumber } text={ bookIds.length } />
                </View>
                <View style={[styles.groupStatsPart, { paddingRight }]}>
                    <Ionicons name='md-people' size={23} color={ MAIN_COLOR } />
                    <MontSBold style={ styles.statNumber } text={ memberIds.length } />
                </View>
                <Location 
                    location={ location } 
                    textStyle={{ fontSize: 13, color: '#333', flex: 1, paddingRight: 10 }} 
                    iconSize={20}
                    iconColor={ MAIN_COLOR } 
                    containerStyle={{ flex: 1 }}
                    charLength={ 25 }
                />
                { showRequestJoin ?
                <Button 
                    text={ t('group.join') }
                    size='xSmall'
                    buttonStyle={{ marginBottom: 0, height: 30, paddingHorizontal: 10 }} 
                    onPress={ () => confirmJoinGroup(handleJoinGroup, dispatch, { name }) } /> :
                <MenuGroup 
                    group={group}
                    placement='auto'
                    navigation={navigation}
                    style={{ width: 32, height: 30, marginLeft: 'auto' }}
                    TriggerButton={ () => (
                        <Button 
                            iconSize={ 20 } 
                            size='xSmall'
                            theme='grayColor' 
                            iconName='ellipsis-horizontal' 
                            buttonStyle={{ width: 32, height: 30, marginBottom: 0, paddingHorizontal: 5 }} />
                    )} />
                }
            </View> 
        </TouchableOpacity>
    )
}

export default memo(GroupListItem);

const styles = StyleSheet.create({
    container: {
        borderRadius: 8, 
        borderWidth: StyleSheet.hairlineWidth, 
        borderColor: GRAY_LINE_COLOR, 
        padding: 12, 
        paddingBottom: 8, 
        backgroundColor: '#fafafa', 
    },
    groupName: { 
        color: SECONDARY_COLOR,
        fontSize: 16, 
        marginBottom: 5,
        marginTop: -2 
    },
    groupDescription: {
        color: '#333',
        fontSize: 14,
        lineHeight: 18
    },
    footer: {
        borderTopWidth: StyleSheet.hairlineWidth, 
        borderTopColor: '#ddd', 
        marginTop: 10
    },
    groupStats: {
        flexDirection: 'row',
        paddingTop: 8
    },
    groupStatsPart: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    statNumber: {
        color: '#666',
        fontSize: 13,
        marginLeft: 6
    },
    requestName: { 
        fontFamily: 'rubik-medium',
        fontSize: 14,
    },
})