import React, { memo, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { groupCollectionMenu } from '../utils/menuConfigs';
import { deleteGroupCollectionApi } from '../actions/deleteGroupCollection';
import { openModalOrPushScreen } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';
import Menu from './Menu';

function MenuGroupCollection({ style, groupCollection, navigation, deleteDone, TriggerButton, hasItems=()=>{}, placement='auto' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const { id:groupCollectionId, groupId } = groupCollection;
    const menuOptions = groupCollectionMenu({ 
        name: groupCollection.name,
        dispatch,
        actions: {
            onDelete:            () => { dispatch(deleteGroupCollectionApi({ groupCollectionId, groupId })).then(deleteDone) },
            onUpdate:            () => { openModalOrPushScreen(navigation, 'UpdateGroupCollection', { groupCollectionId }) },
            onAddRemoveBooks:    () => { openModalOrPushScreen(navigation, 'BooksInGroupCollection', { groupCollectionId }) },
        },
        t
    })
    useEffect(() => {
        if(!menuOptions.length) hasItems(false);
    }, [])
    return (
        <Menu
            placement={placement}
            style={style}
            TriggerButton={ TriggerButton }
            menuOptions={menuOptions}
        />
    )
}

export default memo(MenuGroupCollection);