import React, { useMemo, useCallback, useContext, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { getMyGroupsApi, getMyGroupsInProgress } from '../../actions/getMyGroups';
import { useIsLoading, useGetDataOnNavFocus } from '../../hooks';
import { selectMyGroups } from '../../selectors';
import GroupsList from '../../components/GroupsList';
import Empty from '../../components/Empty';
import Button from '../../components/Button';
import { sortAlphabetically, isLargeScreen, isWeb } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';

const tBase = 'screens.myGroups';

export default function MyGroupsScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [refreshing, setRefreshing] = useState(false);
    const isGettingMyGroups = useIsLoading(getMyGroupsInProgress);
    const groups = useSelector(selectMyGroups);
    const openAddGroupModal = () => {
        if(isWeb()) {
            navigation.navigate('Modal', { screen: 'ChoseGroupProduct' })
        } else {
            navigation.navigate('Modal', { screen: 'AddGroup' })
        }
    }
    const renderEmpty = () => (
        <Empty 
            title={t(`${tBase}.empty.title`)} 
            body={t(`${tBase}.empty.body`)}
            primaryActionLabel={t(`${tBase}.empty.createGroup`)}
            primaryActionOnPress={ openAddGroupModal } />      
    )
    const getMyGroups = (checkCaching=true, showRefreshing=false) => {
        if(!isGettingMyGroups) {
            showRefreshing && setRefreshing(true);
            dispatch(getMyGroupsApi({ showLoading: false, checkCaching })).then(() => setRefreshing(false));
        }
    }
    const data = useMemo(() => sortAlphabetically(groups, 'name'), [groups])
    useGetDataOnNavFocus(navigation, getMyGroups);
    const onRefresh = useCallback(() => getMyGroups(false, true), []);
    const renderHeader = useMemo(() => (
        !isLargeScreen() && groups.length ?
        <View style={{ paddingBottom: 25, paddingHorizontal: 15 }}>
            <Button 
                text={ t(`group.add`) } 
                size='small'
                onPress={ openAddGroupModal } 
                buttonStyle={{ paddingHorizontal: 15, alignSelf: 'center', marginBottom: 0, maxWidth: 200, minWidth: 200 }} />
        </View> : 
        null
    ));
    return (
        <GroupsList
            data={data}
            onRefresh={onRefresh}
            refreshing={refreshing}
            renderListHeader={renderHeader}
            renderEmpty={renderEmpty}
            navigation={navigation} />
    )
}