import { createReducer } from '@reduxjs/toolkit'
import { loginOrSignup, matchResetStore } from './matchers';

const initialState = null;

export const loggedUserId = createReducer(initialState, (builder) => {
    builder
    .addMatcher(loginOrSignup, (state, action) => {
        return action.payload.accountId;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})