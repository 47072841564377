import { REFUSE_GROUP_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const refuseGroupRequestInProgress = createAction('REFUSE_GROUP_REQUEST_IN_PROGRESS');
export const refuseGroupRequestSuccess = createAction('REFUSE_GROUP_REQUEST_SUCCESS');
export const refuseGroupRequestFailure = createAction('REFUSE_GROUP_REQUEST_FAILURE');

export function refuseGroupRequestApi({ groupRequestId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupRequestId, groupId },
            actions: [refuseGroupRequestInProgress, refuseGroupRequestSuccess, refuseGroupRequestFailure],
            name: 'refuseGroupRequest',
            dispatch,
            getState
        })
    }
}