import React, { memo } from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import { OFF_BLACK } from '../constants';

const MontserratBold = memo(({ 
        style={}, 
        text, 
        onPress, 
        onTextLayout=() => {}, 
        allowFontScaling=false, 
        numberOfLines=null 
    }) => {
    return (
        <Text 
            style={[styles.text, style]}
            onTextLayout={ onTextLayout }
            {...{allowFontScaling, numberOfLines, onPress }}>
            {text}
        </Text>
    )
})

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Montserrat_700Bold', 
        color: OFF_BLACK,
        ...Platform.select({
            web: {
            userSelect: 'text'
            },
            default: {},
        }),
    }
});


export default MontserratBold;