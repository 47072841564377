import { createReducer } from '@reduxjs/toolkit'
import { setPrivateSuccess } from '../actions/setPrivate';
import { hasShelves, hasShelf, matchResetStore, hasShelfToRemove } from './matchers';
import { getUserSuccess } from '../actions/getUser';
import { removedUnreturned } from './helpers';

const initialState = {};

export const shelves = createReducer(initialState, (builder) => {
    builder
    .addCase(setPrivateSuccess, (state, action) => {
        if(action.payload.isPrivate) {
            for(const [key, shelf] of Object.entries(state)) {
                if(shelf.visibility == 'public') {
                    state[key].visibility = 'followers';
                }
            }
        } else {
            for(const [key, shelf] of Object.entries(state)) {
                if(shelf.visibility == 'followers') {
                    state[key].visibility = 'public';
                }
            }
        }
    })
    .addMatcher(hasShelfToRemove, (state, action) => {
        delete state[action.payload.shelfId]; 
    })
    .addMatcher(hasShelf, (state, action) => {
        state[action.payload.shelf.id] = action.payload.shelf;
    })
    .addMatcher(hasShelves, (state, action) => {
        action.payload.shelves.forEach(shelf => state[shelf.id] = shelf);
        if(action.type == getUserSuccess) {
            removedUnreturned({ action, state, returned: action.payload.shelves });
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})