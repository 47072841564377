export function removedUnreturned({ action, state, returned, matchingKey='ownerId' }) {
    const { loggedUserId, requestedUserId } = action.payload;
    if(requestedUserId !== loggedUserId) {
        const keysToRemove = [];
        for(const [key, value] of Object.entries(state)) {
            if(value[matchingKey] === requestedUserId && returned.findIndex(({ id }) => id == value.id ) == -1) {
                keysToRemove.push(value.id);
            }
        }
        for(let keyToRemove of keysToRemove) {
            delete state[keyToRemove];
        }

    }
}