// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if(typeof window !== 'undefined') {
    window._frameTimestamp = null
}

import React from 'react';
import "setimmediate";
import { Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Provider } from 'react-redux';
import setupBlurhashGL from './utils/setupBlurHash';
import { PersistGate } from 'redux-persist/integration/react';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NotifierWrapper } from 'react-native-notifier';
import { MenuProvider } from 'react-native-popup-menu';
import { store, persistor } from './store';
import * as SplashScreen from 'expo-splash-screen';
import Navigation from './navigation';
import ModalBgClickable from './components/ModalBgClickable';
import Loader from './components/Loader';
import Alert from './components/Alert';
import { useFonts, Montserrat_700Bold } from '@expo-google-fonts/montserrat';
import { Inter_400Regular, Inter_600SemiBold } from '@expo-google-fonts/inter';
import { enableScreens } from 'react-native-screens';
import * as Sentry from 'sentry-expo';
import * as ScreenOrientation from 'expo-screen-orientation';
import AppSettingsContext from './context/AppSettingsContext';
import BooksSearchContext from './context/BooksSearchContext';
import ShelvesSearchContext from './context/ShelvesSearchContext';
import ListsSearchContext from './context/ListsSearchContext';
import { isIOS, getMetrics } from './utils';
import { useAppSettings, useBooksSearchContextValue } from './hooks';
import {
    MaterialCommunityIcons,
    Entypo,
    Feather,
    Ionicons,
    SimpleLineIcons,
    MaterialIcons
} from "@expo/vector-icons";

SplashScreen.preventAutoHideAsync();

enableScreens(false);

Sentry.init({
  dsn: 'https://6a533277134648dd8daf0acf5582241f@o1163309.ingest.sentry.io/6251290',
  enableInExpoDevelopment: false,
  release: getMetrics().revisionId,
  debug: __DEV__,
});

if (Platform.isPad) {
    (async () => await ScreenOrientation.unlockAsync())();
}
export default function App() {
    let [fontsLoaded] = useFonts({
        Montserrat_700Bold,
        Inter_600SemiBold,
        Inter_400Regular,
        IcoMoon: require('./assets/icomoon/icomoon.ttf'),
        ...MaterialCommunityIcons.font,
        ...Entypo.font,
        ...Feather.font,
        ...Ionicons.font,
        ...SimpleLineIcons.font,
        ...MaterialIcons.font
    });
    const setUpBlurHash = isIOS() ? setupBlurhashGL() : true;
    // (async() => await persistor.purge())()
    const appSettingsContext = useAppSettings();
    const booksSearchContextValue = useBooksSearchContextValue();
    const shelvesSearchContextValue = useBooksSearchContextValue();
    const listsSearchContextValue = useBooksSearchContextValue();
    if (!fontsLoaded) {
        return null;
    }
    return (
        <Provider store={ store }>
            <AppSettingsContext.Provider value={appSettingsContext}>
                <PersistGate loading={null} persistor={persistor}>
                    <ActionSheetProvider>
                        <NotifierWrapper>
                            <BooksSearchContext.Provider value={booksSearchContextValue}>
                                <ShelvesSearchContext.Provider value={shelvesSearchContextValue}>
                                    <ListsSearchContext.Provider value={listsSearchContextValue}>
                                        <MenuProvider>
                                            <ModalBgClickable>
                                                <SafeAreaProvider>
                                                    <StatusBar translucent backgroundColor="transparent" />
                                                    <Navigation />
                                                </SafeAreaProvider>
                                            </ModalBgClickable>
                                            <Alert />
                                            <Loader />
                                        </MenuProvider>
                                    </ListsSearchContext.Provider>
                                </ShelvesSearchContext.Provider>
                            </BooksSearchContext.Provider>
                        </NotifierWrapper>
                    </ActionSheetProvider>
                </PersistGate>
            </AppSettingsContext.Provider>
        </Provider>
    );
}