import { useContext } from 'react';
import AppSettingsContext from '../context/AppSettingsContext';
import Empty from './Empty';
import { bookFilterIsEmpty } from '../utils';

export default function NoBookResults({ tBase, pattern, filters, resetSearch }) {
    const { t } = useContext(AppSettingsContext);
    const isFilterEmpty = bookFilterIsEmpty(filters);
    const bodyKey = (() => {
        if(pattern && !isFilterEmpty) {
            return `${tBase}.noResults.patternAndFilterBody`;
        } else if(pattern && isFilterEmpty) {
            return `${tBase}.noResults.patternBody`;
        }
        return `${tBase}.noResults.filterBody`
    })()
    return (
        <Empty 
            title={t(`${tBase}.noResults.title`)}
            body={t(bodyKey, { pattern })}
            primaryActionLabel={ t(`${tBase}.noResults.showAll`)}
            primaryActionOnPress={resetSearch} />
    )
}