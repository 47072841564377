import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import en from '../locales/en.js';
import fr from '../locales/fr.js';

const i18n = new I18n({ en, fr });

i18n.enableFallback = true;
i18n.locale = Localization.locale;

export default i18n;
