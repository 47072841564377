import { UPDATE_USER_KEYWORD_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateUserKeywordInProgress = createAction('UPDATE_USER_KEYWORD_IN_PROGRESS');
export const updateUserKeywordSuccess = createAction('UPDATE_USER_KEYWORD_SUCCESS');
export const updateUserKeywordFailure = createAction('UPDATE_USER_KEYWORD_FAILURE');

export function updateUserKeywordApi({ keyword }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { keyword },
            actions: [updateUserKeywordInProgress, updateUserKeywordSuccess, updateUserKeywordFailure],
            name: 'updateUserKeyword',
            dispatch,
            getState
        })
    }
}