import React, { memo } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { MAIN_COLOR } from '../constants';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import MenuAdd from '../components/MenuAdd';

const SIZE = 40;

function TabAddButton({navigation}) {
    const { width:windowWidth, height:windowHeight } = useWindowDimensions()
    const insets = useSafeAreaInsets()
    const renderAddButton = () => (
        <View 
            style={{ width: SIZE, height: SIZE, backgroundColor: MAIN_COLOR, borderRadius: 8, alignItems: 'center', justifyContent: 'center' }}>
            <View><Feather name='plus' size={32} color='#fff' /></View>
        </View>
    )
    return (
        <View style={{ alignItems: 'center' }}>
            <View style={{ alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: '100%', width: windowWidth/5, paddingBottom: 5+insets.bottom }}>
                <MenuAdd 
                    placement='above'
                    style={{ height: SIZE, width: SIZE }}
                    TriggerButton={renderAddButton} 
                    navigation={navigation} />
            </View> 
        </View>
    )
}

export default memo(TabAddButton)