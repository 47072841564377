import { createReducer } from '@reduxjs/toolkit'
import { matchResetStore } from './matchers';

const initialState = {
    isLoading: false,
    showLoading: true,
    actionStates: {}
}

const ignoreActionTypes = [
    'LOG_EVENT',
    'GET_UNREAD_NOTIFS',
    'SET_EXPO_TOKEN',
    'SET_HAS_SEEN',
    'GET_KEYWORDS',
    'SET_FOUND_IN_SEARCH',
    'SET_MESSAGES_BY_EMAIL',
    'SET_NEWSLETTER',
    'SET_NOTIFS_BY_EMAIL',
    'SET_PRIVATE',
    'SET_READ_MESSAGES',
    'GET_ACCOUNT_CONTACTS_STATUS',
    'GET_GROUP_PRODUCTS',
    'GET_GROUP_SUBSCRIPTION',
    'GET_GROUP_SUBSCRIPTIONS',
    'GET_BOOK_OWNERS',
    'GET_BOOK_LIKES',
    'GET_BOOK_REVIEWS',
    'SET_GROUP_COLLECTIONS_ORDER'
]
function shouldIgnoreType(type) {
    for(let ignoreActionType of ignoreActionTypes) {
        if(type.includes(ignoreActionType)) return true;
    }
    return false;
}
function isInProgress(action) {
    if(shouldIgnoreType(action.type)) return false;
    return action.type.indexOf('IN_PROGRESS') > -1;
}
function isSuccessOrFailure(action) {
    if(shouldIgnoreType(action.type)) return false;
    return action.type.indexOf('_SUCCESS')  > -1 || action.type.indexOf('_FAILURE')  > -1;
} 

export const loading = createReducer(initialState, (builder) => {
    builder
    .addMatcher(isInProgress, (state, action) => {
        state.actionStates[action.type] = true;
        state.showLoading = action?.payload?.showLoading ?? true;
        state.isLoading = true;
    })
    .addMatcher(isSuccessOrFailure, (state, action) => {
        const actionsKey = action.type.replace(/_FAILURE|_SUCCESS/gi, '_IN_PROGRESS' );
        state.actionStates[actionsKey] = false;
        state.isLoading = false;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})