import React from 'react';
import { View } from 'react-native';
export const Profile = React.lazy(() => import('../screens/ProfileScreen'));
export const UpdateProfile = React.lazy(() => import('../screens/UpdateProfileScreen'));
export const Settings = React.lazy(() => import('../screens/SettingsScreen'));
export const Account = React.lazy(() => import('../screens/AccountScreen'));
export const ChangePassword = React.lazy(() => import('../screens/ChangePasswordScreen'));
export const Terms = React.lazy(() => import('../screens/TermsScreen'));
export const AddBook = React.lazy(() => import('../screens/AddBookScreen'));
export const AddBookSearch = React.lazy(() => import('../screens/AddBookSearchScreen'));
export const UpdateBook = React.lazy(() => import('../screens/UpdateBookScreen'));
export const Scan = React.lazy(() => import('../screens/ScanScreen'));
export const AddShelf = React.lazy(() => import('../screens/AddShelfScreen'));
export const UpdateShelf = React.lazy(() => import('../screens/UpdateShelfScreen'));
export const AddList = React.lazy(() => import('../screens/AddListScreen'));
export const UpdateList = React.lazy(() => import('../screens/UpdateListScreen'));
export const TakePhoto = React.lazy(() => import('../screens/TakePhotoScreen'));
export const Lend = React.lazy(() => import('../screens/LendScreen'));
export const BookInLists = React.lazy(() => import('../screens/BookInListsScreen'));
export const FollowRequests = React.lazy(() => import('../screens/FollowRequestsScreen'));
export const Discussions = React.lazy(() => import('../screens/DiscussionsScreen'));
export const Discussion = React.lazy(() => import('../screens/DiscussionScreen'));
export const ShareWeb = React.lazy(() => import('../screens/ShareWebScreen'));
export const Contact = React.lazy(() => import('../screens/ContactScreen'));
export const ChoseGroupProduct = React.lazy(() => import('../screens/ChoseGroupProductScreen'));
export const AddGroup = React.lazy(() => import('../screens/AddGroupScreen'));
export const PrePaymentGroupProduct = React.lazy(() => import('../screens/PrePaymentGroupProductScreen'));
export const UpdateGroup = React.lazy(() => import('../screens/UpdateGroupScreen'));
export const BooksInGroup = React.lazy(() => import('../screens/BooksInGroupScreen'));
export const UpdateShelfShareBooksGroup = React.lazy(() => import('../screens/UpdateShelfShareBooksGroupScreen'));
export const InviteToJoinGroup = React.lazy(() => import('../screens/InviteToJoinGroupScreen'));
export const AddBookReview = React.lazy(() => import('../screens/AddBookReviewScreen'));
export const UpdateBookReview = React.lazy(() => import('../screens/UpdateBookReviewScreen'));
export const Search = React.lazy(() => import('../screens/SearchScreen'));
export const BookProviderResult = React.lazy(() => import('../screens/BookProviderResultScreen'));
export const InviteUsers = React.lazy(() => import('../screens/InviteUsersScreen'));
export const AddGroupCollection = React.lazy(() => import('../screens/AddGroupCollectionScreen'));
export const UpdateGroupCollection = React.lazy(() => import('../screens/UpdateGroupCollectionScreen'));
export const BooksInGroupCollection = React.lazy(() => import('../screens/BooksInGroupCollectionScreen'));
export const GroupCollection = React.lazy(() => import('../screens/GroupCollectionScreen'));
export const GroupShare = React.lazy(() => import('../screens/GroupShareScreen'));
export const Subscriptions = React.lazy(() => import('../screens/SubscriptionsScreen'));
export const GroupProductCheckoutSuccess = React.lazy(() => import('../screens/GroupProductCheckoutSuccessScreen'));
export const GroupProduct = React.lazy(() => import('../screens/GroupProductScreen'));
export const GroupProductPro = React.lazy(() => import('../screens/GroupProductScreen'));
export const PremiumGroupOnly = React.lazy(() => import('../screens/PremiumGroupOnlyScreen'));
export const AddUserKeyword = React.lazy(() => import('../screens/AddUserKeywordScreen'));
export const UpdateUserKeyword = React.lazy(() => import('../screens/UpdateUserKeywordScreen'));
export const AskForFeedback = () => <View />;

import Book from '../screens/BookScreen';
import Shelf from '../screens/ShelfScreen';
import List from '../screens/ListScreen';
import Group from '../screens/GroupScreen';
import User from '../screens/UserScreen';
import Users from '../screens/UsersScreen';
import Followers from '../screens/FollowersScreen';
import Following from '../screens/FollowingScreen';
import GroupMembers from '../screens/GroupMembersScreen';

export {
    Book,
    Shelf,
    List,
    Group,
    User,
    Users,
    Followers,
    Following,
    GroupMembers
}