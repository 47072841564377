import { createReducer } from '@reduxjs/toolkit'
import { 
    hasUsers, 
    hasUser, 
    hasBook, 
    hasBooks, 
    matchResetStore, 
    hasShelf, 
    hasShelves, 
    hasList, 
    hasLists, 
    hasBookToRemove, 
    hasShelfToRemove,
    hasListToRemove,
    hasUserFullProfile,
    hasGroupCollection,
    hasGroupCollections
} from './matchers';

import Dayjs from 'dayjs';
import { CACHING_WHITE_LIST, CACHING_SUB_KEY_DATA_PATH } from '../utils/handleCaching';

const initialState = {};

function successfulAndHasCacheKey(action) {
    return (
        action.type.indexOf('SUCCESS') > -1 && 
        CACHING_WHITE_LIST.includes(action.payload.cacheKey)
    );
}

export const caching = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasUserFullProfile, (state, action) => {
        if(!state.usersFullProfile) state.usersFullProfile = {};
        const userId = action.payload.requestedUserId || action.payload.accountId;
        state.usersFullProfile[userId] = Dayjs().format();
    })
    .addMatcher(hasUser, (state, action) => {
        if(!state.users) state.users = {};
        state.users[action.payload.user.id] = Dayjs().format();
    })
    .addMatcher(hasUsers, (state, action) => {
        if(!state.users) state.users = {};
        action.payload.users.forEach(user => state.users[user.id] = Dayjs().format());
    })
    .addMatcher(hasBook, (state, action) => {
        if(!state.books) state.books = {};
        state.books[action.payload.book.id] = Dayjs().format();
    })
    .addMatcher(hasBooks, (state, action) => {
        if(!state.books) state.books = {};
        action.payload.books.forEach(book => state.books[book.id] = Dayjs().format());
    })
    .addMatcher(hasBookToRemove, (state, action) => {
        if(state.books) delete state.books[action.payload.bookId];
    })
    .addMatcher(hasShelf, (state, action) => {
        if(!state.shelves) state.shelves = {};
        state.shelves[action.payload.shelf.id] = Dayjs().format();
    })
    .addMatcher(hasShelves, (state, action) => {
        if(!state.shelves) state.shelves = {};
        action.payload.shelves.forEach(shelf => state.shelves[shelf.id] = Dayjs().format());
    })
    .addMatcher(hasShelfToRemove, (state, action) => {
        if(state.shelves) delete state.shelves[action.payload.shelfId];
    })
    .addMatcher(hasList, (state, action) => {
        if(!state.lists) state.lists = {};
        state.lists[action.payload.list.id] = Dayjs().format();
    })
    .addMatcher(hasLists, (state, action) => {
        if(!state.lists) state.lists = {};
        action.payload.lists.forEach(list => state.lists[list.id] = Dayjs().format());
    })
    .addMatcher(hasListToRemove, (state, action) => {
        if(state.lists) delete state.lists[action.payload.listId];
    })
    .addMatcher(hasGroupCollection, (state, action) => {
        if(!state.groupCollections) state.groupCollections = {};
        state.groupCollections[action.payload.groupCollection.id] = Dayjs().format();
    })
    .addMatcher(hasGroupCollections, (state, action) => {
        if(!state.groupCollections) state.groupCollections = {};
        action.payload.groupCollections.forEach(groupCollection => state.groupCollections[groupCollection.id] = Dayjs().format());
    })
    .addMatcher(successfulAndHasCacheKey, (state, action) => {
        const { cacheKey } = action.payload;
        if(CACHING_SUB_KEY_DATA_PATH[cacheKey] && CACHING_SUB_KEY_DATA_PATH[cacheKey].payloadPath) {
            const subKeyDataPathParts = CACHING_SUB_KEY_DATA_PATH[cacheKey].payloadPath.split('.');
            let subKey;
            for(let subKeyDataPathPart of subKeyDataPathParts) {
                subKey = subKey ? subKey[subKeyDataPathPart] : action.payload[subKeyDataPathPart];
            }
            if(subKey) {
                if(state[cacheKey]) {
                    state[cacheKey][subKey] = Dayjs().format();
                 } else {
                    state[cacheKey] = { [subKey]: Dayjs().format() }
                 }
            } else {
                return state;
            }
        } else {
            state[cacheKey] = Dayjs().format();
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})