import React, { useEffect } from 'react';
import AppHeader from '../navigation/AppHeader';
import UsersList from '../components/UsersList';
import { isInModal } from '../utils';

export default function UsersScreen({ navigation, route }) {
    const { userIds, title, context='list' } = route.params;
    const inModal = isInModal(navigation);
    useEffect(() => {
        if(inModal) navigation.setOptions({ title })
    })
    return (
        <>
            { !inModal &&
            <AppHeader 
                title={title} 
                navigation={ navigation } />
            }
            <UsersList 
                navigation={navigation}
                context={context}
                route={route}
                userIds={userIds} />
            </>
    )
}