import React, { useContext, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import BorrowingScreen from '../screens/Lends/BorrowingScreen';
import LendingScreen from '../screens/Lends/LendingScreen';
import BookRequestsScreen from '../screens/Lends/BookRequestsScreen';
import Badge from '../components/Badge';
import MainHeader from './MainHeader';
import { getTabBarOptions, isLargeScreen, isIOS } from '../utils';
import { TAB_HEIGHT } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';
import { useUnreadNotifs } from '../hooks';

const LendsStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();
const Tab = createMaterialTopTabNavigator();

export default function LendsTabsStack() {
    const {t} = useContext(AppSettingsContext);
    const insets = useSafeAreaInsets()
    const paddingBottom = (() => {
        if(isLargeScreen()) return 0;
        return TAB_HEIGHT + insets.bottom;
    })()
    return (
        <LendsStack.Navigator 
            screenOptions={{ 
                header: ({navigation}) => (
                    <MainHeader title={t('tabs.lends.header.title')} navigation={navigation} />
                ),
                cardStyle: { flex: 1, paddingBottom }
            }}>
            <LendsStack.Screen
                name="LendsTabs"
                component={LendsTabs} />
        </LendsStack.Navigator>
    )
} 

function LendsTabs() {
    const {t} = useContext(AppSettingsContext);
    const { unreadBorrowingTabCount, unreadLendingTabCount, unreadBorrowRequestsTabCount } = useUnreadNotifs();
    const renderBorrowingBadge = useCallback(() => {
        if(unreadBorrowingTabCount) return <Badge style={styles.badge} count={unreadBorrowingTabCount} /> 
        return null;   
    }, [unreadBorrowingTabCount]);
    const renderLendingBadge = useCallback(() => {
        if(unreadLendingTabCount) return <Badge style={styles.badge} count={unreadLendingTabCount} /> 
        return null;   
    }, [unreadLendingTabCount]);
    const renderBookRequestsBadge = useCallback(() => {
        if(unreadBorrowRequestsTabCount) return <Badge style={styles.badge} count={unreadBorrowRequestsTabCount} /> 
        return null;   
    }, [unreadBorrowRequestsTabCount]);
    return (
        <Tab.Navigator 
            screenOptions={{...getTabBarOptions()}}>
            <Tab.Screen 
                name="Borrowing" 
                component={BorrowingScreen}
                options={{
                    tabBarBadge: renderBorrowingBadge,
                    tabBarLabel: t('tabs.lends.tabs.borrowing.title')
                }} />
            <Tab.Screen 
                name="Lending" 
                component={LendingScreen}
                options={{
                    tabBarBadge: renderLendingBadge,
                    tabBarLabel: t('tabs.lends.tabs.lending.title')
                }} />
            <Tab.Screen 
                name="BookRequests" 
                component={BookRequestsScreen}
                options={{
                    tabBarBadge: renderBookRequestsBadge,
                    tabBarLabel: t('tabs.lends.tabs.bookRequests.title')
                }} />
        </Tab.Navigator>
    );
}

const styles = StyleSheet.create({
    badge: {
        position: 'relative',
        top: 15,
        marginRight: 5
    }
})