import { createReducer } from '@reduxjs/toolkit'
import { hasFollowRequests, hasFollowRequest, removeFollowRequest, matchResetStore } from './matchers';

const initialState = [];

export const followRequests = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasFollowRequest, (state, action) => {
        const requestIndex = state.findIndex(request => request.id == action.payload.followRequest.id);
        if(requestIndex > -1) {
            state[requestIndex] = action.payload.followRequest;
        } else {
            state.push(action.payload.followRequest)
        }
    })
    .addMatcher(removeFollowRequest, (state, action) => {
        return state.filter(followRequest => followRequest.id != action.payload.followRequestId);
    })
    .addMatcher(hasFollowRequests, (_, action) => {
        return action.payload.followRequests;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})