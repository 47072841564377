import React, { useMemo, useState, useContext, useCallback } from 'react';
import { View } from 'react-native';
import UsersList from '../../components/UsersList';
import HorizontalFilters from '../../components/HorizontalFilters';
import Empty from '../../components/Empty';
import Button from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux'
import { getUserApi } from '../../actions/getUser';
import { selectAccount } from '../../selectors';
import { uniq } from '../../utils';
import AppSettingsContext from '../../context/AppSettingsContext';
import { useGetDataOnNavFocus } from '../../hooks';

const tBase = 'screens.community';

const filters = [
    { key: 'all', label: 'filter.all' },
    { key: 'following', label: 'filter.following' },
    { key: 'followers', label: 'filter.followers' }
]

export default function CommunityScreen(props) {
    const account = useSelector(selectAccount);
    if(!account) return null;
    return <Screen account={account} {...props} />
}

function Screen({ account, navigation, route }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('all');
    const userIds = uniq([...account.followerIds, ...account.followingIds]);
    const getUser = () => { dispatch(getUserApi({ userId: account.id, showLoading: false })); }
    useGetDataOnNavFocus(navigation, getUser);
    const inviteUsers = () => {
        navigation.navigate('Modal', { screen: 'InviteUsers' })
    }
    const filteredUserIds = useMemo(() => {
        if(filter == 'following') return userIds.filter(userId => account.followingIds.includes(userId));
        if(filter == 'followers') return userIds.filter(userId => account.followerIds.includes(userId));
        return userIds;
    }, [userIds, filter]);
    const emptyBody = (() => {
        let tKey = `${tBase}.empty.`;
        tKey += !userIds.length ? 'body' : '';
        tKey += !account.followingIds.length && account.followerIds.length ? 'bodyNoFollowing' : ''; 
        tKey += account.followingIds.length && !account.followerIds.length ? 'bodyNoFollowers' : ''; 
        return t(tKey);
    })()
    const renderEmpty = () => (
        <Empty
            title={ t(`${tBase}.empty.title`) }
            body={ emptyBody } 
            img={ require('../../assets/noUsersSearch.png') }
            primaryActionLabel={ t(`${tBase}.empty.invite`)}
            primaryActionOnPress={ inviteUsers } />
    )
    const showHeader = userIds.length;
    const renderListHeader = useCallback(() => (
        <View style={{ flexDirection: 'row', paddingVertical: 15, paddingRight: 15, justifyContent: 'center', backgroundColor: '#fff' }}>
            <HorizontalFilters 
                tBase={tBase}
                style={{ flex: 1, marinRight: 15 }} 
                filters={filters} 
                selected={filter} 
                onSelect={setFilter} />
            <Button 
                text={ t(`${tBase}.inviteButton`) } 
                size='xSmall'
                onPress={ inviteUsers } 
                buttonStyle={{ marginLeft: 'auto', height: 34, paddingHorizontal: 15, marginBottom: 0 }} />
        </View> 
    ), [filter]);
    return (
        <UsersList 
            navigation={navigation}
            context='list'
            useStickyHeader={true}
            renderEmpty={renderEmpty}
            listHeaderStyle={showHeader ? { paddingBottom: 10 } : {} }
            contentContainerStyle={showHeader ? { paddingTop: 0 } : {}}
            renderListHeader={showHeader ? renderListHeader : undefined}
            route={route}
            userIds={filteredUserIds} />
    )
}