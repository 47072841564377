import { createReducer } from '@reduxjs/toolkit'
import { hasKeywords, matchResetStore } from './matchers';

const initialState = [];

export const keywords = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasKeywords, (_, action) => {
        return action.payload.keywords;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})