import React, { useMemo, useState, useRef } from 'react';
import { useScrollToTop } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { getUsersApi } from '../actions/getUsers';
import UserListItem from '../components/UserListItem';
import { useGetUserItemLayout, useFlatListPadding, useGetDataOnNavFocus } from '../hooks';
import { sortAlphabetically, navigateToUser, keyExtractor, getInitialScreenWidth } from '../utils';
import { useUsers } from '../hooks';

const ITEM_X_SPACING = 20;
const ITEM_Y_SPACING = 15;

export default function UsersList({ navigation, userIds, useStickyHeader=false, contentContainerStyle={}, context, route, renderEmpty=() => null, renderListHeader=() => null, listHeaderStyle={} }) {
    const ref = useRef(null);
    const [width, setWidth] = useState(getInitialScreenWidth(navigation));
    const dispatch = useDispatch();
    const users = useUsers(userIds);
    const handleUserPress = ({id:userId}) => { navigateToUser(navigation, { userId })};
    const renderItem = ({item, index}) => (
        <UserListItem
            route={route}
            user={item}
            onUserPress={handleUserPress}
            numColumns={columns}
            itemWidth={itemWidth}
            marginHorizontal={ITEM_X_SPACING/2}
            navigation={navigation}  
            index={index}
            context={context}
            lastItem={users.length == (index+1)} 
        />
    )
    const renderSeparator = () => (
        <View style={{ marginTop: columns > 1 ? ITEM_Y_SPACING : 0 }} />
    )
    const onLayout = ({ nativeEvent: { layout } }) => {
        if(layout.width && layout.width != width) setWidth(layout.width);
    }
    const getUsers = () => { dispatch(getUsersApi({ userIds })); };
    useGetDataOnNavFocus(navigation, getUsers);
    const sortedUsers = useMemo(() => sortAlphabetically(users, 'nickname'), [users])
    const {paddingBottom, paddingTop, paddingHorizontal} = useFlatListPadding();
    const {columns, itemWidth} = useGetUserItemLayout(width, paddingHorizontal, ITEM_X_SPACING);
    const columnWrapperStyle = columns > 1 ? { paddingHorizontal } : undefined;
    useScrollToTop(ref);
    return (
        <FlatList
            ref={ref}
            data={sortedUsers}
            key={columns}
            onLayout={onLayout}
            numColumns={columns}
            ListEmptyComponent={renderEmpty}
            ListHeaderComponent={renderListHeader}
            ListHeaderComponentStyle={listHeaderStyle}
            ItemSeparatorComponent={renderSeparator}
            columnWrapperStyle={columnWrapperStyle}
            stickyHeaderIndices={useStickyHeader ? [0] : []}
            contentContainerStyle={[
                { flexGrow: 1, paddingBottom, paddingTop },
                contentContainerStyle
            ]}
            keyExtractor={keyExtractor}
            renderItem={renderItem} />
    )
}