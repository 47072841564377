import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { IMAGE_CACHE_FOLDER } from '../constants';
import { useIsMounted } from '../hooks';

const CachedImage = props => {
    const isMounted = useIsMounted();
    const { source: { uri }, cacheKey } = props;
    const fileURI = `${IMAGE_CACHE_FOLDER}${cacheKey}`;
    const [imgUri, setImgUri] = useState(fileURI);

    useEffect(() => {
        loadImage()
    });

    const loadImage = async () => {
        try {
            const metadata = await FileSystem.getInfoAsync(fileURI);
            if (!metadata.exists || metadata?.size === 0) {
                // download to cache
                if(isMounted()) {
                    const response = await FileSystem.downloadAsync(uri, fileURI);
                    if(isMounted() && response.status === 200) {
                        setImgUri(`${fileURI}?`) // deep clone to force re-render
                    }
                    if(response.status !== 200) {
                        await FileSystem.deleteAsync(fileURI, { idempotent: true } );
                    }
                }
            }
        } catch (err) {
            console.log({ err })
        }
    }

    if(!imgUri) return null

    return (
        <Image {...props} source={{ uri: imgUri }} />
    )
}

export default CachedImage