import { FOLLOW_USER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const followUserInProgress = createAction('FOLLOW_USER_IN_PROGRESS');
export const followUserSuccess = createAction('FOLLOW_USER_SUCCESS');
export const followUserFailure = createAction('FOLLOW_USER_FAILURE');

export function followUserApi({ userId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId },
            actions: [followUserInProgress, followUserSuccess, followUserFailure],
            name: 'followUser',
            dispatch,
            getState
        })
    }
}