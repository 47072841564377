import { UNFOLLOW_USER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const unfollowUserInProgress = createAction('UNFOLLOW_USER_IN_PROGRESS');
export const unfollowUserSuccess = createAction('UNFOLLOW_USER_SUCCESS');
export const unfollowUserFailure = createAction('UNFOLLOW_USER_FAILURE');

export function unfollowUserApi({ userId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId },
            actions: [unfollowUserInProgress, unfollowUserSuccess, unfollowUserFailure],
            name: 'unfollowUser',
            dispatch,
            getState
        })
    }
}