import { I18nManager, StyleSheet, View, Platform } from 'react-native';
import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Animated, { withTiming, useSharedValue, useAnimatedStyle, Easing, runOnJS, interpolate } from 'react-native-reanimated';
import Svg, { Path } from 'react-native-svg';

const popoverPadding = 7;
const anchorHeight = 9;
const anchorWidth = 15;
const anchorHyp = Math.sqrt(anchorWidth*anchorHeight + anchorWidth*anchorHeight);
const anchorOffset = (anchorHyp + anchorHeight) / 2 - popoverPadding;

// left/top placement
function axisNegativeSideProperties({ oDim, tPos }) {
  return { position: tPos - oDim };
}

// right/bottom placement
function axisPositiveSideProperties({ tPos, tDim }) {
  // substract also anchor placeholder from the beginning
  return { position: tPos + tDim - anchorHeight };
}

// computes offsets (off screen overlap) of popover when trying to align it to the center
function centeringProperties({ oDim, wDim, tPos, tDim }) {
  const center = Math.round(tPos + (tDim / 2));
  const leftOffset = (oDim / 2) - center;
  const rightOffset = center + (oDim / 2) - wDim;
  return { center, leftOffset, rightOffset };
}

/**
 * Computes position and offset of popover when trying to align it to the triger center.
 * It consideres window boundaries.
 * Returns object with keys:
 *   - position: <Number> Absolute position - top/left,
 *   - offset: <Number> window overlapping size if window boundaries were not considered
 */
function axisCenteredPositionProperties(options) {
  const { oDim, wDim } = options;
  const { center, leftOffset, rightOffset } = centeringProperties(options);
  if (leftOffset > 0 || rightOffset > 0) {
    // right/bottom position is better
    if (leftOffset < rightOffset) {
      return { offset: rightOffset, position: wDim - oDim };
    }
    // left/top position is better
    if (rightOffset < leftOffset) {
      return { offset: -leftOffset, position: 0 };
    }
  }
  // centered position
  return { offset: 0, position: center - oDim / 2 };
}

/* Evaluate centering placement */
function getCenteringPrice(options) {
  const { leftOffset, rightOffset } = centeringProperties(options);
  // TODO: currently shifted popovers have higher price,
  // popover shift could be taken into account with the same price
  return Math.max(0, leftOffset) + Math.max(0, rightOffset);
}

/* Evaluate top placement */
function getTopPrice(hOptions, vOptions) {
  const centerOffset = getCenteringPrice(vOptions)
  const sideOffset =  Math.max(0, hOptions.oDim - hOptions.tPos)
  return centerOffset + sideOffset
}

/* Evaluate bottom placement */
function getBottomPrice(hOptions, vOptions) {
  const centerOffset = getCenteringPrice(vOptions)
  const sideOffset =  Math.max(0, hOptions.tPos + hOptions.tDim + hOptions.oDim - hOptions.wDim)
  return centerOffset + sideOffset
}

/* Evaluate left placement */
function getLeftPrice(hOptions, vOptions) {
  const centerOffset = getCenteringPrice(hOptions)
  const sideOffset =  Math.max(0, vOptions.oDim - vOptions.tPos)
  return centerOffset + sideOffset
}

/* Evaluate right placement */
function getRightPrice(hOptions, vOptions) {
  const centerOffset = getCenteringPrice(hOptions)
  const sideOffset =  Math.max(0, vOptions.tPos + vOptions.tDim + vOptions.oDim - vOptions.wDim)
  return centerOffset + sideOffset
}

function getStartPosKey(isRTL) {
  return isRTL ? 'right' : 'left';
}

function topProperties(hOptions, vOptions, isRTL) {
  const centered = axisCenteredPositionProperties(vOptions);
  const side = axisNegativeSideProperties(hOptions);
  return {
    position: {
      top: side.position,
      [getStartPosKey(isRTL)]: centered.position,
    },
    offset: centered.offset,
    placement: 'top',
  };
}

function bottomProperties(hOptions, vOptions, isRTL) {
  const centered = axisCenteredPositionProperties(vOptions);
  const side = axisPositiveSideProperties(hOptions);
  return {
    position: {
      top: side.position,
      [getStartPosKey(isRTL)]: centered.position,
    },
    offset: centered.offset,
    placement: 'bottom',
  };
}

function rightProperties(hOptions, vOptions, isRTL) {
  const centered = axisCenteredPositionProperties(hOptions);
  const side = axisPositiveSideProperties(vOptions);
  return {
    position: {
      top: centered.position,
      [getStartPosKey(isRTL)]: side.position,
    },
    offset: centered.offset,
    placement: 'right',
  };
}

function leftProperties(hOptions, vOptions, isRTL) {
  const centered = axisCenteredPositionProperties(hOptions);
  const side = axisNegativeSideProperties(vOptions);
  return {
    position: {
      top: centered.position,
      [getStartPosKey(isRTL)]: side.position,
    },
    offset: centered.offset,
    placement: 'left',
  };
}

// maps placement to function which computes correct properties
const propertiesByPlacement = {
  top: topProperties,
  bottom: bottomProperties,
  left: leftProperties,
  right: rightProperties,
};

/**
 * Computes properties needed for drawing popover.
 * Returns object with keys:
 *   - position: <Object> { top: Number, left: Number } - popover absolute position
 *   - placement: <Enum> top|left|top|bottom - position to the trigger
 *   - offset: <Number> value by which must be anchor shifted
 */
export function computeProperties (
    { windowLayout, triggerLayout, optionsLayout },
    placement,
    preferredPlacement,
    isRTL,
) {
  const { x: wX, y: wY, width: wWidth, height: wHeight } = windowLayout;
  const { x: tX, y: tY, height: tHeight, width: tWidth } = triggerLayout;
  const { height: oHeight, width: oWidth } = optionsLayout;
  const hOptions = {
    oDim: oHeight + popoverPadding * 2,
    wDim: wHeight,
    tPos: tY - wY,
    tDim: tHeight,
  };
  const vOptions = {
    oDim: oWidth + popoverPadding * 2,
    wDim: wWidth,
    tPos: tX - wX,
    tDim: tWidth,
  };
  if (placement !== 'auto' && propertiesByPlacement[placement]) {
    return propertiesByPlacement[placement](hOptions, vOptions, isRTL)
  }

  const prices = {
    top: getTopPrice(hOptions, vOptions),
    bottom: getBottomPrice(hOptions, vOptions),
    right: getRightPrice(hOptions, vOptions),
    left: getLeftPrice(hOptions, vOptions),
  };
  const bestPrice = Object.values(prices).sort((a, b) => a - b)[0]
  const bestPlacement = prices[preferredPlacement] === bestPrice
    ? preferredPlacement
    : Object.keys(prices).find(pl => prices[pl] === bestPrice)

  return propertiesByPlacement[bestPlacement](hOptions, vOptions, isRTL)
}

export default class CustomMenu extends React.Component {

    constructor(props) {
      super(props);
    }
    close() {
        this.menu.close()
    }
  
    render() {
      return (
        <Menu ref={ (ref) => this.menu = ref } { ...this.props} />
      );
    }
}

  CustomMenu.propTypes = {
    anchorStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.array,
    ]),
    placement: PropTypes.oneOf(['auto', 'top', 'right', 'bottom', 'left']),
    preferredPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    };

    CustomMenu.defaultProps = {
    preferredPlacement: 'top',
    placement: 'auto',
};


const x = anchorWidth * 0.4;
const y = anchorHeight / 5;

const d = `M0,0 H${anchorWidth} L${anchorWidth - x},${anchorHeight - y} Q${anchorWidth /
    2},${anchorHeight} ${x},${anchorHeight - y} Z`

// `a ${R} ${R} 0 0 ${reverse ? "0" : "1"} ${x} ${y}`;

const Menu = forwardRef(({
        style,
        children,
        layouts,
        anchorStyle,
        preferredPlacement='top',
        placement: userPlacement,
        ...other
    }, ref) => {

    const { position, placement, offset } = computeProperties(
        layouts,
        userPlacement,
        preferredPlacement,
        isRTL,
    );
        
    const isVisible = useSharedValue(0);
    const menuPlacement = useSharedValue(placement);

    useEffect(() => {
        isVisible.value = withTiming(1, { duration: 200, easing: Easing.quad });
    })

    useImperativeHandle(ref, () => ({
        close() {
            return new Promise(resolve => {
                isVisible.value = withTiming(0, { duration: 100, easing: Easing.ease }, () => runOnJS(resolve)());
            });
        }
    }))

    const isRTL = I18nManager.isRTL;
 
    const animation = useAnimatedStyle(() => {
        const start = 30;
        const end = 5;
        let translate;
        switch(menuPlacement.value) {
            case 'top':
                translate = { translateY: interpolate(isVisible.value, [0, 1], [-start, -end]) };
                break;
            case 'bottom':
                translate = { translateY: interpolate(isVisible.value, [0, 1], [start, end]) };
                break;
            case 'left':
                translate = { translateX: interpolate(isVisible.value, [0, 1], [-start, -end]) };
                break;
            case 'right':
                translate = { translateX: interpolate(isVisible.value, [0, 1], [start, end]) };
                break;
        }
        return {
            opacity: interpolate(isVisible.value, [0, 0.5, 1], [0, 0.8, 1]),
            transform: [translate]
        }
    })
    return (
      <Animated.View
        style={[
          styles.animated,
          animation,
          position,
          getContainerStyle({ placement, isRTL }),
        ]}
        pointerEvents="box-none"
      >
        <Svg style={[
            styles.anchor,
            dynamicAnchorStyle({ placement, offset, isRTL }),
            anchorStyle,
          ]} width={ anchorWidth } height={ anchorHeight } viewBox={ `0 0 ${anchorWidth} ${anchorHeight}` }>
            <Path d={d} fill={anchorStyle.fill || '#fff'} />
        </Svg>
        <View {...other} style={[styles.options, style]}>
          {children}
        </View>
      </Animated.View>
    );
})

const getContainerStyle = ({ placement, isRTL }) => ({
  left: {
    flexDirection: isRTL ? 'row' : 'row-reverse',
  },
  right: {
    flexDirection: isRTL ? 'row-reverse' : 'row',
  },
  top: {
    flexDirection: 'column-reverse',
  },
  bottom: {
    flexDirection: 'column',
  },
})[placement]

const dynamicAnchorStyle = ({ offset, placement, isRTL }) => {
  const start = getStartPosKey(isRTL);
  switch (placement) {
    case 'right':
      return {
        top: offset,
        transform: [
          { translateX: anchorOffset-2 },
          { rotate: '90deg' },
        ],
      };
    case 'left':
      return {
        top: offset,
        transform: [
          { translateX: -anchorOffset+2 },
          { rotate: '-90deg' },
        ],
      };
    case 'top':
      return {
        [start]: offset,
        transform: [
          { translateY: -anchorOffset+5 },
          { rotate: '0deg' },
        ],
      };
    case 'bottom':
      return {
        [start]: offset,
        transform: [
          { translateY: anchorOffset-5 },
          { rotate: '180deg' },
        ],
      };
  }
}

export const styles = StyleSheet.create({
  animated: {
    padding: popoverPadding,
    backgroundColor: 'transparent',
    position: 'absolute',
    alignItems: 'center',
  },
  options: {
    borderRadius: 2,
    minWidth: anchorHyp,
    minHeight: anchorHyp,
    backgroundColor: 'white',

    // Shadow only works on iOS.
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowOffset: { width: 3, height: 3 },
    shadowRadius: 4,

    // This will elevate the view on Android, causing shadow to be drawn.
    elevation: 5,
  },
  anchor: {
    position: 'relative',
    width: anchorWidth,
    height: anchorHeight,
    ...Platform.select({
        android: {
          elevation: 5
        },
        ios: {
          shadowColor: 'black',
          shadowOpacity: 0.15,
          shadowOffset: { width: 0, height: 4 },
          shadowRadius: 3
        },
        default: {
          filter: 'drop-shadow( 0 3px 2px rgba(0, 0, 0, .15))'
        }
      })
  },
});