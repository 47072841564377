import React, { memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons as MCIcons } from '@expo/vector-icons';
import MontSBold from './MontserratBold';
import { SECONDARY_COLOR } from '../constants';

const PremiumPill = memo(({ containerStyle={}, textStyle={} }) => {
    return (
        <View style={[styles.container, containerStyle]}>
            <MCIcons style={styles.icon} size={14} name='crown' color='white' />
            <MontSBold style={[styles.text, textStyle]} text='Premium' />
        </View>
    )
})

export default PremiumPill;
    
const styles = StyleSheet.create({
    container: {
        borderRadius: 10, 
        backgroundColor: SECONDARY_COLOR, 
        position: 'absolute',
        flexDirection: 'row',
        paddingHorizontal: 6,
        paddingLeft: 4,
        paddingVertical: 2,
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        marginRight: 2
    },
    text: {
        textTransform: 'uppercase', 
        color: '#fff', 
        fontSize: 10
    }
})