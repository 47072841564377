import { ACCEPT_GROUP_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const acceptGroupRequestInProgress = createAction('ACCEPT_GROUP_REQUEST_IN_PROGRESS');
export const acceptGroupRequestSuccess = createAction('ACCEPT_GROUP_REQUEST_SUCCESS');
export const acceptGroupRequestFailure = createAction('ACCEPT_GROUP_REQUEST_FAILURE');

export function acceptGroupRequestApi({ groupRequestId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupRequestId, groupId },
            actions: [acceptGroupRequestInProgress, acceptGroupRequestSuccess, acceptGroupRequestFailure],
            name: 'acceptGroupRequest',
            dispatch,
            getState
        })
    }
}