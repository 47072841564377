import { SEARCH_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const searchInProgress = createAction('SEARCH_IN_PROGRESS');
export const searchSuccess = createAction('SEARCH_SUCCESS');
export const searchFailure = createAction('SEARCH_FAILURE');

export function searchApi({ pattern }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { pattern },
            actions: [searchInProgress, searchSuccess, searchFailure],
            name: 'search',
            dispatch,
            getState
        })
    }
}