import { DELETE_USER_KEYWORD_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteUserKeywordInProgress = createAction('DELETE_USER_KEYWORD_IN_PROGRESS');
export const deleteUserKeywordSuccess = createAction('DELETE_USER_KEYWORD_SUCCESS');
export const deleteUserKeywordFailure = createAction('DELETE_USER_KEYWORD_FAILURE');

export function deleteUserKeywordApi({ userKeywordId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userKeywordId },
            actions: [deleteUserKeywordInProgress, deleteUserKeywordSuccess, deleteUserKeywordFailure],
            name: 'deleteUserKeyword',
            dispatch,
            getState
        })
    }
}