import { ADD_SHELF_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addShelfInProgress = createAction('ADD_SHELF_IN_PROGRESS');
export const addShelfSuccess = createAction('ADD_SHELF_SUCCESS');
export const addShelfFailure = createAction('ADD_SHELF_FAILURE');

export function addShelfApi({ shelf }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { shelf },
            actions: [addShelfInProgress, addShelfSuccess, addShelfFailure],
            name: 'addShelf',
            dispatch,
            getState
        })
    }
}