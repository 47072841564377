import React from 'react';
import i18n from '../utils/i18n';
import { showAlert } from '../actions';
import ConfirmBorrowBook from '../components/ConfirmBorrowBook';
import PromptLoginOrSignup from '../components/PromptLoginOrSignup';
import { MAIN_COLOR } from '../constants'

export function confirmDeleteAccount(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.deleteAccount.title'), 
        subTitle: i18n.t('alerts.deleteAccount.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmDeleteBook(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.deleteBook.title'), 
        subTitle: i18n.t('alerts.deleteBook.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmDeleteShelf(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.deleteShelf.title'), 
        subTitle: i18n.t('alerts.deleteShelf.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmDeleteList(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.deleteList.title'), 
        subTitle: i18n.t('alerts.deleteList.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmRemoveFromList(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.removeFromList.title'), 
        subTitle: i18n.t('alerts.removeFromList.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.remove'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmAddExistingBook(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.addExistingBook.title'), 
        subTitle: i18n.t('alerts.addExistingBook.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.add'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmReturnBook(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.returnBook.title'), 
        subTitle: i18n.t('alerts.returnBook.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.return'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmReturnBookByBorrower(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.returnBookNyBorrower.title'), 
        subTitle: i18n.t('alerts.returnBookNyBorrower.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.return'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmLentTo(callback, dispatch, { username }) {
    _showAlert({
        title: i18n.t('alerts.bookLentTo.title', { username }), 
        subTitle: i18n.t('alerts.bookLentTo.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.exchanged'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmBorrowedFrom(callback, dispatch, { username }) {
    _showAlert({
        title: i18n.t('alerts.bookBorrowedFrom.title', { username }), 
        subTitle: i18n.t('alerts.bookBorrowedFrom.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.exchanged'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmAcceptBorrowRequest(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.acceptBorrowRequest.title'), 
        subTitle: i18n.t('alerts.acceptBorrowRequest.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.accept'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmRefuseBorrowRequest(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.refuseBorrowRequest.title'), 
        subTitle: i18n.t('alerts.refuseBorrowRequest.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.refuse'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmCancelBorrowRequest(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.cancelBorrowRequest.title'), 
        subTitle: i18n.t('alerts.cancelBorrowRequest.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.cancelRequest'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmSendBorrowRequest(callback, dispatch, { book, user }) {
    _showAlert({
        Content: <ConfirmBorrowBook book={book} user={user} />, 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.request'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmRemoveFromAllLists(callback, dispatch, {title }) {
    _showAlert({
        title: i18n.t('alerts.removeFromAllLists.title'), 
        subTitle: i18n.t('alerts.removeFromAllLists.subTitle', { title }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.remove'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmRequestFollowUser(callback, dispatch, { nickname }) {
    _showAlert({
        title: i18n.t('alerts.requestFollowUser.title'), 
        subTitle: i18n.t('alerts.requestFollowUser.subTitle', { nickname }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.sendRequest'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmAcceptRequestFollowUser(callback, dispatch, { nickname }) {
    _showAlert({
        title: i18n.t('alerts.acceptRequestFollowUser.title'), 
        subTitle: i18n.t('alerts.acceptRequestFollowUser.subTitle', { nickname }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.accept'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmRefuseRequestFollowUser(callback, dispatch, { nickname }) {
    _showAlert({
        title: i18n.t('alerts.refuseRequestFollowUser.title'), 
        subTitle: i18n.t('alerts.refuseRequestFollowUser.subTitle', { nickname }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.refuse'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmDeleteGroup(callback, dispatch, { name }) {
    _showAlert({
        title: i18n.t('alerts.deleteGroup.title'), 
        subTitle: i18n.t('alerts.deleteGroup.subTitle', { name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmLeaveGroup(callback, dispatch, { name }) {
    _showAlert({
        title: i18n.t('alerts.leaveGroup.title'), 
        subTitle: i18n.t('alerts.leaveGroup.subTitle', { name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.leave'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmRemoveMemberFromGroup(callback, dispatch, { nickname }) {
    _showAlert({
        title: i18n.t('alerts.removeMemberFromGroup.title'), 
        subTitle: i18n.t('alerts.removeMemberFromGroup.subTitle', { nickname }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.remove'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmJoinGroup(callback, dispatch, { name }) {
    _showAlert({
        title: i18n.t('alerts.joinGroup.title'), 
        subTitle: i18n.t('alerts.joinGroup.subTitle', { name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.sendRequest'), onPress: callback },
        ],
        dispatch
    })
}

export function confirmAcceptGroupRequest(callback, dispatch, { nickname, name }) {
    _showAlert({
        title: i18n.t('alerts.acceptGroupRequest.title'), 
        subTitle: i18n.t('alerts.acceptGroupRequest.subTitle', { nickname, name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.accept'), onPress: callback },
        ],
        dispatch
    })
}

export function confirmRefuseGroupRequest(callback, dispatch, { nickname, name }) {
    _showAlert({
        title: i18n.t('alerts.refuseGroupRequest.title'), 
        subTitle: i18n.t('alerts.refuseGroupRequest.subTitle', { nickname, name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.refuse'), onPress: callback },
        ],
        dispatch
    })
}

export function confirmDeleteBookReview(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.deleteBookReview.title'), 
        subTitle: i18n.t('alerts.deleteBookReview.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive' },
        ],
        dispatch
    })
}

export function noResultFromScannedIsbn(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.noResultFromScannedIsbn.title'), 
        subTitle: i18n.t('alerts.noResultFromScannedIsbn.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.addManually'), onPress: callback },
        ],
        dispatch
    })
}

export function profileMustBeFilledToRequestBook(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.profileMustBeFilledToRequestBook.title'), 
        subTitle: i18n.t('alerts.profileMustBeFilledToRequestBook.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.fillProfile'), onPress: callback }
        ],
        dispatch
    })
}

export function promptLoginOrSignup(dispatch) {
    _showAlert({
        Content: <PromptLoginOrSignup dispatch={ dispatch } />, 
        actions: [],
        dispatch,
        alertStyle: { backgroundColor: MAIN_COLOR }
    })
}

export function emailSmtpError(callback, dispatch, { email }) {
    _showAlert({
        title: i18n.t('alerts.emailSmtpError.title'), 
        subTitle: i18n.t('alerts.emailSmtpError.subTitle', { email }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.modify'), onPress: callback }
        ],
        dispatch
    })
}

export function confirmDeleteGroupCollection(callback, dispatch, { name }) {
    _showAlert({
        title: i18n.t('alerts.deleteUserKeyword.title'), 
        subTitle: i18n.t('alerts.deleteGroupCollection.subTitle', { name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

export function confirmRequestReturnBook(callback, dispatch, { nickname, bookTitle }) {
    _showAlert({
        title: i18n.t('alerts.requestReturnBook.title'), 
        subTitle: i18n.t('alerts.requestReturnBook.subTitle', { nickname, bookTitle }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.sendRequest'), onPress: callback },
        ],
        dispatch
    })
}

export function askForFeedback(callbackOk, callbackGreat, dispatch) {
    _showAlert({
        title: i18n.t('alerts.askForFeedback.title'), 
        subTitle: i18n.t('alerts.askForFeedback.subTitle'), 
        actions: [
            {text: i18n.t('alerts.askForFeedback.ok'), theme: 'secondaryColor', onPress: callbackOk},
            {text: i18n.t('alerts.askForFeedback.great'), onPress: callbackGreat},
        ],
        dispatch,
        canBeDismissed: false
    })
}

export function allowAccessToContacts(callback, dispatch) {
    _showAlert({
        title: i18n.t('alerts.allowAccessToContacts.title'), 
        subTitle: i18n.t('alerts.allowAccessToContacts.subTitle'), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.modify'), onPress: callback},
        ],
        dispatch
    })
}

export function confirmDeleteUserKeyword(callback, dispatch, { name }) {
    _showAlert({
        title: i18n.t('alerts.deleteUserKeyword.title'), 
        subTitle: i18n.t('alerts.deleteUserKeyword.subTitle', { name }), 
        actions: [
            {text: i18n.t('alerts.actions.cancel'), style: 'cancel'},
            {text: i18n.t('alerts.actions.delete'), onPress: callback, style: 'destructive'},
        ],
        dispatch
    })
}

function _showAlert({title, subTitle, canBeDismissed=true, actions, Content, dispatch, alertStyle={} }) {
    dispatch(showAlert({ title, subTitle, Content, canBeDismissed, actions, alertStyle }))
}