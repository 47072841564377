import React, { memo, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { GRAY_LINE_COLOR, MAIN_COLOR, MAIN_COLOR_SUBTLE } from '../constants';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    interpolateColor,
    withSequence,
    withDelay,
    withTiming,
    cancelAnimation
} from 'react-native-reanimated';

function ItemMultiColumn({ 
        navigation, 
        itemWidth,
        numColumns,
        itemIsUnread=false,
        marginHorizontal, 
        containerStyle={},
        children
    }) {
    const isUnread = useSharedValue(0);
    useEffect(() => {
        if(itemIsUnread) {
            isUnread.value = withSequence(
                withDelay(500, withTiming(1, { duration: 300 })),
                withDelay(500, withTiming(0, { duration: 300 })),
                withDelay(500, withTiming(1, { duration: 300 })),
                withDelay(500, withTiming(0, { duration: 300 })),
                withDelay(500, withTiming(1, { duration: 300 }))
            )
        }
    }, [itemIsUnread]);
    
    useEffect(() => {
        const unsubscribe = navigation.addListener('blur', () => {
            isUnread.value = 0;
        });
        return unsubscribe;
    }, [])

    const computedStyles = useAnimatedStyle(() => {
        const backgroundColor = numColumns > 1 ? '#fdfdfd' : '#fff';
        const backgroundColorHighlight = MAIN_COLOR_SUBTLE;
        const borderColor = GRAY_LINE_COLOR;
        const borderColorHighlight = numColumns > 1 ? MAIN_COLOR : GRAY_LINE_COLOR;
        return {
            width: numColumns > 1 ? itemWidth : '100%',
            maxWidth: numColumns > 1 ? itemWidth : '100%',
            minWidth: numColumns > 1 ? itemWidth : '100%',
            marginHorizontal: numColumns > 1 ? marginHorizontal : 0,
            borderColor: interpolateColor(
                isUnread.value,
                [0, 1],
                [borderColor, borderColorHighlight]
            ),
            backgroundColor: interpolateColor(
                isUnread.value,
                [0, 1],
                [backgroundColor, backgroundColorHighlight]
            )
        }
    }, [numColumns, isUnread])
    return (
        <Animated.View style={[
            styles.container, 
            numColumns > 1 && styles.multiColumn, 
            containerStyle,
            computedStyles]}>
            { children }
        </Animated.View>
    )
}

export default memo(ItemMultiColumn);

const styles = StyleSheet.create({
    container: {
        paddingVertical: 20, 
        paddingHorizontal: 17, 
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderColor: GRAY_LINE_COLOR
    },
    multiColumn: { 
        borderRadius: 8,
        borderWidth: StyleSheet.hairlineWidth, 
        borderColor: GRAY_LINE_COLOR, 
        padding: 12,
        paddingTop: 15, 
        paddingBottom: 12, 
        backgroundColor: '#fafafa'
    }
})