import React from 'react';
import { Platform } from 'react-native';
import { Menu, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import CustomMenu from '../utils/customMenu';
import FilterLibrary from './FilterLibraryWithContext';

export default function ContextMenu({ 
        style,
        filterKey,
        TriggerButton, 
        triggerButtonHitSlop={ top: 5, left: 5, right: 5, bottom: 5 },
        menuWidth=350,
        anchorFill='#fff',
        placement='auto', 
        preferredPlacement='bottom' }) {
    const optionsContainerStyles = { 
        borderRadius: 8, 
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
          })
        
    }
    return (
        <Menu 
            style={ style }
            renderer={ CustomMenu } 
            rendererProps={{
                placement,
                preferredPlacement,
                anchorStyle: { fill: anchorFill, zIndex: 2 } 
            }}>
            <MenuTrigger customStyles={{ 
                    triggerTouchable: { 
                        hitSlop: triggerButtonHitSlop,
                        underlayColor: 'transparent'
                    }
                }}>
                { TriggerButton() }
            </MenuTrigger>
            <MenuOptions customStyles={{ 
                    optionsContainer: { width: menuWidth, height: 500, margin: 0, paddingVertical: 0, ...optionsContainerStyles }, 
                }}>
                <FilterLibrary filterKey={filterKey} height={500} />
            </MenuOptions>
        </Menu>
    )
}