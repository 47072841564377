import React, { useContext } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ShelvesScreen from '../screens/Library/ShelvesScreen';
import ListsScreen from '../screens/Library/ListsScreen';
import BooksScreen from '../screens/Library/BooksScreen';
import FavouritesScreen from '../screens/Library/FavouritesScreen';
import MainHeader from './MainHeader';
import { getTabBarOptions, isLargeScreen, isIOS } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';
import BooksScrollContext from '../context/BooksScrollContext';
import ShelvesScrollContext from '../context/ShelvesScrollContext';
import ListsScrollContext from '../context/ListsScrollContext';
import { TAB_HEIGHT } from '../constants';
import { useBooksScrollContext } from '../hooks';

import { createBooksTabNavigator } from './BooksTabNavigator';

const tBase = 'tabs.library';

const LibraryStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();
const Tab = createMaterialTopTabNavigator();

export default function LibraryTabsStack() {
    const {t} = useContext(AppSettingsContext);
    const insets = useSafeAreaInsets()
    const paddingBottom = (() => {
        if(isLargeScreen()) return 0;
        return TAB_HEIGHT + insets.bottom;
    })()
    return (
        <LibraryStack.Navigator 
            screenOptions={{ 
                header: ({navigation}) => (
                    <MainHeader title={t(`${tBase}.header.title`)} navigation={navigation} />
                ),
                cardStyle: { flex: 1, paddingBottom }
            }}>
            <LibraryStack.Screen
                name="LibraryTabs"
                component={LibraryTabs} />
        </LibraryStack.Navigator>
    )
} 

function LibraryTabs() {
    const {t} = useContext(AppSettingsContext);
    return (
        <Tab.Navigator 
            screenOptions={{...getTabBarOptions(), lazy: true }}>
            <Tab.Screen
                name="BooksTabs"
                component={BooksTabs} 
                options={{
                    title: t(`${tBase}.tabs.books.title`)
                }} />
            <Tab.Screen 
                name="Favourites" 
                component={FavouritesScreen}
                options={{
                    title: t(`${tBase}.tabs.favourites.title`)
                }} />
        </Tab.Navigator>
    );
}

const BooksTab = createBooksTabNavigator();

function BooksTabs() {
    const {t} = useContext(AppSettingsContext);
    const booksScrollContextValue = useBooksScrollContext();
    const shelvesScrollContextValue = useBooksScrollContext();
    const listsScrollContextValue = useBooksScrollContext();
    return (
        <BooksScrollContext.Provider value={booksScrollContextValue}>
            <ShelvesScrollContext.Provider value={shelvesScrollContextValue}>
                <ListsScrollContext.Provider value={listsScrollContextValue}>
                    <BooksTab.Navigator>
                        <BooksTab.Screen 
                            name="Books" 
                            component={BooksScreen}
                            options={{
                                title: t(`${tBase}.tabs.books.tabs.all.title`)
                            }} />
                        <BooksTab.Screen 
                            name="Shelves" 
                            component={ShelvesScreen}
                            options={{
                                title: t(`${tBase}.tabs.books.tabs.shelves.title`)
                            }} />
                        <BooksTab.Screen 
                            name="Lists" 
                            component={ListsScreen}
                            options={{
                                title: t(`${tBase}.tabs.books.tabs.lists.title`)
                            }} />
                    </BooksTab.Navigator>
                </ListsScrollContext.Provider>
            </ShelvesScrollContext.Provider>
        </BooksScrollContext.Provider>
      );
}