import React, { useState, useEffect, useRef, Suspense, useContext } from 'react';
import { 
    View, 
    Platform, 
    Keyboard, 
    Pressable, 
    StatusBar, 
    ScrollView, 
    StyleSheet, 
    ActivityIndicator, 
    useWindowDimensions, 
    KeyboardAvoidingView 
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIsFocused, getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { TransitionPresets, createStackNavigator } from '@react-navigation/stack';
import { useHeaderHeight } from '@react-navigation/elements';
import { modalOpen, modalClose, setModalSize  } from '../actions';
import { getRootState } from '../navigation/RootNavigation';
import { GRAY_LINE_COLOR, MODAL_MAX_HEIGHT, MAIN_COLOR } from '../constants';
import{ isIOS, isWeb, isAndroid, getModalType } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';
import Button from '../components/Button';

import { ModalConfig } from './ModalConfig';
import { ModalScreens } from './ModalScreens';

const ModalStack = createStackNavigator();

export default function Modal({ route }) {
    const dispatch = useDispatch();
    const routeName = getFocusedRouteNameFromRoute(route);
    let modalScreenKey = route.params?.screen ?? routeName;
    if(!modalScreenKey) return <View></View>;
    const renderHeaderRight = ({ tintColor }) => (
        <Pressable 
            onPress={ () => dispatch(modalClose()) }
            hitSlop={{ top: 7, left: 7, bottom: 7, right: 7 }}
            style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
            <Ionicons name='close' color={ tintColor } size={ 27 } />
        </Pressable>
    )   
    return (
        <ModalStack.Navigator 
            screenOptions={{ 
                headerShown: true, 
                animationEnabled: true,
                headerTitleAlign: "center",
                headerBackTitleVisible: false,
                headerStatusBarHeight: 0,
                headerRight: renderHeaderRight,
            cardStyle: {
                backgroundColor: Platform.OS == "ios" ? "#fff" : "transparent"
            },
            ...TransitionPresets.SlideFromRightIOS }}>
            {  
            ModalScreens[modalScreenKey].map((props) => (
                <ModalStack.Screen 
                    key={props.name} 
                    component={ModalContent} 
                    {...props} />
                )
            )}
      </ModalStack.Navigator>
    )
}

function ModalContent({ route, navigation }) {
    const {t} = useContext(AppSettingsContext);
    const { height:windowHeight, width:windowWidth } = useWindowDimensions();
    const modalType = getModalType(windowWidth);
    const modal = ModalConfig[route.name];
    const modalIsOpen = useSelector(state => state.settings.modal.isOpen, shallowEqual);
    const alertIsOpen = useSelector(state => !!state.alert.title, shallowEqual);
    const dispatch = useDispatch();
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const [opened, setOpened] = useState(false);
    const [keyboardVisible, setKeyboardVisible] = useState(false);
    const isFocused = useIsFocused();
    const headerHeight = useHeaderHeight();
    const insets = useSafeAreaInsets();
    const childRef = useRef();
    const showActionBtns = modal.settings?.showActionBtns ?? false;
    const cancelButtonLabel = modal.settings?.cancelButtonLabel ?? 'modal.cta.cancel';
    const saveButtonLabel = modal.settings?.saveButtonLabel ?? 'modal.cta.save';
    const scrollContainerStyle = modal.settings?.scrollContainerStyle ?? {};
    const useInsetBottom = (() => {
        if(modalType == 'transparentModal' && windowHeight > (MODAL_MAX_HEIGHT + 20)) return false;
        return modal.settings?.useInsetBottom ?? true;
    })()
    const scrollEnabled = modal.settings?.scrollEnabled ?? true;
    const tBase = modal.settings?.tBase ?? '';
    const title = tBase ? t(`${tBase}.title`) : null;
  
    const closeModalIfEscapeKeyPressed = (e) => { if(e.code == "Escape" && !alertIsOpen) close() };
    const handleSubmitPress = () => childRef.current.submit();
    const handleCancelPress = () => { 
        const navState = navigation.getState();
        if(keyboardVisible) {
            Keyboard.dismiss();
        } else if(navState.index > 0 && navigation.canGoBack()) {
            navigation.goBack(); 
        } else {
            close();
        }
    }
    const onLayout = ({nativeEvent: { layout: { width, height }}}) => {
        isFocused && dispatch(setModalSize({ width, height: height + headerHeight }))
    }

    useEffect(() => {
        const keywordDidShowListener = Keyboard.addListener("keyboardDidShow", keyboardDidShow);
        const keywordDidHideListener = Keyboard.addListener("keyboardDidHide", keyboardDidHide);
        return () => {
            keywordDidShowListener.remove();
            keywordDidHideListener.remove();
        };
    }, []);

    const keyboardDidShow = () => setKeyboardVisible(true);
    const keyboardDidHide = () => setKeyboardVisible(false);
  
    useEffect(() => {
        title != null && navigation.setOptions({ title })
        if((!opened && !modalIsOpen && isFocused) || (!opened && modalIsOpen && isFocused)) {
            dispatch(modalOpen());
            setOpened(true);
        }
        if(opened && !modalIsOpen && isFocused) close();
    }, [modalIsOpen, opened, isFocused, title])

    useEffect(() => {
        if(isWeb()) {
            window.addEventListener('keydown', closeModalIfEscapeKeyPressed);
            return function cleanup() {
                window.removeEventListener('keydown', closeModalIfEscapeKeyPressed);
            };
        }
    })

    const close = (callback) => {
        if(isFocused) {
            if(modalIsOpen) dispatch(modalClose());
            const callbackIsNavigate = callback && callback.toString().indexOf('navigate') > -1;
            if(callback && typeof(callback) == 'function') callback();
            if(callbackIsNavigate) return;
            const routeNames = getRootState()?.routeNames;
            if(routeNames && routeNames.includes('Root') && loggedUserId) {
                navigation.navigate('Root');
            } else {
                navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Auth');    
            }
        }
    }
    const { component:Content } = modal;
    const ContentComponent = scrollEnabled ? ScrollView : View;
    const ContentComponentProps = (() => {
        if(scrollEnabled) return {
            contentContainerStyle: [{ flexGrow: 1  }, styles.modalContent, scrollContainerStyle],
            style: { alignSelf: "stretch", flex: 1 },
            keyboardShouldPersistTaps: "handled"
        }
        return {
            style: [{ alignSelf: "stretch", flex: 1 }, styles.modalContent, scrollContainerStyle]
        }
    })()
    const renderContent = () => (
        <Content 
            route={route}
            headerTitle={title}
            navigation={navigation} 
            closeModal={ close } 
            ref={showActionBtns ? childRef : null} />
    )
    const suspenseLoading = () => (
        <View style={{ flex: 1, justifyContent: "center", alignSelf: "center"}}>
            <ActivityIndicator color={ MAIN_COLOR } size="large" />
        </View>
    )
    return (
        <View
            onLayout={ onLayout }
            style={ styles.modalContainer }>
            <StatusBar hidden={ isAndroid() ? true : false } />
            <View style={{ alignSelf: "stretch", flex: 1, paddingBottom: useInsetBottom ? insets.bottom : 0 }}>
                <ContentComponent 
                    {...ContentComponentProps}>
                    { isWeb ? 
                        <Suspense fallback={ suspenseLoading() }>
                            { renderContent() }
                        </Suspense> :
                        renderContent()
                    }
                </ContentComponent>
                { showActionBtns &&
                    <KeyboardAvoidingView
                        style={ styles.modalActionBtns }
                        behavior={ isIOS() ? "padding" : "height" }
                        keyboardVerticalOffset={headerHeight+insets.top + 10}>
                        <Button 
                            theme='grayColor' 
                            size='small'
                            text={ t(cancelButtonLabel) } 
                            buttonStyle={{ flex: 1, marginRight: 15 }} 
                            onPress={ handleCancelPress } />
                        <Button 
                            text={ t(saveButtonLabel) } 
                            size='small'
                            buttonStyle={{ flex: 1 }} 
                            onPress={ handleSubmitPress } />
                    </KeyboardAvoidingView>
                }
            </View>
        </View>
    )
}
const styles = StyleSheet.create({
    modalContainer: {
        flex: 1, 
        width: "100%", 
        backgroundColor: "white", 
        alignItems: "center", 
        justifyContent: "center"
    },
    modalContent: {
        paddingTop: 20, 
        paddingBottom: 20, 
        paddingLeft: 20, 
        paddingRight: 20
    },
    modalActionBtns: {
        alignSelf: "stretch", 
        flexDirection: "row", 
        marginTop: "auto", 
        paddingHorizontal: 15, 
        borderTopWidth: 1, 
        borderTopColor: GRAY_LINE_COLOR, 
        paddingTop: 15,
        paddingBottom: 5
    }
})