import { ADD_LIST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addListInProgress = createAction('ADD_LIST_IN_PROGRESS');
export const addListSuccess = createAction('ADD_LIST_SUCCESS');
export const addListFailure = createAction('ADD_LIST_FAILURE');

export function addListApi({ list }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { list },
            actions: [addListInProgress, addListSuccess, addListFailure],
            name: 'addList',
            dispatch,
            getState
        })
    }
}