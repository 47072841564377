import { SET_READ_MESSAGES_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setReadMessagesInProgress = createAction('SET_READ_MESSAGES_IN_PROGRESS');
export const setReadMessagesSuccess = createAction('SET_READ_MESSAGES_SUCCESS');
export const setReadMessagesFailure = createAction('SET_READ_MESSAGES_FAILURE');

export function setReadMessagesApi({ messageIds, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { messageIds },
            actions: [setReadMessagesInProgress, setReadMessagesSuccess, setReadMessagesFailure],
            name: 'setReadMessages',
            dispatch,
            showLoading,
            getState
        })
    }
}