import { GET_GROUP_COLLECTION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getGroupCollectionInProgress = createAction('GET_GROUP_COLLECTION_IN_PROGRESS');
export const getGroupCollectionSuccess = createAction('GET_GROUP_COLLECTION_SUCCESS');
export const getGroupCollectionFailure = createAction('GET_GROUP_COLLECTION_FAILURE');

export function getGroupCollectionApi({ groupCollectionId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupCollectionId },
            actions: [getGroupCollectionInProgress, getGroupCollectionSuccess, getGroupCollectionFailure],
            name: 'getGroupCollection',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}