import { GET_GROUP_REQUESTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getGroupRequestsInProgress = createAction('GET_GROUP_REQUESTS_IN_PROGRESS');
export const getGroupRequestsSuccess = createAction('GET_GROUP_REQUESTS_SUCCESS');
export const getGroupRequestsFailure = createAction('GET_GROUP_REQUESTS_FAILURE');

export function getGroupRequestsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getGroupRequestsInProgress, getGroupRequestsSuccess, getGroupRequestsFailure],
            name: 'getGroupRequests',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}