import React, { useEffect, memo, useMemo, useCallback } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useSelector, shallowEqual } from 'react-redux';
import { isNative, isLent, isWeb } from '../utils';
import { useBookCurrentLend, useBook, useGroupBookAttributes } from '../hooks';
import { LinearGradient } from 'expo-linear-gradient';
import Cover from './Cover';
import MenuBook from './MenuBook';
import MenuInfo from './MenuInfo';
import MenuReadingStatus from './MenuReadingStatus';
import GroupBookOnSiteInfo from './GroupBookOnSiteInfo';
import { SharedElement } from 'react-navigation-shared-element';
import { Feather, MaterialCommunityIcons as MCIcons, MaterialIcons as MIcons } from '@expo/vector-icons';
import { IcoMoon } from '../utils/customIcons';
import Animated, { useSharedValue, useAnimatedStyle, withTiming, withDelay, interpolate } from 'react-native-reanimated';

const SIZE = 28;
const GAP = 5;

function BookCoverMenu({ navigation, bookId, listId='', groupId='', context, contextId, showMenu, coverWidth }) {
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const book = useBook(bookId);
    const lend = useBookCurrentLend(bookId);
    const isOwner = book.ownerId == loggedUserId;
    const groupBookAttributes = useGroupBookAttributes(groupId, book.id);
    const showAvailableOnSite = groupBookAttributes && groupBookAttributes?.availableOnSite;
    const showReadingStatus = isOwner && !groupId;
    const showBookVisiblity = isOwner && !groupId;
    const visible = useSharedValue(1);
    const showHidden = useSharedValue(book.hidden ? 1 : 0);
    const showLent = useSharedValue(isLent(lend) ? 1 : 0);
    function deleteDone() {}
    const renderReadingStatusIcon = () => {
        switch(book.readingStatus) {
            case 'reading':
                return <MCIcons name='bookmark' color="#fff" size={21} />;
            case 'read':
                return <MCIcons name='bookmark-check' color="#fff" size={21} />;
            case 'toRead':
                return <MIcons name='watch-later' color="#fff" size={19} />;
            default:
                return <MCIcons name='bookmark-minus' color="#fff" size={22} />;
        } 
    }
    const menuStyle = useAnimatedStyle(() => ({ opacity: visible.value }))
    const hiddenStyle = useAnimatedStyle(() => {
        return { 
            opacity: showHidden.value,
            transform: [{ translateY: interpolate(showHidden.value, [0,1], [100, 0]) }],
            width: interpolate(showHidden.value, [0, 1], [0, SIZE])
        }
    })
    const lentStyle = useAnimatedStyle(() => {
        return { 
            opacity: showLent.value,
            transform: [{ translateY: interpolate(showLent.value, [0,1], [100, 0]) }],
            width: interpolate(showLent.value, [0, 1], [0, SIZE])
        }
    })
    useEffect(() => {
        if(showMenu && !visible.value) {
            visible.value = withDelay(400, withTiming(1))
        } else if(!showMenu && visible.value) {
            visible.value = withTiming(0)
        }
    }, [showMenu])
    useEffect(() => {
        showHidden.value = withTiming(book.hidden ? 1 : 0)
    }, [book.hidden])
    useEffect(() => {
        showLent.value = withTiming(isLent(lend) ? 1 : 0)
    }, [lend])
    const menuTriggerButton = useCallback(() => (
        <View style={styles.roudedSqr} pointerEvents='none'>
            <Feather name="more-horizontal" size={24} color="#fff" />
        </View>
    ), []);
    const menuReadingStatusTrigger = useCallback(() => (
        <View style={styles.roudedSqr}>
            { renderReadingStatusIcon() }
        </View>
    ), [book.readingStatus]);
    const menuOnSiteTrigger = useCallback(() => (
        <View style={styles.roudedSqr}>
            <MCIcons name='map-marker-radius' size={18} color="#fff" />
        </View>
    ), [])
    return (
        <View style={{ overflow: 'hidden' }}>
            { isNative() && book.cover ?
                <SharedElement id={`cover.${book.id}${listId}.photo`}>
                    <Cover 
                        cover={book.cover} 
                        blurHash={book.blurHash} 
                        coverHash={book.coverHash} 
                        width={coverWidth} />
                </SharedElement> : 
                <Cover 
                    cover={book.cover} 
                    blurHash={book.blurHash} 
                    coverHash={book.coverHash} 
                    width={coverWidth} /> 
            }
            <Animated.View style={[menuStyle, { height: SIZE + 30 }, styles.icons]}>
                <Gradient loggedUserId={loggedUserId} />
                { showBookVisiblity && 
                    <Animated.View style={[styles.roudedSqr, hiddenStyle, { marginRight: isLent(lend) ? 3 : -3 }]}>
                        <MCIcons name='eye-off-outline' size={20} color='#fff' />
                    </Animated.View>
                }
                <Animated.View style={[styles.roudedSqr, lentStyle]}>
                    <IcoMoon name='upload' size={16} color="#fff" />
                </Animated.View>
                { showReadingStatus &&
                    <MenuReadingStatus 
                        bookId={book.id}
                        currentReadingStatus={book.readingStatus}
                        style={{ width: SIZE+(GAP), height: SIZE }}
                        TriggerButton={menuReadingStatusTrigger}
                    />
                }
                { showAvailableOnSite &&
                    <MenuInfo TriggerButton={menuOnSiteTrigger} menuWidth={250}>
                        <GroupBookOnSiteInfo groupBookAttributes={groupBookAttributes} groupId={groupId} />
                    </MenuInfo>
                }
                { loggedUserId &&
                <View style={{ width: SIZE+(GAP/2), height: SIZE }}>
                    <MenuBook
                        placement='auto'
                        deleteDone={ deleteDone }
                        book={ book }
                        listId={listId}
                        groupId={groupId}
                        navigation={navigation}
                        context={context}
                        contextId={contextId}
                        style={{ width: SIZE+(GAP/2), height: SIZE }}
                        TriggerButton={menuTriggerButton} />
                </View>
                }
            </Animated.View>
        </View>
    )
}

const Gradient = memo(({ loggedUserId }) => {
    if(!loggedUserId) {
        return null;   
    } else if(isWeb()) {
        return <View pointerEvents='none' style={styles.gradient} />
    }
    return <LinearGradient pointerEvents='none' style={styles.gradient} locations={[0,1]} colors={['rgba(255,255,255,0)', 'rgba(255,255,255,0.5)']} />
})

export default memo(BookCoverMenu)

const styles = StyleSheet.create({
    icons: {
        width: '100%',
        position: 'absolute', 
        bottom: 0, 
        right: 0, 
        paddingRight: 5, 
        paddingTop: 5, 
        paddingBottom: 5, 
        flexDirection: 'row', 
        justifyContent: 'flex-end', 
        alignItems: 'flex-end'
    },
    roudedSqr: {
        marginRight: GAP/2,
        marginLeft: GAP/2,
        width: SIZE, 
        height: SIZE, 
        borderRadius: 5, 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'rgba(0,0,0,0.7)'
    },
    gradient: {
        ...StyleSheet.absoluteFill,
        ...Platform.select({
            web: {
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 100%)'
            },
            default: {},
        }),
    }
})