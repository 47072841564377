import React, { useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import MontBold from './MontserratBold';
import Animated, { FadeIn, FadeOutUp } from 'react-native-reanimated';

export default function InputSuggestion({ text, children }) {
    const [containerHeight, setContainerHeight] = useState(37);
    const onLayout = ({ nativeEvent: { layout: { height }}}) => setContainerHeight(height);
    return (
        <Animated.View 
            entering={FadeIn} 
            exiting={FadeOutUp}
            onLayout={onLayout} 
            style={styles.container}>
            { children ?
                children :
                <MontBold text={text} />
            }
            <View style={[styles.shadow4, styles.shadow, { top: containerHeight }]}/>
            <View style={[styles.shadow3, styles.shadow, { top: containerHeight }]}/>
            <View style={[styles.shadow2, styles.shadow, { top: containerHeight }]}/>
            <View style={[styles.shadow1, styles.shadow, { top: containerHeight }]}/>
            <View style={[styles.arrow, { top: containerHeight }]}/>
        </Animated.View>
    )
} 

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        padding: 10,
        paddingHorizontal: 14,
        right: 0, 
        bottom: '100%',
        marginBottom: -10,
        backgroundColor: '#fff',
        borderRadius: 6,
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
        })
    },
    arrow: {
        borderWidth: 8,
        borderColor: 'transparent',
        borderTopColor: '#fff',
        position: 'absolute',
        right: 40
    },
    shadow: {
        borderColor: 'transparent',
        position: 'absolute'
    },
    shadow1: {
        borderWidth: 9,
        borderTopColor:'rgba(0, 0, 0, 0.04)',
        right: 39
    },
    shadow2: {
        borderWidth: 10,
        borderTopColor: 'rgba(0, 0, 0, 0.03)',
        right: 38
    },
    shadow3: {
        borderWidth: 11,
        borderTopColor: 'rgba(0, 0, 0, 0.02)',
        right: 37
    },
    shadow4: {
        borderWidth: 12,
        borderTopColor: 'rgba(0, 0, 0, 0.01)',
        right: 36
    }
})