import React, { useRef } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import InterReg from './InterReg';
import PremiumPill from './PremiumPill';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { useHover } from 'react-native-web-hooks';

import CustomMenu from '../utils/customMenu';
import { SECONDARY_COLOR, GRAY_LINE_COLOR, GRAY_LIGHT, DANGER_COLOR } from '../constants';

const MenuItem = ({onSelect, Icon, label, showPremiumOnly=false, iconName, last, first, destructive=false}) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <MenuOption 
            onSelect={onSelect} 
            customStyles={{
                optionTouchable: {
                    ref: ref,
                    underlayColor: GRAY_LIGHT,
                    style: { 
                        backgroundColor: isHovered ? '#fcfcfc' : 'transparent',
                        borderTopRightRadius: first ? 8 : 0,
                        borderTopLeftRadius: first ? 8 : 0,
                        borderBottomRightRadius: last ? 8 : 0,
                        borderBottomLeftRadius: last ? 8 : 0
                    }
                }
            }}
            style={{ 
                paddingHorizontal: 10, 
                paddingTop: first ? 14 : 12,
                paddingBottom: last ? 14 : 12,
                borderBottomWidth: last ? 0 : StyleSheet.hairlineWidth, 
                borderBottomColor: GRAY_LINE_COLOR, 
                flexDirection: 'row', 
                alignItems: 'center' 
            }}>
            <View style={ styles.menuOptionIcon }>
                <Icon name={iconName} size={ 20 } color={ destructive ? DANGER_COLOR : SECONDARY_COLOR } />
            </View>
            <InterReg text={label} style={[styles.menuOptionText, { color: destructive ? DANGER_COLOR : SECONDARY_COLOR }]} />
            { showPremiumOnly && <PremiumPill containerStyle={{right: 10}} /> }
        </MenuOption>
    )
}

export default function ContextMenu({ 
        style, 
        TriggerButton, 
        triggerButtonHitSlop={ top: 10, left: 10, right: 10, bottom: 10 },
        menuWidth=250,
        Header=null,
        anchorFill='#fff',
        placement='auto', 
        preferredPlacement='bottom', 
        menuOptions }) {
    const optionsContainerStyles = { 
        borderRadius: 8, 
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
          })
        
    }
    if(!menuOptions.length) return <View />;
    return (
        <Menu 
            style={ style }
            renderer={ CustomMenu } 
            rendererProps={{
                placement,
                preferredPlacement,
                anchorStyle: { fill: anchorFill, zIndex: 2 } 
            }}>
            <MenuTrigger customStyles={{ 
                    triggerTouchable: { 
                        hitSlop: triggerButtonHitSlop,
                        underlayColor: 'transparent'
                    }
                }}>
                { TriggerButton() }
            </MenuTrigger>
            <MenuOptions customStyles={{ 
                    optionsContainer: { width: menuWidth, margin: 0, paddingVertical: 0, ...optionsContainerStyles }, 
                }}>
                { Header && <Header /> }
                { menuOptions.map((props, index) => ( 
                    <MenuItem 
                        key={ index } 
                        {...props} 
                        first={index==0} 
                        last={index==menuOptions.length-1} /> 
                    )
                )}
            </MenuOptions>
        </Menu>
    )
}

const styles = StyleSheet.create({
    menuOptionIcon: {
        width: 25, 
        alignItems: 'center', 
        justifyContent: 'center',
        marginRight: 8,
    },
    menuOptionText: {
        fontSize: 15,
        marginTop: 1,
    },
})