import React, { useEffect, useState, memo, useMemo, useCallback, useContext } from 'react';
import { View, ActivityIndicator, FlatList } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getUserApi, getUserInProgress } from '../actions/getUser';
import { TAB_HEIGHT, MAIN_COLOR, SECONDARY_COLOR, GRAY_LINE_COLOR } from '../constants';
import UserShelves from '../components/UserShelves';
import UserLists from '../components/UserLists';
import UserHeader from '../components/UserHeader';
import UserPrivate from '../components/UserPrivate';
import FetchError from '../components/FetchError';
import Empty from '../components/Empty';
import Tabs from '../components/Tabs';
import { Ionicons } from '@expo/vector-icons';
import { shouldAnimateUserHeader, isInModal, isNative, isWeb, getResponsiveValue, capitalizeWord } from '../utils';
import { USER_HEADER_HEIGHT_MIN } from '../constants';
import Animated, { useSharedValue, useAnimatedScrollHandler, scrollTo, useAnimatedRef } from 'react-native-reanimated';
import { useGetDataOnNavFocus, useSetInitialPath, useUser, useUserShelves, useUserLists, useIsLoading, useGetUserDone } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

const TABS = ['shelves', 'lists'];
const tBase = 'screens.user';
const USER_HEADER_APPROX_HEIGHT = 252;
const AnimatedFlatlist = Animated.createAnimatedComponent(FlatList);


export default function UserScreen({ navigation, route }) {
    const userId = route?.params?.userId;
    const user = useUser(userId);
    const dispatch = useDispatch();
    const [userFetchStatus, done] = useGetUserDone(userId);
    const getUser = () => { dispatch(getUserApi({ userId, showLoading: !user })).then(done); }
    useGetDataOnNavFocus(navigation, getUser);
    if(!userFetchStatus && !user) return <View />;
    if(['failed', 'notFound'].includes(userFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                navigation={navigation} 
                status={ userFetchStatus } />
        )
    }
    if(!user) return <View />;
    return (
        <User 
            user={user} 
            navigation={navigation} 
            route={route} />
    )
}

const User = memo(({ user, navigation, route }) => {
    const {t} = useContext(AppSettingsContext);
    const inModal = isInModal(navigation);
    const [activeTab, setActiveTab] = useState(TABS[0]);
    const isLoadingUser = useIsLoading(getUserInProgress);
    const [scrollHeight, setScrollHeight] = useState(null);
    const [scrollContentHeight, setScrollContentHeight] = useState(null);
    const [headerInputMaxRange, setHeaderImputMaxRange] = useState(USER_HEADER_APPROX_HEIGHT-USER_HEADER_HEIGHT_MIN); 
    const [scrollPaddingTop, setScrollPaddingTop] = useState(USER_HEADER_APPROX_HEIGHT);
    const shelves = useUserShelves(user.id);
    const lists = useUserLists(user.id);
    const headerHeight = useSharedValue(0);
    const animateHeader = useSharedValue(0);
    const scrollRef = useAnimatedRef();
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const insets = useSafeAreaInsets();
    const isFollowing = user?.followerIds.includes(loggedUserId);
    const isYou = user?.id == loggedUserId;
    const canViewProfile = !user?.isPrivate || (user?.isPrivate && isFollowing) || isYou;
    const scrollY = useSharedValue(0);
    const insetTop = (() => {
        if((isNative() && !inModal) || (inModal && !isNative())) return Math.max(insets.top, 28);
        return 20 
    })()
    const onHeaderHeightChange = useCallback((height) => { 
        setHeaderImputMaxRange(Math.round(height-insetTop-USER_HEADER_HEIGHT_MIN));
        setScrollPaddingTop(height);
        headerHeight.value = height;
    }, []);
    const scrollHandler = useAnimatedScrollHandler({
        onScroll: (e) => {
            if(animateHeader.value) scrollY.value = e.contentOffset.y;
        },
        onBeginDrag: (e, ctx) => ctx.y = e.contentOffset.y,
        onEndDrag: (e, ctx) => {
            if(animateHeader.value  && e.velocity.y == 0) {
                const scrollingUp = ctx.y < e.contentOffset.y;
                const scrollingDown = ctx.y > e.contentOffset.y;
                if(scrollingUp && e.contentOffset.y < headerInputMaxRange && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, headerInputMaxRange, true);
                } else if (scrollingDown && e.contentOffset.y < headerInputMaxRange && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, 0, true);
                }
            }
        },
        onMomentumEnd: (e, ctx) => {
            if(animateHeader.value) {
                const scrollingUp = ctx.y < e.contentOffset.y;
                const scrollingDown = ctx.y > e.contentOffset.y;
                if(scrollingUp && e.contentOffset.y < headerInputMaxRange && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, headerInputMaxRange, true);
                } else if (scrollingDown && e.contentOffset.y < headerInputMaxRange && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, 0, true);
                }
            }
        }
    })
    function onContentSizeChange(_, contentHeight) {
        const diff = scrollContentHeight - Math.round(contentHeight);
        if(scrollContentHeight == 0 || scrollContentHeight == null || (diff > 2 || diff < -2)) {
            setScrollContentHeight(Math.round(contentHeight));
        }
    }
    const renderListHeader = useMemo(() => {
        if(isLoadingUser && !shelves.length && !lists.length) return null;
        const minWidth = inModal ? '100%' : getResponsiveValue(['100%', '100%', '100%', '100%', 450], 'x');
        const marginLeft = getResponsiveValue([0, 0, 0, 0, 20], 'x');
        const marginRight = inModal ? getResponsiveValue([0, 0, 0, 0, 20], 'x') : 0;
        return (
            <View style={{ marginLeft, marginRight, borderBottomWidth: 1, borderBottomColor: GRAY_LINE_COLOR }}>
                <Tabs 
                    tabs={TABS} 
                    tBase={tBase} 
                    style={{ marginTop: 8, minWidth, maxWidth: 450, position: 'relative', bottom: -1 }}
                    icons={{
                        shelves: () => <Ionicons size={16} style={{ marginRight: 6 }} color={SECONDARY_COLOR} name='library' />,
                        lists: () => <Ionicons size={19} style={{ marginRight: 6, marginTop: -1 }} color={SECONDARY_COLOR} name='list' />
                    }}
                    counts={{ shelves: shelves.length, lists: lists.length }} 
                    activeTab={activeTab} 
                    onTabPress={setActiveTab} />
            </View>
        )
    }, [activeTab, shelves, lists, isLoadingUser]);
    const renderListEmpty = (() => {
        if(isLoadingUser && ((activeTab == 'shelves' && !shelves.length) || (activeTab == 'lists' && !lists.length))) {
            return (
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={ MAIN_COLOR } size="large" />
                </View>
            )
        }
        return (
            <Empty 
                title={ t(`${tBase}.noPublic${capitalizeWord(activeTab)}.title`)}
                img={ require('../assets/empty.png')}
                body={ t(`${tBase}.noPublic${capitalizeWord(activeTab)}.body`, { nickname: user.nickname })} />
        )
    })
    const renderItem = () => (
        <UserContent 
            navigation={navigation} 
            shelves={shelves}
            lists={lists}
            activeTab={activeTab}
            user={user} />
    )
    const onLayout = ({nativeEvent: { layout: { height }}}) => { 
        if(!scrollHeight || scrollHeight == scrollContentHeight) setScrollHeight(height);
    }
    useEffect(() => {
        if(user?.nickname) navigation.setOptions({ title: user.nickname })
    }, [])
    useEffect(() => {
        const shouldAnimateHeader = shouldAnimateUserHeader(scrollHeight, scrollContentHeight, headerHeight.value);
        if(shouldAnimateHeader !== Boolean(animateHeader.value)) animateHeader.value = shouldAnimateHeader ? 1 : 0;
    }, [scrollContentHeight, scrollHeight, headerHeight.value])
    useSetInitialPath(route);
    const getData = () => {
        if(activeTab == 'shelves' && shelves.length) return [{}];
        if(activeTab == 'lists' && lists.length) return [{}];
        return [];
    }   
    return (
        <View style={{ flex: 1 }}>
            <UserHeader 
                scrollY={scrollY}
                insetTop={insetTop}
                headerInputMaxRange={headerInputMaxRange}
                headerHeight={headerHeight}
                user={user} 
                navigation={navigation} 
                isYou={isYou}
                bookCount={shelves.map(({bookIds}) => bookIds).flat().length}
                isFollowing={isFollowing}
                canViewProfile={canViewProfile}
                onHeaderHeightChange={onHeaderHeightChange} />
                { canViewProfile ?
                    <AnimatedFlatlist 
                        onScroll={scrollHandler}
                        ref={scrollRef}
                        data={getData()}
                        renderItem={renderItem}
                        ListEmptyComponent={renderListEmpty}
                        scrollEventThrottle={16}
                        onLayout={onLayout}
                        onContentSizeChange={onContentSizeChange}
                        ListHeaderComponent={renderListHeader}
                        contentContainerStyle={{
                            flexGrow: 1, 
                            justifyContent: 'flex-start',
                            paddingTop: isWeb() ? 10 : scrollPaddingTop, 
                            paddingBottom: TAB_HEIGHT + insets.bottom + 40 
                    }} /> :
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                        <UserPrivate 
                            loggedUserId={loggedUserId} 
                            user={user} /> 
                    </View>
                }
        </View>
    )
})

const UserContent = memo(({ navigation, shelves, lists, user, activeTab }) => (
    activeTab == 'shelves' ?
    <UserShelves 
        navigation={navigation} 
        shelves={shelves}
        userId={user.id} /> :
    <UserLists
        navigation={navigation} 
        lists={lists}
        userId={user.id} />   
))