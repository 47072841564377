import { createReducer } from '@reduxjs/toolkit'
import { getGroupRequestsSuccess } from '../actions/getGroupRequests';
import { hasGroupRequests, hasGroupRequest, removeGroupRequest, matchResetStore } from './matchers';
import { removeOldKeysFromState } from '../utils';

const initialState = {};

export const groupRequests = createReducer(initialState, (builder) => {
    builder
    .addMatcher(removeGroupRequest, (state, action) => {
        delete state[action.payload.groupRequestId];
    })
    .addMatcher(hasGroupRequest, (state, action) => {
        state[action.payload.groupRequest.id] = action.payload.groupRequest;
    })
    .addMatcher(hasGroupRequests, (state, action) => {
        action.payload.groupRequests.forEach(groupRequest => state[groupRequest.id] = groupRequest)
        if(action.type == getGroupRequestsSuccess) {
            removeOldKeysFromState(state, action.payload.groupRequests);
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})