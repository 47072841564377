import { LOGIN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const loginInProgress = createAction('LOGIN_IN_PROGRESS');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');

export function loginApi({ email, password }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { email, password },
            actions: [loginInProgress, loginSuccess, loginFailure],
            name: 'login',
            dispatch,
            getState
        })
    }
}