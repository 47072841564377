import React, { useContext } from 'react';
import { shareWebMenu } from '../utils/menuConfigs';
import Menu from './Menu';
import { share } from '../utils/';
import AppSettingsContext from '../context/AppSettingsContext';

export default function MenuWebShare({ style, type, url, name, isOwner, TriggerButton, placement='auto' }) {
    const {t} = useContext(AppSettingsContext);
    const onShareFacebook   = () => share({ type, method: 'facebook', url, name, isOwner, t })
    const onShareTwitter    = () => share({ type, method: 'twitter', url, name, isOwner, t })
    const onShareEmail      = () => share({ type, method: 'email', url, name, isOwner, t })
    return (
        <Menu
            placement={placement}
            menuOptions={ 
                shareWebMenu({ 
                    actions: { 
                        onShareFacebook,
                        onShareTwitter,
                        onShareEmail
                    },
                    t
                }) 
            }
            style={ style }
            TriggerButton={ TriggerButton } />
    )
}