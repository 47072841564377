import React, { useContext } from 'react';
import { Pressable, View, StyleSheet, KeyboardAvoidingView } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loginApi } from '../actions/login';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSettingsContext from '../context/AppSettingsContext';
import Input from '../components/Input';
import Button from '../components/Button';
import MontSBold from '../components/MontserratBold';
import AuthLogo from '../components/AuthLogo';
import { MAIN_COLOR, GRAY_TEXT_PALE } from '../constants';
import { isIOS, handleFormErrors } from '../utils';

const tBase = 'forms.signin';
const tErrors = `${tBase}.errors`;
  
const schema = (t) => yup.object().shape({
    email: yup
        .string()
        .required(() => t(`${tErrors}.emailRequired`))
        .email(() => t(`${tErrors}.emailNotValid`)),
    password: yup
        .string()
        .required(() => t(`${tErrors}.passwordRequired`)),
});


export default function SignInScreen({route, navigation}) {
    const {t} = useContext(AppSettingsContext);
    const yupSchema = schema(t);
    const { handleSubmit, formState: { errors }, control, setError } =  useForm({
        resolver: yupResolver(yupSchema)
    });
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const onSubmit = data => dispatch(loginApi(data)).then(done);
    const done = (json) => {
        if(json?.errors) {
            handleFormErrors(json.errors, setError, yupSchema);
        }
    }
    const handleSignupPress = () => navigation.navigate('SignUp');
    return (
        <KeyboardAvoidingView
            behavior={isIOS() ? "padding" : "height"}
            style={[ styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
            <AuthLogo />
            <View style={{ maxWidth: 500, minWidth: 300, width: '100%' }}>
                <Controller
                    name="email"
                    defaultValue={route?.params?.email ?? ""}
                    control={control}
                    render={ ({ field }) =>(
                        <Input { ...{
                            inputProps: {
                                ...field,
                                autoCapitalize: 'none',
                                autoCorrect: false,
                            }, tBase, errors
                        }} />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={ ({ field }) => (
                        <Input { ...{
                            inputProps: {
                                ...field,
                                onSubmitEditing: handleSubmit(onSubmit),
                                returnKeyType: 'send'
                            }, tBase, errors, hasPlaceholder: false, resetPasswordLink: true,
                        }} />
                    )}
                />
                <Button text={ t(`${tBase}.submit`) } buttonStyle={{ marginTop: 10 }} onPress={ handleSubmit(onSubmit) } />
                <View style={{ paddingTop: 20, justifyContent: "center", flexDirection: 'row' }}>
                    <View style={{ paddingVertical: 5 }}><MontSBold style={{ color: GRAY_TEXT_PALE }} text={ t(`${tBase}.dontHaveAccount`) } /></View>
                    <Pressable style={{ padding: 5 }} onPress={ handleSignupPress }>
                        <MontSBold style={{ color: MAIN_COLOR }} text={ t(`${tBase}.navigate.signup`) } />
                    </Pressable>
                </View>
            </View>
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center', 
        justifyContent: 'center',
        paddingHorizontal: 25,
    }
})