import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector, shallowEqual } from 'react-redux';
import Empty from '../components/Empty';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = "screens.notFound";


export default function NotFoundScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const route = loggedUserId ? 'Root' : 'Auth';
    const goHome = () => navigation.replace(route);
    return (
        <View style={styles.container}>
            <Empty 
                title={ t(`${tBase}.empty.title`) }
                body={ t(`${tBase}.empty.body`) }
                primaryActionLabel={ t(`${tBase}.empty.goHome`) }
                primaryActionOnPress={goHome}
                img={ require('../assets/noBooksSearch.png') } />
        </View>
  );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
    }

});
