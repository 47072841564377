import { SET_HAS_SEEN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setHasSeenInProgress = createAction('SET_HAS_SEEN_ROGRESS');
export const setHasSeenSuccess = createAction('SET_HAS_SEEN_SUCCESS');
export const setHasSeenFailure = createAction('SET_HAS_SEEN_FAILURE');

export function setHasSeenApi({ hasSeen }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { hasSeen },
            actions: [setHasSeenInProgress, setHasSeenSuccess, setHasSeenFailure],
            name: 'setHasSeen',
            dispatch,
            getState
        })
    }
}