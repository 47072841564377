import { 
    emptyStoresAction, 
    userFollowed, 
    userCancelledFollowRequest, 
    groupRequestAccepted,
    messageReceived,
    removeGroupFromStore,
    removeBookFromStore,
    removeListFromStore,
    removeShelfFromStore,
    removeGroupCollectionFromStore
} from '../actions';

import { loginSuccess                       } from '../actions/login';
import { logoutSuccess                      } from '../actions/logout';
import { signupSuccess                      } from '../actions/signup';
import { deleteAccountSuccess               } from '../actions/deleteAccount';
import { updateAccountSuccess               } from '../actions/updateAccount';    
import { setHasSeenSuccess                  } from '../actions/setHasSeen'; 
import { setFoundInSearchSuccess            } from '../actions/setFoundInSearch';
import { setLendBookDurationInDaysSuccess   } from '../actions/setLendBookDurationInDays';
import { setMessagesByEmailSuccess          } from '../actions/setMessagesByEmail';
import { setNotifsByEmailSuccess            } from '../actions/setNotifsByEmail';
import { setNewsletterSuccess               } from '../actions/setNewsletter';
import { setPrivateSuccess                  } from '../actions/setPrivate';
import { setExpoTokenSuccess                } from '../actions/setExpoToken';
import { setPreferredLangSuccess            } from '../actions/setPreferredLang';
import { addUserKeywordSuccess              } from '../actions/addUserKeyword';
import { updateUserKeywordSuccess           } from '../actions/updateUserKeyword';

// User
import { getUsersSuccess                    } from '../actions/getUsers';
import { getUserSuccess                     } from '../actions/getUser';
import { getUserBookLikesSuccess            } from '../actions/getUserBookLikes';

// Book
import { getBookSuccess                     } from '../actions/getBook';
import { addBookSuccess                     } from '../actions/addBook';
import { updateBookSuccess                  } from '../actions/updateBook';
import { deleteBookSuccess                  } from '../actions/deleteBook';
import { getBookOwnersSuccess               } from '../actions/getBookOwners';
import { lendBookSuccess                    } from '../actions/lendBook';
import { updateBookInListsSuccess           } from '../actions/updateBookInLists';
import { removeBookFromListSuccess          } from '../actions/removeBookFromList';
import { removeBookFromGroupSuccess         } from '../actions/removeBookFromGroup';
import { getBookLikesSuccess                } from '../actions/getBookLikes';
import { setBookLikeSuccess                 } from '../actions/setBookLike';
import { addBookReviewSuccess               } from '../actions/addBookReview';
import { getBookReviewsSuccess              } from '../actions/getBookReviews';
import { updateBookReviewSuccess            } from '../actions/updateBookReview';
import { returnBookSuccess                  } from '../actions/returnBook';
import { requestReturnBookSuccess           } from '../actions/requestReturnBook'; 
import { setSortLibraryBooksSuccess         } from '../actions/setSortLibraryBooks';


// List
import { getListsSuccess                    } from '../actions/getLists';
import { addListSuccess                     } from '../actions/addList';
import { updateListSuccess                  } from '../actions/updateList';
import { deleteListSuccess                  } from '../actions/deleteList';
import { getListSuccess                     } from '../actions/getList';

// Shelf
import { getShelvesSuccess                  } from '../actions/getShelves';
import { addShelfSuccess                    } from '../actions/addShelf';
import { updateShelfSuccess                 } from '../actions/updateShelf';
import { deleteShelfSuccess                 } from '../actions/deleteShelf';
import { getShelfSuccess                    } from '../actions/getShelf';

// Lends
import { getBorrowRequestsSuccess           } from '../actions/getBorrowRequests';
import { refuseBorrowRequestSuccess         } from '../actions/refuseBorrowRequest';
import { cancelBorrowRequestSuccess         } from '../actions/cancelBorrowRequest';
import { finaliseBorrowRequestSuccess       } from '../actions/finaliseBorrowRequest';
import { getLendsSuccess                    } from '../actions/getLends';

// Discussions
import { getDiscussionsSuccess              } from '../actions/getDiscussions';
import { createDiscussionSuccess            } from '../actions/createDiscussion';
import { sendMessageSuccess                 } from '../actions/sendMessage';

// Following/followers
import { followUserSuccess                  } from '../actions/followUser';
import { unfollowUserSuccess                } from '../actions/unfollowUser';

import { getFollowUserRequestsSuccess       } from '../actions/getFollowUserRequests';
import { requestFollowUserSuccess           } from '../actions/requestFollowUser';
import { cancelFollowUserRequestSuccess     } from '../actions/cancelFollowUserRequest';
import { acceptFollowUserRequestSuccess     } from '../actions/acceptFollowUserRequest';
import { refuseFollowUserRequestSuccess     } from '../actions/refuseFollowUserRequest';

// Group
import { addGroupSuccess                    } from '../actions/addGroup';
import { updateGroupSuccess                 } from '../actions/updateGroup';
import { removeGroupMemberSuccess           } from '../actions/removeGroupMember';

import { getGroupSuccess                    } from '../actions/getGroup';
import { getMyGroupsSuccess                 } from '../actions/getMyGroups';
import { getPublicGroupsSuccess             } from '../actions/getPublicGroups';

import { getGroupRequestsSuccess            } from '../actions/getGroupRequests';
import { sendGroupRequestSuccess            } from '../actions/sendGroupRequest';
import { cancelGroupRequestSuccess          } from '../actions/cancelGroupRequest';
import { refuseGroupRequestSuccess          } from '../actions/refuseGroupRequest';
import { acceptGroupRequestSuccess          } from '../actions/acceptGroupRequest';

import { getGroupInvitationsSuccess         } from '../actions/getGroupInvitations';
import { sendGroupInvitationSuccess         } from '../actions/sendGroupInvitation';
import { sendGroupInvitationsSuccess        } from '../actions/sendGroupInvitations';
import { acceptGroupInvitationSuccess       } from '../actions/acceptGroupInvitation';
import { refuseGroupInvitationSuccess       } from '../actions/refuseGroupInvitation';

import { updateBooksInGroupSuccess          } from '../actions/updateBooksInGroup';
import { leaveGroupSuccess                  } from '../actions/leaveGroup';
import { deleteGroupSuccess                 } from '../actions/deleteGroup';

import { addAdminSuccess                    } from '../actions/addAdmin';
import { removeAdminSuccess                 } from '../actions/removeAdmin';

import { getGroupCollectionSuccess          } from '../actions/getGroupCollection';
import { addGroupCollectionSuccess          } from '../actions/addGroupCollection'; 
import { updateGroupCollectionSuccess       } from '../actions/updateGroupCollection';
import { deleteGroupCollectionSuccess       } from '../actions/deleteGroupCollection';
import { updateBooksInGroupCollectionSuccess } from '../actions/updateBooksInGroupCollection';

import { setGroupCollectionsOrderSuccess    } from '../actions/setGroupCollectionsOrder';

// Keywords
import { getKeywordsSuccess                 } from '../actions/getKeywords';    

// Other
import { searchSuccess                      } from '../actions/search';
import { getActivitySuccess                 } from '../actions/getActivity';
import { getUnreadNotifsSuccess             } from '../actions/getUnreadNotifs';
import { updateUnreadNotifsSuccess          } from '../actions/updateUnreadNotifs';

import { doesActionTypeMatch } from '../utils';

export const matchResetStore = (action) => doesActionTypeMatch([
        emptyStoresAction, 
        logoutSuccess,
        deleteAccountSuccess
    ], action.type);

export const hasBookLike = (action) => doesActionTypeMatch([
    setBookLikeSuccess
], action.type);

export const hasBookLikes = (action) => doesActionTypeMatch([
    getUserBookLikesSuccess,
    getBookLikesSuccess
], action.type);

export const hasBookReview = (action) => doesActionTypeMatch([
    addBookReviewSuccess,
    updateBookReviewSuccess
], action.type);

export const hasBookReviews = (action) => doesActionTypeMatch([
    getBookReviewsSuccess,
    getActivitySuccess
], action.type);

export const hasDeletedBookIds = (action) => doesActionTypeMatch([
    removeBookFromListSuccess,
    deleteShelfSuccess,
    deleteListSuccess,
    updateBookInListsSuccess
], action.type);

export const hasBooks = (action) => doesActionTypeMatch([
    loginSuccess,
    getUserSuccess,
    getBorrowRequestsSuccess,
    getLendsSuccess,
    getShelvesSuccess,
    getGroupSuccess,
    getUserBookLikesSuccess,
    updateBooksInGroupSuccess,
    getListsSuccess,
    searchSuccess,
    getActivitySuccess,
    getShelfSuccess,
    getListSuccess,
    getGroupCollectionSuccess
], action.type);

export const hasBook = (action) => doesActionTypeMatch([
    getBookSuccess,
    addBookSuccess,
    updateBookSuccess,
    removeBookFromListSuccess,
    updateBookInListsSuccess,
    removeBookFromGroupSuccess
], action.type);

export const hasBorrowRequests = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess,
    getBorrowRequestsSuccess
], action.type);

export const removeBorrowRequest = (action) => doesActionTypeMatch([
    cancelBorrowRequestSuccess,
    refuseBorrowRequestSuccess,
    finaliseBorrowRequestSuccess
], action.type);

export const hasUsers = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess,
    getBorrowRequestsSuccess, 
    getLendsSuccess, 
    followUserSuccess, 
    unfollowUserSuccess, 
    getUsersSuccess, 
    getUserSuccess,
    acceptFollowUserRequestSuccess,
    getFollowUserRequestsSuccess,
    getDiscussionsSuccess,
    getGroupSuccess,
    getGroupInvitationsSuccess,
    getGroupRequestsSuccess,
    getBookOwnersSuccess,
    getBookReviewsSuccess,
    searchSuccess,
    getActivitySuccess,
    sendGroupInvitationSuccess,
    sendGroupInvitationsSuccess,
    getGroupCollectionSuccess
], action.type);

export const hasUser = (action) => doesActionTypeMatch([
    lendBookSuccess, 
    getListSuccess,
    getShelfSuccess,
    acceptGroupInvitationSuccess,
    leaveGroupSuccess,
    acceptGroupRequestSuccess
], action.type);

export const hasFollowRequests = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess,
    getFollowUserRequestsSuccess
], action.type);

export const hasFollowRequest = (action) => doesActionTypeMatch([
    requestFollowUserSuccess
], action.type);

export const removeFollowRequest = (action) => doesActionTypeMatch([
    cancelFollowUserRequestSuccess,
    refuseFollowUserRequestSuccess,
    acceptFollowUserRequestSuccess,
    userFollowed,
    userCancelledFollowRequest
], action.type);

export const hasGroupInvitations = (action) => doesActionTypeMatch([
    getGroupSuccess,
    getGroupInvitationsSuccess,
    sendGroupInvitationsSuccess
], action.type);

export const hasGroupInvitation = (action) => doesActionTypeMatch([
    sendGroupInvitationSuccess
], action.type);

export const removeGroupInvitation = (action) => doesActionTypeMatch([
    acceptGroupInvitationSuccess,
    refuseGroupInvitationSuccess
], action.type);

export const hasGroupRequests = (action) => doesActionTypeMatch([
    getGroupSuccess,
    getGroupRequestsSuccess
], action.type);

export const hasGroupRequest = (action) => doesActionTypeMatch([
    sendGroupRequestSuccess
], action.type);

export const removeGroupRequest = (action) => doesActionTypeMatch([
    cancelGroupRequestSuccess,
    acceptGroupRequestSuccess,
    groupRequestAccepted,
    refuseGroupRequestSuccess
], action.type);

export const hasGroups = (action) => doesActionTypeMatch([
    getMyGroupsSuccess,
    getGroupRequestsSuccess,
    getGroupInvitationsSuccess,
    getPublicGroupsSuccess,
    searchSuccess,
    getActivitySuccess
], action.type);

export const hasGroup = (action) => doesActionTypeMatch([
    addGroupSuccess,
    updateGroupSuccess,
    getGroupSuccess,
    sendGroupInvitationSuccess,
    sendGroupInvitationsSuccess,
    updateBooksInGroupSuccess,
    acceptGroupInvitationSuccess,
    refuseGroupInvitationSuccess,
    leaveGroupSuccess,
    removeGroupMemberSuccess,
    cancelGroupRequestSuccess,
    acceptGroupRequestSuccess,
    refuseGroupRequestSuccess,
    removeBookFromGroupSuccess,
    addAdminSuccess,
    removeAdminSuccess,
    addGroupCollectionSuccess,
    deleteGroupCollectionSuccess,
    getGroupCollectionSuccess
], action.type);

export const hasGroupBookAttributes = (action) => doesActionTypeMatch([
    getGroupSuccess,
    updateBooksInGroupSuccess
], action.type)

export const loginOrSignup = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess
], action.type);

export const hasLend = (action) => doesActionTypeMatch([
    finaliseBorrowRequestSuccess,
    requestReturnBookSuccess,
    returnBookSuccess,
    lendBookSuccess,
    getBookSuccess
], action.type);

export const hasLends = (action) => doesActionTypeMatch([
    getGroupSuccess,
    getUserSuccess, 
    getLendsSuccess
], action.type);

export const hasLists = (action) => doesActionTypeMatch([
    addBookSuccess,
    loginSuccess, 
    signupSuccess,
    updateBookSuccess,
    getUserSuccess,
    updateBookInListsSuccess,
    getListsSuccess,
    removeBookFromListSuccess,
    deleteListSuccess
], action.type);

export const hasList = (action) => doesActionTypeMatch([
    updateListSuccess, 
    addListSuccess,
    removeBookFromListSuccess,
    getListSuccess
], action.type);

export const hasMessage = (action) => doesActionTypeMatch([
    createDiscussionSuccess,
    sendMessageSuccess,
    messageReceived
], action.type);

export const hasDiscussion = (action) => doesActionTypeMatch([
    createDiscussionSuccess,
    messageReceived
], action.type);

export const hasShelves = (action) => doesActionTypeMatch([
    addBookSuccess, 
    deleteBookSuccess, 
    loginSuccess, 
    signupSuccess, 
    updateBookSuccess, 
    getUserSuccess, 
    getShelvesSuccess
], action.type);

export const hasShelf = (action) => doesActionTypeMatch([
    updateShelfSuccess,
    addShelfSuccess,
    getShelfSuccess
], action.type);

export const hasKeywords = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess,
    getKeywordsSuccess
], action.type)

export const hasGroupToRemove = (action) => doesActionTypeMatch([
    deleteGroupSuccess,
    removeGroupFromStore
], action.type)

export const hasBookToRemove = (action) => doesActionTypeMatch([
    deleteBookSuccess,
    removeBookFromStore
], action.type)

export const hasListToRemove = (action) => doesActionTypeMatch([
    deleteListSuccess,
    removeListFromStore
], action.type)

export const hasShelfToRemove = (action) => doesActionTypeMatch([
    deleteShelfSuccess,
    removeShelfFromStore
], action.type)

export const hasUserFullProfile = (action) => doesActionTypeMatch([
    getUserSuccess,
    signupSuccess,
    loginSuccess
], action.type)

export const hasGroupCollections = (action) => doesActionTypeMatch([
    getGroupSuccess,
    updateBooksInGroupSuccess,
    removeBookFromGroupSuccess,
    setGroupCollectionsOrderSuccess,
    getActivitySuccess
], action.type)

export const hasGroupCollection = (action) => doesActionTypeMatch([
    getGroupCollectionSuccess,
    addGroupCollectionSuccess,
    updateGroupCollectionSuccess,
    updateBooksInGroupCollectionSuccess
], action.type)

export const hasGroupCollectionToRemove = (action) => doesActionTypeMatch([
    deleteGroupCollectionSuccess,
    removeGroupCollectionFromStore
], action.type) 

export const hasUnreadNotifs = (action) => doesActionTypeMatch([
    getUnreadNotifsSuccess,
    updateUnreadNotifsSuccess
], action.type)

export const hasAccount = (action) => doesActionTypeMatch([
    updateAccountSuccess,
    setHasSeenSuccess
], action.type)

export const hasAccountParam = (action) => doesActionTypeMatch([
    setNewsletterSuccess,
    setNotifsByEmailSuccess,
    setMessagesByEmailSuccess,
    setLendBookDurationInDaysSuccess,
    setFoundInSearchSuccess,
    setSortLibraryBooksSuccess
], action.type)

export const hasAccountProp = (action) => doesActionTypeMatch([
    setPrivateSuccess,
    setExpoTokenSuccess,
    setPreferredLangSuccess
], action.type)

export const hasUserKeyword = (action) => doesActionTypeMatch([
    addUserKeywordSuccess,
    updateUserKeywordSuccess
], action.type)

export const hasUserKeywords = (action) => doesActionTypeMatch([
    loginSuccess,
    signupSuccess,
    getBookSuccess,
    getGroupSuccess
], action.type)