import { SET_SORT_LIBRARY_BOOKS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setSortLibraryBooksInProgress = createAction('SET_SORT_LIBRARY_BOOKS_IN_PROGRESS');
export const setSortLibraryBooksSuccess = createAction('SET_SORT_LIBRARY_BOOKS_SUCCESS');
export const setSortLibraryBooksFailure = createAction('SET_SORT_LIBRARY_BOOKS_FAILURE');

export function setSortLibraryBooksApi({ value, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { sortLibraryBooks: value },
            actions: [setSortLibraryBooksInProgress, setSortLibraryBooksSuccess, setSortLibraryBooksFailure],
            name: 'setSortLibraryBooks',
            showLoading,
            dispatch,
            getState
        })
    }
}