import React, { useMemo, memo } from 'react';
import { View } from 'react-native';
import { sortListsAlphabetically } from '../utils';
import { List } from '../screens/Library/ListsScreen';
import { useListBooks } from '../hooks';

export default function UserLists({ userId, lists, navigation}) {
    const sortedList =  useMemo(() => sortListsAlphabetically(lists), [])
    return (
        <View>
            { sortedList.map(list => (
                <UserList 
                    key={list.id} 
                    list={list}
                    userId={userId} 
                    navigation={navigation} /> 
                )
            )}
        </View>
    );
}

const UserList = memo(({ list, userId, navigation }) => {
    const books = useListBooks(list.sort, list.bookIds);
    return (
        <List 
            list={list} 
            userId={userId}
            books={books}
            context='user'
            contextId={userId}
            navigation={navigation} />
    )
})