import React, { useEffect, useState, memo } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { useSelector, shallowEqual } from 'react-redux';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming,
    Easing,
    runOnJS
} from 'react-native-reanimated';
import { MAIN_COLOR } from '../constants';

function Loader() {
    const opacity = useSharedValue(null);
    const scale = useSharedValue(0.9);
    const [showLoader, setShowLoader] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const { isLoading, showLoading } = useSelector(state => state.loading, shallowEqual);
    const shouldShowLoading = isLoading && showLoading;
    const enterAnimationCompleted = () => setLoaderVisible(true);
    const exitAnimationCompleted = () => setLoaderVisible(false);
    useEffect(() => {
        if(shouldShowLoading && !showLoader) {
            setShowLoader(true);
            opacity.value = 1;
            scale.value = 1;
        }
        if(!shouldShowLoading && showLoader && loaderVisible) {
            opacity.value = 0;
            scale.value = 0.9;
        }
        if(!shouldShowLoading && showLoader && !loaderVisible && opacity.value == 0) {
            setShowLoader(false);
            opacity.value = null;
        }
    }, [shouldShowLoading, showLoader, loaderVisible, opacity]);
    const containerStyle = useAnimatedStyle(() => {
        if(opacity.value == null) return { opacity: 0 }
        return {
            opacity: withTiming(opacity.value, {
                duration: 200,
                easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            }, () => { 
                opacity.value ? 
                    runOnJS(enterAnimationCompleted)() :
                    runOnJS(exitAnimationCompleted)();
            }),
        };
    });
    const spinnerStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: withTiming(scale.value, {
                duration: 300,
                easing: Easing.bezier(0.25, 0.1, 0.25, 1)
            })}],
        };
    });
    return (
        <Animated.View style={[styles.container, { height: showLoader ? 'auto': 0 }, containerStyle]}>
            <Animated.View style={[styles.spinnerContainer, spinnerStyle]}>
                <ActivityIndicator color={ MAIN_COLOR } size="large" />
            </Animated.View>
        </Animated.View>
    )
}

export default memo(Loader);

const styles = StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
    spinnerContainer: {
        borderRadius: 10,
        padding: 15,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.85)'
    }
});