import React, { useRef, memo } from 'react';
import { useLinkPseudos } from '../hooks';
import InterReg from './InterReg';

const TextLink = memo(props => {
    const ref = useRef(null);
    const [showHover, showFocused, showActive] = useLinkPseudos(ref, true);
    return (
        <InterReg 
            ref={ref}
            {...props}
            showHover={ showHover }
            showFocused={ showFocused }
            showActive={ showActive } />
    )
})

export default TextLink;