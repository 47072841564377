import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { MAIN_COLOR } from '../constants';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import TitleLine from './TitleLine';
import Button from './Button';
import { hideAlert } from '../actions';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getResponsiveValue } from '../utils'
import { navigation } from '../navigation/RootNavigation';

import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'alerts.promptLoginOrSignup';

export default function PromptLoginOrSignup({ dispatch }) {
    const {t} = useContext(AppSettingsContext);
    const handleLoginPress = () => {
        dispatch(hideAlert());
        navigation.navigate('Auth', { screen: 'SignIn' });
    }
    const handleSignUpPress = () => {
        dispatch(hideAlert());
        navigation.navigate('Auth', { screen: 'SignUp' });
    }
    const paddingHorizontal = getResponsiveValue(['15', '15', '20', '40'])
    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column', alignItems: 'center'}}> 
                <MaterialCommunityIcons name="account-lock" size={40} color="white" />
                <MontSBold text={ t(`${tBase}.title`) } style={styles.title}/>
            </View>
            <InterReg text={ t(`${tBase}.subTitle`)} style={[styles.subTitle, { paddingHorizontal}]} />
            <View style={{ width: '100%', marginBottom: 20 }}>
                <Button 
                    size='medium'
                    theme='whiteColor'
                    text={ t(`${tBase}.signup`) }
                    textStyle={{ color: MAIN_COLOR }}
                    buttonStyle={styles.button} 
                    onPress={handleSignUpPress} />
                <TitleLine 
                    title={ t(`${tBase}.or`) } 
                    titleStyle={{ color: 'rgba(255,255,255,06)', backgroundColor: MAIN_COLOR }} 
                    lineStyle={{ borderTopColor: 'rgba(255,255,255,0.4)' }}
                    style={{ marginTop: 20, marginBottom: 20 }} />
                <Button
                    size='medium'
                    text={ t(`${tBase}.login`) }
                    theme='whiteColorBorder' 
                    buttonStyle={[styles.button, { backgroundColor: 'transparent' }]}
                    onPress={handleLoginPress} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column', 
        paddingHorizontal: 18,
        paddingTop: 8,
        alignItems: 'center'
    },
    title: { 
        color: '#fff',
        textAlign: 'center', 
        fontSize: 18, 
        paddingHorizontal: 17,
        marginTop: 10,
        marginBottom: 12 
    },
    subTitle: {
        color: 'rgba(255,255,255,0.85)',
        textAlign: 'center', 
        paddingBottom: 20,
        marginBottom: 10,
        fontSize: 15
    },
    button: {
        width: '100%',
        maxWidth: 260, 
        alignSelf: 'center',
        marginBottom: 0
    }
})