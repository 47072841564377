import React, {memo, useRef, useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { Pressable, View, StyleSheet, Platform } from 'react-native';
import { Entypo, MaterialCommunityIcons as MCIcons, Ionicons } from '@expo/vector-icons';
import MontSBold from '../components/MontserratBold';
import Badge from '../components/Badge';
import { SECONDARY_COLOR, MAIN_COLOR } from '../constants';
import { useHover } from 'react-native-web-hooks';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { navigateToHomeTab, navigateToLibraryTab, navigateToLendsTab, navigateToGroupsTab } from '../utils';
import { useUnreadNotifs } from '../hooks';
import { setActiveBottomTab } from '../actions';
import AppSettingsContext from '../context/AppSettingsContext';

const NAV_ITEMS = [
    {
        label: 'tabs.home.tabBarLabel',
        iconName: 'home',
        iconSize: 20,
        Icon: Entypo,
        navKey: 'HomeTab'
    },
    {
        label: 'tabs.library.tabBarLabel',
        iconName: 'library',
        iconSize: 18,
        iconStyle: { marginLeft: 1 },
        Icon: Ionicons,
        navKey: 'LibraryTab'
    },
    {
        label: 'tabs.lends.tabBarLabel',
        iconName: 'swap-horizontal',
        iconSize: 27,
        iconStyle: { marginLeft: -2 },
        Icon: MCIcons,
        navKey: 'LendsTab'
    },
    {
        label: 'tabs.groups.tabBarLabel',
        iconName: 'account-group',
        iconSize: 22,
        Icon: MCIcons,
        navKey: 'GroupsTab'
    },
]

const navigateToTabStartScreen = (navigation) => ({
    'HomeTab':      () => { navigateToHomeTab(navigation, 'Activity') },
    'LibraryTab':   () => { navigateToLibraryTab(navigation, 'Books') },
    'LendsTab':     () => { navigateToLendsTab(navigation, 'Borrowing') },
    'GroupsTab':    () => { navigateToGroupsTab(navigation, 'PublicGroups') },
})

const DrawerMainNav = memo(({ navigation }) => {
    const dispatch = useDispatch();
    const initialTab = (() => {
        const state = navigation.getState();
        return state?.routes[0]?.state?.routes[0]?.name || 'HomeTab';
    })()
    const [activeTab, setActiveTab] = useState(initialTab)
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    useEffect(() => { 
        if(initialTab && activeBottomTab !== initialTab) {
            dispatch(setActiveBottomTab(initialTab)); 
        }
    }, [])
    useEffect(() => {
        if(activeBottomTab !== null && activeTab !== activeBottomTab) {
            setActiveTab(activeBottomTab);
        }
    }, [activeBottomTab])
    const handleOnPress = useCallback((navKey) => { 
        if(navKey == activeTab && navigation.canGoBack()) {
            navigation.popToTop();
        } else if(navKey == activeTab && !navigation.canGoBack()) {
            navigateToTabStartScreen(navigation)[navKey]();
            setActiveTab(navKey);
        } else if(navKey != activeTab) {
            navigation.navigate(navKey);
            setActiveTab(navKey);
        }
    }, [activeTab])
    const {
        unreadLendsTabCount,
        unreadGroupsTabCount
    } = useUnreadNotifs();
    const unreadCounts = useMemo(() => {
        return {
            'LendsTab': unreadLendsTabCount,
            'GroupsTab': unreadGroupsTabCount,
        }
    }, [unreadLendsTabCount, unreadGroupsTabCount])
    return (
        <View style={styles.container}>
            { NAV_ITEMS.map((props, index) => {
                return (
                    <Item 
                        {...props} 
                        key={index} 
                        unreadCounts={unreadCounts} 
                        activeTab={activeTab} 
                        onPress={handleOnPress} />
                );
            })}
        </View>
    )
}) 

const Item = memo(({Icon, iconName, iconSize, label, iconStyle={}, navKey, activeTab, onPress, unreadCounts}) => {
    const {t} = useContext(AppSettingsContext);
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const isActive = activeTab == navKey;
    const handleItemPress = () => { onPress(navKey) };
    const unreadCount = unreadCounts[navKey] || 0;
    return (
        <Pressable 
            ref={ref}
            style={[styles.item, { backgroundColor: isActive || isHovered ? '#fff': 'transparent'}]} 
            onPress={ handleItemPress }>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ width: 34 }}>
                    <Icon name={ iconName } style={iconStyle} size={iconSize} color={isActive ? MAIN_COLOR: SECONDARY_COLOR } />
                </View>
                <MontSBold text={t(label)} style={[styles.label, { color: isActive ? MAIN_COLOR: SECONDARY_COLOR}]} />
            </View>
            { unreadCount > 0 && <Badge count={unreadCount} style={styles.badge} /> }
        </Pressable>
    )
})

export default DrawerMainNav;

const styles = StyleSheet.create({
    container: {
        marginVertical: 30,
    },
    item: {
        flexDirection: 'row',
        paddingVertical: 10,
        alignItems: 'center',
        marginHorizontal: 10,
        borderRadius: 10,
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        height: 44,
        paddingLeft: 20,
        marginBottom: 8,
        ...Platform.select({
            web: {
                transitionDuration: '200ms'
            },
            default: {},
        }),
    },
    label: {
        fontSize: 15,
        lineHeight: 24
    },
    badge: {
        position: 'relative',
        marginLeft: 'auto',
        borderRadius: 5,
        marginRight: 12
    },
    badgeNbr: {
        color: '#fff',
        fontSize: 12
    }
})