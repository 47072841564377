import React, { useState, useCallback, memo, useContext } from 'react';
import { Pressable, View, TouchableOpacity } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Entypo, MaterialCommunityIcons as MCIcons, Ionicons } from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';
import TabAddButton from './TabAddButton';
import InterReg from '../components/InterReg';
import { useUnreadNotifs } from '../hooks';
import { MAIN_COLOR, GRAY_LINE_COLOR, TAB_HEIGHT } from '../constants';
import { useSelector, shallowEqual } from 'react-redux';
import { getResponsiveValue } from '../utils';

export default memo(function TabBar(props) {
    const [addIsOpen, setAddIsOpen] = useState(false);
    const insets = useSafeAreaInsets();
    const { height } = useSelector(state => state.settings.window, shallowEqual);
    const handleSetAddOpen = useCallback((value) => setAddIsOpen(value));
    const handleBackdropPress = () => setAddIsOpen(false);
    return (
        <Pressable 
            style={{ position: 'absolute', left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', marginTop: 'auto', height: addIsOpen ? height: TAB_HEIGHT+insets.bottom }}
            onPress={ handleBackdropPress }>
            <View style={{ height: TAB_HEIGHT+insets.bottom, backgroundColor: '#fff' }}>
                <View style={{ flexDirection: 'row', borderTopWidth: 1, borderTopColor: GRAY_LINE_COLOR, marginBottom: insets.bottom, alignItems: 'center', height: TAB_HEIGHT }}>
                    <StaticTabBar {...props} setAddIsOpen={handleSetAddOpen} />
                </View>
            </View>
            <TabAddButton 
                navigation={props.navigation}
                isOpen={ addIsOpen } 
                setIsOpen={ handleSetAddOpen } 
            />
        </Pressable>
    )
})

const StaticTabBar = memo(({ state, descriptors, navigation, setAddIsOpen }) => { 
    const {t} = useContext(AppSettingsContext);
    const { 
        lendsTabHasUnreadNotifs,
        groupsTabHasUnreadNotifs
    } = useUnreadNotifs();
    const fontSize = getResponsiveValue([12, 12, 13], 'x');
    return (
        <>
            {state.routes.map((route, index) => {
                const { options } = descriptors[route.key];
                const label =
                options.tabBarLabel !== undefined
                    ? t(options.tabBarLabel)
                    : options.title !== undefined
                    ? options.title
                    : route.name;

                const isFocused = state.index === index;

                const onPress = () => {
                    if(!isFocused) {
                        navigation.navigate(route.name);
                    } else {
                        navigation.popToTop();
                    }
                    setAddIsOpen(false);
                };

                const onLongPress = () => {
                    setAddIsOpen(false);
                    navigation.emit({
                        type: 'tabLongPress',
                        target: route.key,
                    });
                };
                const iconProps = {
                    color: isFocused ? MAIN_COLOR : '#98a9b1',
                    style: { marginBottom: -3, height: 33 }, 
                    label
                }
                let icon = null;
                let top = 0;
                switch(route.name) {
                    case "HomeTab":
                        icon = <Entypo   {...iconProps} size={28} name="home"  />;
                        break;
                    case "LibraryTab":
                        icon = <Ionicons {...iconProps} size={28} name="library" />;
                        break;
                    case "LendsTab":
                        icon = <MCIcons  {...iconProps} size={36} style={{ marginBottom: -1}} name="swap-horizontal" />;
                        top = -3;
                        break;
                    case "GroupsTab":
                        icon = <MCIcons  {...iconProps} size={33} style={{ marginBottom: -1}} name="account-group" />;
                        top = -1;
                        break;
                }
                let showUnread = (() => {
                    switch(route.name) {
                        case "LendsTab":
                            return lendsTabHasUnreadNotifs;
                        case "GroupsTab":
                            return groupsTabHasUnreadNotifs;
                        default:
                            return false;
                    }
                })()
                if(route.name == 'Add') return (<View key={index} style={{ flex: 1 }}></View>)
                return (
                    <TouchableOpacity
                        style={{ flex: 1, alignItems: 'center' }}
                        accessibilityRole="button"
                        accessibilityState={isFocused ? { selected: true } : {}}
                        accessibilityLabel={options.tabBarAccessibilityLabel}
                        testID={options.tabBarTestID}
                        onPress={onPress}
                        onLongPress={onLongPress}
                        key={index}
                    >
                        { icon }
                        { showUnread && <View style={{ position: 'absolute', top: 8, right: 14, width: 7, height: 7, borderRadius: 7, backgroundColor: MAIN_COLOR }} /> }
                        <InterReg 
                            text={label} 
                            style={{ 
                                color: isFocused ? MAIN_COLOR : '#707D84', 
                                fontFamily: 'Inter_400Regular', 
                                paddingBottom: 2, 
                                fontSize,
                                position: 'relative',
                                top
                            }} />
                    </TouchableOpacity>
                );
            })}
        </>
    )
}, (prevProps, nextProps) => {
    return prevProps.state.index === nextProps.state.index;
})