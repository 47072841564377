import { ADD_ADMIN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addAdminInProgress = createAction('ADD_ADMIN_IN_PROGRESS');
export const addAdminSuccess = createAction('ADD_ADMIN_SUCCESS');
export const addAdminFailure = createAction('ADD_ADMIN_FAILURE');

export function addAdminApi({ userId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId, groupId },
            actions: [addAdminInProgress, addAdminSuccess, addAdminFailure],
            name: 'addAdmin',
            dispatch,
            getState
        })
    }
}