import { REFUSE_FOLLOW_USER_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const refuseFollowUserRequestInProgress = createAction('REFUSE_FOLLOW_USER_REQUEST_IN_PROGRESS');
export const refuseFollowUserRequestSuccess = createAction('REFUSE_FOLLOW_USER_REQUEST_SUCCESS');
export const refuseFollowUserRequestFailure = createAction('REFUSE_FOLLOW_USER_REQUEST_FAILURE');

export function refuseFollowUserRequestApi({ followRequestId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { followRequestId },
            actions: [refuseFollowUserRequestInProgress, refuseFollowUserRequestSuccess, refuseFollowUserRequestFailure],
            name: 'refuseFollowUserRequest',
            dispatch,
            getState
        })
    }
}