import React, { useEffect, useState, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import AppSettingsContext from '../context/AppSettingsContext';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import GroupPicture from './GroupPicture';
import { GRAY_LINE_COLOR, MAIN_COLOR, SECONDARY_COLOR } from '../constants';
import { acceptGroupInvitationApi } from '../actions/acceptGroupInvitation';
import { refuseGroupInvitationApi } from '../actions/refuseGroupInvitation';
import { navigateToGroup } from '../utils';
import { useGroup, useUnreadNotifs } from '../hooks';
import Button from './Button';
import ItemMultiColumn from './ItemMultiColumn';

const tBase = 'group.invitation';

export default function GroupInvitation(props) {
    const { groupInvitation, navigation } = props;
    const {t, l} = useContext(AppSettingsContext);
    const [isUnread, setIsUnread] = useState(false);
    const { groupId, id:groupInvitationId, created } = groupInvitation;
    const dispatch = useDispatch();
    const group = useGroup(groupId);
    const {unreadGroupInvitations} = useUnreadNotifs();
    const handleAcceptInvitation = () => { dispatch(acceptGroupInvitationApi({ groupId, groupInvitationId })) }
    const handleRefuseInvitation = () => { dispatch(refuseGroupInvitationApi({ groupId, groupInvitationId })) }
    const handleGroupPress = () => { navigateToGroup(navigation, group); }

    useEffect(() => {
        if(unreadGroupInvitations.includes(groupInvitation.id)) {
            setIsUnread(true);
        }
    }, [unreadGroupInvitations]);

    if(!group) return <View />
    return (
        <ItemMultiColumn {...props} itemIsUnread={isUnread}>
            <TouchableOpacity onPress={handleGroupPress}  style={{ flexDirection: 'row'}}>
                <GroupPicture picture={group.picture} blurHash={ group.blurHash } isPremium={group?.echelon?.includes('premium')} size={70} />
                <View style={{ flex: 1, marginLeft: 10 }}>
                    <MontSBold style={styles.name} numberOfLines={2} text={ group.name } />
                    <InterReg style={styles.description} numberOfLines={2} text={ group.description } />
                </View>
            </TouchableOpacity>
            <View style={styles.actionContainer}>
                <Text style={styles.actionInfo}>
                    <InterReg text={ t(`${tBase}.youWereInvited`)} />
                    <InterReg style={{ color: '#333' }} text={t(`${tBase}.onThe`)} />
                    <MontSBold style={{ color: MAIN_COLOR }} text={ l(`date.formats.short`, created) } />
                </Text>
                <View style={{ flexDirection: 'row' }}>
                    <Button
                        size='xSmall'
                        onPress={handleAcceptInvitation}
                        buttonStyle={[styles.buttons, { marginRight: 8 }]}  
                        text={ t(`${tBase}.accept`)} />
                    <Button 
                        size='xSmall'
                        theme='grayColorBorder'
                        onPress={handleRefuseInvitation}
                        buttonStyle={styles.buttons} 
                        text={ t(`${tBase}.refuse`)} />
                </View>
            </View>
        </ItemMultiColumn>
    )
}

const styles = StyleSheet.create({
    name: {
        color: SECONDARY_COLOR, 
        marginTop: -2, 
        lineHeight: 16, 
        marginBottom: 3
    },
    description: {
        color: '#666'
    },
    actionContainer: {
        flexDirection: 'row', 
        marginTop: 10, 
        paddingTop: 10, 
        borderTopWidth: StyleSheet.hairlineWidth, 
        borderTopColor: GRAY_LINE_COLOR
    },
    actionInfo: {
        flex: 1, 
        paddingRight: 15, 
        marginTop: -2
    },
    buttons: {
        paddingHorizontal: 10, 
        marginBottom: 0
    }
})