import { GET_SHELVES_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getShelvesInProgress = createAction('GET_SHELVES_IN_PROGRESS');
export const getShelvesSuccess = createAction('GET_SHELVES_SUCCESS');
export const getShelvesFailure = createAction('GET_SHELVES_FAILURE');

export function getShelvesApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getShelvesInProgress, getShelvesSuccess, getShelvesFailure],
            name: 'getShelves',
            showLoading,
            checkCaching,
            dispatch,
            getState
        })
    }
}