import { LOGOUT_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const logoutInProgress = createAction('LOGOUT_IN_PROGRESS');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutFailure = createAction('LOGOUT_FAILURE');

export function logoutApi() {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [logoutInProgress, logoutSuccess, logoutFailure],
            name: 'logout',
            dispatch,
            getState
        })
    }
}