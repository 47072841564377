import React, { memo, useState, useContext} from 'react';
import { View, StyleSheet, Text } from 'react-native';
import InterReg from './InterReg';
import InterSemiBold from './InterSemiBold';
import { GRAY_LINE_COLOR, SECONDARY_COLOR_SUBTLE, SECONDARY_COLOR } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

function MenuGroupAdminHeader({ group }) {
    const {t} = useContext(AppSettingsContext);
    const [barWidth, setBarWidth] = useState(260);
    const onLayout = ({ nativeEvent: { layout: { width }}}) => setBarWidth(width);
    const renderQuota = (quotaKey, quotaConsumed) => {
        const quota = group.quotas[quotaKey];
        const width = quotaConsumed > 0 ? Math.max((barWidth * (quotaConsumed/quota)), 4) : 0;
        return (
            <>
                <View style={styles.quota}>
                    <InterSemiBold text={ t(`menus.group.header.titles.${quotaKey}`)} style={styles.label} />
                    <Text style={{ fontSize: 12 }}>
                        <InterReg text={quotaConsumed} style={styles.count} />
                        <InterReg text='/' style={styles.count} />
                        <InterReg text={quota} style={styles.count} />
                    </Text>
                </View>
                <View onLayout={onLayout} style={styles.bar}>
                    <View style={[{ width }, styles.progress]} />
                </View>
            </>
        )
    }
    return (
        <View style={styles.container}>
              { renderQuota('members', group.memberIds.length) }  
              { renderQuota('books', group.bookIds.length) }  
              { !!group.quotas.collections && renderQuota('collections', group.collectionIds.length) }  
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderTopRightRadius: 8, 
        borderTopLeftRadius: 8, 
        borderBottomColor: GRAY_LINE_COLOR, 
        paddingHorizontal: 15, 
        paddingTop: 17, 
        paddingBottom: 12, 
        backgroundColor: SECONDARY_COLOR_SUBTLE 
    },
    quota: {
        flexDirection: 'row', 
        alignItems: 'center'
    },
    label: {
        marginRight: 'auto', 
        color: SECONDARY_COLOR
    },
    count: {
        color: 'rgba(0,0,0,0.6)'
    },
    bar: {
        height: 6, 
        borderRadius: 6, 
        overflow: 'hidden', 
        backgroundColor: 'rgba(43, 69, 112, 0.1)', 
        marginTop: 5, 
        marginBottom: 10
    },
    progress: { 
        height: 6, 
        position: 'absolute', 
        left: 0,
        backgroundColor: SECONDARY_COLOR 
    }
})

export default memo(MenuGroupAdminHeader);