import { SEND_GROUP_INVITATION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const sendGroupInvitationInProgress = createAction('SEND_GROUP_INVITATION_IN_PROGRESS');
export const sendGroupInvitationSuccess = createAction('SEND_GROUP_INVITATION_SUCCESS');
export const sendGroupInvitationFailure = createAction('SEND_GROUP_INVITATION_FAILURE');

export function sendGroupInvitationApi({ groupId, email, userId, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId, userId, email },
            actions: [sendGroupInvitationInProgress, sendGroupInvitationSuccess, sendGroupInvitationFailure],
            name: 'sendGroupInvitation',
            dispatch,
            getState,
            showLoading
        })
    }
}