import React, { memo } from 'react';
import { StyleSheet } from 'react-native';
import BlurHash from './BlurHash';

function GroupHeaderBg({ width, height, blurhash }) {
    return (
        <BlurHash
            blurhash={blurhash}
            height={height}
            width={width}
            resizeMode='cover'
            style={StyleSheet.absoluteFillObject} />
    )
}

export default memo(GroupHeaderBg);