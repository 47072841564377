import { ADD_BOOK_REVIEW_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addBookReviewInProgress = createAction('ADD_BOOK_REVIEW_IN_PROGRESS');
export const addBookReviewSuccess = createAction('ADD_BOOK_REVIEW_SUCCESS');
export const addBookReviewFailure = createAction('ADD_BOOK_REVIEW_FAILURE');

export function addBookReviewApi({ bookId, body, tags, recommended }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId, body, tags, recommended },
            actions: [addBookReviewInProgress, addBookReviewSuccess, addBookReviewFailure],
            name: 'addBookReview',
            dispatch,
            getState
        })
    }
}