import i18n from '../utils/i18n';
import { Platform } from 'react-native';
import { NotifierComponents } from 'react-native-notifier';
import { DANGER_COLOR } from '../constants';
import { initialWindowMetrics } from 'react-native-safe-area-context';

const defaultProps = {
    translucentStatusBar: false
}

const styles = {
    titleStyle: {
        fontFamily: 'Montserrat_700Bold',
        fontSize: 14,
        paddingTop: Platform.OS === 'android' ? initialWindowMetrics?.insets?.top : 0
    },
    descriptionStyle: {
        fontFamily: 'Inter_400Regular'
    }
}

const warningAlert = {
    ...defaultProps,
    Component: NotifierComponents.Alert,
    componentProps: {
    	alertType: 'warn',
        ...styles,
  	}
}

const errorAlert = {
    ...defaultProps,
    Component: NotifierComponents.Alert,
    componentProps: {
    	alertType: 'error',
        backgroundColor: DANGER_COLOR,
        ...styles
  	}
}

const successAlert = {
    ...defaultProps,
    Component: NotifierComponents.Alert,
    componentProps: {
    	alertType: 'success',
        ...styles
  	}
}

export const getNoInternetNotif = () => ({
    ...warningAlert,
    title: i18n.t('notifs.noInternet.title'),
    description: i18n.t('notifs.noInternet.description'),
    duration: 5000,
})

export const getApiErrorNotif = (json) => {
    let title = json.msg || i18n.t('notifs.generic.title');
    let description = json.description ? json.description : "";
    return {
        ...errorAlert,
        title,
        description,
        duration: 5000,
    }
}

export const getApiCatchNotif = (title, description) => ({
    ...errorAlert,
    title,
    description,
    duration: 5000,
})

export const getNoCameraRollAccessNotif = () => ({
    ...warningAlert,
    title: i18n.t('notifs.noCameraAccess.title'),
    description: i18n.t('notifs.noCameraAccess.description'),
    duration: 5000
})

export const getImageProblemNotif = () => ({
    ...warningAlert,
    title: i18n.t('notifs.noCameraAccess.title'),
    description: i18n.t('notifs.noCameraAccess.description'),
    duration: 5000
})

export const getNoCameraAccessNotif = () => ({
    ...warningAlert,
    title: i18n.t('notifs.noCameraAccess.title'),
    description: i18n.t('notifs.noCameraAccess.description'),
    duration: 5000
})

export const getFormErrorNotif = (title, description='') => ({
    ...errorAlert,
    title,
    description,
    duration: 7000
})

export const getWarningNotif = ({title, description='', duration=7000}) => ({
    ...warningAlert,
    title,
    description,
    duration
})

export const getSuccessNotif = ({title, description='', duration=7000}) => ({
    ...successAlert,
    title,
    description,
    duration
})