import { createReducer } from '@reduxjs/toolkit'
import { deleteBookReviewSuccess } from '../actions/deleteBookReview';
import { hasBookReview, hasBookReviews, matchResetStore } from './matchers';

const initialState = {};

export const bookReviews = createReducer(initialState, (builder) => {
    builder
    .addCase(deleteBookReviewSuccess, (state, action) => {
        delete state[action.payload.bookReviewId];
    })
    .addMatcher(hasBookReview, (state, action) => {
        const { bookReview } = action.payload; 
        state[bookReview.id] = bookReview;
    })
    .addMatcher(hasBookReviews, (state, action) => {
        const { bookReviews } = action.payload; 
        bookReviews.forEach(bookReview => state[bookReview.id] = bookReview);
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})