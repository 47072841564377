import React, { memo, useEffect, useState } from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import MontserratBold from '../components/MontserratBold';
import { Feather, MaterialIcons as MIcons } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { SECONDARY_COLOR } from '../constants';
import { isNative } from '../utils';

export const HEADER_HEIGHT = 40;
const PRIVATE_ICON_WIDTH = 22;

function AppHeader({ 
        headerLeft,
        headerRight,
        title, 
        titleStyle={},
        isPrivate=false,
        navigation,
        headerLeftStyle={}, 
        headerRightStyle={},
        handleBack 
    }) {
    const insets = useSafeAreaInsets();
    const [headerLeftWidth, setHeaderLeftWidth] = useState(null);
    const [headerRightWidth, setHeaderRightWidth] = useState(null);
    const [titleFitsOnOneLine, setTitleFitsOnOneLine] = useState(null);
    const [titleWrapStyle, setTitleWrapStyle] = useState({ left: HEADER_HEIGHT, right: HEADER_HEIGHT })
    const paddingTop = Math.max(insets.top, 3);
    const makeOnPress = () => handleBack ? handleBack : navigation.goBack;
    const onLayoutRight = ({ nativeEvent: { layout: { width }} }) => setHeaderRightWidth(width);
    const onLayoutLeft = ({ nativeEvent: { layout: { width }} }) => setHeaderLeftWidth(width);
    const onTextLayout = (e) => {
        if(headerLeftWidth && headerRightWidth && titleFitsOnOneLine === null) {
            setTitleFitsOnOneLine(e.nativeEvent.lines.length === 1)
        }
    }
    useEffect(() => {
        const widest = (() => {
            let width = null;
            if(headerLeftWidth && headerRightWidth) {
                width = Math.max(headerLeftWidth, headerRightWidth);
            }
            return width;
        })()
        if(widest && (titleFitsOnOneLine || titleFitsOnOneLine == null)) {
            setTitleWrapStyle({
                left: widest + (isPrivate ? PRIVATE_ICON_WIDTH/2 : 8),
                right: widest + (isPrivate ? PRIVATE_ICON_WIDTH/2 : 8),
            })
        } else if(widest && titleFitsOnOneLine === false) {
            setTitleWrapStyle({
                left: headerLeftWidth + (isPrivate ? PRIVATE_ICON_WIDTH/2 : 8),
                right: headerRightWidth + (isPrivate ? PRIVATE_ICON_WIDTH/2 : 8),
            })
        }
    }, [headerLeftWidth, headerRightWidth, titleFitsOnOneLine])
    return (
        <View style={[styles.container, { paddingTop, height: HEADER_HEIGHT + paddingTop }]}>
            <View onLayout={ onLayoutLeft } style={styles.headerLeft}>
            { headerLeft ?
                headerLeft() :
                <Pressable
                    onPress={ makeOnPress() }>
                    <Feather name="chevron-left" size={34} color={ SECONDARY_COLOR } />
                </Pressable>
            }
            </View>
            <View pointerEvents='none' style={[styles.title, titleWrapStyle]}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center'}}>
                <MontserratBold 
                    text={ title }
                    onTextLayout={onTextLayout}
                    numberOfLines={titleFitsOnOneLine === null && isNative() ? 2 : 1}
                    style={[styles.titleText, titleStyle]} />
                { isPrivate && <View style={{ paddingLeft: 5 }}><MIcons name='lock' size={16} color={SECONDARY_COLOR} /></View> }
                </View>
            </View>
            { headerRight ?
                <View onLayout={ onLayoutRight } style={[styles.headerRight, headerRightStyle]}>
                    { headerRight() } 
                </View>
                : null
            }       
        </View>
    )
}

export default memo(AppHeader);

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        flexDirection: 'row', 
        alignItems: 'center'
    },  
    title: {
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 10
    },
    titleText: {
        fontSize: 17, 
        color: SECONDARY_COLOR,
        textAlign: 'center'
    },
    headerLeft: {
        height: HEADER_HEIGHT,
        minWidth: HEADER_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2
    },
    headerRight: {
        height: HEADER_HEIGHT,
        minWidth: HEADER_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 'auto'
    }
})