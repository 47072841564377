import { SEARCH_BOOK_PROVIDERS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const searchBookProvidersInProgress = createAction('SEARCH_BOOK_PROVIDERS_IN_PROGRESS');
export const searchBookProvidersSuccess = createAction('SEARCH_BOOK_PROVIDERS_SUCCESS');
export const searchBookProvidersFailure = createAction('SEARCH_BOOK_PROVIDERS_FAILURE');
export const clearBookProviderResultsAction = createAction('CLEAR_BOOK_PROVIDERS_RESULTS');
export const updateBookProvidersResult = createAction('UPDATE_BOOK_PROVIDERS_RESULT');

export function searchBookProvidersApi({ query }, method) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { query },
            actions: [searchBookProvidersInProgress, searchBookProvidersSuccess, searchBookProvidersFailure],
            name: 'searchBookProviders',
            dispatch,
            getState,
            addToPayload: { method, query }
        })
    }
}

export function clearBookProviderResults() {
    return (dispatch, getState) => {
    	dispatch(clearBookProviderResultsAction())
    	return Promise.resolve(true);
    }
}