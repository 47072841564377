import React, { useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { unfollowUserApi } from '../actions/unfollowUser';
import { followUserApi } from '../actions/followUser';
import { selectGroupRequestsToMe } from '../selectors';
import { cancelFollowUserRequestApi } from '../actions/cancelFollowUserRequest';
import { requestFollowUserApi } from '../actions/requestFollowUser';
import { removeGroupMemberApi } from '../actions/removeGroupMember';
import { addAdminApi } from '../actions/addAdmin';
import { removeAdminApi } from '../actions/removeAdmin';
import { menuUser } from '../utils/menuConfigs';
import { hasRequestedFollowUser, getFollowRequestByMeToUser } from '../utils/';
import { useStartDiscussion, useFollowRequestsForUser } from '../hooks';
import { openModalOrPushScreen } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';
import Menu from './Menu';

export default function MenuUser({ style, user, group, TriggerButton, navigation, context='detail', placement='auto' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const { id:userId, isPrivate } = user;
    const groupId = group?.id;
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const groupRequestForMe = useSelector(selectGroupRequestsToMe);
    const isLoggedUserGroupAdmin = group ? group.adminIds.includes(loggedUserId) : null;
    const isUserGroupAdmin = group?.adminIds.includes(user.id) ?? null;
    const isAllowedMultipleAdmins = group?.features?.multipleAdmins || false;
    const isLoggedIn = !!loggedUserId;
    const isYou = userId == loggedUserId;
    const isFollowing = user.followerIds.includes(loggedUserId);
    const isFollowed = user.followingIds.includes(loggedUserId);
    const followRequests = useFollowRequestsForUser(userId);
    const followRequestByMe = getFollowRequestByMeToUser(followRequests, userId, loggedUserId);
    const hasRequested = hasRequestedFollowUser(followRequests, userId, loggedUserId);
    const allowContactUser = isLoggedUserGroupAdmin && groupRequestForMe.find(({ fromId, groupId }) => fromId === user.id && groupId === group?.id);
    const onContactUser         = useStartDiscussion(navigation, user.id);
    const onEditMyProfile       = () => openModalOrPushScreen(navigation, 'UpdateProfile');
    const onViewMyProfile       = () => openModalOrPushScreen(navigation, 'Profile');
    const onViewUserProfile     = () => { navigation.push('User', { userId }) };
    const onUnfollow            = () => { dispatch(unfollowUserApi({ userId })) }
    const onFollow              = () => { dispatch(followUserApi({ userId })) }
    const onRequestFollow       = () => { dispatch(requestFollowUserApi({ userId })) };
    const onCancelFollowRequest = () => { dispatch(cancelFollowUserRequestApi({ followRequestId: followRequestByMe.id })) }
    const onRemoveFromGroup     = () => { dispatch(removeGroupMemberApi({ groupId, userId })) }
    const onAdminRemove         = () => { dispatch(removeAdminApi({ groupId, userId })) }
    const onAdminAdd            = () => { 
        if(isAllowedMultipleAdmins) {
            dispatch(addAdminApi({ groupId, userId })) 
        } else {
            openModalOrPushScreen(navigation, 'PremiumGroupOnly', { feature: 'multipleAdmins', groupId })
        }
    }
    return (
        <Menu
            placement={placement}
            menuWidth={280}
            menuOptions={ 
                menuUser({
                    isFollowing,
                    isFollowed,
                    isAllowedMultipleAdmins,
                    isUserGroupAdmin,
                    isYou,
                    isLoggedIn,
                    hasRequested,
                    allowContactUser,
                    isPrivate,
                    isLoggedUserGroupAdmin,
                    context,
                    dispatch,
                    nickname: user.nickname,
                    actions: { 
                        onFollow,
                        onUnfollow,
                        onEditMyProfile,
                        onViewMyProfile,
                        onCancelFollowRequest,
                        onRequestFollow,
                        onViewUserProfile,
                        onRemoveFromGroup,
                        onAdminAdd,
                        onContactUser,
                        onAdminRemove
                    },
                    t 
                }) 
            }
            style={ style }
            TriggerButton={ TriggerButton } />
    )
}