import React, { memo, useContext } from 'react';
import { View } from 'react-native';
import SelectMenu from './SelectMenu';
import { MaterialCommunityIcons as MCIcons} from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';

function MenuSort({ 
        options,
        onSelect, 
        selected,
        multiselect=false 
    }) {
    const {t} = useContext(AppSettingsContext);
    function changeOption(index) {
        const { value } = options[index];
        onSelect(value)
    }
    function getSortIconName() {
        switch(selected) {
            case 'title_asc':
            case 'collection_asc':
            case 'author_asc':
                return 'order-alphabetical-ascending';
            case 'title_desc':
            case 'collection_desc':
            case 'author_desc':
                return 'order-alphabetical-descending';
            case 'added_asc':
                return 'order-bool-ascending';
            case 'added_desc':
                return 'order-bool-descending';
        }
    }
    const renderTriggerButton = () => {
        return (
            <View style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center'}}>
                <MCIcons name={ getSortIconName() } color='#666' size={28} />
            </View>
        )
    }
    return (
        <SelectMenu 
            style={{ width: 30, height: 30 }}
            placement='bottom'
            TriggerButton={ renderTriggerButton }
            selected={ selected }
            options={ options.map(({label, value}) => ({ label: t(label), value })) }
            menuWidth={ 220 }
            multiselect={multiselect} 
            onSelect={ changeOption }
        /> 
    )
}

export default memo(MenuSort);
