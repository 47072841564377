import { ACCEPT_FOLLOW_USER_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const acceptFollowUserRequestInProgress = createAction('ACCEPT_FOLLOW_USER_REQUEST_IN_PROGRESS');
export const acceptFollowUserRequestSuccess = createAction('ACCEPT_FOLLOW_USER_REQUEST_SUCCESS');
export const acceptFollowUserRequestFailure = createAction('ACCEPT_FOLLOW_USER_REQUEST_FAILURE');

export function acceptFollowUserRequestApi({ followRequestId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { followRequestId },
            actions: [acceptFollowUserRequestInProgress, acceptFollowUserRequestSuccess, acceptFollowUserRequestFailure],
            name: 'acceptFollowUserRequest',
            dispatch,
            getState
        })
    }
}