import { DELETE_BOOK_REVIEW_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteBookReviewInProgress = createAction('DELETE_BOOK_REVIEW_IN_PROGRESS');
export const deleteBookReviewSuccess = createAction('DELETE_BOOK_REVIEW_SUCCESS');
export const deleteBookReviewFailure = createAction('DELETE_BOOK_REVIEW_FAILURE');

export function deleteBookReviewApi({ bookReviewId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookReviewId },
            actions: [deleteBookReviewInProgress, deleteBookReviewSuccess, deleteBookReviewFailure],
            name: 'deleteBookReview',
            dispatch,
            getState
        })
    }
}