import { createReducer } from '@reduxjs/toolkit'
import { getDiscussionsSuccess } from '../actions/getDiscussions';
import { setReadMessagesSuccess } from '../actions/setReadMessages';
import { hasMessage, matchResetStore } from './matchers';

const initialState = [];

export const messages = createReducer(initialState, (builder) => {
    builder
    .addCase(setReadMessagesSuccess, (state, action) => {
        action.payload.messages.forEach(message => {
            const index = state.findIndex(({id}) => id == message.id);
            if(index > -1) {
                state[index] = message;
            } else {
                state.push(message);
            }
        })
    })
    .addCase(getDiscussionsSuccess, (state, action) => {
        return action.payload.messages;
    })
    .addMatcher(hasMessage, (state, action) => {
        const {message} = action.payload;
        if(message.id) {
            const index = state.findIndex(({id}) => id == message.id)
            if(index > -1) {
                state[index] = message;
            } else {
                state.push(message);
            }
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})