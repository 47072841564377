import React, { useState, memo, useCallback, useRef } from 'react';
import { useScrollToTop } from '@react-navigation/native';
import { View, FlatList } from 'react-native';
import { useFlatListPadding, useGetGroupItemLayout } from '../hooks';
import GroupListItem from './GroupListItem';
import { navigateToGroup, keyExtractor, getInitialScreenWidth } from '../utils';

const ITEM_X_SPACING = 20;
const ITEM_Y_SPACING = 15;

function GroupsList({ navigation, data, onRefresh, refreshing, useStickyHeader=false, renderEmpty=() => null, renderListHeader=() => null }) {
    const ref = useRef(null);
    const [width, setWidth] = useState(getInitialScreenWidth(navigation));
    const handlePressGroup = useCallback((group) => navigateToGroup(navigation, group), [])
    const renderItem = ({item}) => (
        <GroupListItem 
            width={itemWidth}
            marginHorizontal={ITEM_X_SPACING/2}
            onPressGroup={handlePressGroup}
            group={item} 
            navigation={navigation} />
    )
    const onLayout = ({ nativeEvent: { layout } }) => {
        if(layout.width && layout.width != width) setWidth(layout.width);
    }
    const renderSeparator = () => (
        <View style={{ marginTop: ITEM_Y_SPACING }} />
    )
    const {paddingBottom, paddingTop, paddingHorizontal} = useFlatListPadding();
    const {columns, itemWidth} = useGetGroupItemLayout(width, paddingHorizontal, ITEM_X_SPACING);
    useScrollToTop(ref);
    return (
        <FlatList
            ref={ref}
            contentContainerStyle={{ flexGrow: 1, paddingBottom, paddingTop, paddingHorizontal }}
            data={data}
            key={columns}
            numColumns={columns}
            onLayout={onLayout}
            keyExtractor={keyExtractor}
            refreshing={refreshing}
            onRefresh={onRefresh}
            stickyHeaderIndices={useStickyHeader ? [0] : []}
            ItemSeparatorComponent={renderSeparator}
            ListHeaderComponent={renderListHeader}
            ListEmptyComponent={renderEmpty}
            renderItem={renderItem}
        />
    )
}

export default memo(GroupsList);