import React, { memo, useEffect, useState } from 'react';
import { View, Pressable } from 'react-native';
import Cover from './Cover';
import MontSBold from './MontserratBold';
import BookLendStatus from './BookLendStatus';
import BookLendActions from './BookLendActions';
import { SharedElement } from 'react-navigation-shared-element';
import { navigateToBook } from '../utils';
import { useBook, useUnreadNotifs } from '../hooks';
import ItemMultiColumn from './ItemMultiColumn';

function BookLend(props) {
    const { 
        lend, 
        borrowRequest, 
        navigation,
        containerStyle={}, 
        shouldShowContactButton=true, 
        context
    } = props;
    const [isUnread, setIsUnread] = useState(false);
    const bookId = lend?.bookId ?? borrowRequest?.bookId ?? undefined;
    const book = useBook(bookId);
    const handleBookPress = () => { navigateToBook(navigation, { bookId }) };
    const {
        unreadBorrowRequests,
        unreadBorrowRequestsAccepted,
        unreadLendReturnRequests,
        unreadLends
    } = useUnreadNotifs();
    
    useEffect(() => {
        const id = lend?.id ?? borrowRequest?.id;
        if(unreadBorrowRequests.includes(id) || 
           unreadBorrowRequestsAccepted.includes(id) ||
           unreadLendReturnRequests.includes(id) ||
           unreadLends.includes(id)) {
            setIsUnread(true);

        }
    }, [unreadBorrowRequests, unreadBorrowRequestsAccepted, unreadLendReturnRequests, unreadLends]);
    if(!book) return <View />;
    return (
        <ItemMultiColumn {...props} itemIsUnread={isUnread} containerStyle={{...containerStyle, paddingHorizontal: 15 }}>
            <View style={{ flexDirection: 'row' }}>
                <Pressable 
                    onPress={handleBookPress}
                    style={{  
                        shadowColor: 'black',
                        shadowOpacity: 0.15,
                        shadowOffset: { width: 0, height: 0 },
                        shadowRadius: 1,
                        marginRight: 13  }}>
                    <SharedElement id={`cover.${book.id}.photo`}>
                        <Cover 
                            cover={book.cover} 
                            blurHash={book.blurHash} 
                            coverHash={book.coverHash}
                            width={ 68 } />
                    </SharedElement>
                </Pressable>
                <View style={{ flex: 1 }}>
                    <MontSBold style={{ marginTop: -2 }} text={ book.title } numberOfLines={1}/>
                    <BookLendStatus 
                        context={context} 
                        borrowRequest={borrowRequest} 
                        lend={lend} 
                        navigation={navigation} />
                    <BookLendActions 
                        context={context} 
                        shouldShowContactButton={shouldShowContactButton}
                        borrowRequest={borrowRequest} 
                        lend={lend} 
                        navigation={navigation} 
                        book={book} />
                </View>
            </View>
        </ItemMultiColumn>
    )
}

export default memo(BookLend);