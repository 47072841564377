import React, { memo } from 'react'
import { StyleSheet } from 'react-native'
import Button from './Button';

const IsStatus = memo(({ 
        style={},
        theme='mainColorSubtle', 
        text='',
        iconName='checkmark-circle',
        iconSize=17,
        height=30,
        onLayout
    }) => {
        return (
            <Button 
                iconName={ iconName }
                theme={ theme } 
                iconSize={ iconSize }
                onLayout={ onLayout }
                buttonStyle={[ { height, borderRadius: height / 2}, styles.button, style ]} 
                textStyle={{ marginLeft: 5 }}
                size='xSmall' 
                text={ text } /> 
        )
})

export default IsStatus;

const styles = StyleSheet.create({
	button: {
        marginBottom: 0,
        paddingLeft: 7,
        paddingRight: 12,
        paddingTop: 1,
        marginBottom: 0,
        alignItems: 'center', 
        justifyContent: 'center',
    }
})