import React, { memo, useContext } from 'react';
import InterReg from './InterReg';
import Badge from './Badge';
import { StyleSheet, Pressable } from 'react-native';
import { GRAY_LINE_COLOR, SECONDARY_COLOR } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

function DrawerNavItem({ style={}, labelStyle={}, icon, label="", onPress, badge }) {
    const {t} = useContext(AppSettingsContext);
    return (
        <Pressable onPress={ onPress } style={[styles.container, style]}>
            { icon() }
            <InterReg style={[styles.label, labelStyle ]} text={ t(label) } />
            { !!badge && <Badge count={badge} size={17} style={ styles.badge } /> }
        </Pressable>
    )
}

export default memo(DrawerNavItem, (prevProps, nextProps) => {
    return prevProps.label === nextProps.label && prevProps.badge === nextProps.badge;
})

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: GRAY_LINE_COLOR,
        paddingVertical: 10,
        alignItems: 'center',
        height: 44,
        paddingLeft: 33
    },
    label: {
        color: SECONDARY_COLOR,
        marginLeft: 12,
        fontSize: 15
    },
    badge: {
        position: 'relative',
        borderRadius: 5,
        marginLeft: 'auto',
        marginRight: 12
    },
    badgeNbr: {
        color: '#fff',
        fontSize: 12
    }
})
