import { SET_EXPO_TOKEN_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setExpoTokenInProgress = createAction('SET_EXPO_TOKEN_IN_PROGRESS');
export const setExpoTokenSuccess = createAction('SET_EXPO_TOKEN_SUCCESS');
export const setExpoTokenFailure = createAction('SET_EXPO_TOKEN_FAILURE');

export function setExpoTokenApi(tokenExpo) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { tokenExpo },
            actions: [setExpoTokenInProgress, setExpoTokenSuccess, setExpoTokenFailure],
            name: 'setExpoToken',
            dispatch,
            showLoading: false,
            getState
        })
    }
}