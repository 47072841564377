import { REFUSE_GROUP_INVITATION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const refuseGroupInvitationInProgress = createAction('REFUSE_GROUP_INVITATION_IN_PROGRESS');
export const refuseGroupInvitationSuccess = createAction('REFUSE_GROUP_INVITATION_SUCCESS');
export const refuseGroupInvitationFailure = createAction('REFUSE_GROUP_INVITATION_FAILURE');

export function refuseGroupInvitationApi({ groupInvitationId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupInvitationId, groupId },
            actions: [refuseGroupInvitationInProgress, refuseGroupInvitationSuccess, refuseGroupInvitationFailure],
            name: 'refuseGroupInvitation',
            dispatch,
            getState
        })
    }
}