import { GET_USER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getUserInProgress = createAction('GET_USER_IN_PROGRESS');
export const getUserSuccess = createAction('GET_USER_SUCCESS');
export const getUserFailure = createAction('GET_USER_FAILURE');

export function getUserApi({ userId, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId },
            actions: [getUserInProgress, getUserSuccess, getUserFailure],
            name: 'getUser',
            dispatch,
            getState,
            showLoading
        })
    }
}