import React, { useEffect, useMemo, memo, useContext } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Feather } from '@expo/vector-icons';
import AppHeader from '../navigation/AppHeader';
import FilterStateHeader from '../components/FilterStateHeader';
import BooksList from '../components/BooksList';
import FetchError from '../components/FetchError';
import MenuShelf from '../components/MenuShelf';
import Empty from '../components/Empty';
import ShareButton from '../components/ShareButton';
import ShelfInfo from '../components/ShelfInfo';
import { SECONDARY_COLOR } from '../constants';
import { getShelfApi } from '../actions/getShelf';
import { goBackFromShelf, isInModal, isWeb, getShortUrl } from '../utils';
import { useUser, useShelf, useGetDataOnNavFocus, useSearchShelfOrListBooks, useGetShelfDone, useSetInitialPath, useShelfBooks } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';
import ShelvesSearchContext from '../context/ShelvesSearchContext';

const tBase = 'screens.shelf';

const ADD_BOOK_SCREEN = isWeb() ? 'AddBookSearch' : 'Scan';

export default function ShelfScreen({ navigation, route }) {
    const shelfId = route.params.shelfId;
    const shelf = useShelf(shelfId);
    const dispatch = useDispatch();
    const [shelfFetchStatus, done] = useGetShelfDone(shelfId);
    const getShelf = () => { dispatch(getShelfApi({ shelfId, showLoading: !shelf })).then(done); }
    useGetDataOnNavFocus(navigation, getShelf);
    if(!shelfFetchStatus && !shelf) return <View />;
    if(['failed', 'notFound', 'notAllowed'].includes(shelfFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                navigation={navigation} 
                status={ shelfFetchStatus } />
        )
    }
    return (
        <Shelf 
            shelf={shelf} 
            route={route} 
            navigation={navigation} />
    )
}

function Shelf({ shelf, navigation, route }) {
    if(!shelf) return <View />;
    useSetInitialPath(route);
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isOwner = shelf?.ownerId == loggedUserId;
    const inModal = isInModal(navigation);
    const url = getShortUrl('shelf', shelf.shortId);
    useEffect(() => {
        navigation.setOptions({ title: shelf?.name ?? '' })
    })
    function deleteDone(json) {
        if(json?.success) handleBack();
    }
    function handleBack() {
        goBackFromShelf(navigation, activeBottomTab);
    }
    function headerRight() {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center'}}>
                { Boolean(shelf.shortId) && 
                    <ShareButton 
                        url={ url } 
                        type='shelf'
                        isOwner={ isOwner } 
                        name={ shelf.name } /> 
                }
                { isOwner &&
                    <MenuShelf
                        shelf={shelf}
                        navigation={navigation}
                        deleteDone={deleteDone}
                        TriggerButton={ () => (
                            <Feather name="more-horizontal" size={28} color={ SECONDARY_COLOR } />
                        )} />
                }
            </View>    
        )
    }
    if(!shelf) return <View />;
    return (
        <>  
            { !inModal &&
            <AppHeader 
                title={ shelf.name }
                isPrivate={shelf.visibility == 'private'}
                headerRight={ headerRight } 
                headerRightStyle={{ paddingRight: 12 }}
                handleBack={ handleBack }
                navigation={ navigation } />
            }
            <ShelfBooks shelf={shelf} isOwner={isOwner} navigation={navigation} />
        </>
    )
}

const ShelfBooks = memo(({ shelf, isOwner, navigation }) => {
    const { t } = useContext(AppSettingsContext);
    const primaryActionOnPress = () => navigation.navigate('Modal', { screen: ADD_BOOK_SCREEN, params: { shelfId: shelf.id } });
    const books = useShelfBooks(shelf.sort, shelf.bookIds);
    const user = useUser(shelf.ownerId);
    const { matchedBooks, hasSearch, resetSearch } = useSearchShelfOrListBooks(books, isOwner, ShelvesSearchContext);
    const emptyBodyKey = isOwner ? 'bodyOwner' : 'bodyUser';
    const renderEmpty = useMemo(() => (
        <Empty  
            title={ t(`${tBase}.empty.title`) }
            body={ t(`${tBase}.empty.${emptyBodyKey}`, { nickname: user?.nickname || '' }) }
            img={ require('../assets/empty.png') }
            primaryActionLabel={ t(`${tBase}.empty.addBooks`) }
            primaryActionOnPress={ isOwner ? primaryActionOnPress : null } />
    ), []);
    const renderHeader = () => {
        if(hasSearch && isOwner) {
            return (
                <FilterStateHeader 
                    tBase={tBase} 
                    resetPattern={resetSearch}
                    bookCount={matchedBooks.length} />
            )
        }
        return (
            <ShelfInfo 
                shelf={shelf} 
                user={user} 
                tBase={tBase}
                isOwner={isOwner}
                navigation={navigation} />
        )
    }
    return (
        <BooksList 
            books={matchedBooks}
            context='shelf'
            contextId={shelf.id}
            useStickyHeader={ hasSearch && isOwner ? true : false } 
            renderListHeader={ renderHeader }
            renderEmpty={renderEmpty} 
            navigation={navigation} />
    )
})