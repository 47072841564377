import React, { useMemo, memo } from 'react';
import { View } from 'react-native';
import { sortAlphabetically } from '../utils';
import { Shelf } from '../screens/Library/ShelvesScreen';
import { useShelfBooks } from '../hooks';

export default function UserShelfs({ userId, shelves, navigation}) {
    const sortedShelf =  useMemo(() => sortAlphabetically(shelves, 'name'), [])
    return (
        <View>
            { sortedShelf.map(shelf => (
                <UserShelf 
                    key={shelf.id} 
                    shelf={shelf}
                    userId={userId} 
                    navigation={navigation} /> 
                )
            )}
        </View>
    );
}

const UserShelf = memo(({ shelf, userId, navigation }) => {
    const books = useShelfBooks(shelf.sort, shelf.bookIds);
    return (
        <Shelf 
            shelf={shelf} 
            userId={userId}
            books={books}
            context='user'
            contextId={userId}
            navigation={navigation} />
    )
})