
import { usePromise } from '../hooks';
import { init } from './blurhash';

export default function setupBlurhashGL() {
    if (global.nativeCallSyncHook) {
        let { state, error } = usePromise(init, []);
        if (state === 'fulfilled') {
            return true;
        }
        else if (state === 'rejected') {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return true;
    }
}