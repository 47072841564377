
import React, { useContext } from 'react';
import { selectFilteredAccountBooks } from '../../selectors';
import AppSettingsContext from '../../context/AppSettingsContext';
import BooksSearchContext from '../../context/BooksSearchContext';
import NoBookResults from '../../components/NoBookResults';
import BooksList from '../../components/BooksList';
import Empty from '../../components/Empty';
import { useSearch, useAskForFeedback, useLibraryScrollHandler } from '../../hooks';
import BooksScrollContext from '../../context/BooksScrollContext';
import { isWeb } from '../../utils';
import { PILL_TABS_HEIGHT } from '../../constants'

const tBase = 'screens.books';

const ADD_BOOK_SCREEN = isWeb() ? 'AddBookSearch' : 'Scan';

export default function AllBooksScreen({ navigation }) {
    const { t } = useContext(AppSettingsContext);
    const {
        pattern, 
        filters, 
        filteredBooks, 
        hasSearch,
        hasBooks, 
        resetSearch
     } = useSearch(selectFilteredAccountBooks, BooksSearchContext);
    useAskForFeedback(navigation, t);
    const scrollHandler = useLibraryScrollHandler(BooksScrollContext);
    if(!hasBooks) {
        return (
            <Empty 
                title={ t(`${tBase}.empty.title`) }
                body={ t(`${tBase}.empty.body`) }
                img={ require('../../assets/emptyLibrary.png') }
                primaryActionLabel={ t(`${tBase}.empty.addBooks`) }
                primaryActionOnPress={ () => navigation.navigate('Modal', { screen: ADD_BOOK_SCREEN }) } />
        )
    } else if(hasSearch && !filteredBooks.length) {
        return (
            <NoBookResults
                tBase={tBase}
                pattern={pattern}
                filters={filters}
                resetSearch={resetSearch} />
        )
    } 
    const paddingTop = isWeb() ? 10 : PILL_TABS_HEIGHT + 10;
    return (
        <BooksList 
            scrollHandler={scrollHandler}
            books={filteredBooks}
            contentContainerStyle={{ paddingTop }}
            renderListHeader={() => null }
            navigation={navigation} />
    )
}
