import { createReducer } from '@reduxjs/toolkit'
import { getGroupProductsSuccess } from '../actions/getGroupProducts';
import { matchResetStore } from './matchers';

const initialState = [];

export const groupProducts = createReducer(initialState, (builder) => {
    builder
    .addCase(getGroupProductsSuccess, (state, action) => {
        const {products} = action.payload;
        return products;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})