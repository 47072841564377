import { DELETE_BOOK_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteBookInProgress = createAction('DELETE_BOOK_IN_PROGRESS');
export const deleteBookSuccess = createAction('DELETE_BOOK_SUCCESS');
export const deleteBookFailure = createAction('DELETE_BOOK_FAILURE');

export function deleteBookApi({ bookId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookId },
            actions: [deleteBookInProgress, deleteBookSuccess, deleteBookFailure],
            name: 'deleteBook',
            dispatch,
            getState
        })
    }
}