import React, { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import MenuWebShare from './MenuWebShare';
import Button from './Button';
import RoundButton from './RoundButton';
import { Feather } from '@expo/vector-icons';
import { SECONDARY_COLOR } from '../constants';
import { useWebShareApiAvailable } from '../hooks';
import { isWeb, share } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';

const shareIconName = 'share';

export default function ShareButton({ type, placement='bottom', url, name, isOwner, buttonType='button' }) {
    const {t} = useContext(AppSettingsContext);
    function TriggerButton()  {
        if(buttonType == 'round') return <RoundButton type='share' size={ 34 } iconName={ shareIconName } />
        return (
            <View style={[ styles.triggerButton ]}>
                <Feather name={ shareIconName } size={ 22 } color={ SECONDARY_COLOR } />
            </View>
        )
    }
    function IconButton() {
        return (
            <Feather name={ shareIconName } size={ 22 } color={ SECONDARY_COLOR } />
        )
    }
    const webShareApiAvailable = useWebShareApiAvailable();
    function handleShare() {
        share({ 
            url, 
            type, 
            name,
            isOwner, 
            method: isWeb() && webShareApiAvailable ? 'webShare' : 'native' ,
            t
        })
    }
    if(isWeb() && !webShareApiAvailable) return (
        <MenuWebShare
            url={ url }
            type={ type }
            placement={ placement }
            isOwner={ isOwner }
            name={ name }
            TriggerButton={ TriggerButton } />
    )
    if(buttonType === 'round') {
        return (
            <RoundButton 
                type='share' 
                size={ 34 }
                onPress={ handleShare } 
                iconName={ shareIconName } />
        )
    }
    return (
        <Button 
            Icon={ IconButton() }
            showHover={ false }
            onPress={ handleShare }
            hitSlop={{ top: 0, left: 3, right: 3, bottom: 0 }}
            size='xSmall' 
            buttonStyle={[styles.button]}/>
    )
}

const styles = StyleSheet.create({
    button: { 
        height: 40, 
        paddingHorizontal: 5, 
        marginRight: 2, 
        backgroundColor: 'transparent', 
        marginBottom: 0 
    },
    triggerButton: { 
        height: 40, 
        paddingHorizontal: 3, 
        marginRight: 5, 
        alignItems: 'center', 
        justifyContent: 'center' 
    }
})