import React, { useEffect, useContext } from 'react';
import { View } from 'react-native';
import { Feather } from '@expo/vector-icons';
import MenuGroupCollection from '../components/MenuGroupCollection';
import BooksList from '../components/BooksList';
import FetchError from '../components/FetchError';
import AppHeader from '../navigation/AppHeader';
import Empty from '../components/Empty';
import ShareButon from '../components/ShareButton';
import GroupCollectionInfo from '../components/GroupCollectionInfo';
import { getGroupCollectionApi } from '../actions/getGroupCollection';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { goBackFromGroupCollection, getShortUrl, isInModal, openModalOrPushScreen } from '../utils';
import { useUser, useGroupCollection, useGetDataOnNavFocus, useGroupCollectionBooks, useGroup, useGetGroupCollectionDone } from '../hooks';
import { SECONDARY_COLOR } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.groupCollection';

export default function GroupCollectionScreen({ navigation, route }) {
    const groupCollectionId = route.params.groupCollectionId;
    const groupCollection = useGroupCollection(groupCollectionId);
    const dispatch = useDispatch();
    const [groupCollectionFetchStatus, done] = useGetGroupCollectionDone(groupCollectionId);
    const getGroupCollection = () => { dispatch(getGroupCollectionApi({ groupCollectionId, showLoading: !groupCollection })).then(done); }
    useGetDataOnNavFocus(navigation, getGroupCollection);
    if(!groupCollectionFetchStatus && !groupCollection) return <View />;
    if(['failed', 'notFound', 'notAllowed'].includes(groupCollectionFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                navigation={navigation} 
                status={ groupCollectionFetchStatus } />
        )
    }
    return (
        <GroupCollection 
            groupCollection={groupCollection} 
            navigation={navigation} />
    )
}

function GroupCollection({ groupCollection, navigation }) {
    if(!groupCollection) return <View />;
    const group = useGroup(groupCollection.groupId);
    const activeBottomTab = useSelector(state => state.settings.activeBottomTab, shallowEqual);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isOwner = groupCollection?.creatorId == loggedUserId;
    const isGroupAdmin = group?.adminIds.includes(loggedUserId) || false;
    const inModal = isInModal(navigation);
    const url = getShortUrl('groupCollection', groupCollection.shortId);
    useEffect(() => {
        navigation.setOptions({ title: groupCollection.name })
    })
    function deleteDone(json) {
        if(json?.success) handleBack();
    }
    function handleBack() {
        goBackFromGroupCollection(navigation, activeBottomTab, group);
    }
    function headerRight() {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center'}}>
                { Boolean(groupCollection.shortId) && 
                    <ShareButon 
                        url={ url } 
                        type='groupCollection' 
                        isOwner={ false }
                        name={ groupCollection.name } />
                }
                { isGroupAdmin &&
                    <MenuGroupCollection
                        placement='bottom'
                        groupCollection={groupCollection}
                        navigation={navigation}
                        deleteDone={deleteDone}
                        TriggerButton={ () => (
                            <View style={{ height: 25, alignItems: 'center', justifyContent: 'center' }}>
                                <Feather name="more-horizontal" size={30} color={ SECONDARY_COLOR } />
                            </View>
                        )} />
                }
            </View>
        )
    }
    if(!groupCollection) return <View />
    return (
        <>
            { !inModal &&
            <AppHeader 
                title={ groupCollection.name } 
                headerRight={ headerRight } 
                headerRightStyle={{ paddingRight: 12 }}
                handleBack={ handleBack }
                navigation={ navigation } />
            }
            <GroupCollectionBooks
                groupId={group.id}
                groupSlug={group.slug} 
                isOwner={isOwner}
                isGroupAdmin={isGroupAdmin}
                groupCollection={groupCollection} 
                navigation={navigation} />
        </>
    )
}

function GroupCollectionBooks({ groupCollection, isGroupAdmin, groupId, groupSlug, navigation }) {
    const {t} = useContext(AppSettingsContext);
    const primaryActionOnPress = () => openModalOrPushScreen(navigation, 'BooksInGroupCollection', { groupCollectionId: groupCollection.id })
    const user = useUser(groupCollection.creatorId);
    const emptyBodyKey = isGroupAdmin ? 'bodyAdmin' : 'bodyUser';
    const renderEmpty = () => (
        <Empty  
            title={ t(`${tBase}.empty.title`) }
            body={ t(`${tBase}.empty.${emptyBodyKey}`, { nickname: user?.nickname || '' }) }
            img={ require('../assets/empty.png') }
            primaryActionLabel={ t(`${tBase}.empty.addBooks`) }
            primaryActionOnPress={ isGroupAdmin ? primaryActionOnPress : null } />
    )
    const renderGroupCollectionInfo = () => (
        <GroupCollectionInfo 
            groupCollection={groupCollection} 
            user={user} 
            tBase={tBase}
            navigation={navigation} />
    )
    const books = useGroupCollectionBooks(groupCollection.id);
    return (
        <BooksList 
            books={books} 
            groupId={groupId}
            groupSlug={groupSlug}
            renderEmpty={renderEmpty} 
            groupCollectionId={groupCollection.id} 
            context='groupCollection'
            renderListHeader={ renderGroupCollectionInfo }
            contextId={groupCollection.id}
            navigation={navigation} />
    )
}