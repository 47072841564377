import { GET_DISCUSSIONS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getDiscussionsInProgress = createAction('GET_DISCUSSIONS_IN_PROGRESS');
export const getDiscussionsSuccess = createAction('GET_DISCUSSIONS_SUCCESS');
export const getDiscussionsFailure = createAction('GET_DISCUSSIONS_FAILURE');

export function getDiscussionsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getDiscussionsInProgress, getDiscussionsSuccess, getDiscussionsFailure],
            name: 'getDiscussions',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}