import React, { memo, useEffect, useContext} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { groupMenu } from '../utils/menuConfigs';
import { deleteGroupApi } from '../actions/deleteGroup';
import { leaveGroupApi } from '../actions/leaveGroup';
import { cancelGroupRequestApi } from '../actions/cancelGroupRequest';
import { openModalOrPushScreen } from '../utils';
import { useGroupRequest } from '../hooks';
import { SECONDARY_COLOR_SUBTLE } from '../constants';
import MenuGroupAdminHeader from './MenuGroupAdminHeader'
import AppSettingsContext from '../context/AppSettingsContext';
import Menu from './Menu';

function MenuGroup({ style, group, navigation, deleteDone, context='groupItem', TriggerButton, hasItems=()=>{}, placement='auto' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const groupId = group.id;
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isAdmin = group.adminIds.includes(loggedUserId);
    const isMember = group.memberIds.includes(loggedUserId);
    const groupRequestByMe = useGroupRequest(group.id);
    const menuOptions = groupMenu({ 
        dispatch,
        isMember,
        isAdmin,
        hasRequestedJoin: !!groupRequestByMe,
        name: group.name,
        navigation, 
        actions: {
            onDelete:            () => { dispatch(deleteGroupApi({ groupId })).then(deleteDone) },
            onUpdate:            () => { openModalOrPushScreen(navigation, 'UpdateGroup', { groupId }) },
            onLeave:             () => { dispatch(leaveGroupApi({ groupId})) },
            onAddRemoveBooks:    () => { openModalOrPushScreen(navigation, 'BooksInGroup', { groupId }) },
            onInviteToJoin:      () => { openModalOrPushScreen(navigation, 'InviteToJoinGroup', { groupId }) },
            onGroupShare:        () => { openModalOrPushScreen(navigation, 'GroupShare', { groupId }) },
            onCancelJoinRequest: () => { dispatch(cancelGroupRequestApi({ groupId, groupRequestId: groupRequestByMe.id })) }
        },
        t
    })
    useEffect(() => {
        if(!menuOptions.length) hasItems(false);
    }, [])
    const showAdminHeader = context === 'groupDetail' && group?.quotas?.books && isAdmin;
    const AdminHeader = () => {
        if(showAdminHeader) return <MenuGroupAdminHeader group={group} />
        return null;
    };
    return (
        <Menu
            placement={placement}
            style={style}
            menuWidth={290}
            Header={AdminHeader}
            anchorFill={ showAdminHeader ? SECONDARY_COLOR_SUBTLE : '#fff' }
            TriggerButton={ TriggerButton }
            menuOptions={menuOptions}
        />
    )
}

export default memo(MenuGroup);