import { UPDATE_GROUP_COLLECTION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateGroupCollectionInProgress = createAction('UPDATE_GROUP_COLLECTION_IN_PROGRESS');
export const updateGroupCollectionSuccess = createAction('UPDATE_GROUP_COLLECTION_SUCCESS');
export const updateGroupCollectionFailure = createAction('UPDATE_GROUP_COLLECTION_FAILURE');

export function updateGroupCollectionApi({ groupCollection }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { collection: groupCollection },
            actions: [updateGroupCollectionInProgress, updateGroupCollectionSuccess, updateGroupCollectionFailure],
            name: 'updateGroupCollection',
            dispatch,
            getState
        })
    }
}