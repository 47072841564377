import { UPDATE_UNREAD_NOTIFS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateUnreadNotifsInProgress = createAction('UPDATE_UNREAD_NOTIFS_IN_PROGRESS');
export const updateUnreadNotifsSuccess = createAction('UPDATE_UNREAD_NOTIFS_SUCCESS');
export const updateUnreadNotifsFailure = createAction('UPDATE_UNREAD_NOTIFS_FAILURE');

export function updateUnreadNotifsApi({ notifsRead, showLoading=false, checkCaching=false }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { notifsRead },
            actions: [updateUnreadNotifsInProgress, updateUnreadNotifsSuccess, updateUnreadNotifsFailure],
            name: 'updateUnreadNotifs',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}