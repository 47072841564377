import React, { memo, useContext } from 'react';
import FilterLibrary from './FilterLibrary';
import BooksSearchContext from '../context/BooksSearchContext';
import ShelvesSearchContext from '../context/ShelvesSearchContext';
import ListsSearchContext from '../context/ListsSearchContext';

function FilterLibraryWithContext({ filterKey, height }) {
    if(filterKey === 'booksFilter') return <FilterBooksWithContext height={height} />
    if(filterKey === 'shelvesFilter') return <FilterShelvesWithContext height={height} />
    if(filterKey === 'listsFilter') return <FilterListsWithContext height={height} />
}

const FilterBooksWithContext = memo(({ height }) => {
    const searchContext = useContext(BooksSearchContext);
    return (
        <FilterLibrary searchContext={searchContext} height={height} />
    )
})

const FilterShelvesWithContext = memo(({ height }) => {
    const searchContext = useContext(ShelvesSearchContext);
    return (
        <FilterLibrary searchContext={searchContext} height={height} />
    )
})

const FilterListsWithContext = memo(({ height }) => {
    const searchContext = useContext(ListsSearchContext);
    return (
        <FilterLibrary searchContext={searchContext} height={height} />
    )
})

export default memo(FilterLibraryWithContext);
