import { DELETE_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteGroupInProgress = createAction('DELETE_GROUP_IN_PROGRESS');
export const deleteGroupSuccess = createAction('DELETE_GROUP_SUCCESS');
export const deleteGroupFailure = createAction('DELETE_GROUP_FAILURE');

export function deleteGroupApi({ groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId },
            actions: [deleteGroupInProgress, deleteGroupSuccess, deleteGroupFailure],
            name: 'deleteGroup',
            dispatch,
            getState
        })
    }
}