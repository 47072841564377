import React, { memo } from 'react';
import { Text, StyleSheet, Platform } from 'react-native';
import { OFF_BLACK, MAIN_COLOR, MAIN_COLOR_DARK } from '../constants';

const InterReg = React.forwardRef(({
    style={}, 
    text, 
    showHover=false,
    showActive=false,
    hoverColor=MAIN_COLOR, 
    activeColor=MAIN_COLOR_DARK,
    allowFontScaling=false, 
    numberOfLines=null,
    onTextLayout=() => {},
    onPress
    }, ref) => { 
    return (
        <Text ref={ ref }
            {...{numberOfLines, allowFontScaling, onPress, onTextLayout}}
            style={[
                styles.text,
                style,
                showHover && { color: hoverColor },
                showActive && { color: activeColor },
        ]}>{text}</Text>
    )
})

export default memo(InterReg);

const styles = StyleSheet.create({
    text: {
        fontFamily: 'Inter_400Regular', 
        color: OFF_BLACK,
        ...Platform.select({
            web: {
            borderBottomColor: 'transparent',
            transitionDuration: '200ms',
            userSelect: 'text'
            },
            default: {},
        }),
    }
});