import React, { useMemo, memo, useState, useEffect, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { useGroupCollections, useBooks } from '../hooks';
import CollectionInfo from './CollectionInfo';
import MontSBold from './MontserratBold';
import PremiumPill from './PremiumPill';
import Cover from './Cover';
import * as Haptics from 'expo-haptics';
import { Entypo } from '@expo/vector-icons';

import { GRAY_LIGHT, GRAY_LINE_COLOR } from '../constants';
import { getAverageColorFromBlurhash, sortGroupCollectionBooks, isIOS, isWeb, openModalOrPushScreen, isInModal } from '../utils';
import { setGroupCollectionsOrderApi } from '../actions/setGroupCollectionsOrder';
import { useDispatch, useSelector } from 'react-redux';
import { TouchableOpacity, GestureDetector, Gesture } from 'react-native-gesture-handler';
import Animated, {
    runOnJS,
    useAnimatedScrollHandler,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
    withTiming,
    withDelay,
    cancelAnimation,
    scrollTo,
    useAnimatedReaction,
    useAnimatedRef
} from 'react-native-reanimated';

import AppSettingsContext from '../context/AppSettingsContext';

const CIRCLE_SIZE = 90;
const COLLECTION_HOR_SPACING = 5;
const COLLECTION_WIDTH = CIRCLE_SIZE + (COLLECTION_HOR_SPACING*2);
const COLLECTION_TOP_SPACING = 5;
const PADDING_TOP = 10;
const PADDING_BOTTOM = 15;
const TITLE_HEIGHT = 25;
const SCROLL_VIEW_LEFT_SPACING = 15;
const SCROLL_VIEW_RIGHT_SPACING = COLLECTION_WIDTH/2;
const COLLECTION_HEIGHT = CIRCLE_SIZE + TITLE_HEIGHT + COLLECTION_TOP_SPACING + PADDING_TOP + PADDING_BOTTOM;
export const COLLECTIONS_HEIGHT = COLLECTION_HEIGHT;

const IOS = isIOS();
const Web = isWeb();

function listToObject(list) {
    const values = Object.values(list);
    const object = {};
    for (let i = 0; i < values.length; i++) {
      object[values[i].id] = i;
    }
    return object;
}

function clamp(value, lowerBound, upperBound) {
    'worklet';
    return Math.max(lowerBound, Math.min(value, upperBound));
}

function havePositionsChanged(current, previous) {
    'worklet';
    let hasChanged = false;
    if(!Object.keys(previous).length) return hasChanged;
    for(let [id, order] of Object.entries(current)) {
        if(previous[id] !== order) {
            hasChanged = true;
            break;
        }
    }
    return hasChanged;
}

function objectMove(object, from, to) {
    'worklet';
    const newObject = Object.assign({}, object);
  
    for (const id in object) {
        if(object[id] === from) {
            newObject[id] = to;
        }
  
        if(object[id] === to) {
            newObject[id] = from;
        }
    }
    return newObject;
}

export default function GroupCollections({ group, isAdmin, navigation, tBase }) {
    let updateTimer;
    const inModal = isInModal(navigation);
    const scrollX = useSharedValue(0);
    const scollViewX = useSharedValue(0);
    const scollViewWidth = useSharedValue(0);
    const isInteracting = useSharedValue(false);
    const positionsBeforeInteraction = useSharedValue({});
    const groupCollections = useGroupCollections(group.collectionIds, isAdmin);
    const positions = useSharedValue(listToObject(groupCollections));
    const scrollViewRef = useAnimatedRef();
    const showCreateCollection = isAdmin;
    const canModifyCollectionsOrder = isAdmin && groupCollections.length > 1;
    const dispatch = useDispatch();
    const modalSize = useSelector(state => state.settings.modal.size?.width);
    const windowWidth = useSelector(state => state.settings.window?.width);
    const { rgba } = group?.blurHash ? getAverageColorFromBlurhash(group.blurHash) : { rgba: `rgba(211,211,211,1)` };
    const backgroundColor = rgba;
    const startOffsetLeft = (() => {
        let offset = 0;
        if(showCreateCollection) offset = COLLECTION_WIDTH;
        return offset + SCROLL_VIEW_LEFT_SPACING;
    })()
    const handleScroll = useAnimatedScrollHandler((event) => {
        scrollX.value = event.contentOffset.x;
    });
    function onLayout({ nativeEvent: { layout }}) {
        const x = (() => {
            if(isWeb() && inModal && modalSize && windowWidth) {
                return (windowWidth - modalSize) / 2;
            } else if(isWeb()) {
                return layout.left;
            }
            return layout.x;
        })();
        if(!scollViewX.value) scollViewX.value = x;
        if(!scollViewWidth.value) scollViewWidth.value = layout.width;
    }
    useAnimatedReaction(
        () => scrollX.value,
        (scrolling) => scrollTo(scrollViewRef, scrolling, 0, false)
    );
    useAnimatedReaction(
        () => ({ 
            isInteracting: isInteracting.value, 
            positions: positions.value
        }),
        (current, previous) => {
            if(current?.positions && Object.keys(current.positions).length && !Object.keys(positionsBeforeInteraction.value).length) {
                positionsBeforeInteraction.value = current.positions;
            }
            if(current.isInteracting === false && previous?.isInteracting === true) {
                if(havePositionsChanged(current.positions, positionsBeforeInteraction.value )) {
                    runOnJS(updatePositions)(current.positions);
                    positionsBeforeInteraction.value = current.positions;
                }
            }
        }, 
        [isInteracting, positions, positionsBeforeInteraction]
    );
    function updatePositions(positions) {
        if(updateTimer) clearTimeout(updateTimer);
        updateTimer = setTimeout(() => {
            dispatch(setGroupCollectionsOrderApi({ groupCollections: positions, groupId: group.id, showLoading: false }))
        }, 1500)
    }
    useEffect(() => {
        if(Object.keys(positions.value).length !== groupCollections.length) {
            positions.value = listToObject(groupCollections);
        }
    }, [groupCollections])
    const renderCollections = useMemo(() => {
        return groupCollections.map((groupCollection, index) => (
            <MovableGroupCollection 
                index={index}
                scrollX={scrollX}
                groupId={group.id}
                groupSlug={group.slug}
                key={groupCollection.id}
                navigation={navigation}
                positions={positions}
                scollViewX={scollViewX}
                isInteracting={isInteracting}
                scollViewWidth={scollViewWidth}
                collectionsCount={groupCollections.length}
                canModifyCollectionsOrder={canModifyCollectionsOrder}
                startOffsetLeft={startOffsetLeft}
                backgroundColor={backgroundColor}
                groupCollection={groupCollection} />
        ))
    }, [groupCollections])
    return (  
        <Animated.ScrollView 
            onLayout={onLayout}
            style={styles.collections}
            horizontal={true}
            ref={scrollViewRef}
            onScroll={handleScroll}
            scrollEventThrottle={16}
            showsHorizontalScrollIndicator={false} 
            contentContainerStyle={{
                height: COLLECTION_HEIGHT, 
                width: groupCollections.length ? COLLECTION_WIDTH * (groupCollections.length) + startOffsetLeft + SCROLL_VIEW_RIGHT_SPACING : '100%', 
                paddingTop: PADDING_TOP, 
                paddingBottom: PADDING_BOTTOM
            }}>
            { showCreateCollection && 
                <GroupCollectionAdd 
                    navigation={navigation}
                    collectionCount={group.collectionIds.length}
                    canAddCollections={!!group.quotas.collections}
                    groupId={group.id} 
                    tBase={tBase} />
            }
            { renderCollections }
        </Animated.ScrollView>
    )
}

const MovableGroupCollection = (props) => {
    const [moving, setMoving] = useState(false);
    const { positions, groupCollection, startOffsetLeft, scrollX, collectionsCount, scollViewX, scollViewWidth, isInteracting, index, canModifyCollectionsOrder } = props;
    const left = useSharedValue(positions.value[groupCollection.id] * COLLECTION_WIDTH + startOffsetLeft);
    useAnimatedReaction(
        () => positions.value,
        (currentPositions, prevPositions) => {
            if(prevPositions === null && Object.keys(currentPositions).length) {
                left.value = positions.value[groupCollection.id] * COLLECTION_WIDTH + startOffsetLeft;
            }
        }, 
        [left]
    )
    useAnimatedReaction(
        () => positions.value[groupCollection.id],
        (currentPosition, previousPosition) => {
            if(currentPosition !== previousPosition) {
                if(!moving) {
                    left.value = withSpring(currentPosition * COLLECTION_WIDTH + startOffsetLeft, { stiffness: 50, mass: 0.9 });
                }
            }
        },
        [moving]
    );
    const panGesture = useMemo(() => 
        Gesture.Pan()
            .activateAfterLongPress(700)
            .onBegin(() => {
                if(Web) {
                    runOnJS(setMoving)(true)
                    isInteracting.value = true;
                }
            })
            .onStart(() => {
                runOnJS(setMoving)(true)
                if(IOS && !isInteracting.value) {
                    runOnJS(Haptics.impactAsync)(
                        Haptics.ImpactFeedbackStyle.Medium
                    );
                }
                isInteracting.value = true;
            })
            .onUpdate((event) => {
                const positionX = (event.absoluteX-scollViewX.value) + scrollX.value;
                const contentWidth = (collectionsCount * COLLECTION_WIDTH) + startOffsetLeft + SCROLL_VIEW_RIGHT_SPACING;
                const maxScroll = contentWidth - scollViewWidth.value;
                const scrollToDurationLeft = Math.max(Math.floor((maxScroll-scrollX.value) / COLLECTION_WIDTH) * 100, 100);
                const scrollToDurationRight = Math.max(Math.floor(scrollX.value / COLLECTION_WIDTH) * 100, 100);
                if (positionX <= scrollX.value + COLLECTION_WIDTH) {
                    scrollX.value = withTiming(0, { duration: scrollToDurationRight });
                } else if (positionX >= scrollX.value + scollViewWidth.value - COLLECTION_WIDTH) {
                    scrollX.value = withTiming(maxScroll, { duration: scrollToDurationLeft });
                } else {
                    cancelAnimation(scrollX);
                }
                
                left.value = withTiming(positionX - (COLLECTION_WIDTH/2), {
                    duration: 16,
                });
                
                const newPosition = clamp(
                    Math.floor((positionX - startOffsetLeft) / COLLECTION_WIDTH),
                    0,  
                    collectionsCount - 1
                );
                    
                if (newPosition !== positions.value[groupCollection.id]) {
                    positions.value = objectMove(
                        positions.value,
                        positions.value[groupCollection.id],
                        newPosition
                    );
                    if(IOS) {
                        runOnJS(Haptics.impactAsync)(
                            Haptics.ImpactFeedbackStyle.Light
                        );
                    }
                }
            })
            .onFinalize(() => {
                isInteracting.value = false;
                left.value = positions.value[groupCollection.id] * COLLECTION_WIDTH + startOffsetLeft;
                runOnJS(setMoving)(false);
            })
        , [moving, setMoving]
    );
    const animStyle = useAnimatedStyle(() => {
        return {
            position: 'absolute',
            width: COLLECTION_WIDTH,
            borderRadius: 10,
            backgroundColor: '#fff', 
            left: Number.isNaN(left.value) ? 0 : left.value,
            top: 10,
            opacity: withDelay(index * 100, withTiming(Number.isNaN(left.value) ? 0 : 1)),
            alignItems: 'center',
            zIndex: moving ? 1 : 0,
            elevation: moving ? 2 : 0,
            shadowColor: 'black',
            shadowOpacity: withSpring(moving ? 0.2 : 0),
            shadowRadius: 10,
        };
    }, [moving]);
    return (
        <Animated.View style={animStyle}>
            { canModifyCollectionsOrder ?
                <GestureDetector gesture={panGesture}>
                    <GroupCollectionCircle {...props} moving={moving} />
                </GestureDetector> :
                <GroupCollectionCircle {...props} moving={moving} />
            }
        </Animated.View>
    )
}

const GroupCollectionCircle = memo(({ groupCollection, navigation, backgroundColor, moving, groupId, groupSlug }) => {
    const books = useBooks(groupCollection.bookIds)
    function handlePress() {
        if(!moving) navigation.push('GroupCollection', { groupCollectionId: groupCollection.id, groupId, groupSlug });
    }
    const sortedBooks = useMemo(() => sortGroupCollectionBooks(books, groupCollection.sort, groupCollection.id), [groupCollection.sort, books]);
    const covers = (() => {
        const booksWithCovers = sortedBooks.filter(({ cover }) => cover );
        if(booksWithCovers.length == 1) {
            return [booksWithCovers[0].cover];
        } else if(booksWithCovers.length == 2 || booksWithCovers.length == 3) {
            return [booksWithCovers[0].cover, booksWithCovers[1].cover];
        } else if(booksWithCovers.length > 3) {
            return [booksWithCovers[0].cover, booksWithCovers[1].cover, booksWithCovers[2].cover, booksWithCovers[3].cover];
        }   
        return [];
    })()
    return (
        <Animated.View style={{ width: CIRCLE_SIZE, marginHorizontal: COLLECTION_HOR_SPACING, marginTop: COLLECTION_TOP_SPACING, alignItems: 'center' }}>
            <TouchableOpacity 
                onPress={handlePress}
                activeOpacity={moving ? 1 : 0.7}
                style={[styles.circle, { backgroundColor }]}>
                { !!covers.length && <Covers covers={covers} /> }
            </TouchableOpacity>
            <MontSBold numberOfLines={1} style={styles.name} text={ groupCollection.name } />
        </Animated.View>
    )
})

const Covers = memo(({ covers }) => {
    const width = CIRCLE_SIZE/2;
    return (
        <View style={{ width: CIRCLE_SIZE, position: 'absolute', flexDirection: 'row', flexWrap: 'wrap' }}>
            { [0,1,2,3].map((index) => {
                const cover = covers[index];
                const coverStyles = (() => {
                    if(index < 2) {
                        return { bottom: 0 }
                    }
                    return { top: 0 }
                })();
                return (
                    <View key={index} style={{ width, height: width }}>
                        { cover && <Cover width={width} style={[{ position: 'absolute', borderRadius: 0 }, coverStyles]} cover={cover} /> }
                    </View>
                )
            })}
            <View style={{ backgroundColor: '#fff', position: 'absolute', left: (CIRCLE_SIZE/2)-1, top: 0, width: 2, height: '100%'  }} />
            <View style={{ backgroundColor: '#fff', position: 'absolute', left: 0, top: '50%', width: '100%', height: 2 }} />
        </View>
    )
})

const GroupCollectionAdd = memo(({ navigation, groupId, tBase, collectionCount, canAddCollections }) => {
    const [showInfo, setShowInfo] = useState(collectionCount === 0);
    const {t} = useContext(AppSettingsContext);
    function handleAddGroupCollection() {
        const modal = canAddCollections ? 'AddGroupCollection' : 'PremiumGroupOnly';
        const params = canAddCollections ? { groupId } : { feature: 'collections', groupId };
        openModalOrPushScreen(navigation, modal, params);
    }
    useEffect(() => {
        if(collectionCount && showInfo) {
            setShowInfo(false);
        } else if(!collectionCount && !showInfo) {
            setShowInfo(true);
        }
    }, [collectionCount])
    return (
        <View style={styles.addCollection}>
            <TouchableOpacity style={styles.circle} onPress={handleAddGroupCollection}>
                <Entypo name='plus' size={40} color={ '#ccc' } />
            </TouchableOpacity>
            <MontSBold numberOfLines={1} style={styles.name} text={ t(`${tBase}.collections.add`) }/>
            { !canAddCollections && <PremiumPill /> }
            { showInfo &&
                <CollectionInfo
                    handleCloseInfo={ () => setShowInfo(false) }
                    circleHeight={CIRCLE_SIZE} 
                    tBase={tBase} />
            }
        </View>
    )
})

const styles = StyleSheet.create({
    collections: {
        borderBottomColor: GRAY_LINE_COLOR, 
        borderBottomColor: isIOS() ? GRAY_LINE_COLOR : '#f1f1f1',
        borderBottomWidth: StyleSheet.hairlineWidth 
    },
    addCollection: {
        position: 'absolute',
        left: SCROLL_VIEW_LEFT_SPACING, 
        top: 10,
        width: COLLECTION_WIDTH,
        paddingHorizontal: COLLECTION_HOR_SPACING,
        paddingTop: COLLECTION_TOP_SPACING,
        alignItems: 'center'
    },
    name: {
        height: TITLE_HEIGHT,
        paddingTop: 5,
        fontSize: 13
    },
    circle: {
        width: CIRCLE_SIZE,
        height: CIRCLE_SIZE,
        borderRadius: CIRCLE_SIZE / 2,
        backgroundColor: GRAY_LIGHT,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    }
})