import React, { memo, useContext } from 'react';
import { View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { SUCCESS_COLOR, SECONDARY_COLOR } from '../constants';
import { useGroup } from '../hooks';
import InterReg from './InterReg';
import InterSemiBold from './InterSemiBold';
import AppSettingsContext from '../context/AppSettingsContext';

function GroupBookOnSiteInfo({ groupBookAttributes, groupId }) {
    const group = useGroup(groupId);
    const {t} = useContext(AppSettingsContext);
    const location = groupBookAttributes?.location || group.location;
    return (
        <View>
            { groupBookAttributes?.availableOnSite &&
            <View style={{ marginBottom: groupBookAttributes?.availableForSelfService ? 8 : 0 }}>
                <InterSemiBold text={t(`screens.group.onSiteInfo.availableOnSite`)} style={{ fontSize: 15, color: SECONDARY_COLOR }} />
                <InterReg text={location} style={{marginTop: 5}} />
            </View>
            }
            { groupBookAttributes?.availableForSelfService &&
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Ionicons name='checkmark-circle' color={SUCCESS_COLOR} size={18} />
                <InterReg text={t(`screens.group.onSiteInfo.availableForSelfService`)} style={{ marginLeft: 5 }} />
            </View>
            }
        </View>
    )
}

export default memo(GroupBookOnSiteInfo)