import { SEND_MESSAGE_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const sendMessageInProgress = createAction('SEND_MESSAGE_IN_PROGRESS');
export const sendMessageSuccess = createAction('SEND_MESSAGE_SUCCESS');
export const sendMessageFailure = createAction('SEND_MESSAGE_FAILURE');

export function sendMessageApi({ discussionId, message, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { discussionId, message },
            actions: [sendMessageInProgress, sendMessageSuccess, sendMessageFailure],
            name: 'sendMessage',
            dispatch,
            getState,
            showLoading
        })
    }
}