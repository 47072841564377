import React, { memo, useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Linking } from 'react-native';
import Empty from './Empty';
import { navigateToBottomTab, isNative } from '../utils';
import { DOMAIN } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';


function FetchError({ navigation, status, tBase, bottomTab='HomeTab' }) {
    const {t} = useContext(AppSettingsContext);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const actionKey = (() => {
        if(navigation.canGoBack()) return 'goBack';
        return 'goHome'  
    })()
    const handleEmptyActionPress = () => {
        if(navigation.canGoBack()) {
            navigation.goBack();
        } else if(loggedUserId) {
            navigateToBottomTab(navigation, bottomTab);
        } else if(!loggedUserId && isNative()) {
            navigation.navigate('Auth', { screen: 'Welcome' });
        } else {
            Linking.openURL(DOMAIN)
        }
    }
    
    return (
        <Empty 
            title={ t(`${tBase}.${status}.title`) } 
            body={ t(`${tBase}.${status}.body`) } 
            img={ require('../assets/empty.png') }
            primaryActionLabel={ t(`${tBase}.${status}.${actionKey}`) }
            primaryActionOnPress={ handleEmptyActionPress }
        />
    )
}

export default memo(FetchError);