import React, { memo, useContext } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Ionicons, MaterialIcons as MIcons } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import {  } from '../actions/getUser';
import MontSBold from './MontserratBold';
import { SECONDARY_COLOR } from '../constants';
import Button from './Button';
import InterReg from './InterReg';
import { cancelFollowUserRequestApi } from '../actions/cancelFollowUserRequest';
import { requestFollowUserApi } from '../actions/requestFollowUser';
import { confirmRequestFollowUser } from '../utils/alerts';
import { getFollowRequestByMeToUser } from '../utils';
import { useFollowRequestsForUser } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.user';

function UserPrivate({ loggedUserId, user: { id:userId, nickname } }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();
    const followRequests = useFollowRequestsForUser(userId);
    const followRequestByMe = getFollowRequestByMeToUser(followRequests, userId, loggedUserId);
    const onCancelFollowRequest = () => { dispatch(cancelFollowUserRequestApi({ followRequestId: followRequestByMe.id })) }
    const onRequestFollowUser   = () => { confirmRequestFollowUser(requestFollowUser, dispatch, { nickname }) }
    const requestFollowUser     = () => { dispatch(requestFollowUserApi({ userId })) }
    return (
        <View style={{ alignItems: 'center', paddingBottom: insets.bottom }}>
            <MIcons name='lock' size={70} color={SECONDARY_COLOR} /> 
            <MontSBold style={{ marginVertical: 15 }} text={ t(`${tBase}.private`) } />
            { followRequestByMe ?
                <View style={{ alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', marginBottom: 15 }}>
                        <Ionicons name="checkmark-circle" size={18} color="green"  />
                        <InterReg style={{ fontSize: 15, marginLeft: 4 }} text={ t(`${tBase}.requestSent`) } />
                    </View>
                    <Button 
                        onPress={onCancelFollowRequest}
                        size='xSmall' 
                        buttonStyle={{ alignSelf: 'center', paddingHorizontal: 8 }} 
                        theme='grayColorBorder' 
                        text={ t(`${tBase}.cancelRequest`) } />
                </View> :
                <Button 
                    onPress={onRequestFollowUser}
                    size='small' 
                    requiresAccount={ true }
                    buttonStyle={{ alignSelf: 'center', paddingHorizontal: 12 }} 
                    text={ t(`${tBase}.requestFollow`)} />
            }
        </View>
    )
}

export default memo(UserPrivate)