import React, { memo } from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Feather, MaterialCommunityIcons as MCIcons } from '@expo/vector-icons';
import Animated, { useAnimatedStyle, useSharedValue, withTiming, interpolate } from 'react-native-reanimated';
import { promptLoginOrSignup } from '../utils/alerts';
import { useDispatch, useSelector } from 'react-redux';
const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const ICONS_NAMES = {
    back: 'chevron-left',
    options: 'more-horizontal'    
}

function RoundButton({ onPress, style={}, size, type='back', iconName='', requiresAccount=false }) {
    const width = size;
    const height = size;
    const borderRadius = Math.ceil(size/2);
    const pressed = useSharedValue(0);
    const dispatch = useDispatch();
    const animatedStyles = useAnimatedStyle(() => ({
        opacity: interpolate(pressed.value, [0,1], [1, 0.8]),
    }))
    const handlePressIn = () => pressed.value = withTiming(1, { duration: 200 });
    const handlePressOut = () => pressed.value = withTiming(0, { duration: 200 });
    const loggedUserId = useSelector(state => state.loggedUserId);
    const makeOnPress = () => requiresAccount && loggedUserId === null ? () => promptLoginOrSignup(dispatch) : onPress;
    const props = {
        hitSlop: { top: 5, bottom: 5, left: 5, right: 5 },
        onPress: makeOnPress(),
        pointerEvents: onPress ? 'auto' : 'none',
        onPressIn: handlePressIn,
        onPressOut: handlePressOut
    }
    if(type == 'back') {
        return (
            <AnimatedPressable 
                {...props}
                style={[animatedStyles, styles.button, styles.back, { width, height, borderRadius }, style ]}>
                <Feather name={iconName || ICONS_NAMES[type]} size={28} color="#fff" />
            </AnimatedPressable>
        )
    }
    if(type == 'options') {
        return (
            <AnimatedPressable 
                {...props}
                style={[animatedStyles, styles.button, { width, height, borderRadius }, style ]}>
                <Feather name={iconName || ICONS_NAMES[type]} size={24} color="#fff" />
            </AnimatedPressable>
        )
    }
    if(type == 'like') {
        return (
            <AnimatedPressable 
                {...props}
                style={[animatedStyles, styles.button, { width, height, borderRadius }, style ]}>
                <MCIcons name={iconName || ICONS_NAMES[type]} size={20} style={{ marginBottom: -1 }} color="#fff" />
            </AnimatedPressable>
        )
    }
    if(type == 'share') {
        return (
            <AnimatedPressable 
                {...props}
                style={[animatedStyles, styles.button, { width, height, borderRadius }, style ]}>
                <Feather name={iconName || ICONS_NAMES[type]} size={18} style={{ marginBottom: 1 }} color="#fff" />
            </AnimatedPressable>
        )
    }
    return null;
}

export default memo(RoundButton);

const styles = StyleSheet.create({ 
    button: { 
        backgroundColor: 'rgba(0,0,0,0.3)',
        alignItems: 'center', 
        justifyContent: 'center', 
    },
    back: {
        paddingRight: 2
    },
    halo: {
        position: 'absolute',
        top: -6,
        left: -6,
        borderColor: 'rgba(0,0,0,0.3)' 
    }
})