import { ADD_GROUP_COLLECTION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addGroupCollectionInProgress = createAction('ADD_GROUP_COLLECTION_IN_PROGRESS');
export const addGroupCollectionSuccess = createAction('ADD_GROUP_COLLECTION_SUCCESS');
export const addGroupCollectionFailure = createAction('ADD_GROUP_COLLECTION_FAILURE');

export function addGroupCollectionApi({ groupCollection }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { collection: groupCollection },
            actions: [addGroupCollectionInProgress, addGroupCollectionSuccess, addGroupCollectionFailure],
            name: 'addGroupCollection',
            dispatch,
            getState
        })
    }
}