import React, { memo, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import Img from './Img'
import { DOMAIN, SECONDARY_COLOR } from '../constants';
import { getAverageColor } from '../utils/blurhash';
import MontSBold from './MontserratBold';
import { MaterialCommunityIcons as MCIcons } from '@expo/vector-icons';

function GroupPicture({ size, picture, isAdmin=false, isPremium=false, blurHash, style={} }) {
    const uri = picture && picture.indexOf('/public/group/pictures/') > -1 ? `${DOMAIN}${picture}` : picture;
    const [backgroundColor, setBackgroudColor] = useState(blurHash ? `rgb(${getAverageColor(blurHash).join(',')})` : '#fff');
    const handleImageLoadEnd = () => setBackgroudColor('#fff');
	return (
		<View style={ [styles.container, style, { width: size, height: size }] }>
            { picture ?
                <Img style={{ width: size, height: size, backgroundColor }} onLoadEnd={handleImageLoadEnd} ImageResizeMode='cover' {...{uri}} /> :
                <MontSBold text='G' style={{ fontSize: size * 0.6, color: 'rgba(255,255,255,0.7)' }} />
            }
            { isPremium &&
                <View style={ styles.proBadge }>
                    <MCIcons size={14} name='crown' color='white' />
                </View>  
            }
            { isAdmin &&
                <View style={ styles.adminBadge }>
                    <MontSBold style={ styles.badgeText } text='ADMIN' />
                </View>
            }
        </View>
	)
}

export default memo(GroupPicture);

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'lightgray', 
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5
    },
    proBadge: {
        backgroundColor: SECONDARY_COLOR,
        position: 'absolute',
        height: 22,
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        width: 22,
        left: 3,
        top: 3
    },
    adminBadge: { 
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.6)',
        bottom: 0,
        flexDirection: 'row',
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    badgeText: { 
        color: '#fff', 
        paddingVertical: 2, 
        paddingHorizontal: 4,
        fontSize: 9, 
    },
})