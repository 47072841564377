import { RESET_PASSWORD_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const resetPasswordInProgress = createAction('RESET_PASSWORD_IN_PROGRESS');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailure = createAction('RESET_PASSWORD_FAILURE');

export function resetPasswordApi({ email }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { email },
            actions: [resetPasswordInProgress, resetPasswordSuccess, resetPasswordFailure],
            name: 'resetPassword',
            dispatch,
            getState
        })
    }
}