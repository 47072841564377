import React, { memo, useMemo, useContext } from 'react';
import { Image, Linking } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { View, StyleSheet, ScrollView, Pressable } from 'react-native';
import { MAIN_COLOR, GRAY_LINE_COLOR, SECONDARY_COLOR, GRAY_LIGHT, DRAWER_WIDTH, DOMAIN, GRAY_TEXT_PALE } from '../constants';
import { Ionicons } from '@expo/vector-icons';
import InterReg from '../components/InterReg';
import Button from '../components/Button';
import DrawerNavItem from '../components/DrawerNavItem';
import { useDispatch } from 'react-redux';
import { getKeywordsApi } from '../actions/getKeywords';
import { useGetDataOnNavFocus } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';
import { isLargeScreen, getMetrics } from '../utils';
import BookScreen from '../screens/BookScreen';
import UserScreen from '../screens/UserScreen';
import GroupScreen from '../screens/GroupScreen';
import ShelfScreen from '../screens/ShelfScreen';
import ListScreen from '../screens/ListScreen';
import GroupCollectionScreen from '../screens/GroupCollectionScreen';

const tBase = 'sidebar';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

function getDrawerNavItems(navigation) {

    const items = [
        {
            label: 'sidebar.navItems.contact',
            icon: () => <Ionicons name='megaphone' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Contact', navigation)
        },
        {
            label: 'sidebar.navItems.findOutMore',
            icon: () => <Ionicons name='information-circle' size={18} color={SECONDARY_COLOR} />,
            onPress: () => Linking.openURL(DOMAIN)
        }
    ]
    return items;
}


function handleDrawerNavItemPress(screen, navigation) {
    navigation.dispatch(DrawerActions.closeDrawer());
    navigation.navigate('Modal', { screen })
}

const CustomDrawerContent = memo(({ navigation }) => {
    const {t} = useContext(AppSettingsContext);
    const drawerNavItems = useMemo(() => getDrawerNavItems(navigation));
    const { channel, revisionId } = getMetrics();
    const handleLoginPress = () => navigation.navigate('Auth', { screen: 'SignIn' });
    const handleSignUpPress = () => navigation.navigate('Auth', { screen: 'SignUp' });
    const handleHeaderPress = () => Linking.openURL(DOMAIN);
    return (
        <ScrollView contentContainerStyle={{ flex: 1, backgroundColor: isLargeScreen() ? GRAY_LIGHT : '#fff' }}>
            <View style={styles.header}>
                <Pressable style={styles.headerPressable} onPress={handleHeaderPress}>
                    <Image 
                        source={ require('../assets/myblio-vert-logo-white.png') } 
                        resizeMode='contain' 
                        style={{ height: 80, width: '100%' }} 
                    />
                </Pressable>
            </View>
            <View style={{ paddingHorizontal: 15, marginBottom: 40 }}>
                <InterReg text={ t(`${tBase}.public.about`)} style={{ textAlign: 'center', paddingBottom: 20}} />
                <Button 
                    size='small'
                    text={ t(`${tBase}.public.signup`) }
                    buttonStyle={{ marginBottom: 0}} 
                    onPress={handleSignUpPress} />
                <InterReg text={ t(`${tBase}.public.or`) } style={{ textAlign: 'center', paddingVertical: 10, color: '#666' }} />
                <Button
                    size='small'
                    text={ t(`${tBase}.public.login`) }
                    theme='secondaryColorBorder' 
                    buttonStyle={{backgroundColor: 'transparent'}} 
                    onPress={handleLoginPress} />
            </View>
            { drawerNavItems.map((props, i) => (
                <DrawerNavItem 
                    key={i} 
                    style={[
                        i == 0 ? styles.borderTop : {},
                        { paddingLeft: isLargeScreen() ? 33 : 40 }
                    ]} 
                    {...props} /> 
                )
            )}
            <View style={styles.footer}>
                <InterReg style={{ color: GRAY_TEXT_PALE, fontSize: 12, textAlign: 'center' }} text={ t('sidebar.credit') } />
                <InterReg 
                    text={ `v.${revisionId} ${channel}` }
                    style={{ textAlign: 'center', color: GRAY_TEXT_PALE, marginTop: 15, fontSize: 10 }} />
            </View>
        </ScrollView>
    );
})

function PublicStack({}) {
    return (
        <Stack.Navigator
            screenOptions={{ 
                headerShown: false,
                cardStyle: { flex: 1 }
            }}>
                <Stack.Screen name="Book" component={BookScreen} />
                <Stack.Screen name="GroupBook" component={BookScreen} />
                <Stack.Screen name="User" component={UserScreen} />
                <Stack.Screen name="Group" component={GroupScreen} />
                <Stack.Screen name="Shelf" component={ShelfScreen} />
                <Stack.Screen name="List" component={ListScreen} />
                <Stack.Screen name="GroupCollection" component={GroupCollectionScreen} />
        </Stack.Navigator>
    )
}

function PublicDrawerNavigator({ navigation }) {
    const dispatch = useDispatch();
    useGetDataOnNavFocus(navigation, () => dispatch(getKeywordsApi()));
    const renderDrawerContent = ({ navigation }) => <CustomDrawerContent navigation={navigation} />;
    return (
        <Drawer.Navigator
            drawerContent={renderDrawerContent} 
            screenOptions={{ 
                headerShown: false,
                drawerType: isLargeScreen() ? 'permanent' : 'front',
                drawerStyle: { width: DRAWER_WIDTH }
            }}>
            <Drawer.Screen name="PublicStack" component={PublicStack} />
        </Drawer.Navigator>
    );
}
export default memo(PublicDrawerNavigator);

const styles = StyleSheet.create({
    borderTop: {
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: GRAY_LINE_COLOR
    },
    header: { 
        backgroundColor: MAIN_COLOR,
        borderBottomColor: 'rgba(255,255,255,0.3)',
        borderBottomWidth: 4,
        marginBottom: 40
    },
    headerPressable: {
        paddingVertical: 20,
        alignItems: 'center',
    },
    welcome: {
        color: '#fff', 
        fontSize: 14,
        marginBottom: 2
    },
    nickname: {
        color: '#fff',
        fontSize: 15
    },
    footer: {
        marginTop: 'auto',
        paddingVertical: 20,
        paddingHorizontal: 15,
        borderTopWidth: 1,
        borderTopColor: GRAY_LINE_COLOR,
        backgroundColor: GRAY_LIGHT
    }
})