import React, { memo } from 'react';
import { View, StyleSheet } from 'react-native';
import MontSBold from './MontserratBold';
import { GRAY_LINE_COLOR } from '../constants'

function TitleLine({ title, style, lineStyle={}, titleStyle={} }) {
    return (
        <View style={[styles.container, style]}>
            <View style={[styles.line, lineStyle]} />
            <MontSBold style={[styles.title, titleStyle ]} text={title} />
        </View>
    )
}

export default memo(TitleLine);

const styles = StyleSheet.create({
    container: { 
        alignSelf: 'stretch',
        alignItems: 'center', 
        marginBottom: 15 
    },
    line: { 
        borderTopWidth: StyleSheet.hairlineWidth, 
        borderTopColor: GRAY_LINE_COLOR, 
        position: 'absolute', 
        left: 0, 
        right: 0, 
        top: '50%' 
    },
    title: { 
        backgroundColor: '#fff', 
        paddingHorizontal: 10,
        zIndex: 2
    },
})