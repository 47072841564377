import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import Avatar from './Avatar';
import { getResponsiveValue } from '../utils';
import { Feather } from '@expo/vector-icons';
import { MAIN_COLOR, SECONDARY_COLOR, GRAY_LIGHT, GRAY_LINE_COLOR } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

export default function ShelfInfo({shelf, user, isOwner, navigation, tBase }) {
    const {t, locale, timeAgoInWords} = useContext(AppSettingsContext);
    const showOwner = !isOwner && user;
    const handleUserPress = () => navigation.push('User', { userId: user.id });
    const flexDirection = showOwner ? getResponsiveValue(['column', 'column', 'column', 'column', 'row'], 'x') : 'row';
    const marginVertical = getResponsiveValue([7, 7, 7, 7, 0], 'x');
    const marginHorizontal = getResponsiveValue([0, 0, 0, 0, 6], 'x');
    return (
        <View style={ styles.container }>
            <View style={{ alignItems: 'center' }}>
                <View style={{ flexDirection, flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <InterReg style={{ fontSize: 14 }} text={ t(`${tBase}.info.shelfWith`) } />
                        <MontSBold style={{ fontSize: 14, color: MAIN_COLOR }} text={ t(`${tBase}.info.books`, { count: shelf.bookIds.length }) } />
                        <InterReg style={{ fontSize: 14 }} text={ t(`${tBase}.info.${showOwner ? 'createdBy' : 'created'}`) } />
                    </View>
                    { showOwner &&
                    <TouchableOpacity
                        onPress={ handleUserPress }
                        style={[ styles.user, { marginVertical, marginHorizontal }]}>
                        <Avatar avatar={ user.avatar } avatarSize={ 20 } blurHash={user.blurHash} />
                        <MontSBold style={{ fontSize: 13, paddingLeft: 8, paddingRight: 4 }} text={ user.nickname } />
                        <Feather name='chevron-right' size={ 19 } color='rgba(0,0,0,0.3)' />
                    </TouchableOpacity>  
                    }
                    { shelf.created &&
                    <Text>
                        { locale.indexOf('fr') === 0 && <InterReg style={{ fontSize: 14 }} text={t(`${tBase}.info.ago`)} /> }
                        <MontSBold style={{ fontSize: 14, color: SECONDARY_COLOR }} text={ ' ' + timeAgoInWords(shelf.created, new Date()) } />
                        { locale.indexOf('en') === 0 && <InterReg style={{ fontSize: 14 }} text={t(`${tBase}.info.ago`)} /> }
                    </Text>
                    }
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center', 
        marginTop: 6, 
        marginBottom: 25, 
        paddingBottom: 25,
        marginHorizontal: 10, 
        borderBottomWidth: StyleSheet.hairlineWidth, 
        borderBottomColor: GRAY_LINE_COLOR
    },
    user: { 
        borderRadius: 26,
        paddingVertical: 3, 
        paddingHorizontal: 3, 
        flexDirection: 'row', 
        alignItems: 'center',
        backgroundColor: GRAY_LIGHT 
    }
})