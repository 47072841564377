import React, { useContext } from 'react';
import { View, StyleSheet, KeyboardAvoidingView } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetPasswordApi } from '../actions/resetPassword';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { yupResolver } from '@hookform/resolvers/yup';
import { GRAY_LINE_COLOR, MAIN_COLOR } from '../constants';
import * as yup from 'yup';
import AppSettingsContext from '../context/AppSettingsContext';
import Input from '../components/Input';
import Button from '../components/Button';
import AuthLogo from '../components/AuthLogo';
import { isIOS, handleFormErrors } from '../utils';

const tBase = 'forms.passwordReset';
const tErrors = `${tBase}.errors`;

const schema = (t) => yup.object().shape({
    email: yup
        .string()
        .required(() => t(`${tErrors}.emailRequired`))
        .email(() => t(`${tErrors}.emailNotValid`))
});


export default function ResetPasswordScreen({ navigation }) {
    const {t} = useContext(AppSettingsContext);
    const yupSchema = schema(t);
    const { handleSubmit, formState: { errors }, control, reset, setError } =  useForm({
        resolver: yupResolver(yupSchema)
    });
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const onSubmit = data => dispatch(resetPasswordApi(data)).then(done);
    const done = (json) => {
        if(json?.errors) {
            handleFormErrors(json.errors, setError, yupSchema);
        } else if(json?.success) {
            reset();
        }
    }
    const handleSigninPress = () => navigation.navigate('SignIn');
    return (
        <KeyboardAvoidingView
            behavior={isIOS() ? "padding" : "height"}
            style={[ styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
            <AuthLogo />
            <View style={{ maxWidth: 500, minWidth: 300, width: '100%' }}>
                <Controller
                    name="email"
                    defaultValue=""
                    control={control}
                    render={ ({ field }) =>(
                        <Input { ...{
                            inputProps: {
                                ...field,
                                autoCapitalize: 'none',
                                onSubmitEditing: handleSubmit(onSubmit),
                                returnKeyType: 'send',
                                autoCorrect: false,
                            }, tBase, errors
                        }} />
                    )}
                />
                <Button text={ t(`${tBase}.submit`) } buttonStyle={{ marginTop: 10 }} onPress={ handleSubmit(onSubmit)} />
                <View style={{ borderBottomWidth: 1, marginVertical: 30, borderBottomColor: GRAY_LINE_COLOR, marginHorizontal: 10 }}/>
                <Button text={ t(`${tBase}.navigate.signin`) } theme='mainColorBorder' buttonStyle={{ marginTop: 10 }} onPress={ handleSigninPress } />
            </View>
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center', 
        justifyContent: 'center',
        paddingHorizontal: 25,
    },
    button: {
        paddingVertical: 10,
        backgroundColor: MAIN_COLOR,
        alignSelf: 'stretch',
        borderRadius: 10,
        alignItems: 'center',
    }
})