import { createAction } from '@reduxjs/toolkit';

export const clearUserIdAction = createAction('CLEAR_USER_ID');

export function clearUserId() {
    return (dispatch) => {
    	dispatch(clearUserIdAction())
    	return Promise.resolve(true);
    }
}

export const emptyStoresAction = createAction('EMPTY_STORES');

export function emptyStores() {
    return (dispatch) => {
    	dispatch(emptyStoresAction())
    	return Promise.resolve(true);
    }
}

export const modalOpen = createAction('MODAL_OPEN');
export const modalClose = createAction('MODAL_CLOSE');
export const setModalSize = createAction('MODAL_SET_SIZE');
export const resetSearchPattern = createAction('SET_SEARCH_PATTERN');
export const setWindowDimensions = createAction('SET_WINDOW_DIMENSIONS');
export const showAlert = createAction('SHOW_ALERT');
export const hideAlertAction = createAction('HIDE_ALERT');
export const setInitialLoadPath = createAction('SET_INITIAL_LOAD_PATH');
export const setActiveBottomTab = createAction('SET_ACTIVE_BOTTOM_TAB');
export const setBookReviewHeight = createAction('SET_BOOK_REVIEW_HEIGHT');

export function hideAlert() {
    return (dispatch) => {
    	dispatch(hideAlertAction())
    	return Promise.resolve(true);
    }
}

export const addNotifIdentifier = createAction('ADD_NOTIF_IDENTIFIER');

export const userFollowedMe = createAction('USER_FOLLOWED_ME');
export const userFollowed = createAction('USER_FOLLOWED');
export const userCancelledFollowRequest = createAction('USER_CANCELLED_FOLLOW_REQUEST');
export const messageReceived = createAction('MESSAGE_RECEIVED');
export const groupRequestAccepted = createAction('GROUP_REQUEST_ACCEPTED');

export const removeGroupFromStore = createAction('REMOVE_GROUP_FROM_STORE');
export const removeBookFromStore = createAction('REMOVE_BOOK_FROM_STORE');
export const removeListFromStore = createAction('REMOVE_LIST_FROM_STORE');
export const removeShelfFromStore = createAction('REMOVE_SHELF_FROM_STORE');
export const removeGroupCollectionFromStore = createAction('REMOVE_GROUP_COLLECTION_FROM_STORE');