import React, { useRef, useMemo, useContext } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import InterReg from './InterReg';
import InterSemiBold from './InterSemiBold';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import { useHover } from 'react-native-web-hooks';
import { MaterialCommunityIcons as MCIcons, MaterialIcons as MIcons } from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';
import { updateBookApi } from '../actions/updateBook';
import { useDispatch } from 'react-redux';
import CustomMenu from '../utils/customMenu';
import { SECONDARY_COLOR, GRAY_LINE_COLOR, GRAY_LIGHT, READING_STATUSES, MAIN_COLOR } from '../constants';

const MenuItem = ({onSelect, readingStatus, currentReadingStatus, last, first}) => {
    const {t} = useContext(AppSettingsContext);
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const isActive = currentReadingStatus === readingStatus;
    const iconColor = isActive ? MAIN_COLOR : SECONDARY_COLOR;
    const renderReadingStatusIcon = useMemo(() => {
        if(readingStatus == 'reading') return <MCIcons name='bookmark' color={iconColor} size={24} />
        if(readingStatus == 'read') return <MCIcons name='bookmark-check' color={iconColor} size={24} />
        if(readingStatus == 'toRead') return <MIcons name='watch-later' color={iconColor} size={21} />
        return <MCIcons name='bookmark-minus' color={iconColor} size={24} />
    });
    return (
        <MenuOption 
            onSelect={onSelect} 
            customStyles={{
                optionTouchable: {
                    ref: ref,
                    underlayColor: GRAY_LIGHT,
                    style: { 
                        backgroundColor: isHovered ? '#fcfcfc' : 'transparent',
                        borderTopRightRadius: first ? 8 : 0,
                        borderTopLeftRadius: first ? 8 : 0,
                        borderBottomRightRadius: last ? 8 : 0,
                        borderBottomLeftRadius: last ? 8 : 0
                    }
                }
            }}
            style={{ 
                paddingHorizontal: 10, 
                paddingTop: first ? 12 : 10,
                paddingBottom: last ? 12 : 10,
                borderBottomWidth: last ? 0 : StyleSheet.hairlineWidth, 
                borderBottomColor: GRAY_LINE_COLOR, 
                flexDirection: 'row', 
                alignItems: 'center'
            }}>
            <View style={ styles.menuOptionIcon }>
                {renderReadingStatusIcon}
            </View>
            { isActive ?
                <InterSemiBold  text={t(`book.status.${readingStatus}`)} style={[styles.menuOptionText, { color: MAIN_COLOR}]} /> :
                <InterReg text={t(`book.status.${readingStatus}`)} style={styles.menuOptionText} />
            }
        </MenuOption>
    )
}

export default function ContextMenu({ 
        style, 
        bookId,
        TriggerButton, 
        triggerButtonHitSlop={ top: 10, left: 10, right: 10, bottom: 10 },
        menuWidth=200,
        anchorFill='#fff',
        placement='auto', 
        preferredPlacement='bottom', 
        currentReadingStatus }) {
    const dispatch = useDispatch();
    const optionsContainerStyles = { 
        borderRadius: 8, 
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
          })
        
    }
    return (
        <Menu 
            style={ style }
            renderer={ CustomMenu } 
            rendererProps={{
                placement,
                preferredPlacement,
                anchorStyle: { fill: anchorFill, zIndex: 2 } 
            }}>
            <MenuTrigger customStyles={{ 
                    triggerTouchable: { 
                        hitSlop: triggerButtonHitSlop,
                        underlayColor: 'transparent'
                    }
                }}>
                { TriggerButton() }
            </MenuTrigger>
            <MenuOptions customStyles={{ 
                    optionsContainer: { width: menuWidth, margin: 0, paddingVertical: 0, ...optionsContainerStyles }, 
                }}>
                { READING_STATUSES.map((readingStatus, index) => ( 
                    <MenuItem 
                        key={index}
                        readingStatus={readingStatus}
                        currentReadingStatus={currentReadingStatus}
                        first={index==0} 
                        onSelect={ () => {
                            if(currentReadingStatus !== readingStatus) {
                                dispatch(updateBookApi({ book: { readingStatus, id: bookId }}))
                            }
                        }}
                        last={index==READING_STATUSES.length-1} /> 
                    )
                )}
            </MenuOptions>
        </Menu>
    )
}

const styles = StyleSheet.create({
    menuOptionIcon: {
        width: 25, 
        alignItems: 'center', 
        justifyContent: 'center',
        marginRight: 8,
    },
    menuOptionText: {
        fontSize: 15,
        marginTop: 1,
        color: SECONDARY_COLOR
    },
})