import { REMOVE_GROUP_MEMBER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const removeGroupMemberInProgress = createAction('REMOVE_GROUP_MEMBER_IN_PROGRESS');
export const removeGroupMemberSuccess = createAction('REMOVE_GROUP_MEMBER_SUCCESS');
export const removeGroupMemberFailure = createAction('REMOVE_GROUP_MEMBER_FAILURE');

export function removeGroupMemberApi({ userId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { userId, groupId },
            actions: [removeGroupMemberInProgress, removeGroupMemberSuccess, removeGroupMemberFailure],
            name: 'removeGroupMember',
            dispatch,
            getState
        })
    }
}