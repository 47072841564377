import React, { memo, useEffect } from 'react';
import { StyleSheet, KeyboardAvoidingView, View, Image, useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useHeaderHeight } from '@react-navigation/elements';
import { useKeyboard } from '@react-native-community/hooks';
import InterReg from './InterReg';
import MontSBold from './MontserratBold';
import Button from './Button';
import { isIOS } from '../utils';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useIsMounted } from '../hooks';

function Empty({title, body, primaryActionLabel='', primaryActionTheme='mainColor', primaryActionOnPress, img, Icon }) {
    const isMounted = useIsMounted();
    const insets = useSafeAreaInsets();
    const headerHeight = useHeaderHeight();
    const translateY = useSharedValue(-insets.bottom);
    const keyboard = useKeyboard();
    const { height, width } = useWindowDimensions();
    const minHeight = (() => {
        if(height > 900) return 250;
        if(height < 700) return 80;
        return 150;
    })()
    const maxHeight = (() => {
        if(height > 900) return 250;
        if(height < 700) return 150;
        return 200;
    })()
    const marginBottom = (() => {
        if(height < 700) return '4%';
        return '7%';
    })()
    const titleStyle = (() => {
        if(height < 700) return { fontSize: 16, marginBottom: 6 };
        return { fontSize: 18, marginBottom: 10 };
    })()
    useEffect(() => {
        if(isMounted() && keyboard?.coordinates?.start?.screenY && keyboard?.coordinates?.end?.screenY) {
            const keyboardWillBeShown = keyboard.coordinates.start.screenY > keyboard.coordinates.end.screenY && keyboard.keyboardShown == false;
            const keyboardWillBeHidden = keyboard.coordinates.start.screenY < keyboard.coordinates.end.screenY && keyboard.keyboardShown == true;
            if(keyboardWillBeShown || (keyboard.keyboardShown && !keyboardWillBeHidden)) {
                translateY.value = withTiming(0, { duration: 200 })
            } else if(keyboardWillBeHidden || !keyboard.keyboardShown) {
                translateY.value = withTiming(-insets.bottom, { duration: 200 })
            }
        }
    }, [keyboard]);
    const animatedStyle = useAnimatedStyle(() => ({ transform: [{translateY: translateY.value}] }));
    function renderImg() {
        if(Icon) {
            return <View style={{ alignItems: 'center', marginBottom: 15 }}>{ Icon }</View>
        }
        if(img) {
            return <Image source={img} resizeMode='contain'  style={{ marginBottom, height: '50%', minHeight, maxHeight, width: 'auto' }} />
        }
        return null;
    }
    return (
        <KeyboardAvoidingView 
            behavior={ isIOS() ? "padding" : "height" }
            keyboardVerticalOffset={headerHeight+insets.top + 10}
            style={styles.container}>
            <Animated.View style={[styles.content, animatedStyle]}>
                { renderImg() }
                <MontSBold style={[titleStyle, styles.title]} text={title} />
                <InterReg style={styles.body} text={body} />
                { primaryActionOnPress &&
                    <View style={styles.buttons}> 
                        <Button
                            size="small"
                            theme={primaryActionTheme}
                            buttonStyle={{ alignSelf: 'center' }}
                            onPress={primaryActionOnPress} 
                            text={primaryActionLabel} />
                    </View>
                }
            </Animated.View>
        </KeyboardAvoidingView>
    )
}

export default memo(Empty)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        justifyContent: 'center',
        width: '100%',
        minWidth: 320,
        maxWidth: 500, 
        paddingHorizontal: 20
    },
    title: {
        color: '#333',
        textAlign: 'center'
    },
    body: {
        color: '#666',
        textAlign: 'center'
    },
    buttons: {
        paddingTop: 20
    }
})