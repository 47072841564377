import { CANCEL_FOLLOW_USER_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const cancelFollowUserRequestInProgress = createAction('CANCEL_FOLLOW_USER_REQUEST_IN_PROGRESS');
export const cancelFollowUserRequestSuccess = createAction('CANCEL_FOLLOW_USER_REQUEST_SUCCESS');
export const cancelFollowUserRequestFailure = createAction('CANCEL_FOLLOW_USER_REQUEST_FAILURE');

export function cancelFollowUserRequestApi({ followRequestId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { followRequestId },
            actions: [cancelFollowUserRequestInProgress, cancelFollowUserRequestSuccess, cancelFollowUserRequestFailure],
            name: 'cancelFollowUserRequest',
            dispatch,
            getState
        })
    }
}