import { LEAVE_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const leaveGroupInProgress = createAction('LEAVE_GROUP_IN_PROGRESS');
export const leaveGroupSuccess = createAction('LEAVE_GROUP_SUCCESS');
export const leaveGroupFailure = createAction('LEAVE_GROUP_FAILURE');

export function leaveGroupApi({ groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId },
            actions: [leaveGroupInProgress, leaveGroupSuccess, leaveGroupFailure],
            name: 'leaveGroup',
            dispatch,
            getState
        })
    }
}