import { createReducer } from '@reduxjs/toolkit'
import { getDiscussionsSuccess } from '../actions/getDiscussions';
import { matchResetStore, hasDiscussion } from './matchers';

const initialState = [];

export const discussions = createReducer(initialState, (builder) => {
    builder
    .addCase(getDiscussionsSuccess, (state, action) => {
        return action.payload.discussions;
    })
    .addMatcher(hasDiscussion, (state, action) => {
        const {discussion} = action.payload;
        const index = state.findIndex(({id}) => id == discussion.id);
        if(index > -1) {
            state[index] = discussion;
        } else {
            state.push(discussion);
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})