import React, { useEffect, useState, memo, useContext } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { GRAY_LINE_COLOR, SECONDARY_COLOR, GRAY_TEXT_PALE } from '../constants';
import InterReg from '../components/InterReg';
import Animated, { withTiming, useAnimatedStyle, useSharedValue, Easing } from 'react-native-reanimated';
import { isEmpty } from '../utils';
import AppSettingsContext from '../context/AppSettingsContext';

const Tabs = memo(({ tabs, style={}, activeTab, counts={}, icons={}, onTabPress, tBase }) => {
    const {t} = useContext(AppSettingsContext);
    const [tabsWidth, setTabsWidth] = useState(0);
    const index = useSharedValue(0);
    useEffect(() => {
        const activeIndex = tabs.indexOf(activeTab);
        index.value = withTiming(activeIndex, { duration: 200, easing: Easing.cubic });
    }, [activeTab]);
    const indicatorStyle = useAnimatedStyle(() => {
        const portion = 100 / tabs.length;
        const translateX = (tabsWidth / tabs.length) * index.value;
        return { 
            transform: [{translateX}],
            width: portion + '%'
        }
    })
    const onLayout = ({nativeEvent}) => setTabsWidth(nativeEvent.layout.width);
    return (
        <View style={[styles.tabs, style]} onLayout={onLayout}>
            { tabs.map(tab => (
                <TouchableOpacity
                    key={tab} 
                    onPress={ () => onTabPress(tab) }
                    style={styles.tab}>
                        <View style={{flexDirection: 'row'}}>
                            { icons[tab] && icons[tab]() }
                            <InterReg 
                                style={[
                                    styles.tabLabel, 
                                    tab == activeTab && styles.activeTabLabel
                                ]}
                                text={t(`${tBase}.tab.label.${tab}`)} />
                            { typeof counts[tab] === 'number' &&
                                <InterReg 
                                    style={styles.countBadge}
                                    text={`(${counts[tab]})`} />
                            }
                        </View>
                </TouchableOpacity>
            ))}
            <Animated.View style={[styles.indicator, indicatorStyle]} />
        </View>
    )
}, (prevProps, nextProps) => {
    const activeTabSame = prevProps.activeTab == nextProps.activeTab;
    const countsSame = (() => {
        let isEqual = true;
        if(isEmpty(prevProps.counts) && isEmpty(nextProps.counts)) return isEqual;
        for(const [key, count] of Object.entries(prevProps.counts)) {
            if(nextProps.counts[key] !== count) {
                isEqual = false;
                break;
            }
        }
        return isEqual;
    })()
    return activeTabSame && countsSame;
})

const styles = StyleSheet.create({
    tabs: {
        flexDirection: 'row',
        marginTop: 15
    },
    tab: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        paddingBottom: 2,
        borderBottomWidth: 1,
        borderBottomColor: GRAY_LINE_COLOR
    },
    tabLabel: {
        color: '#666'
    },
    activeTabLabel: {
        color: SECONDARY_COLOR,
        fontFamily: 'Inter_600SemiBold'
    },
    countBadge: {
        fontSize: 13,
        marginLeft: 6,
        transform: [{translateY: 1}],
        color: GRAY_TEXT_PALE
    },
    indicator: {
        position: 'absolute',
        bottom: 0,
        height: 3,
        backgroundColor: SECONDARY_COLOR
    }
})

export default Tabs;