import { SET_PRIVATE_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setPrivateInProgress = createAction('SET_PRIVATE_IN_PROGRESS');
export const setPrivateSuccess = createAction('SET_PRIVATE_SUCCESS');
export const setPrivateFailure = createAction('SET_PRIVATE_FAILURE');

export function setPrivateApi({value, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { isPrivate: value },
            actions: [setPrivateInProgress, setPrivateSuccess, setPrivateFailure],
            name: 'setPrivate',
            showLoading,
            dispatch,
            getState
        })
    }
}