import { createReducer } from '@reduxjs/toolkit'
import { generateCoverSuccess } from '../actions/generateCover';
import { matchResetStore } from './matchers';

const initialState = {};

export const generatedCovers = createReducer(initialState, (builder) => {
    builder
    .addCase(generateCoverSuccess, (state, action) => {
        state[action.payload.isbn] = {
            url: action.payload.cover,
            source: action.payload.coverSource
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})