import { CANCEL_GROUP_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const cancelGroupRequestInProgress = createAction('CANCEL_GROUP_REQUEST_IN_PROGRESS');
export const cancelGroupRequestSuccess = createAction('CANCEL_GROUP_REQUEST_SUCCESS');
export const cancelGroupRequestFailure = createAction('CANCEL_GROUP_REQUEST_FAILURE');

export function cancelGroupRequestApi({ groupRequestId, groupId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupRequestId, groupId },
            actions: [cancelGroupRequestInProgress, cancelGroupRequestSuccess, cancelGroupRequestFailure],
            name: 'cancelGroupRequest',
            dispatch,
            getState
        })
    }
}