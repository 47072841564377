import { GET_KEYWORDS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getKeywordsInProgress = createAction('GET_KEYWORDS_IN_PROGRESS');
export const getKeywordsSuccess = createAction('GET_KEYWORDS_SUCCESS');
export const getKeywordsFailure = createAction('GET_KEYWORDS_FAILURE');

export function getKeywordsApi({ showLoading=false, checkCaching=true }={}) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getKeywordsInProgress, getKeywordsSuccess, getKeywordsFailure],
            name: 'getKeywords',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}