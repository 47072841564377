import React, { memo, useContext } from 'react';
import { View, Text } from 'react-native';
import MontSBold from './MontserratBold';
import InterReg from './InterReg';
import { MAIN_COLOR, SECONDARY_COLOR, GRAY_TEXT_PALE } from '../constants';
import AppSettingsContext from '../context/AppSettingsContext';

function FilterStateHeader({ bookCount, tBase, resetPattern }) {
    const {t} = useContext(AppSettingsContext);
    const handlePressReset = () => resetPattern();
    return (
        <View style={{ backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center', paddingTop: 6, paddingBottom: 18, paddingHorizontal: 30, flexDirection: 'row'}}>
            <Text style={{ textAlign: 'center' }}>
                <MontSBold style={{color: SECONDARY_COLOR }} text={`${bookCount} ${t('general.books', { count: bookCount })}`} />{' '} 
                <InterReg text={t(`${tBase}.searchHeader.matches`, { count: bookCount })} />
                <InterReg style={{color: GRAY_TEXT_PALE }} text=' – ' />{' '}
                <InterReg onPress={handlePressReset} style={{ color: MAIN_COLOR}} text={t(`${tBase}.searchHeader.showAll`)}  />
            </Text>
        </View>
    )
}

export default memo(FilterStateHeader)