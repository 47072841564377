import React, { useState, useContext, useEffect } from 'react';
import { Pressable, View, Text, StyleSheet, KeyboardAvoidingView } from 'react-native';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { signupApi } from '../actions/signup';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSettingsContext from '../context/AppSettingsContext';
import Input from '../components/Input';
import Button from '../components/Button';
import MontSBold from '../components/MontserratBold';
import InterReg from '../components/InterReg';
import Checkbox from '../components/Checkbox';
import AuthLogo from '../components/AuthLogo';
import TextLink from '../components/TextLink';
import { isIOS, handleFormErrors } from '../utils';
import { MAIN_COLOR, GRAY_TEXT_PALE } from '../constants';

const tBase = 'forms.signup';
const tErrors = `${tBase}.errors`;

const schema = (t) => yup.object().shape({
    email: yup
        .string()
        .required(() => t(`${tErrors}.emailRequired`))
        .email(() => t(`${tErrors}.emailNotValid`)),
    password: yup
        .string()
        .required(() => t(`${tErrors}.passwordRequired`))
        .min(6, () => t(`${tErrors}.passwordNotStrong`))
        .matches(/\d/, () => t(`${tErrors}.passwordNotStrong`)),
    terms: yup
        .boolean()
        .oneOf([true], () => t(`${tErrors}.termsMustBeAccepted`)),
});

export default function SignUpScreen({ route, navigation }) {
    const [suggestedEmail, setSuggestedEmail] = useState(null);
    const [suggestionForEmail, setSuggestionForEmail] = useState(null);
    const [showSuggestedEmail, setShowSuggestedEmail] = useState(false);
    const {t} = useContext(AppSettingsContext);
    const yupSchema = schema(t);
    const { handleSubmit, formState: { errors }, setError, setValue, watch, control } =  useForm({
        resolver: yupResolver(yupSchema)
    });
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const onSubmit = data => {
        if(route?.params?.invitedBy) data.invitedBy = route.params.invitedBy;
        dispatch(signupApi({
            ...data,
            prevSuggestedEmail: suggestedEmail,
            prevSuggestionForEmail: suggestionForEmail
        })).then(done);
    }
    const done = (json) => {
        if(json?.errors) {
            handleFormErrors(json.errors, setError, yupSchema);
        } else if(json?.suggestedEmail) {
            setSuggestedEmail(json?.suggestedEmail);
            setSuggestionForEmail(email);
            setShowSuggestedEmail(true);
        } else {
            setSuggestedEmail(null);
            setSuggestionForEmail(null);
            setShowSuggestedEmail(false);
        }
    }
    const handleSigninPress = () => navigation.navigate('SignIn');
    const handleSuggesstedEmailPress = () => {
        setValue('email', suggestedEmail);
        setShowSuggestedEmail(false);
    }
    const renderEmailSuggestion = () => {
        if(!showSuggestedEmail) return null;
        return (
            <Text>
                <InterReg text={ t(`${tBase}.didYouMeanToType`)} />
                { ' ' }
                <TextLink style={{color: MAIN_COLOR}} onPress={handleSuggesstedEmailPress} text={suggestedEmail} />
                <InterReg text={ t(`${tBase}.questionMark`)} />
            </Text>
        )
    }
    const email = watch('email');
    useEffect(() => {
        if(showSuggestedEmail && suggestedEmail && suggestedEmail === email) {
            setShowSuggestedEmail(false);
        } else if(!showSuggestedEmail && suggestedEmail && suggestionForEmail === email) {
            setShowSuggestedEmail(true);
        }
    }, [email])
    return (
        <KeyboardAvoidingView
            behavior={isIOS() ? "padding" : "height"}
            style={[ styles.container, { paddingTop: insets.top, paddingBottom: insets.bottom }]}>
            <AuthLogo />
            <View style={{ maxWidth: 500, minWidth: 300, width: '100%' }}>
                <Controller
                    name="email"
                    defaultValue={route?.params?.email ?? ""}
                    control={control}
                    render={ ({ field }) =>(
                        <Input { ...{
                            inputProps: {
                                ...field,
                                autoCapitalize: 'none',
                                
                                autoCorrect: false,
                            }, tBase, errors, Suggestion: renderEmailSuggestion()
                        }} />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={ ({ field }) => (
                        <Input { ...{
                            inputProps: {
                                ...field,
                                onSubmitEditing: handleSubmit(onSubmit),
                                returnKeyType: 'send'
                            }, tBase, errors
                        }} />
                    )}
                />
                <Controller
                    name="terms"
                    control={control}
                    defaultValue={ false }
                    render={ ({ field: { value, name, onChange }}) => (
                        <Checkbox 
                            name={ name }
                            onChange={ onChange }
                            onLabelPress={ () => console.log('Show terms') }
                            checked={ value }
                            errors={ errors }
                            tBase={ tBase }
                        />
                    )}
                />
                <Button text={ t(`${tBase}.submit`) } buttonStyle={{ marginTop: 8 }} onPress={ handleSubmit(onSubmit)} />
                <View style={{ paddingTop: 20, justifyContent: "center", flexDirection: 'row' }}>
                    <View style={{ paddingVertical: 5 }}><MontSBold style={{ color: GRAY_TEXT_PALE }} text={ t(`${tBase}.alreadyHaveAccount`) } /></View>
                    <Pressable style={{ padding: 5 }} onPress={ handleSigninPress }>
                        <MontSBold style={{ color: MAIN_COLOR }} text={ t(`${tBase}.navigate.signin`) } />
                    </Pressable>
                </View>
            </View>
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center', 
        justifyContent: 'center',
        paddingHorizontal: 25,
    }
})