import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Entypo } from '@expo/vector-icons';

export default function IconPossessed({ size=34, color='#fff', iconSize, bgColor='#ccc', style }) {
    const circleSize = size;
    return (
        <View style={[styles.circle, { backgroundColor: bgColor, width: circleSize, height: circleSize, borderRadius: circleSize/2 }, style]}>
            <Entypo name="check" color={ color } size={ iconSize ?? size-10 } />
        </View>
    )
}

const styles = StyleSheet.create({
    circle: { 
        alignItems: 'center', 
        justifyContent: 'center' 
    }
})