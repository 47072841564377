import { createReducer, isAnyOf } from '@reduxjs/toolkit'
import { userFollowedMe, userFollowed } from '../actions';
import { hasUsers, hasUser, hasAccount, loginOrSignup, matchResetStore, hasAccountParam, hasAccountProp } from './matchers';

const initialState = {};

const paramsToSet = ['receiveNewsletter', 'receiveNotifsByEmail', 'receiveMessagesByEmail', 'lendBookDurationInDays', 'foundInSearch', 'sortLibraryBooks'];
const propsToSet = ['isPrivate', 'tokenExpo', 'preferredLang'];

export const users = createReducer(initialState, (builder) => {
    builder
    .addCase(userFollowedMe, (state, action) => {
        if(state[action.payload.loggedUserId]) {
            state[action.payload.loggedUserId].followerIds.push(action.payload.userId)
        }
    })
    .addCase(userFollowed, (state, action) => {
        state[action.payload.loggedUserId].followingIds.push(action.payload.userId)
    })
    .addMatcher(hasAccountProp, (state, action) => {
        if(state[action.payload.loggedUserId]) {
            for(let propToSet of propsToSet) {
                const newProp = (() => {
                    if(action.payload[propToSet] !== undefined) return action.payload[propToSet];
                    if(action.payload?.account && action.payload?.account[propToSet]) return  action.payload?.account[propToSet];
                    return
                })();
                if(newProp !== undefined) {
                    state[action.payload.loggedUserId][propToSet] = newProp;
                    break;
                }
            }
        }
    })
    .addMatcher(hasAccountParam, (state, action) => {
        if(state[action.payload.loggedUserId]) {
            for(let paramToSet of paramsToSet) {
                if(action.payload[paramToSet] !== undefined) {
                    state[action.payload.loggedUserId].params[paramToSet] = action.payload[paramToSet];
                    break;
                }
            }
        }
    })
    .addMatcher(hasAccount, (state, action) => {
        if(action?.payload?.account) {
            state[action.payload.account.id] = action.payload.account;
        }
    })
    .addMatcher(hasUser, (state, action) => {
        if(action.payload?.user?.id) state[action.payload.user.id] = action.payload.user;
    })
    .addMatcher(isAnyOf(hasUsers, loginOrSignup), (state, action) => {
        action.payload.users.forEach(user => {
            if(user.id == action.payload.loggedUserId && !user.email) console.error(action.type, 'logged user missing email')
            state[user.id] = user
        })
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})