import { ADD_BOOK_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const addBookInProgress = createAction('ADD_BOOK_IN_PROGRESS');
export const addBookSuccess = createAction('ADD_BOOK_SUCCESS');
export const addBookFailure = createAction('ADD_BOOK_FAILURE');

export function addBookApi({ book, cover }, addMethod='') {
    return (dispatch, getState) => {
        return api({
            url,
            data: { book, cover },
            actions: [addBookInProgress, addBookSuccess, addBookFailure],
            name: 'addBook',
            dispatch,
            getState,
            addToPayload: { addMethod }
        })
    }
}