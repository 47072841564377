import { createReducer } from '@reduxjs/toolkit'
import { addNotifIdentifier } from '../actions';
import { matchResetStore } from './matchers';

const initialState = [];

export const handledNotifs = createReducer(initialState, (builder) => {
    builder
    .addCase(addNotifIdentifier, (state, action) => {
        state.push(action.payload.identifier);
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})