import { GET_USERS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getUsersInProgress = createAction('GET_USERS_IN_PROGRESS');
export const getUsersSuccess = createAction('GET_USERS_SUCCESS');
export const getUsersFailure = createAction('GET_USERS_FAILURE');

export function getUsersApi({ userIds, checkCaching=true, showLoading=true }) {
    return (dispatch, getState) => {
        const { loggedUserid } = getState();
        return api({
            url,
            data: { userIds: userIds.filter(({id}) => id != loggedUserid) },
            actions: [getUsersInProgress, getUsersSuccess, getUsersFailure],
            name: 'getUsers',
            dispatch,
            checkCaching,
            showLoading,
            getState
        })
    }
}