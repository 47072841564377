import { GET_LISTS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getListsInProgress = createAction('GET_LISTS_IN_PROGRESS');
export const getListsSuccess = createAction('GET_LISTS_SUCCESS');
export const getListsFailure = createAction('GET_LISTS_FAILURE');

export function getListsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getListsInProgress, getListsSuccess, getListsFailure],
            name: 'getLists',
            showLoading,
            checkCaching,
            dispatch,
            getState
        })
    }
}