import React, { useEffect, useState, memo } from 'react';
import { View } from 'react-native';
import InterReg from './InterReg'
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming,
    interpolate
} from 'react-native-reanimated';

import { DANGER_COLOR } from '../constants';

function FormError({ error }) {
    const [msg, setMsg] = useState(error?.message ?? '');
    const [textHeight, setTextHeight] = useState(null);
    const hasError = useSharedValue(0);
    const aniStyle = useAnimatedStyle(() => {
        return {
            opacity: hasError.value,
            height: interpolate(hasError.value, [0, 1], [14, textHeight + 10] )
        }
    })
    useEffect(() => {
        if(textHeight) hasError.value = withTiming(error ? 1 : 0, { duration: 350 });
        if(error && error.message) setMsg(error.message);
    }, [error, textHeight])
    const onLayout = ({ nativeEvent: { layout: { height } } }) => setTextHeight(height);
    return (
        <Animated.View style={[{ minHeight: 10 }, aniStyle]}>
            <View onLayout={onLayout} style={{ position: 'absolute', width: '100%' }}>
                <InterReg 
                    text={ msg } 
                    style={{ color: DANGER_COLOR, fontSize: 13, paddingTop: 3, paddingLeft: 5 }} />
            </View>
        </Animated.View>
    )
}

export default memo(FormError);