import { createReducer } from '@reduxjs/toolkit'
import { setPrivateSuccess } from '../actions/setPrivate';
import { hasLists, hasList, matchResetStore, hasListToRemove } from './matchers';
import { getUserSuccess } from '../actions/getUser';
import { removedUnreturned } from './helpers';

const initialState = {};

export const lists = createReducer(initialState, (builder) => {
    builder
    .addCase(setPrivateSuccess, (state, action) => {
        if(action.payload.isPrivate) {
            for(const [key, list] of Object.entries(state)) {
                if(list.visibility == 'public') {
                    state[key].visibility = 'followers';
                }
            }
        } else {
            for(const [key, list] of Object.entries(state)) {
                if(list.visibility == 'followers') {
                    state[key].visibility = 'public';
                }
            }
        }
    })
    .addMatcher(hasListToRemove, (state, action) => {
        delete state[action.payload.listId];
    })
    .addMatcher(hasList, (state, action) => {
        state[action.payload.list.id] = action.payload.list;
    })
    .addMatcher(hasLists, (state, action) => {
        action.payload.lists.forEach(list => state[list.id] = list)
        if(action.type == getUserSuccess) {
            removedUnreturned({ action, state, returned: action.payload.lists });
        }
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})