import React, { useState, useEffect, memo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { modalClose } from '../actions';
import { View, Pressable } from 'react-native';
import { getStateFromPath } from '@react-navigation/native';
import linkingConfig from '../navigation/LinkingConfiguration'; 
import { isWeb } from '../utils';


function ModalBgClickable({ children }) {
    const modalIsOpen = useSelector(state => state.settings.modal.isOpen, shallowEqual);
    const modalSize = useSelector(state => state.settings.modal.size, shallowEqual);
    const { width:windowWidth, height:windowHeight } = useSelector(state => state.settings.window, shallowEqual);
    const dispatch = useDispatch();
    const [dims, setDims] = useState({ top: 0, left: 0, right: 0, bottom: 0 })
    const calculateDims = () => {
        const horizontal = (windowWidth - modalSize.width) / 2;
        const vertical = (windowHeight - modalSize.height) / 2;
        return {
            top: vertical,
            bottom: vertical,
            left: horizontal,
            right: horizontal,
        }
    }
    function onHistoryStateChange() {
        let { pathname } = window.location;
        const navState = getStateFromPath(pathname, linkingConfig.config);
        if(!navState.routes[0]?.name == 'Modal' && modalIsOpen) {
            dispatch(modalClose());
        }
    }
    useEffect(() => {
        isWeb() && window.addEventListener("popstate", onHistoryStateChange);
        return function cleanup() {
            isWeb() && window.removeEventListener("popstate", onHistoryStateChange);
        }
    })
    useEffect(() => {
        if(modalSize) setDims(calculateDims())
    }, [modalSize])
    const handlePress = useCallback(() => dispatch(modalClose()), []);
    const onLayout = () => {
        if(modalSize && modalIsOpen) setDims(calculateDims())
    }
    return (
        <View onLayout={onLayout} style={{ flex: 1 }}>
            {children}
            { modalIsOpen && windowWidth > 500 ?
            <>
                <Pressable onPress={handlePress} style={{ position: "absolute", top: 0, height: dims.top, width: "100%" }}></Pressable>
                <Pressable onPress={handlePress} style={{ position: "absolute", left: 0, width: dims.left, height: "100%" }}></Pressable>
                <Pressable onPress={handlePress} style={{ position: "absolute", right: 0, width: dims.right, height: "100%" }}></Pressable>
                <Pressable onPress={handlePress} style={{ position: "absolute", bottom: 0, height: dims.bottom, width: "100%" }}></Pressable>
            </>
            : null }
        </View>
    )
}

export default memo(ModalBgClickable);