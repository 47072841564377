import { GET_GROUP_INVITATIONS_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getGroupInvitationsInProgress = createAction('GET_GROUP_INVITATIONS_IN_PROGRESS');
export const getGroupInvitationsSuccess = createAction('GET_GROUP_INVITATIONS_SUCCESS');
export const getGroupInvitationsFailure = createAction('GET_GROUP_INVITATIONS_FAILURE');

export function getGroupInvitationsApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getGroupInvitationsInProgress, getGroupInvitationsSuccess, getGroupInvitationsFailure],
            name: 'getGroupInvitations',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}