import React from 'react';
import { View, Image } from 'react-native';
import { usePromise } from '../hooks';
import { isIOS, getAverageColorFromBlurhash } from '../utils'
import { interpolate, Extrapolate } from 'react-native-reanimated';
import { render as renderBlurhash } from '../utils/blurhash';
import { DEFAULT_BLUR_HASH } from '../constants';

export default function Blurhash({
        blurhash,
        height,
        width,
        punch,
        style
    }) {
    blurhash = blurhash || DEFAULT_BLUR_HASH;
    const { rgba, hsp, opacity } = getAverageColorFromBlurhash(blurhash);
    punch = (() => {
        if(punch === undefined) return interpolate(hsp, [0, 255], [0,1], Extrapolate.CLAMP);
        return 1;
    })();
    const backgroundColor = rgba;
    if(height && height && isIOS()) {
        const { state, value, error } = usePromise(async () => {
            return renderBlurhash({
                blurhash,
                height,
                width,
                punch
            });
        }, [blurhash, height, width, punch]);
        if (state === 'fulfilled') {
            const source = {
                uri: value.uri,
                height,
                width,
            };
            return <Image height={height} width={width} source={source} style={[style, { opacity }]} />
        } else {
            if (error)
                __DEV__ && console.warn('Blurhash failed', error, state, value);
            return (
                <View style={[{ backgroundColor }, { width, height }, style]}/>
            );
        }
    }
    else {
        return (
            <View
                style={[{ backgroundColor }, { width, height: height ? height : 'auto' }, style]}
            />
        );
    }
}