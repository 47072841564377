import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deleteShelfApi } from '../actions/deleteShelf';
import { shelfMenu } from '../utils/menuConfigs';
import AppSettingsContext from '../context/AppSettingsContext';

import Menu from './Menu';

export default function MenuShelf({ style, shelf, TriggerButton, deleteDone, navigation, placement='bottom' }) {
    const {t} = useContext(AppSettingsContext);
    const dispatch = useDispatch();
    const onDelete = () => { dispatch(deleteShelfApi({ shelfId: shelf.id })).then(deleteDone); }
    const onUpdate = () => { navigation.navigate('Modal', { screen: 'UpdateShelf', params: { shelfId: shelf.id }}) }
    return (
        <Menu
            placement={placement}
            menuOptions={ 
                shelfMenu({
                    dispatch, 
                    navigation, 
                    actions: { 
                        onDelete, 
                        onUpdate
                    },
                    t
                }) 
            }
            TriggerButton={ TriggerButton } 
        />
    )
}