import { SEND_GROUP_REQUEST_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const sendGroupRequestInProgress = createAction('SEND_GROUP_REQUEST_IN_PROGRESS');
export const sendGroupRequestSuccess = createAction('SEND_GROUP_REQUEST_SUCCESS');
export const sendGroupRequestFailure = createAction('SEND_GROUP_REQUEST_FAILURE');

export function sendGroupRequestApi({ groupId, showLoading=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId },
            actions: [sendGroupRequestInProgress, sendGroupRequestSuccess, sendGroupRequestFailure],
            name: 'sendGroupRequest',
            dispatch,
            getState,
            showLoading
        })
    }
}