import { RETURN_BOOK_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const returnBookInProgress = createAction('RETURN_BOOK_IN_PROGRESS');
export const returnBookSuccess = createAction('RETURN_BOOK_SUCCESS');
export const returnBookFailure = createAction('RETURN_BOOK_FAILURE');

export function returnBookApi({ lendId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { lendId },
            actions: [returnBookInProgress, returnBookSuccess, returnBookFailure],
            name: 'returnBook',
            dispatch,
            getState
        })
    }
}