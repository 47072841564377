import { GENERATE_COVER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const generateCoverInProgress = createAction('GENERATE_COVER_IN_PROGRESS');
export const generateCoverSuccess = createAction('GENERATE_COVER_SUCCESS');
export const generateCoverFailure = createAction('GENERATE_COVER_FAILURE');

export function generateCoverApi({ isbn, showLoading }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { isbn },
            actions: [generateCoverInProgress, generateCoverSuccess, generateCoverFailure],
            name: 'generateCover',
            dispatch,
            getState,
            showLoading
        })
    }
}