import React, { useContext } from 'react'
import { View, Pressable, StyleSheet } from 'react-native'
import InterReg from './InterReg'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SECONDARY_COLOR } from '../constants';
import Animated, {
    useSharedValue,
    useAnimatedStyle,
    withTiming
} from 'react-native-reanimated';
import AppSettingsContext from '../context/AppSettingsContext';
import FormError from './FormError';

export default function Checkbox({ checked, onLabelPress=() => {}, tBase, name, onChange, errors }) {
    const {t} = useContext(AppSettingsContext);
	return (
        <View>
            <View style={ styles.container }>
                <Check checked={checked} onChange={onChange} />
                <Pressable 
                    onPress={ () => { 
                        onChange(!checked); 
                        onLabelPress(); 
                    }}
                    style={{ marginLeft: 10, flex: 1 }}>
                    <InterReg 
                        text={ t(`${tBase}.labels.${name}`)} 
                        style={{ color: '#666' }} />
                </Pressable>

            </View>
            <View style={{ minHeight: 30, paddingVertical: 5}}>
                <FormError error={ errors[name] }/>
            </View>  
        </View>
	)
}

export function Check({ checked, onChange, style, iconSize=20 }) {
    const scale = useSharedValue(1);
    const scaleStyle = useAnimatedStyle(() => {
        return {
            transformOrigin: 'center',
            transform: [{ scale: withTiming(scale.value, { duration: 250 })}]
        }
    })
    return (
        <Animated.View style={scaleStyle}>
            <Pressable
                hitSlop={{top: 5, left: 5, bottom: 5, right: 5}}
                onPress={ () => onChange(!checked) }
                onPressIn={ () => scale.value = 1.2}
                onPressOut={ () => scale.value = 1}>
                <View style={[styles.checkbox, { backgroundColor: checked ? SECONDARY_COLOR : 'white', borderColor: SECONDARY_COLOR }, style]}>
                    { checked ?
                    <MaterialCommunityIcons
                        name={ 'check' } 
                        size={ iconSize } 
                        color={ "#fff" } /> : null }
                </View>
            </Pressable>
        </Animated.View>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingLeft: 6,
        flexDirection: 'row', 
        alignItems: 'center', 
        alignSelf: 'stretch'
    },
    checkbox: {
        width: 22, 
        height: 22, 
        borderWidth: 2, 
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    }
})