import React, { useMemo, useCallback } from 'react';
import { View } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import { isNative, isLargeScreen, isIOS } from '../utils';
import { TAB_HEIGHT } from '../constants';

import ShelfScreen from '../screens/ShelfScreen';
import ListScreen from '../screens/ListScreen';
import BookScreen from '../screens/BookScreen';
import UserScreen from '../screens/UserScreen';
import UsersScreen from '../screens/UsersScreen';
import GroupScreen from '../screens/GroupScreen';
import GroupMembersScreen from '../screens/GroupMembersScreen';
import GroupCollectionScreen from '../screens/GroupCollectionScreen';
import ActivityBooksScreen from '../screens/ActivityBooksScreen';
import FollowersScreen from '../screens/FollowersScreen';
import FollowingScreen from '../screens/FollowingScreen';
import HomeTabs from '../navigation/HomeTabs';
import LibraryTabs from '../navigation/LibraryTabs';
import LendsTabsStack from './LendsTabs';
import GroupsTabs from '../navigation/GroupsTabs';
import TabBar from '../navigation/TabBar';

const stackScreenOptions = { 
    headerShown: false, 
    animationEnabled: isNative(), 
    cardStyle: { flex: 1 }
};

const getBottomPadding = (insets) => {
    if(isLargeScreen()) return 0;
    return TAB_HEIGHT + insets.bottom;
}

const cardStyleInterpolator = ({ current: { progress }}) => ({
    cardStyle: {
        opacity: progress.interpolate({
            inputRange: [0, 0.5, 0.9, 1],
            outputRange: [0, 0.25, 0.7, 1]
        }),
        transform: [{ 
            translateY: progress.interpolate({
                inputRange: [0, 0.7, 1],
                outputRange: [100, 20, 0]
            }) 
        }]
    }
})

const handleSharedElements = (route, otherRoute, showing) => {
    //console.warn({ route: { name: route.name, params: route.params }, otherRoute: { name: otherRoute.name, params: otherRoute.params }, showing });
    const { bookId, listId='', shelfId='' } = route.params;
    if(otherRoute?.params?.userId && route?.params?.bookId && !showing) return
    return [`cover.${bookId}${listId}${shelfId}.photo`];
}

const sharedScreens = (paddingBottom) => ({
    Book: {
        screen: BookScreen,
        options: {
            gestureEnabled: false,
            cardStyleInterpolator,
        },
        sharedElements: handleSharedElements
    },
    GroupBook: {
        screen: BookScreen,
        options: {
            gestureEnabled: false,
            cardStyleInterpolator,
        },
        sharedElements: handleSharedElements
    },
    Shelf: { 
        screen: ShelfScreen,
        options: { 
            cardStyle: { flex: 1, paddingBottom } 
        } 
    },
    List: { 
        screen: ListScreen,
        options: { 
            cardStyle: { flex: 1, paddingBottom } 
        }
    },
    User: {
        screen: UserScreen,
        options: {
            gestureEnabled: false,
            cardStyleInterpolator
        }
    },
    Users: { 
        screen: UsersScreen,
        options: { 
            cardStyle: { flex: 1, paddingBottom } 
        }
    },
    Followers: { screen: FollowersScreen },
    Following: { screen: FollowingScreen },
    Group: { 
        screen: GroupScreen,
        options: {
            gestureEnabled: false,
            cardStyleInterpolator
        }
    },
    GroupMembers: { 
        screen: GroupMembersScreen,
        options: { 
            cardStyle: { flex: 1, paddingBottom } 
        }
    },
    GroupCollection: { 
        screen: GroupCollectionScreen,
        options: { 
            cardStyle: { flex: 1, paddingBottom } 
        }
    }
})

const makeBottomTabScreenOptions = (tabBarLabel) => {
    return ({ route, navigation }) => {
        const routeName = getFocusedRouteNameFromRoute(route, navigation);
        return { 
            tabBarLabel,
            tabBarVisible: !['Book', 'GroupBook', 'User', 'Group'].includes(routeName) && !isLargeScreen(),
            tabBarHideOnKeyboard: true
        }
    }
}

const bottomTabScreens = {
    HomeTab: {
        screen: HomeNavigator,
        options: makeBottomTabScreenOptions('tabs.home.tabBarLabel')
    },
    LibraryTab: { 
        screen: LibraryNavigator,
        options: makeBottomTabScreenOptions('tabs.library.tabBarLabel')
    },
    Add: {
        screen: AddScreen
    },
    LendsTab: { 
        screen: LendsNavigator,
        options: makeBottomTabScreenOptions('tabs.lends.tabBarLabel')
    },
    GroupsTab: {
        screen: GroupNavigator,
        options: makeBottomTabScreenOptions('tabs.groups.tabBarLabel')
    }
}

const BottomTab = createBottomTabNavigator();

export default function BottomTabNavigator() {
    const tabBar = useCallback((props) => { 
        const { descriptors, state } = props;
        const focusedOptions = descriptors[state.routes[state.index].key].options;
        if(focusedOptions.tabBarVisible === false) return null;
        return <TabBar {...props} /> 
    }, []);
    return (
        <BottomTab.Navigator
            initialRouteName="HomeTab"
            tabBar={tabBar}
            screenOptions={{ headerShown: false, tabBarVisible: !isLargeScreen() }}>
                {Object.entries(bottomTabScreens).map(([name, { screen, options={} }]) => (
                    <BottomTab.Screen 
                        key={name}
                        name={name} 
                        component={screen} 
                        options={options} />
                ))}
            
        </BottomTab.Navigator>
    );
};

const HomeStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();

function HomeNavigator() {
    const insets = useSafeAreaInsets();
    const paddingBottom = getBottomPadding(insets);
    const screens = useMemo(() => sharedScreens(paddingBottom), [paddingBottom]);
    return (
        <HomeStack.Navigator 
            screenOptions={stackScreenOptions}>
            <HomeStack.Screen
                name="Home"
                component={HomeTabs}
            />
            <HomeStack.Screen
                name="BooksList"
                component={ActivityBooksScreen}
            />
            {Object.entries(screens).map(([name, { screen, options={}, sharedElements=()=> [`none`]  }]) => (
                <HomeStack.Screen 
                    key={name}
                    name={name} 
                    component={screen} 
                    options={options} 
                    sharedElements={sharedElements} />
            ))}
        </HomeStack.Navigator>
    );
}

const LibraryStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();

function LibraryNavigator() {
    const insets = useSafeAreaInsets();
    const paddingBottom = getBottomPadding(insets);
    const screens = useMemo(() => sharedScreens(paddingBottom), [paddingBottom]);
    return (
        <LibraryStack.Navigator 
            screenOptions={stackScreenOptions}>
            <LibraryStack.Screen
                name="Library"
                component={LibraryTabs}
            />
            {Object.entries(screens).map(([name, { screen, options={}, sharedElements=()=> [`none`]  }]) => (
                <LibraryStack.Screen 
                    key={name}
                    name={name} 
                    component={screen} 
                    options={options} 
                    sharedElements={sharedElements} />
            ))}
        </LibraryStack.Navigator>
    );
}

const LendsStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();

function LendsNavigator() {
    const insets = useSafeAreaInsets();
    const paddingBottom = getBottomPadding(insets);
    const screens = useMemo(() => sharedScreens(paddingBottom), [paddingBottom]);
    return (
        <LendsStack.Navigator 
            screenOptions={stackScreenOptions}>
            <LendsStack.Screen
                name="Lends"
                initialParams={{ tab: 'borrowing' }}
                component={LendsTabsStack}
            />
            {Object.entries(screens).map(([name, { screen, options={}, sharedElements=()=> [`none`] }]) => (
                <LendsStack.Screen 
                    key={name}
                    name={name} 
                    component={screen} 
                    options={options} 
                    sharedElements={sharedElements} />
            ))}
        </LendsStack.Navigator>
    );
}

const GroupsStack = isIOS() ? createSharedElementStackNavigator() : createStackNavigator();

function GroupNavigator() {
    const insets = useSafeAreaInsets();
    const paddingBottom = getBottomPadding(insets);
    const screens = useMemo(() => sharedScreens(paddingBottom), [paddingBottom]);
    return (
        <GroupsStack.Navigator 
            screenOptions={stackScreenOptions}>
            <GroupsStack.Screen
                name="Groups"
                component={GroupsTabs}
            />
            {Object.entries(screens).map(([name, { screen, options={}, sharedElements=()=> [`none`]  }]) => (
                <GroupsStack.Screen 
                    key={name}
                    name={name} 
                    component={screen} 
                    options={options} 
                    sharedElements={sharedElements} />
            ))}
        </GroupsStack.Navigator>
    );
}

function AddScreen() {
    return <View style={{ backgroundColor: 'transparent', flex: 1 }} />
}

