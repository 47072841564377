import React from 'react';
import { Platform } from 'react-native';
import { Menu, MenuOptions, MenuOption, MenuTrigger } from 'react-native-popup-menu';
import CustomMenu from '../utils/customMenu';

export default function ContextMenu({ 
        style, 
        TriggerButton, 
        triggerButtonHitSlop={ top: 10, left: 10, right: 10, bottom: 10 },
        menuWidth=200,
        anchorFill='#fff',
        placement='auto', 
        preferredPlacement='bottom',
        children
     }) {
    const optionsContainerStyles = {
        width: menuWidth, 
        margin: 0,
        borderRadius: 8, 
        paddingVertical: 8,
        paddingHorizontal: 10,
        ...Platform.select({
            android: {
                elevation: 5
            },
            ios: {
                shadowColor: '#000', 
                shadowOpacity: '0.3', 
                shadowOffset: { width: 0, height: 0 }
            },
            default: {
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px'
            }
          })
        
    }
    return (
        <Menu 
            style={ style }
            renderer={ CustomMenu } 
            rendererProps={{
                placement,
                preferredPlacement,
                anchorStyle: { fill: anchorFill, zIndex: 2 } 
            }}>
            <MenuTrigger customStyles={{ 
                    triggerTouchable: { 
                        hitSlop: triggerButtonHitSlop,
                        underlayColor: 'transparent'
                    }
                }}>
                { TriggerButton() }
            </MenuTrigger>
            <MenuOptions customStyles={{ 
                    optionsContainer: optionsContainerStyles 
                }}>
                    <MenuOption disableTouchable={true}>
                        { children }
                    </MenuOption>
            </MenuOptions>
        </Menu>
    )
}