import React, { useEffect, useState, useMemo, useCallback, memo, useContext } from 'react';
import { View, FlatList, ActivityIndicator, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getGroupApi, getGroupInProgress } from '../actions/getGroup';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Animated, { useSharedValue, useAnimatedScrollHandler, useAnimatedStyle, scrollTo, useAnimatedRef, Extrapolate, interpolate } from 'react-native-reanimated';
import GroupHeader from '../components/GroupHeader';
import FetchError from '../components/FetchError';
import GroupEmpty from '../components/GroupEmpty';
import Empty from '../components/Empty';
import Book from '../components/Book';
import GroupOptionsBar from '../components/GroupOptionsBar';
import GroupCollections, { COLLECTIONS_HEIGHT } from '../components/GroupCollections';
import { useIsFocused } from '@react-navigation/native';
import { useGetBookItemLayout, useGetDataOnNavFocus, useSetInitialPath, useGroup, useIsLoading, useBooks, useGetGroupDone, useOpenPageLoadedAction } from '../hooks';
import { shouldAnimateGroupHeader, sortGroupBooks, keyExtractor, getInitialScreenWidth, isLargeScreen, isNative, isInModal, isWeb, filterGroupBooks } from '../utils';
import { MAIN_COLOR, GROUP_HEADER_HEIGHT_MIN, BOOK_LIST_ITEM_BOTTOM_SPACING } from '../constants';
import {Ionicons} from '@expo/vector-icons';
import AppSettingsContext from '../context/AppSettingsContext';

const tBase = 'screens.group';

export default function GroupScreen({navigation, route}) {
    const groupId = route.params.groupId;
    const group = useGroup(groupId);
    const dispatch = useDispatch();
    const [groupFetchStatus, done] = useGetGroupDone(groupId);
    const getGroup = () => { dispatch(getGroupApi({ groupId, showLoading: !group })).then(done); }
    useGetDataOnNavFocus(navigation, getGroup);
    if(!groupFetchStatus && !group) return <View />;
    if(['failed', 'notFound'].includes(groupFetchStatus)) {
        return (
            <FetchError 
                tBase={ tBase }
                bottomTab='GroupsTab'
                navigation={navigation} 
                status={ groupFetchStatus } />
        );
    }
    if(!group) return <View />;
    return (
        <Group 
            group={group} 
            route={route} 
            navigation={navigation} />
    )
}

const FILTER_HEIGHT = 60;
const ITEM_X_SPACING = 20;
const CONTAINER_HOR_SPACING = 15;
const GROUP_HEADER_APPROX_HEIGHT = 252;

const AnimatedFlatlist = Animated.createAnimatedComponent(FlatList);

function getContentTopHeight(showCollections) {
    if(showCollections) return COLLECTIONS_HEIGHT + FILTER_HEIGHT;
    return FILTER_HEIGHT;
}

const Group = memo(({ group, navigation, route}) => {  
    const {t} = useContext(AppSettingsContext);
    const isGettingGroup = useIsLoading(getGroupInProgress);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isMember = group.memberIds.includes(loggedUserId);
    const isAdmin = group.adminIds.includes(loggedUserId);
    const inModal = isInModal(navigation);
    const showCollections = (() => {
        if(group?.bookIds?.length && (group?.quotas?.collections || (isAdmin && isWeb())) && (group?.collectionIds.length || isAdmin)) {
            return true;
        }
        return false;
    })()
    const contentTopHeight = getContentTopHeight(showCollections);
    const [sort, setSort] = useState('added_desc');
    const [filter, setFilter] = useState('all');
    const [pattern, setPattern] = useState('');
    const [pressedBookId, setPressedBookId] = useState(null);
    const [scrollContentHeight, setScrollContentHeight] = useState(null);
    const [scrollHeight, setScrollHeight] = useState(null)
    const [flatListWidth, setFlatListWidth] = useState(getInitialScreenWidth(navigation));
    const [scrollYToShrinkHeader, setScrollYToShrinkHeader] = useState((GROUP_HEADER_APPROX_HEIGHT)-GROUP_HEADER_HEIGHT_MIN); 
    const [scrollYToHideCollections, setScrollYToHideCollections] = useState(scrollYToShrinkHeader);
    const [scrollPaddingTop, setScrollPaddingTop] = useState(GROUP_HEADER_APPROX_HEIGHT+contentTopHeight+10);
    const scrollY = useSharedValue(0);
    const headerHeight = useSharedValue(0);
    const animateHeader = useSharedValue(0);
    const {columns, itemWidth} = useGetBookItemLayout(flatListWidth, CONTAINER_HOR_SPACING, ITEM_X_SPACING);
    const canAddCollections = showCollections && group?.collectionIds.length < group?.quotas?.collections; 
    const insets = useSafeAreaInsets();
    const scrollRef = useAnimatedRef();
    const books = useBooks(group?.bookIds);
    const filteredBooks = useMemo(() => filterGroupBooks(pattern, filter, books), [books, filter, pattern])
    const insetTop = (() => {
        if((isNative() && !inModal) || (inModal && !isNative())) return Math.max(insets.top, 28);
        return 20 
    })()
    const sortedBooks = useMemo(() => sortGroupBooks(filteredBooks, sort, group.id), [filteredBooks, sort])
    const rememberPressedBookId = useCallback((bookId) => setPressedBookId(bookId), []);
    const onHeaderHeightChange = useCallback((height) => { 
        setScrollYToShrinkHeader(Math.round(height-GROUP_HEADER_HEIGHT_MIN));
        setScrollYToHideCollections(Math.round(height+GROUP_HEADER_HEIGHT_MIN));
        headerHeight.value = height;
    }, []);
    const updateSort = useCallback((value) => setSort(value), []);
    const updateFilter = useCallback((value) => setFilter(value), []);
    const updatePattern = useCallback((value) => setPattern(value), []);
    const isFocused = useIsFocused();
    const showTopContent = Boolean(sortedBooks.length || (books.length && pattern));
    const renderItem = ({ item }) => (
        <Book 
            bookId={item.id}
            title={item.title}
            ownerId={item.ownerId}
            author={item.author}
            groupId={group.id}
            groupSlug={group.slug}
            context='group'
            contextId={group.id}
            isFocused={isFocused}
            rememberPressedBookId={rememberPressedBookId}
            pressedBookId={pressedBookId}
            width={itemWidth}
            marginHorizontal={ITEM_X_SPACING/2} 
            navigation={navigation} />
    )
    const renderEmpty = () => {
        if(isGettingGroup) {
            return (
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                    <ActivityIndicator color={ MAIN_COLOR } size="large" />
                </View>
            )
        }
        if(pattern && group.bookIds.length) {
            return (
                <Empty  
                    title={ t(`${tBase}.noResults.title`) }
                    body={ t(`${tBase}.noResults.body`, { pattern }) }
                    Icon={ <Ionicons name='search' color='#bc9d9d' size={ 55 }  /> }
                    primaryActionLabel={ t(`${tBase}.noResults.showAll`) }
                    primaryActionOnPress={ () => updatePattern('') } />
            )
        } 
        if(!group.bookIds.length) {
            return (
                <GroupEmpty
                    navigation={navigation}
                    group={group}
                    isAdmin={isAdmin}
                    isMember={isMember} />
            )
        }
        return <View />
    }
    const onLayout = ({nativeEvent: {layout}}) => { 
        const { height, width } = layout;
        if(scrollHeight == null) {
            setScrollHeight(height);
        }
        if(width && width != flatListWidth) {
            setFlatListWidth(width);
        }
    }
    useSetInitialPath(route);
    const scrollHandler = useAnimatedScrollHandler({
        onScroll: (e) => {
            if(animateHeader.value) {
                scrollY.value = e.contentOffset.y;
            }
        },
        onBeginDrag: (e, ctx) => {
            ctx.y = e.contentOffset.y;
            ctx.scrollYTo = showCollections ? scrollYToHideCollections : scrollYToShrinkHeader;
        },
        onEndDrag: (e, ctx) => {
            if(animateHeader.value && e.velocity.y == 0) {
                const scrollingUp = ctx.y < e.contentOffset.y;
                const scrollingDown = ctx.y > e.contentOffset.y;
                if(scrollingUp && e.contentOffset.y < ctx.scrollYTo && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, ctx.scrollYTo, true);
                } else if (scrollingDown && e.contentOffset.y < ctx.scrollYTo && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, 0, true);
                }
            }
        },
        onMomentumEnd: (e, ctx) => {
            if(animateHeader.value) {
                const scrollingUp = ctx.y < e.contentOffset.y;
                const scrollingDown = ctx.y > e.contentOffset.y;
                if(scrollingUp && e.contentOffset.y < ctx.scrollYTo && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, ctx.scrollYTo, true);
                } else if (scrollingDown && e.contentOffset.y < ctx.scrollYTo && e.contentOffset.y > 1) {
                    scrollTo(scrollRef, 0, 0, true);
                }
            }
        }
    })
    function onContentSizeChange(_, contentHeight) {
        const diff = scrollContentHeight - Math.round(contentHeight);
        if(scrollContentHeight == 0 || scrollContentHeight == null || (diff > 2 || diff < -2)) {
            setScrollContentHeight(Math.round(contentHeight));
        }
    }
    function calculateScrollPaddingTop() {
        if(headerHeight.value) {
            setScrollPaddingTop(headerHeight.value + contentTopHeight + 10)
        }
    }
    useEffect(() => {
        if(group?.name) navigation.setOptions({ title: group?.name })
    }, [])

    useEffect(() => {
        calculateScrollPaddingTop();
    }, [sortedBooks, headerHeight.value]);

    useEffect(() => {
        const shouldAnimateHeader = shouldAnimateGroupHeader(scrollHeight, scrollContentHeight, headerHeight.value);
        if(shouldAnimateHeader !== Boolean(animateHeader.value)) animateHeader.value = shouldAnimateHeader ? 1 : 0;
    }, [scrollContentHeight, headerHeight.value, scrollHeight]);

    const paddingBottom = (() => {
        if(isLargeScreen()) return insets.bottom + 50;
        return 50;
    })()
    const contentTopStyle = useAnimatedStyle(() => {
        const input = { from: 0, to: showCollections ? scrollYToHideCollections : scrollYToShrinkHeader };
        const output = { from: headerHeight.value, to: showCollections ? -(COLLECTIONS_HEIGHT-(GROUP_HEADER_HEIGHT_MIN+insetTop)) : GROUP_HEADER_HEIGHT_MIN+insetTop };
        return { top: interpolate(scrollY.value, [input.from, input.to], [output.from, output.to], Extrapolate.CLAMP) }
    })
    useOpenPageLoadedAction(route, navigation, 'group', { isMember, isAdmin, canAddCollections });
    const ItemSeparatorComponent = useCallback(() => <View style={{height: BOOK_LIST_ITEM_BOTTOM_SPACING }} />)
    return (
        <View style={{ flex: 1 }}>
            <GroupHeader 
                insetTop={insetTop}
                headerInputMaxRange={scrollYToShrinkHeader}
                onHeaderHeightChange={onHeaderHeightChange}
                headerHeight={headerHeight}
                isMember={isMember}
                isAdmin={isAdmin}
                scrollY={scrollY} 
                group={group} 
                navigation={navigation} />
            { showTopContent &&
            <Animated.View style={[styles.contentTop, isNative() && contentTopStyle]}>
                { showCollections && 
                    <GroupCollections 
                        group={group} 
                        tBase={tBase}
                        isAdmin={isAdmin} 
                        navigation={navigation} /> 
                }
                <GroupOptionsBar 
                    bookIds={group?.bookIds}
                    sort={sort}
                    filter={filter}
                    pattern={pattern}
                    updatePattern={updatePattern}
                    updateFilter={updateFilter}
                    updateSort={updateSort}
                />
            </Animated.View>
            }
            <AnimatedFlatlist 
                key={columns}
                onScroll={scrollHandler}
                ref={scrollRef}
                data={sortedBooks}
                numColumns={columns}
                initialNumToRender={6}
                windowSize={10}
                keyExtractor={keyExtractor}
                scrollEventThrottle={16}
                contentContainerStyle={{ 
                    flexGrow: 1, 
                    marginHorizontal: CONTAINER_HOR_SPACING, 
                    paddingTop: isWeb() ? 10 : scrollPaddingTop, 
                    paddingBottom 
                }}
                onLayout={onLayout}
                renderItem={renderItem}
                ItemSeparatorComponent={ItemSeparatorComponent}
                ListEmptyComponent={renderEmpty}
                onContentSizeChange={onContentSizeChange} />
        </View>
    )
})

const styles = StyleSheet.create({
    contentTop: {
        position: isWeb() ? 'relative' : 'absolute',
        width: '100%',
        zIndex: 1
    }
})