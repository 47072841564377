import { DELETE_SHELF_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const deleteShelfInProgress = createAction('DELETE_SHELF_IN_PROGRESS');
export const deleteShelfSuccess = createAction('DELETE_SHELF_SUCCESS');
export const deleteShelfFailure = createAction('DELETE_SHELF_FAILURE');

export function deleteShelfApi({ shelfId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { shelfId },
            actions: [deleteShelfInProgress, deleteShelfSuccess, deleteShelfFailure],
            name: 'deleteShelf',
            dispatch,
            getState
        })
    }
}