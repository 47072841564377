import * as React from 'react';
import { StackActions } from '@react-navigation/native';

export const isReadyRef = React.createRef();

export const navigationRef = React.createRef();

export function navigate(name, params) {
    if (isReadyRef.current && navigationRef.current) {
        navigationRef.current.navigate(name, params);
    }
}

export function push(name, params) {
    if (isReadyRef.current && navigationRef.current) {
        const pushAction = StackActions.push(name, params);
        dispatch(pushAction);
    }
}

export function dispatch(action) {
    if (isReadyRef.current && navigationRef.current) {
        navigationRef.current.dispatch(action);
    }
}

export function getRootState() {
    if (isReadyRef.current && navigationRef.current) {
        return navigationRef.current.getRootState();
    } else {
        return undefined
    }
}

export function getCurrentRoute() {
    if (isReadyRef.current && navigationRef.current) {
        return navigationRef.current.getCurrentRoute();
    } else {
        return undefined
    }
}

export const navigation = { 
    push,
    navigate,
    dispatch,
    getRootState
}