import React, { memo, useContext } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { deleteBookApi } from '../actions/deleteBook';
import { returnBookApi } from '../actions/returnBook';
import { removeBookFromListApi } from '../actions/removeBookFromList';
import { removeBookFromGroupApi } from '../actions/removeBookFromGroup';
import { setBookHiddenApi } from '../actions/setBookHidden';
import { requestBorrowBookApi } from '../actions/requestBorrowBook';
import { cancelBorrowRequestApi } from '../actions/cancelBorrowRequest';
import { selectRemainingProfileElementsToFill } from '../selectors';
import { bookMenu } from '../utils/menuConfigs';
import { openModalOrPushScreen } from '../utils';
import { useCanBorrowBook, useBookRequestByMe, useBookCurrentLend, useUser, useList } from '../hooks';
import AppSettingsContext from '../context/AppSettingsContext';
import Menu from './Menu';

export default function MenuBook(props) {
    const { book } = props;
    const lend = useBookCurrentLend(book.id);
    const owner = useUser(book.ownerId);
    const list = useList(props.listId);
    const loggedUserId = useSelector(state => state.loggedUserId, shallowEqual);
    const isLoggedIn = !!loggedUserId;
    const remainingProfileElementsToFill = useSelector(selectRemainingProfileElementsToFill);
    const bookRequestByMe = useBookRequestByMe(book.id);
    const canBorrowBook = useCanBorrowBook(book);
    return (
        <BookMenu 
            {...props} 
            lend={lend} 
            list={list}
            owner={owner} 
            bookRequestByMe={bookRequestByMe}
            isOwner={loggedUserId == book.ownerId} 
            isLoggedIn={isLoggedIn}
            canBorrowBook={canBorrowBook}
            isProfileComplet={ remainingProfileElementsToFill === 0 } />
    )
}

const BookMenu = memo(({ 
        book, 
        groupId, 
        list, 
        navigation, 
        lend, 
        context='unknown', 
        contextId,
        isOwner,
        isLoggedIn, 
        isProfileComplet, 
        owner,
        deleteDone, 
        bookRequestByMe, 
        placement, 
        style, 
        TriggerButton, 
        canBorrowBook }) => {
    const {t} = useContext(AppSettingsContext);
    const bookId = book.id;
    const dispatch = useDispatch();
    const menuOptions = bookMenu({ 
        dispatch,
        list,
        groupId,
        navigation,
        owner,
        canBorrowBook,
        isPossessed: book.possessed,
        isOwner,
        isLoggedIn,
        isLent: !!lend,
        isProfileComplet,
        alreadyRequested: !!bookRequestByMe,
        book, 
        actions: { 
            onLend: () => openModalOrPushScreen(navigation, 'Lend', { bookId }),
            onReturn: () => { dispatch(returnBookApi({ lendId: lend?.id, toId: lend?.toId })); },
            onDelete: () => { dispatch(deleteBookApi({ bookId })).then(deleteDone); }, 
            onUpdate: () => openModalOrPushScreen(navigation, 'UpdateBook', { bookId }),
            onSetHidden: () => { dispatch(setBookHiddenApi({ hidden: !book.hidden, bookId  })) }, 
            onAddRemoveFromList: () => openModalOrPushScreen(navigation, 'BookInLists', { bookId }),
            onRemoveFromList: () => { dispatch(removeBookFromListApi({ book: { id: book.id }, listId: list?.id })); },
            onBorrowRequest: () => { dispatch(requestBorrowBookApi({ bookId, context, contextId })) },
            onCancelBorrowRequest: () => { dispatch(cancelBorrowRequestApi({ borrowRequestId: bookRequestByMe?.id })) },
            onRemoveFromGroup: () => { dispatch(removeBookFromGroupApi({ bookId, groupId })) },
            onFillProfileToBorrow: () => openModalOrPushScreen(navigation, 'UpdateProfile')
        },
        t
    });
    return (
        <Menu
            placement={placement}
            menuOptions={menuOptions}
            style={ style }
            TriggerButton={ TriggerButton } />
    )
    
}, (prevProps, nextProps) => {
    return (
        prevProps.book.id === nextProps.book.id && 
        prevProps.isProfileComplet === nextProps.isProfileComplet &&
        prevProps.groupId === nextProps.groupId &&
        prevProps.lend === nextProps.lend &&
        prevProps.bookRequestByMe === nextProps.bookRequestByMe &&
        prevProps.canBorrowBook === nextProps.canBorrowBook &&
        prevProps.listId === nextProps.listId &&
        prevProps.isOwner === nextProps.isOwner &&
        prevProps.placement === nextProps.placement && 
        prevProps.book.hidden === nextProps.book.hidden 
    )
})