import { SET_FOUND_IN_SEARCH_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setFoundInSearchInProgress = createAction('SET_FOUND_IN_SEARCH_IN_PROGRESS');
export const setFoundInSearchSuccess = createAction('SET_FOUND_IN_SEARCH_SUCCESS');
export const setFoundInSearchFailure = createAction('SET_FOUND_IN_SEARCH_FAILURE');

export function setFoundInSearchApi({ value, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { foundInSearch: value },
            actions: [setFoundInSearchInProgress, setFoundInSearchSuccess, setFoundInSearchFailure],
            name: 'setFoundInSearch',
            showLoading,
            dispatch,
            getState
        })
    }
}