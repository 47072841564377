import { GET_USER_BOOK_LIKES_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getUserBookLikesInProgress = createAction('GET_USER_BOOK_LIKES_IN_PROGRESS');
export const getUserBookLikesSuccess = createAction('GET_USER_BOOK_LIKES_SUCCESS');
export const getUserBookLikesFailure = createAction('GET_USER_BOOK_LIKES_FAILURE');

export function getUserBookLikesApi({ showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            actions: [getUserBookLikesInProgress, getUserBookLikesSuccess, getUserBookLikesFailure],
            name: 'getUserBookLikes',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}