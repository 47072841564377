import { SET_NEWSLETTER_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setNewsletterInProgress = createAction('SET_NEWSLETTER_IN_PROGRESS');
export const setNewsletterSuccess = createAction('SET_NEWSLETTER_SUCCESS');
export const setNewsletterFailure = createAction('SET_NEWSLETTER_FAILURE');

export function setNewsletterApi({value, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { receiveNewsletter: value },
            actions: [setNewsletterInProgress, setNewsletterSuccess, setNewsletterFailure],
            name: 'setNewsletter',
            showLoading,
            dispatch,
            getState
        })
    }
}