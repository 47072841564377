import { GET_GROUP_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const getGroupInProgress = createAction('GET_GROUP_IN_PROGRESS');
export const getGroupSuccess = createAction('GET_GROUP_SUCCESS');
export const getGroupFailure = createAction('GET_GROUP_FAILURE');

export function getGroupApi({ groupId, showLoading=true, checkCaching=true }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { groupId },
            actions: [getGroupInProgress, getGroupSuccess, getGroupFailure],
            name: 'getGroup',
            dispatch,
            getState,
            showLoading,
            checkCaching
        })
    }
}