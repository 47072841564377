import { createReducer } from '@reduxjs/toolkit'
import { getLendsSuccess } from '../actions/getLends';
import { getBookSuccess } from '../actions/getBook';
import { loginOrSignup, hasLend, hasLends, matchResetStore } from './matchers';

const initialState = [];

export const lends = createReducer(initialState, (builder) => {
    builder
    .addMatcher(hasLends, (state, action) => {
        const { books, loggedUserId, lends } = action.payload;
        if(action.type != getLendsSuccess) {
            for(const book of books) {
                removeLendFromState(state, book, lends, loggedUserId);
            }
        }
        for(let lend of lends) {
            pushOrReplaceLend(state, lend);
        }
    })
    .addMatcher(hasLend, (state, action) => {
        const { lend, book, loggedUserId } = action.payload;
        if(lend?.id) {
            pushOrReplaceLend(state, lend);
        } else if(action.type == getBookSuccess) {
            removeLendFromState(state, book, [], loggedUserId);
        }
    })
    .addMatcher(loginOrSignup, (_, action) => {
        return action.payload.lends;
    })
    .addMatcher(matchResetStore, () => {
        return initialState;
    })
})

function removeLendFromState(state, book, lends, loggedUserId) {
    const lendInStateIndex = state.findIndex(({ bookId }) => bookId === book.id);
    const lendInPayloadIndex = lends.findIndex(({ bookId }) => bookId === book.id);
    if(lendInStateIndex > -1 && 
       lendInPayloadIndex === -1 && 
       state[lendInStateIndex].fromId !== loggedUserId &&
       state[lendInStateIndex].toId !== loggedUserId) {
        return state.splice(lendInStateIndex, 1);
    }
}

function pushOrReplaceLend(state, lend) {
    const lendInStateIndex = state.findIndex(({id}) => id == lend.id);
    if(lendInStateIndex > -1) {
        state[lendInStateIndex] = lend;
    } else {
        state.push(lend);
    }
}