import i18n from '../utils/i18n';
import NetInfo from './NetInfo';
import { Notifier } from 'react-native-notifier';
import { getNoInternetNotif, getApiErrorNotif, getApiCatchNotif, getSuccessNotif } from './notifs';
import { isWeb, isNative, handleApi401, dictToURI, handleGeneralError, getMetrics } from './';
import { getToken, saveToken, generateToken, deleteToken } from './handleToken';
import shouldFetch from './handleCaching';

const API_PUBLIC_KEYS = ['login', 'signup', 'resetPassword', 'getBook', 'getUser', 'getGroup', 'getShelf', 'getList', 'getKeywords', 'getGroupCollection', 'logEvent'];
const KEYS_EXCLUDE_STRINGIFY = ['avatar', 'cover', 'picture', 'emails'];

const createFormData = (data) => {
    let formData = new FormData();
    for(let key in data) {
        let value = data[key];
        let isFile = value instanceof File;
        if(typeof value === 'object' && !isFile && !KEYS_EXCLUDE_STRINGIFY.includes(key)) value = JSON.stringify(value)
        if(value !== null && value !== undefined) formData.append(key, value)
    }
    return formData;
}

function getFetchCatchFailureMsg(error) {
    let title = i18n.t('notifs.generic.title');
    let message = i18n.t('notifs.generic.description');
    if(error.message == 401) {
        title = i18n.t('notifs.notAuthorised.title');
        message = i18n.t('notifs.notAuthorised.description');
    }
    return { title, message }
}

async function getOrGenerateToken() {
    if(!isNative()) return;
    let token = await getToken();
    if(!token) {
        token = generateToken();
        await saveToken(token);
    }
    return token;
}

function handlePostData(data) {
    let formData;
    let isFormDataEmpty;
    if(data) {
        formData = createFormData(data);
        if((formData?.entries && formData.entries().next().done) || 
           formData?._parts?.length === 0) {
            isFormDataEmpty = true;
        } else {
            isFormDataEmpty = false;
        }
    }
    return {
        formData,
        isFormDataEmpty
    }
}

const showApiLogs = isWeb() && __DEV__;

export async function api({ url, data, actions, loadingFn, successFn, failureFn, name, dispatch, getState, showLoading=true, checkCaching=true, addToPayload={} }) {
    let responseStatus;
    const token = await getOrGenerateToken();
    const { caching, loggedUserId, users } = getState();
    const account = loggedUserId ? users[loggedUserId] : undefined;
    const [shouldFetchData, filteredData] = shouldFetch(caching, name, data);
    const { formData, isFormDataEmpty } = handlePostData(filteredData);
    if((!loggedUserId && !API_PUBLIC_KEYS.includes(name)) || (formData && isFormDataEmpty)) return Promise.resolve(false); 
    if(checkCaching && !shouldFetchData) return Promise.resolve({ cached: true });
    let isConnected = isWeb() ? true : undefined; 
    if(isNative()) isConnected = await NetInfo.fetch().then(state => state.isConnected);
    const { osVersion:os, channel:rc, appVersion:app, revisionId:rid, sdkVersion:sdk } = getMetrics();
    const metrics = { os, app, sdk, rc, rid };
    if(isConnected) {
        loadingFn ? loadingFn(): dispatch(actions[0]({ showLoading }));
        url = `${url}?${dictToURI({ token, ...metrics })}`;
        showApiLogs && console.log(`${name} : `, url);
        const params = {  
            method: formData ? "POST" : "GET",
            body: formData ? formData : undefined,
            credentials: 'include'
        }
        if(isNative()) params.headers = { "Content-Type": "multipart/form-data" };
        if(account?.preferredLang) {
            if(params.headers) {
                params.headers['Accept-Language'] = account.preferredLang;
            } else {
                params.headers = { 'Accept-Language': account.preferredLang };
            }
        }
        return fetch(url, params)
        .then(async (res) => {
            showApiLogs && console.log(`${name} response : `, res);
            responseStatus = res.status;
            if(res.status == 401) {
                if(isNative()) await deleteToken();
                handleApi401(dispatch);
                throw new Error(401);
            }
            return res.json();
        })
        .then(json => { 
            showApiLogs && console.log(`${name} json : `, json);
            if(json?.success) {
                if(json.msg && json.displayMsg != false) {
                    Notifier.showNotification(getSuccessNotif({
                        title: json.msg,
                        duration: 4500
                    }))
                }
                successFn ? successFn({ ...json, cacheKey: name, ...addToPayload, loggedUserId }) : dispatch(actions[1]({ ...json, cacheKey: name, ...addToPayload, loggedUserId }));
                return json;
            } else {
                failureFn ? failureFn() : dispatch(actions[2]());
                if(json.msg && json.displayMsg != false) {
                    Notifier.showNotification(getApiErrorNotif(json))
                } else if(json?.errors && json?.displayErrors) {
                    handleGeneralError(json.errors);
                }
                return { ...json, responseStatus };
            }
        })
        .catch(error => {
            showApiLogs && console.error(`${error} - ${name}`);
            const { title, message } = getFetchCatchFailureMsg(error);
            failureFn ? failureFn() : dispatch(actions[2]());
            Notifier.showNotification(getApiCatchNotif(title, message));
            return false;
        })
    } else {
        Notifier.showNotification(getNoInternetNotif())
    }
}

