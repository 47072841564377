import { SET_NOTIFS_BY_EMAIL_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const setNotifsByEmailInProgress = createAction('SET_NOTIFS_BY_EMAIL_IN_PROGRESS');
export const setNotifsByEmailSuccess = createAction('SET_NOTIFS_BY_EMAIL_SUCCESS');
export const setNotifsByEmailFailure = createAction('SET_NOTIFS_BY_EMAIL_FAILURE');

export function setNotifsByEmailApi({value, showLoading=true}) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { receiveNotifsByEmail: value },
            actions: [setNotifsByEmailInProgress, setNotifsByEmailSuccess, setNotifsByEmailFailure],
            name: 'setNotifsByEmail',
            showLoading,
            dispatch,
            getState
        })
    }
}