import { MAIN_COLOR, MAIN_COLOR_SUBTLE, SECONDARY_COLOR, SECONDARY_COLOR_SUBTLE, OFF_BLACK, OFF_WHITE } from '../constants';
import React from 'react';
import DiscussionHeader from '../components/DiscussionHeader';

const shareHeaderStyle = {
    height: 55,
    shadowOffset: { height: 0, width: 0 },
    borderBottomWidth: 0,
    borderBottomWidth: 0,
    elevation: 0,
    shadowOpacity: 0,
    boxShadow:'',
}

const whiteHeader = {
    headerTintColor: MAIN_COLOR,
    headerStyle: shareHeaderStyle,
}

const offWhiteHeader = {
    headerTintColor: MAIN_COLOR,
    headerStyle: { 
        ...shareHeaderStyle, 
        backgroundColor: OFF_WHITE
    },
}

const darkHeader = {
    headerTintColor: '#fff',
    headerStyle: {
        ...shareHeaderStyle,
        backgroundColor: OFF_BLACK,
    }
}

const mainColorHeader = {
    headerTintColor: '#fff',
    headerStyle: {
        ...shareHeaderStyle,
        backgroundColor: MAIN_COLOR,
    }
}

const mainColorSubtleHeader = {
    headerTintColor: MAIN_COLOR,
    headerStyle: {
        ...shareHeaderStyle,
        backgroundColor: MAIN_COLOR_SUBTLE,
    }
}

const secondaryColorSubtleHeader = {
    headerTintColor: SECONDARY_COLOR,
    headerStyle: {
        ...shareHeaderStyle,
        backgroundColor: SECONDARY_COLOR_SUBTLE,
    }
}

const sharedScreenOptions = {
    cardOverlayEnabled: true,
    headerLeftContainerStyle: {
        paddingLeft: 5
    },
    headerTitleStyle: {
        fontFamily: 'Inter_400Regular',
        fontSize: 17
    },
}

const ProfileScreen = (options={}) => ({
    name: "Profile",
    options: {  
        ...sharedScreenOptions,
        ...mainColorHeader,
        ...options
    }
})

const UpdateProfileScreen = (options={}) => ({
    name: "UpdateProfile",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const SettingsScreen = (options={}) => ({
    name: "Settings",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AccountScreen = (options={}) => ({
    name: "Account",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const TermsScreen = (options={}) => ({
    name: "Terms",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ChangePasswordScreen = (options={}) => ({
    name: "ChangePassword",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const SubscriptionsScreen = (options={}) => ({
    name: "Subscriptions",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const BookScreen = (options={}) => ({
    name: "Book",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddBookScreen = (options={}) => ({
    name: "AddBook",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddBookSearchScreen = (options={}) => ({
    name: "AddBookSearch",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateBookScreen = (options={}) => ({
    name: "UpdateBook",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ScanScreen = (options={}) => ({
    name: "Scan",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddShelfScreen = (options={}) => ({
    name: "AddShelf",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateShelfScreen = (options={}) => ({
    name: "UpdateShelf",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddListScreen = (options={}) => ({
    name: "AddList",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateListScreen = (options={}) => ({
    name: "UpdateList",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const TakePhotoScreen = (options={}) => ({
    name: "TakePhoto",
    options: {
        ...sharedScreenOptions,
        ...darkHeader,
        ...options
    }
})

const LendScreen = (options={}) => ({
    name: "Lend",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const BookInListsScreen = (options={}) => ({
    name: "BookInLists",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const FollowRequestsScreen = (options={}) => ({
    name: "FollowRequests",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UserScreen = (options={}) => ({
    name: "User",
    options: {
        ...options
    }
})

const UsersScreen = (options={}) => ({
    name: "Users",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const InviteUsersScreen = (options={}) => ({
    name: "InviteUsers",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const FollowersScreen = (options={}) => ({
    name: "Followers",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const FollowingScreen = (options={}) => ({
    name: "Following",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const DiscussionsScreen = (options={}) => ({
    name: "Discussions",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const DiscussionScreen = (options={}) => ({
    name: "Discussion",
    options: ({ route, navigation}) => ({
        ...sharedScreenOptions,
        ...whiteHeader,
        headerTitle: props => <DiscussionHeader route={route} navigation={navigation} {...props} />,
        ...options
    })
})

const ContactScreen = (options={}) => ({
    name: "Contact",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ShareWebScreen = (options={}) => ({
    name: "ShareWeb",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupScreen = (options={}) => ({
    name: "Group",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupBookScreen = (options={}) => ({
    name: "GroupBook",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ChoseGroupProductScreen = (options={}) => ({
    name: "ChoseGroupProduct",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupProductScreen = (options={}) => ({
    name: "GroupProduct",
    options: {
        ...sharedScreenOptions,
        ...mainColorSubtleHeader,
        ...options
    }
})

const GroupProductProScreen = (options={}) => ({
    name: "GroupProductPro",
    options: {
        ...sharedScreenOptions,
        ...secondaryColorSubtleHeader,
        ...options
    }
})

const AddGroupScreen = (options={}) => ({
    name: "AddGroup",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const PrePaymentGroupProductScreen = (options={}) => ({
    name: "PrePaymentGroupProduct",
    options: {
        ...sharedScreenOptions,
        ...offWhiteHeader,
        ...options
    }
})

const GroupProductCheckoutSuccessScreen = (options={}) => ({
    name: "GroupProductCheckoutSuccess",
    options: {
        ...sharedScreenOptions,
        ...offWhiteHeader,
        ...options
    }
})

const UpdateGroupScreen = (options={}) => ({
    name: "UpdateGroup",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const BooksInGroupScreen = (options={}) => ({
    name: "BooksInGroup",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateShelfShareBooksGroupScreen = (options={}) => ({
    name: "UpdateShelfShareBooksGroup",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const InviteToJoinGroupScreen = (options={}) => ({
    name: "InviteToJoinGroup",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupMembersScreen = (options={}) => ({
    name: "GroupMembers",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupShareScreen = (options={}) => ({
    name: "GroupShare",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddBookReviewScreen = (options={}) => ({
    name: "AddBookReview",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateBookReviewScreen = (options={}) => ({
    name: "UpdateBookReview",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const SearchScreen = (options={}) => ({
    name: "Search",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ShelfScreen = (options={}) => ({
    name: "Shelf",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const ListScreen = (options={}) => ({
    name: "List",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const BookProviderResultScreen = (options={}) => ({
    name: "BookProviderResult",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddGroupCollectionScreen = (options={}) => ({
    name: "AddGroupCollection",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateGroupCollectionScreen = (options={}) => ({
    name: "UpdateGroupCollection",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const BooksInGroupCollectionScreen = (options={}) => ({
    name: "BooksInGroupCollection",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const GroupCollectionScreen = (options={}) => ({
    name: "GroupCollection",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AskForFeedbackScreen = (options={}) => ({
    name: "AskForFeedback",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const PremiumGroupOnlyScreen = (options={}) => ({
    name: "PremiumGroupOnly",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const DeviceContactsScreen = (options={}) => ({
    name: "DeviceContacts",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const AddUserKeywordScreen = (options={}) => ({
    name: "AddUserKeyword",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const UpdateUserKeywordScreen = (options={}) => ({
    name: "UpdateUserKeyword",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const FilterBooksScreen = (options={}) => ({
    name: "FilterBooks",
    options: {
        ...sharedScreenOptions,
        ...whiteHeader,
        ...options
    }
})

const discussionScreens = [
    DiscussionsScreen(),
    DiscussionScreen()
]

const listScreens = [
    ListScreen(),
    UpdateListScreen(),
    AddListScreen()

]

const shelfScreens = [
    ShelfScreen(),
    AddShelfScreen(),
    UpdateShelfScreen()
]

const userScreens = [
    UserScreen({ headerShown: false }),
    UsersScreen(),
    FollowersScreen(),
    FollowingScreen()
]

const bookScreens = [
    BookScreen({ headerShown: false }),
    BookInListsScreen(),
    UpdateBookScreen(),
    AddBookReviewScreen(),
    UpdateBookReviewScreen(),
    LendScreen(),
    TakePhotoScreen()
]

const groupScreens = [
    GroupScreen({ headerShown: false }),
    AddGroupScreen(),
    UpdateGroupScreen(),
    PrePaymentGroupProductScreen({ headerShown: false }),
    BooksInGroupScreen(),
    InviteToJoinGroupScreen(),
    GroupMembersScreen(),
    AddGroupCollectionScreen(),
    UpdateGroupCollectionScreen(),
    BooksInGroupCollectionScreen(),
    GroupCollectionScreen(),
    GroupBookScreen({ headerShown: false }),
    PremiumGroupOnlyScreen()
]

const accountScreens = [
    UpdateProfileScreen(),
    SettingsScreen(),
    AccountScreen(),
    ChangePasswordScreen(),
    FollowRequestsScreen(),
    SubscriptionsScreen()
]

export const ModalScreens = {
    Profile: [
        ProfileScreen({ headerLeft: null }),
        ...accountScreens,
        ...userScreens,
        ...bookScreens,
        ...discussionScreens,
        ...shelfScreens,
        ...listScreens
    ],
    UpdateProfile: [
        UpdateProfileScreen({ headerLeft: null })
    ],
    Subscriptions: [
        SubscriptionsScreen({ headerLeft: null })
    ],
    Terms: [
        TermsScreen({ headerLeft: null })
    ],
    Settings: [
        SettingsScreen({ headerLeft: null })
    ],
    Account: [
        AccountScreen({ headerLeft: null }),
        SubscriptionsScreen(),
        ChangePasswordScreen()
    ],
    ChangePassword: [
        ChangePasswordScreen({ headerLeft: null })
    ],
    Book: [
        BookScreen({ headerLeft: null })
    ],
    UpdateBook: [
        UpdateBookScreen({ headerLeft: null }),
        AddShelfScreen(),
        AddListScreen(),
        TakePhotoScreen(),
        AddUserKeywordScreen(),
        UpdateUserKeywordScreen()
    ],
    Scan: [
        ScanScreen({ headerShown: false,  headerLeft: null }),
        AddBookScreen(),
        AddShelfScreen(),
        AddListScreen(),
        AddBookSearchScreen(),
        TakePhotoScreen(),
        BookProviderResultScreen()
    ],
    AddShelf: [
        AddShelfScreen({ headerLeft: null })
    ],
    AddBook: [
        AddBookScreen({ headerLeft: null }),
        AddShelfScreen(),
        AddListScreen(),
        AddUserKeywordScreen(),
        UpdateUserKeywordScreen(),
        TakePhotoScreen()
    ],
    UpdateShelf: [
        UpdateShelfScreen({ headerLeft: null })
    ],
    AddList: [
        AddListScreen({ headerLeft: null })
    ],
    UpdateList: [
        UpdateListScreen({ headerLeft: null })
    ],
    TakePhoto: [
        TakePhotoScreen({ headerLeft: null })
    ],
    AddBookSearch: [
        AddBookSearchScreen({ headerLeft: null }),
        BookProviderResultScreen(),
        AddBookScreen(),
        AddShelfScreen(),
        AddListScreen()
    ],
    Lend: [
        LendScreen({ headerLeft: null })
    ],
    BookInLists: [
        BookInListsScreen({ headerLeft: null }),
        AddListScreen(),
    ],
    FollowRequests: [
        FollowRequestsScreen({ headerLeft: null }),
        UserScreen({ headerShown: false, headerLeft: null })
    ],
    Discussions: [
        DiscussionsScreen({ headerLeft: null }),
        DiscussionScreen(),
        ...shelfScreens,
        ...bookScreens,
        ...userScreens,
        ...listScreens,
        ...accountScreens
    ],
    Discussion: [
        DiscussionScreen({ headerLeft: null }),
        ...shelfScreens,
        ...bookScreens,
        ...listScreens,
        ...userScreens,
        ...accountScreens
    ],
    Contact: [
        ContactScreen({ headerLeft: null })
    ],
    ShareWeb: [
        ShareWebScreen({ headerLeft: null })
    ],
    AddGroup: [
        AddGroupScreen({ headerLeft: null }),
        ChoseGroupProductScreen(),
        GroupProductScreen(),
        GroupProductProScreen(),
        PrePaymentGroupProductScreen({ headerLeft: null })
    ],
    ChoseGroupProduct: [
        ChoseGroupProductScreen({ headerLeft: null }),
        GroupProductScreen(),
        GroupProductProScreen(),
        AddGroupScreen(),
        PrePaymentGroupProductScreen({ headerLeft: null })
    ],
    GroupProductCheckoutSuccess: [
        GroupProductCheckoutSuccessScreen({ headerLeft: null })
    ],
    UpdateGroup: [
        UpdateGroupScreen({ headerLeft: null }),
        GroupProductScreen(),
        GroupProductProScreen(),
        ChoseGroupProductScreen(),
        PrePaymentGroupProductScreen({ headerLeft: null })
    ],
    BooksInGroup: [
        BooksInGroupScreen({ headerLeft: null }),
        UpdateShelfShareBooksGroupScreen()
    ],
    InviteToJoinGroup: [
        InviteToJoinGroupScreen({ headerLeft: null })
    ],
    GroupShare: [
        GroupShareScreen({ headerLeft: null }),
        PremiumGroupOnlyScreen(),
        GroupProductScreen(),
        GroupProductProScreen(),
        PrePaymentGroupProductScreen()
    ],
    AddBookReview: [
        AddBookReviewScreen({ headerLeft: null })
    ],
    UpdateBookReview: [
        UpdateBookReviewScreen({ headerLeft: null })
    ],
    InviteUsers: [
       InviteUsersScreen({ headerLeft: null }),
       DeviceContactsScreen({})
    ],
    AddGroupCollection: [
        AddGroupCollectionScreen({ headerLeft: null })
    ],
    UpdateGroupCollection: [
        UpdateGroupCollectionScreen({ headerLeft: null })
    ],
    BooksInGroupCollection: [
        BooksInGroupCollectionScreen({ headerLeft: null })
    ],
    AskForFeedback: [
        AskForFeedbackScreen({ headerLeft: null })
    ],
    PremiumGroupOnly: [
        PremiumGroupOnlyScreen({ headerLeft: null }),
        GroupProductScreen(),
        GroupProductProScreen(),
        PrePaymentGroupProductScreen()
    ],
    Search: [
        SearchScreen({ headerLeft: null }),
        ProfileScreen(),
        ...discussionScreens,
        ...accountScreens,
        ...userScreens,
        ...bookScreens,
        ...groupScreens,
        ...listScreens,
        ...shelfScreens
    ],
    FilterBooks: [
        FilterBooksScreen({ headerLeft: null })
    ] 
}