import { UPDATE_BOOKS_IN_GROUP_COLLECTION_API_URL as url } from '../constants';
import { createAction } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export const updateBooksInGroupCollectionInProgress = createAction('UPDATE_BOOKS_IN_GROUP_COLLECTION_IN_PROGRESS');
export const updateBooksInGroupCollectionSuccess = createAction('UPDATE_BOOKS_IN_GROUP_COLLECTION_SUCCESS');
export const updateBooksInGroupCollectionFailure = createAction('UPDATE_BOOKS_IN_GROUP_COLLECTION_FAILURE');

export function updateBooksInGroupCollectionApi({ bookIds, groupCollectionId }) {
    return (dispatch, getState) => {
        return api({
            url,
            data: { bookIds, groupCollectionId },
            actions: [updateBooksInGroupCollectionInProgress, updateBooksInGroupCollectionSuccess, updateBooksInGroupCollectionFailure],
            name: 'updateBooksInGroupCollection',
            dispatch,
            getState
        })
    }
}